import React, { useState, useContext, useEffect } from 'react';
import {
    Container, Box, Typography, FormControl, Button,
    TextField, Stack, Alert, Checkbox, FormGroup, FormControlLabel, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../../loginContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': { color: 'black' },
    '& .MuiInput-underline:after': { borderBottomColor: 'black' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'black' },
        '&:hover fieldset': { borderColor: 'black' },
        '&.Mui-focused fieldset': { borderColor: '#000000' },
    },
});

const SimpleDialog = ({ onClose, open }) => {
    const { isAuthenticated, login } = useContext(AuthContext);
    const [datax, setInputs] = useState({ username: "", password: "" });
    const [err, setErr] = useState(null);
    const [isAuthInitialized, setAuthInitialized] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(datax);
            setAuthInitialized(true);
            navigate("/Plataformas");
            toast.info("Elija la plataforma", {
                width: "350px",
                position: "bottom-left",
                autoClose: 3000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        } catch (err) {
            setErr(err.response.data.message);
        }
    };

    useEffect(() => {
        if (isAuthenticated && isAuthInitialized) navigate("/Plataformas");
    }, [isAuthenticated, isAuthInitialized, navigate]);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(show => !show);

    return (
        <Dialog onClose={onClose} open={open} fullWidth PaperProps={{ sx: { borderRadius: "8px", width: "430px" } }}>
            <Container component="main">
                <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'column', marginBottom: 5 }}>
                    <Typography variant="h5" sx={{ marginBottom: "1rem" }}>Inicio de sesión</Typography>
                    <Box component="form">
                        <FormControl fullWidth>
                            <CssTextField onChange={handleChange} name="usuario" label="Usuario" margin="normal" type="text" autoComplete="off" />
                        </FormControl>
                        <FormControl fullWidth>
                            <CssTextField label="Contraseña" margin="dense" onChange={handleChange} name="clave" type={showPassword ? 'text' : 'password'} />
                        </FormControl>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onClick={handleClickShowPassword} />} label="Mostrar contraseña" />
                        </FormGroup>
                        {err && (
                            <Stack sx={{ width: '100%', marginTop: "0.9rem" }} spacing={2}>
                                <Alert severity="warning">{err}</Alert>
                            </Stack>
                        )}
                        <Button variant="contained" sx={{ mt: 3, mb: 2 }} fullWidth onClick={handleLogin}>Iniciar Sesión</Button>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
};

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default SimpleDialog;
