import React from 'react'
import "./Principio_valores.css";
import { Typography } from '@mui/material';

import HandshakeIcon from '@mui/icons-material/Handshake';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import LockPersonIcon from '@mui/icons-material/LockPerson';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Groups3Icon from '@mui/icons-material/Groups3';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WavingHandIcon from '@mui/icons-material/WavingHand';

export const PrincipioValores = () => {

    const cartas = [
        { icono: <HandshakeIcon style={{ fontSize: '3rem' }} />, letra: 'R', titulo: 'Respeto', contenido: 'Dignidad, virtudes, diferencias y limitaciones, reconociendo autonomía y autoridad.' },
        { icono: <Diversity3Icon style={{ fontSize: '3rem' }} />, letra: 'I', titulo: 'Integridad', contenido: 'Todos somos parte de la misma organización y unimos esfuerzos para mantener el logro de los objetivos.' },
        { icono: <VisibilityIcon style={{ fontSize: '3rem' }} />, letra: 'T', titulo: 'Transparencia', contenido: 'Prudencia, sinceridad, coherencia y comportamientos dignos de confianza.' },
        { icono: <WbSunnyIcon style={{ fontSize: '3rem' }} />, letra: 'A', titulo: 'Armonía', contenido: 'Voluntad de prevenir conflictos ante eventuales desavenencias, diferencias y/o divergencias de criterios.' },
        { icono: <LockPersonIcon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Confidencialidad', contenido: 'Manejar absoluta y estricta reserva profesional para no revelar información restringida y privada de la organización de manera indefinida.' },
    ];

    const cartasDos = [
        { icono: <LightbulbIcon style={{ fontSize: '3rem' }} />, letra: 'I', titulo: 'Innovación', contenido: 'Generación de conocimiento, y perfeccionamiento de procesos y productos, con el aporte de desarrollos tecnológicos, trascendiendo fronteras.' },
        { icono: <Groups3Icon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Confianza', contenido: 'Generación de ambientes, productos y resultados seguros, con experiencias de desarrollo multidimensional.' },
        { icono: <PsychologyIcon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Coherencia', contenido: 'Desempeño Actuar alineados a las políticas y estándares promulgados por la organización.' },
        { icono: <VolunteerActivismIcon style={{ fontSize: '3rem' }} />, letra: 'B', titulo: 'Bienestar', contenido: 'Asegurar satisfacción integral a todos los grupos de interés.' },
        { icono: <WavingHandIcon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Colaboración', contenido: 'Servir y trabajar por el desarrollo sostenible desde nuestra participación en el mercado.' },
    ];

    return (
        <>
            <section id="pricing" class="ud-pricing">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ud-section-title mx-auto text-center">
                                <h2>Valores Corportativos</h2>
                            </div>
                        </div>
                    </div>

                    <span style={{ fontWeight: "600", fontSize: "18px", color: "#3056d3", marginBottom: "10px" }}>
                        Principios
                    </span>

                    <div className="d-flex flex-wrap justify-content-center" style={{ width: "100%", marginTop: "1em" }}>
                        {cartas.map((carta, index) => (
                            <div
                                key={index}
                                className="mb-4 d-flex justify-content-center mobile-full-width"
                                style={{ width: "20%", minWidth: "200px" }}
                            >
                                <div className="flip-container" style={{ padding: "1rem", borderRadius: "15px", }}>
                                    <div className="card-flip">
                                        <div className="front" style={{ borderRadius: "15px", padding: "1.5rem", backgroundColor: "#2E3B87", color: "#ffffff" }}>
                                            <span>
                                                {carta.icono}
                                                <Typography variant="h3" style={{ marginTop: '0.5rem', color: "white" }}>{carta.letra}</Typography>
                                            </span>
                                        </div>
                                        <div className="back" style={{ borderRadius: "15px", padding: "1.5rem", backgroundColor: "#2E3B87", color: "#ffffff" }}>
                                            <span>
                                                <Typography variant="h5" style={{ fontSize: '24px', fontWeight: 'bold', color: "white" }}>{carta.titulo}</Typography>
                                                <Typography variant="body1" style={{ fontSize: '15px', padding: '0.5rem', color: "white" }}>{carta.contenido}</Typography>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <span style={{ fontWeight: "600", fontSize: "18px", color: "#3056d3", marginBottom: "10px" }}>
                        Valores
                    </span>

                    <div className="d-flex flex-wrap justify-content-center" style={{ width: "100%", marginTop: "1em" }}>
                        {cartasDos.map((carta, index) => (
                            <div
                                key={index}
                                className="mb-4 d-flex justify-content-center mobile-full-width"
                                style={{ width: "20%", minWidth: "200px" }}
                            >
                                <div className="flip-container" style={{ padding: "1rem", borderRadius: "15px", }}>
                                    <div className="card-flip">
                                        <div className="front" style={{ borderRadius: "15px", padding: "1.5rem", backgroundColor: "#2E3B87", color: "#ffffff" }}>
                                            <span>
                                                {carta.icono}
                                                <Typography variant="h3" style={{ marginTop: '0.5rem', color: "white" }}>{carta.letra}</Typography>
                                            </span>
                                        </div>
                                        <div className="back" style={{ borderRadius: "15px", padding: "1.5rem", backgroundColor: "#2E3B87", color: "#ffffff" }}>
                                            <span>
                                                <Typography variant="h5" style={{ fontSize: '24px', fontWeight: 'bold', color: "white" }}>{carta.titulo}</Typography>
                                                <Typography variant="body1" style={{ fontSize: '15px', padding: '0.5rem', color: "white" }}>{carta.contenido}</Typography>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}


