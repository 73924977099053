
import React, { useEffect, useState, Suspense, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Box, Breadcrumbs, Grid, Paper, Stack, Typography, IconButton, Fab, CircularProgress, Divider, Autocomplete, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import newRequest from '../../../utils/newRequest';
import 'react-toastify/dist/ReactToastify.css';
import VisibilityIcon from '@mui/icons-material/Visibility';

import VentasLinea from "./VentasLinea/VentasLinea";
import VentasProductos from "./VentasProducto/VentasProductos";
import VentasVendedor from "./VentasVendedor/VentasVendedor";
import VentasClientes from "./VentasClientes/VentasClientes";
import VentasPorZona from "./VentasPorZona/VentasPorZona";
import VentasTercero from "./VentasTercero/VentasTercero";
import VentasDispostivosM from "./VentasDispostivosM/VentasDispostivosM";



const currentYear = new Date().getFullYear(); // Obtiene el año actual

const CustomAutocomplete = ({ id, name, label, options, getOptionLabel, selectedValue, setSelectedValue }) => (
    <Grid container item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
        <Autocomplete
            id={id}
            name={name}
            autoComplete={false}
            options={options}
            getOptionLabel={getOptionLabel}
            fullWidth
            sx={{ marginBottom: "5px", marginRight: "5px" }}
            onChange={(event, newValue) => setSelectedValue(newValue || '')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    // fullWidth
                    label={label}
                    size="small"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        style: { fontSize: 14, width: "100%", marginBottom: 4 },
                    }}
                />
            )}
        />
    </Grid>
);

const LazyLoadComponent = ({ component: Component, ...props }) => (
    <Suspense fallback={<CircularProgress />}>
        <Component {...props} />
    </Suspense>
);


export const Ventas = React.memo(() => {
    const navigate = useNavigate();

    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectCliente, setSelectCliente] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState(null);
    const [selectLinea, setSelectLinea] = useState([]);
    const [selectedLineas, setSelectedLineas] = useState(null);
    const [selectVendedor, setSelectVendedor] = useState([]);
    const [selectedVendedor, setSelectedVendedor] = useState(null);
    const [selectTercero, setSelectTercero] = useState([]);
    const [selectedTercero, setSelectedTercero] = useState(null);
    const [selectZona, setSelectZona] = useState([]);
    const [selectedZona, setSelectedZona] = useState(null);

    const añoInicial = (year) => {
        // estadoFechasetVisual(true);
        if (year <= currentYear) {
            setAñoInical(year);
        } else {
            toast.warn("El año inicial no puede ser mayor al año final", {
                position: "bottom-center",
                autoClose: 5000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const fetchData = async () => {
        try {
            const [resAno, resProductos, resCliente, resLinea, resVendedor, resTercero, resZona] = await Promise.all([
                newRequest.get('/api/GerenciaVisual/profundidadSelectAno'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasArticulosSelect'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialOpcionesLinea'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect'),
                newRequest.get('/api/GerenciaVisual/profundidadSelectZona'),
            ]);
            setAñoSelect(resAno.data.length ? resAno.data.reverse() : []);
            setSelectProduct(resProductos.data.length ? resProductos.data : []);
            setSelectCliente(resCliente.data.length ? resCliente.data : []);
            setSelectLinea(resLinea.data.length ? resLinea.data : []);
            setSelectVendedor(resVendedor.data.length ? resVendedor.data : []);
            setSelectTercero(resTercero.data.length ? resTercero.data : []);
            setSelectZona(resZona.data.length ? resZona.data : []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }

    const ventasComponents = [
        { id: 'ventasProducto', Component: VentasLinea },
        { id: 'ventasPorZona', Component: VentasPorZona },
        { id: 'ventasProductos', Component: VentasProductos },
        { id: 'ventasDispositivosM', Component: VentasDispostivosM },
        { id: 'ventasVendedor', Component: VentasVendedor },
        { id: 'ventasClientes', Component: VentasClientes },
        { id: 'ventasTercero', Component: VentasTercero }
    ];

    const dividerStyles = { marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 };

    const RenderVentasComponents = () => (
        <Suspense fallback={<CircularProgress />}>
            {ventasComponents.map(({ id, Component }) => (
                <React.Fragment key={id}>
                    <LazyLoadComponent
                        component={Component}
                        selectAñoIncial={selectAñoIncial}
                        selectedTercero={selectedTercero}
                        selectedCliente={selectedCliente}
                        selectedZona={selectedZona}
                        selectedProduct={selectedProduct}
                        selectedLineas={selectedLineas}
                        selectedVendedor={selectedVendedor}
                    />
                    <Divider sx={dividerStyles} />
                </React.Fragment>
            ))}
        </Suspense>
    );


    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <Grid item xs={12} sm={6} md={12} >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between", // Espacio entre los elementos
                                    alignItems: "center", // Alinea verticalmente los elementos en el centro
                                    marginBottom: "1em",
                                }}
                            >
                                <Fab onClick={handleBack} style={{
                                    background: "white",
                                    width: "45px", height: "45px", borderRadius: "13px"
                                }}>
                                    <ChevronLeftIcon />
                                </Fab>

                                <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                    <Typography color="text.primary">Gerencia visual</Typography>
                                    <Typography color="text.primary">Ventas</Typography>
                                </Breadcrumbs>
                            </Box>
                        </Box>
                    </Grid>

                    <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "13px",
                            position: "sticky",
                            top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                            zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                            boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                            transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                        }}
                    >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                            <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>

                                <Grid item xs={12} sm={2} md={2} lg={4} xl={6} sx={{ display: "flex" }}>
                                    <Stack direction="column" alignItems="center" spacing={0.5}>
                                        {añoSelect.map((año) => (
                                            <div key={año.PerMesano}>
                                                <ToggleButtonGroup
                                                    color="primary" value={selectAñoIncial}
                                                    exclusive onClick={() => añoInicial(año.PerMesano)}
                                                    sx={{ height: "30px" }}
                                                >
                                                    <ToggleButton key={año.PerMesano}
                                                        // style={{
                                                        //     backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                        //     color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                        // }}
                                                        value={año.PerMesano}>
                                                        {año.PerMesano}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        ))}
                                    </Stack>
                                </Grid>

                                {/* Aqui va los select */}
                                <Grid item xs={7} sm={8} md={8} lg={7} xl={6} >
                                    <div style={{ display: "flex" }}>

                                        <CustomAutocomplete
                                            id="cliente"
                                            name="Articulo"
                                            label="Tercero"
                                            options={selectTercero}
                                            getOptionLabel={(option) => option.tercero}
                                            selectedValue={selectedTercero}
                                            setSelectedValue={(value) => setSelectedTercero(value?.nitSec || '')}
                                        />

                                        <CustomAutocomplete
                                            id="cliente"
                                            name="Articulo"
                                            label="Clientes"
                                            options={selectCliente}
                                            getOptionLabel={(option) => option.cliente}
                                            selectedValue={selectedCliente}
                                            setSelectedValue={(value) => setSelectedCliente(value?.NitSec || '')}
                                        />

                                        <CustomAutocomplete
                                            id="zona"
                                            name="zona"
                                            label="Zona"
                                            options={selectZona}
                                            getOptionLabel={(option) => option.ZonNom}
                                            selectedValue={selectedZona}
                                            setSelectedValue={(value) => setSelectedZona(value?.ZonCod || '')}
                                        />
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <CustomAutocomplete
                                            id="cliente"
                                            name="Articulo"
                                            label="Producto"
                                            options={selectProduct}
                                            getOptionLabel={(option) => option.producto}
                                            selectedValue={selectedProduct}
                                            setSelectedValue={(value) => setSelectedProduct(value?.ArtSec || '')}
                                        />

                                        <CustomAutocomplete
                                            id="cliente"
                                            name="Articulo"
                                            label="Linea"
                                            options={selectLinea}
                                            getOptionLabel={(option) => option.InvGruNom}
                                            selectedValue={selectedLineas}
                                            setSelectedValue={(value) => setSelectedLineas(value?.invGruCod || '')}
                                        />

                                        <CustomAutocomplete
                                            id="cliente"
                                            name="Articulo"
                                            label="Vendedor"
                                            options={selectVendedor}
                                            getOptionLabel={(option) => option.VenNom}
                                            selectedValue={selectedVendedor}
                                            setSelectedValue={(value) => setSelectedVendedor(value?.VenCod || '')}
                                        />
                                        {/* </Grid> */}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>

                    <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 2 }}>
                        <RenderVentasComponents />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
})