import React, { useCallback, useEffect, useState } from 'react'
import newRequest from '../../../../utils/newRequest';
import TablaAcumuladoPyG from './TablaAcumuladoPyG';

export const AcumualadoPyG = ({ selectedMesInicial, selectedMesFinal, selectAñoIncial }) => {

    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(true);


    const getPyGData = useCallback(async () => {
        try {
            setloading(true); // Mostrar indicador de carga
            const [response] = await Promise.all([
                newRequest.post('/api/Financiera/financieraRoutes/pucAcumulado', {
                    mesInicial: selectedMesInicial,
                    mesFinal: selectedMesFinal,
                    añoSeleccionado: selectAñoIncial
                }),
            ]);
            setdata(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setloading(false); // Ocultar indicador de carga después de la actualización
        }
    }, [selectedMesFinal, selectedMesInicial, selectAñoIncial]);

    useEffect(() => {
        getPyGData();
    }, [getPyGData]);


    return (
        <div>
            <TablaAcumuladoPyG data={data}
                selectedMesInicial={selectedMesInicial}
                selectedMesFinal={selectedMesFinal}
            />
        </div>
    )
}
