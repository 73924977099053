import { useState, useEffect } from 'react';
import newRequest from "../../../../utils/newRequest";

const useProcesos = () => {
    const [procesos, setProcesos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProcesos = async () => {
            try {
                setLoading(true);
                const res = await newRequest.get('api/Calidad/InsertarIndicadores/ProcesosIndicador');
                setProcesos(res.data);
            } catch (error) {
                console.log("error en ObtenerProcesos ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProcesos();
    }, []);

    return { procesos, loading };
};

export default useProcesos;