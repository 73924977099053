import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts'

const GraficaEdades = ({ datos }) => {
    
    const [datosTorta, setDatosTorta] = useState(null);

    useEffect(() => {
        // Suma los valores correspondientes de cada objeto
        const sumaValores = datos.reduce((acumulador, obj) => {
            Object.entries(obj)
                .filter(([key]) => key.includes('-'))
                .forEach(([key, value]) => {
                    const categoria = key.replace('-', '_'); // Reemplaza '-' con '_' para evitar problemas con las propiedades
                    acumulador[categoria] = (acumulador[categoria] || 0) + (value || 0);
                });

            // Asegúrate de agregar la categoría "181 o mas" incluso si no está presente en el objeto
            acumulador['181_o_mas'] = (acumulador['181_o_mas'] || 0) + (obj['181 o mas'] || 0);

            return acumulador;
        }, {});

        setDatosTorta(sumaValores);
    }, [datos]);

    useEffect(() => {
        if (!datosTorta) {
            return;
        }

        // Inicializa el gráfico en el elemento con ID "nightingale-chart"
        const chart = echarts.init(document.getElementById('nightingale-chart'));

        // Configuración del gráfico
        const option = {
            title: {
                text: 'Total de Cartera por edades',
                subtext: 'Dias',
                left: 'center',
            },
            tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b} : {c} ({d}%)',
                formatter: function (params) {
                    const nombreCategoria = params.name.replace('_', '-'); // Reemplaza '_' con '-' para mostrar correctamente en el tooltip
                    const valorFormateado = new Intl.NumberFormat('es-CO').format(params.value);
                    return `${nombreCategoria}: ${valorFormateado} (${params.percent}%)`;
                },

            },
            legend: {
                top: 'bottom',
            },
            // toolbox: {
            //     show: true,
            //     feature: {
            //         mark: { show: true },
            //         dataView: { show: true, readOnly: false },
            //         restore: { show: true },
            //         saveAsImage: { show: true },
            //     },
            // },
            series: [
                {
                    name: 'Cartera Total',
                    type: 'pie',
                    radius: '50%',
                    data: Object.entries(datosTorta).map(([categoria, total]) => ({
                        value: total,
                        name: categoria.replace('_', '-'), // Reemplaza '_' con '-' para mostrar correctamente en la leyenda
                    })),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };

        // Configura la opción en el gráfico
        chart.setOption(option);

        const resizeHandler = () => {
            chart.resize();
        };

        window.addEventListener('resize', resizeHandler);

        // Limpia el gráfico cuando el componente se desmonta
        return () => {
            window.addEventListener('resize', resizeHandler);

            chart.dispose();
        };
    }, [datosTorta, datos]);


    return (
        <div>
            <div id="nightingale-chart" style={{ width: '100%', height: '32.4rem' }}></div>
        </div>
    )
}

export default GraficaEdades