import React, { useCallback, useEffect, useState } from 'react'
import PorRecaudoTabla from './PorRecaudoTabla'
import { Grid } from '@mui/material'
import PorRecaudoGrafica from './PorRecaudoGrafica'
import newRequest from '../../../../utils/newRequest'

const PorRecaudo = ({ selectedMesInicial, selectAñoIncial, selectedMesFinal }) => {

    const [recaudo, setRecaudo] = useState([]);

    const peticionRecaudo = useCallback(async () => {
        try {
            const res = await newRequest.post("/api/GerenciaVisual/Recaudo/GerenciaVisualRecaudoAsesor", {
                mesInicial: selectedMesInicial,
                añoInicial: selectAñoIncial,
                mesFinal: selectedMesFinal
            });
            const data = res.data;
            if (data.length > 0) {
                setRecaudo(res.data);
            } else {
                setRecaudo([]);
            }
        } catch (error) {
            console.log("Error", error);
        }
    }, [selectedMesInicial, selectAñoIncial, selectedMesFinal]);

    useEffect(() => {
        peticionRecaudo();
    }, [peticionRecaudo]);


    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ marginBottom: "25px", }}>
                    <PorRecaudoGrafica recaudo={recaudo} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={7} xl={7} sx={{ marginBottom: "25px", }}>
                    <PorRecaudoTabla recaudo={recaudo} />
                </Grid>
            </Grid>

        </div>
    )
}

export default PorRecaudo