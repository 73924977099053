import React, { useEffect, useState } from 'react'
import {
    FormControlLabel,
    Radio, FormControl, RadioGroup
} from '@mui/material'
const OpcionesTabla = ({ onChangeCambioTabla, cambioTablaFromParent  }) => {

    const [cambioTabla, setCambioTabla] = useState(true);

    const handleSwitchChange = (event) => {
        const newCambioGrafica = event.target.value === 'true';
        setCambioTabla(newCambioGrafica);
        onChangeCambioTabla(newCambioGrafica);
    };

    useEffect(() => {
        setCambioTabla(cambioTablaFromParent);
    }, [cambioTablaFromParent]);

    return (
        <div>
            <FormControl sx={{ marginLeft: 5 }}>
                <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    value={cambioTabla.toString()} // Convertir a string
                    defaultValue="top"
                    onChange={handleSwitchChange}
                >
                    <FormControlLabel
                        // value={true}
                        value="true" // Cambiar a strings
                        // checked={cambioTabla === true}
                        // onChange={handleSwitchChange}
                        control={<Radio />}
                        label="Valores"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        // value={false}
                        value="false" // Cambiar a strings
                        // checked={cambioTabla === false}
                        control={<Radio />}
                        // onChange={handleSwitchChange}
                        label="Cantidad"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default OpcionesTabla