import React, { useContext, useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie';
import routes from './routes';
import { AuthContext } from './loginContext';
import './app.css';
import { ToastContainer } from 'react-toastify';

// Importa directamente todos los componentes sin lazy loading
import { Plataformas } from "./PaginaWeb/paginas/Plataformas/Plataformas.jsx";
import { FormularioQuejas } from "./PaginaWeb/paginas/FormularioQuejas/FormularioQuejas.jsx";
import { Eventos } from './PaginaWeb/paginas/Eventos/Eventos.jsx';
// import MenuLateral from './components/MenuLateral.jsx';
import NavbarInicio from './components/NavbarInicio/NavbarInicio.jsx';
import Inicio from './PaginaWeb/paginas/inicio/Inicio.jsx';
import QuienesSomos from './PaginaWeb/paginas/QuienesSomos/QuienesSomos.jsx';
import Portafolio from './PaginaWeb/paginas/Portafolio/Portafolio.jsx';
import Login from './Login.jsx';
import AppbarMenu from './components/AppbarMenu.jsx';

function App() {
  const { currentUser, isAuthenticated, logout } = useContext(AuthContext);
  // const [validacion, setValidacion] = useState(null);

  const Layout = () => {
    return (
      <Box>
        {isAuthenticated && (
          // <MenuLateral open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
          <AppbarMenu />
        )}
      </Box>
    );
  };

  const InicioLayout = () => (
    <div>
      <NavbarInicio />
      <Outlet />
    </div>
  );

  const ProtectedRoute = ({ children }) => {
    if (!currentUser && !isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    return children ? children : <Outlet />;
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      const GetValidar = async () => {
        try {
          // const validar = await newRequest.get('/api/login/validar');
          // setValidacion(validar.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log("Tu sesión expiró. Vuelve a iniciar sesión.");
            logout();
            Cookies.remove("token");
            Cookies.remove("session_id");
            localStorage.removeItem("token");
            localStorage.removeItem("session_id");
            localStorage.removeItem('selectedIndex');
          } else {
            console.error("Error al validar token:", error);
          }
        }
      };

      GetValidar();
      const interval = setInterval(() => {
        GetValidar();
      }, 4 * 60 * 60 * 1000); // 4 horas en milisegundos

      return () => clearInterval(interval);
    }
  }, [isAuthenticated, logout]);

  const router = createBrowserRouter([
    {
      path: '/Farmanet',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: routes.map((route, index) => ({
        index: index,
        path: route.path,
        element: route.element,
      })),
    },
    {
      path: '/',
      element: <InicioLayout />,
      children: [
        { path: '/', element: <Inicio /> },
        { path: 'QuienesSomos', element: <QuienesSomos /> },
        { path: 'Eventos', element: <Eventos /> },
        { path: 'Portafolio', element: <Portafolio /> },
        { path: 'Contacto', element: <FormularioQuejas /> },
        {
          path: "Plataformas",
          element: isAuthenticated ? <Plataformas /> : <Navigate to="/" />
        },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
  ]);

  return (
    <Box>
      <ToastContainer />
      {/* Cargamos el router sin Suspense */}
      <RouterProvider router={router} />
    </Box>
  );
}

export default App;
