import React, { useCallback, useEffect, useState } from 'react'
import TablaPatrimonio from './TablaPatrimonio'
import newRequest from '../../../../utils/newRequest';

const PorPatrimonio = ({ selectAñoIncial, selectedMesInicial }) => {

    const [data, setdata] = useState([]);

    // Obtenemos los datos
    const getAyPData = useCallback(async () => {
        try {
            const [responseAyP] = await Promise.all([
                newRequest.post('/api/Financiera/financieraRoutes/Patrimonio', {
                    año: selectAñoIncial,
                    mesInicial: selectedMesInicial,
                }),
            ]);
            setdata(Array.isArray(responseAyP.data) ? responseAyP.data : []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [selectAñoIncial, selectedMesInicial]);

    useEffect(() => {
        getAyPData();
    }, [getAyPData]);

    return (
        <div>
            <TablaPatrimonio data={data} />
        </div>
    )
}

export default PorPatrimonio