import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, LinearProgress } from '@mui/material';


import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import TablaTipoProceso from './TablaTipoProceso';
import ModalTipoProceso from './ModalTipoProceso';
import newRequest from '../../../../utils/newRequest';

const TipoProcesos = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [openDrawer, setOpenDrawer] = useState(false); // Estado para abrir y cerrar el modal
    const [tipoProcesos, setTipoProcesos] = useState([])

    const [selectedID, setSelectedID] = useState(""); // Estado para almacenar el ID seleccionado

    // Función para manejar el ID seleccionado
    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };

    const ObtenerTipoProcesos = async () => {
        try {
            const res = await newRequest.get('api/Calidad/TipoProceso/ObtenerTipoProcesos');
            if (res.status !== 200) {
                throw new Error("Error en el servidor")
            } else {
                setTipoProcesos(res.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        ObtenerTipoProcesos();
    }, [])

    const list = (
        <Box sx={{ width: 550 }} >
            <List>
                <ModalTipoProceso tipoProcesos={tipoProcesos} setOpenDrawer={setOpenDrawer}
                    openDrawer={openDrawer}
                    obtenerTipoProcesos={ObtenerTipoProcesos} selectedID={selectedID}
                />
            </List>
        </Box>
    );

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    {isLoading ? ( // Muestra el loader si isLoading es true
                        <LinearProgress />
                    ) : (
                        <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px" }}>
                            <Box sx={{ marginTop: 2, flexDirection: 'column', alignItems: 'center' }} >
                                <TablaTipoProceso setOpenDrawer={setOpenDrawer} tipoProcesos={tipoProcesos}
                                    onIDSelected={handleIDSelected} ObtenerTipoProcesos={ObtenerTipoProcesos}
                                />
                            </Box>
                        </Paper>
                    )}
                </Grid>
                <SwipeableDrawer
                    anchor="right"
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onOpen={() => setOpenDrawer(true)}
                >
                    {list}
                </SwipeableDrawer>
            </Grid>
        </div>
    )
}

export default TipoProcesos