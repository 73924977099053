import React, { useCallback, useEffect, useState } from "react";
import SeguimientoAseTabla from "./SeguimientoAseTabla";
import newRequest from "../../../../utils/newRequest";
import SeguimientoAseGrafico from "./SeguimientoAseGrafico";
import { Grid } from "@mui/material";

const SeguimientoAse = ({ selectedVendedor }) => {

    const [seguimiento, setSeguimiento] = useState([]);
    const [mostrarGrafico, setMostrarGrafico] = useState(false); // Estado para controlar la visibilidad del gráfico


    const peticionSeguimiento = useCallback(async () => {
        try {
            const res = await newRequest.post("/api/GerenciaVisual/Cartera/SeguimCarteraAsesor", {
                vendedor: selectedVendedor
            });
            setSeguimiento(res.data);
        } catch (error) {
            console.log("Error", error);
        }
    }, [selectedVendedor]);

    useEffect(() => {
        peticionSeguimiento();
    }, [peticionSeguimiento, mostrarGrafico]);


    const handleMostrarGrafico = () => {
        setMostrarGrafico(true); // Actualizar el estado para mostrar el gráfico

        if (mostrarGrafico === true) {
            setMostrarGrafico(false); // Actualizar el estado para mostrar el gráfico
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={mostrarGrafico ? 6 : 12} sx={{ display: mostrarGrafico ? "block" : "none" }}>
                    <SeguimientoAseGrafico seguimiento={seguimiento} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={mostrarGrafico ? 6 : 12} sx={{ marginBottom: "25px", }}>
                    <SeguimientoAseTabla seguimiento={seguimiento} mostrarGrafico={mostrarGrafico}
                        onMostrarGrafico={handleMostrarGrafico}
                    />
                </Grid>
            </Grid>
        </div>
    );

}

export default SeguimientoAse