// import React from 'react'
// import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from "recharts";
// import { Box } from '@mui/material';

// const GraficoPerfil = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {

//     const nombrePerfil = `perfil`
//     const ventasKey = `Ventas${selectAñoIncial}`;
//     const costoKey = `Costo${selectAñoIncial}`;
//     const presupuestoKey = `Presupuesto${selectAñoIncial}`;

//     const data = rows.map((row, index) => {
//         const perfil = row[nombrePerfil];
//         const ventas = row[ventasKey];
//         const costo = row[costoKey];
//         const presupuesto = row[presupuestoKey];
//         let tendencia = 0;

//         if (index >= 1) {
//             const ventasAnterior = +rows[index - 1][ventasKey];
//             const presupuestoAnterior = +rows[index - 1][presupuestoKey];
//             const diferencia = (presupuestoAnterior - ventasAnterior);
//             tendencia = diferencia + presupuesto;
//         } else {
//             tendencia = presupuesto;
//         }

//         return {
//             name: perfil,
//             Ventas: ventas,
//             Costo: costo,
//             Presupuesto: presupuesto,
//             Tendencia: tendencia
//         };

//     });

//     const COLORS = ['#59CE8F', '#0A6EBD', '#FF6D60'];

//     return (
//         <div>
//             <Box sx={{ marginTop: "-20px", width: "100%", height: 380 }}>
//                 <ResponsiveContainer width="100%" height={380}>
//                     <PieChart width={600} height={400}>
//                         <Pie
//                             dataKey="Ventas"
//                             isAnimationActive={true}
//                             data={data}
//                             outerRadius={110}
//                             label={({ name, value }) => `${value.toLocaleString()}`} // Aplicar .toLocaleString() al valor en el label
//                         >
//                             {data.map((entry, index) => (
//                                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                             ))}
//                         </Pie>
//                         {/* <Tooltip /> */}
//                         <Tooltip formatter={(value) => value.toLocaleString()} />
//                     </PieChart>
//                 </ResponsiveContainer>
//             </Box>
//         </div>
//     )
// }

// export default GraficoPerfil


import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';

const GraficoPerfil = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {
    const chartRef = useRef(null);

    // Función para formatear números
    const formatNumber = (value) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);



    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const nombrePerfil = `perfil`;
        const ventasKey = `Ventas${selectAñoIncial}`;
        const costoKey = `Costo${selectAñoIncial}`;
        const presupuestoKey = `Presupuesto${selectAñoIncial}`;

        const data = rows.map((row, index) => {
            const perfil = row[nombrePerfil];
            const ventas = row[ventasKey];
            const costo = row[costoKey];
            const presupuesto = row[presupuestoKey];
            let tendencia = 0;

            if (index >= 1) {
                const ventasAnterior = +rows[index - 1][ventasKey];
                const presupuestoAnterior = +rows[index - 1][presupuestoKey];
                const diferencia = (presupuestoAnterior - ventasAnterior);
                tendencia = diferencia + presupuesto;
            } else {
                tendencia = presupuesto;
            }

            return {
                name: perfil,
                value: ventas,
                Costo: costo,
                Presupuesto: presupuesto,
                Tendencia: tendencia
            };
        });

        const option = {
            tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: {c} ({d}%)',
                formatter: (params) => {
                    return `${params.seriesName} <br/>${params.name}: $${formatNumber(params.value)} (${params.percent}%)`;
                },
                confine: true, // Mantener el tooltip dentro del contenedor

            },
            legend: {
                top: 'top',
                left: 'center',
                data: data.map(item => item.name)
            },
            series: [
                {
                    name: 'Ventas',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    // radius: '70%',
                    avoidLabelOverlap: true,
                    padAngle: 5,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff', // Color blanco para el borde
                        borderWidth: 5, // Ancho del borde para crear el efecto de separación
                    },
                    // label: {
                    //     formatter: '{b}: ${c}'
                    // },
                    label: {
                        formatter: (params) => `${params.name}: $${formatNumber(params.value)}`
                    },
                    // emphasis: {
                    //     itemStyle: {
                    //         shadowBlur: 10,
                    //         shadowOffsetX: 0,
                    //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                    //     }
                    // },
                    emphasis: {
                        itemStyle: {
                            show: true
                        }
                    },
                    data: data
                }
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
            window.removeEventListener('resize', handleResize);
        };
    }, [rows, selectAñoIncial]);

    return (
        <div>
            <Box sx={{ width: "100%", height: "400px", marginTop: "2rem" }} ref={chartRef}></Box>
        </div>
    );
};

export default GraficoPerfil;
