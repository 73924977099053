import React, { useEffect, useState } from 'react'
import ProfundidadAlPortafolioTabla from './ProfundidadAlPortafolioTabla';
import { CircularProgress } from '@mui/material';
import newRequest from '../../../../utils/newRequest';

const ProfunidadPor = ({ datosParametros, fechaInicial, fechaFinal, actualizarFechas, tipoValor }) => {
    const [muestraTabla, setMuestraTabla] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/profundidadTabla', {
                    ...datosParametros,
                    fechaInicial,
                    fechaFinal
                });
                // if (!res.data) {
                //     setMuestraTabla([])
                // }
                // setMuestraTabla(res.data);
                const data = res.data;
                if (data.length > 0) {
                    setMuestraTabla(res.data);
                } else {
                    setMuestraTabla([])
                }
            } catch (err) {
                // setError(err.message);
                console.error("Error al obtener los datos: ", err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [actualizarFechas, datosParametros, fechaInicial, fechaFinal, tipoValor]);

    return (
        <>
            {loading ?
                (
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }} >
                        <CircularProgress />
                    </div>
                ) : (
                    <ProfundidadAlPortafolioTabla
                        muestraTabla={muestraTabla}
                        actualizarFechas={actualizarFechas}
                        datosParametros={datosParametros}
                        fechaInicial={fechaInicial}
                        fechaFinal={fechaFinal}
                        tipoValor={tipoValor}
                    />
                )}
            {/* )} */}
        </>
    )
}

export default ProfunidadPor