import React from "react";
import { Typography } from '@mui/material';
// import emailjs from "@emailjs/browser";
import "./Footer.css";
// import { styled } from '@mui/material/styles';
// import video from "../../PaginaWeb/img/video/introFarma.mp4"
// import logoFarma from "../../PaginaWeb/img/logosiglasfarmacomercial.png"

const Footer = () => {
    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: 'black',
    //         // color: '#A0AAB4',
    //     },
    //     '& .MuiInput-underline:after': {
    //         // borderBottomColor: '#B2BAC2',
    //         borderBottomColor: 'black',
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             borderColor: 'black',
    //             // borderColor: '#E0E3E7',
    //         },
    //         '&:hover fieldset': {
    //             // borderColor: '#B2BAC2',
    //             borderColor: 'black',
    //         },
    //         '&.Mui-focused fieldset': {
    //             borderColor: 'white',
    //             // borderColor: '#6F7E8C',
    //         },
    //     },
    // });

    // const phoneNumber = '+573162908167'; // número de teléfono al que se enviará el mensaje
    // const message = '¡Hola!'; // mensaje que se enviará
    // const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // const sendEmail = (event) => {
    //     event.preventDefault();
    //     emailjs.sendForm(
    //         'service_mmugce9',
    //         'template_z6q79rh',
    //         event.target,
    //         'CC7QB8mUXlG9gg-0z'
    //     )
    //         .then(response => {
    //             event.target.reset();
    //             // Swal.fire({
    //             //     icon: 'success',
    //             //     title: 'Correo enviado correctamente',
    //             //     showConfirmButton: false,
    //             //     timer: 1500
    //             // })
    //         })
    //         .catch(error => console.log(error))
    // }

    return (

        <div>
            <section id="contact" className="ud-contact">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-7">
                            <div className="ud-contact-content-wrapper">
                                <div className="ud-section-title" >
                                    <span>Contactenos</span>
                                    <h2>Habla con nosotros</h2>
                                </div>
                                <div className="ud-contact-info-wrapper" >
                                    <div className="ud-single-info">
                                        <div className="ud-info-icon">
                                            <i className="lni lni-map-marker"></i>
                                        </div>
                                        <div className="ud-info-meta">
                                            <h5>Telfono</h5>
                                            <p>316 290 8167</p>
                                        </div>
                                    </div>
                                    <div className="ud-single-info">
                                        <div className="ud-info-icon">
                                            <i className="lni lni-envelope"></i>
                                        </div>
                                        <div className="ud-info-meta">
                                            <h5>Como podemos ayudarte?</h5>
                                            <p>comercial@laboratoriofarmacomercial.com</p>
                                        </div>
                                    </div>
                                    <div className="ud-single-info">
                                        <div className="ud-info-icon">
                                            <i className="lni lni-envelope"></i>
                                        </div>
                                        <div className="ud-info-meta">
                                            <h5>Trabaja con nosotros?</h5>
                                            <p>Vacantes</p>
                                        </div>
                                    </div>
                                    <div className="ud-single-info">
                                        <div className="ud-info-icon">
                                            <i className="lni lni-envelope"></i>
                                        </div>
                                        <div className="ud-info-meta">
                                            <p>Farmacovigilacia</p>
                                            <p>TecnoVigilacia</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div
                                className="ud-contact-form-wrapper wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <h3 className="ud-contact-form-title">Envíanos un mensaje</h3>
                                <form className="ud-contact-form">
                                    <div className="ud-form-group">
                                        <label for="fullName">Nombre Completo*</label>
                                        <input
                                            type="text"
                                            name="fullName"
                                            placeholder="Nombre Completo"
                                        />
                                    </div>
                                    <div className="ud-form-group">
                                        <label for="email">Email*</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="example@yourmail.com"
                                        />
                                    </div>
                                    <div className="ud-form-group">
                                        <label for="phone">Numero*</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder="Numero de contacto"
                                        />
                                    </div>
                                    <div className="ud-form-group">
                                        <label for="message">Mensaje*</label>
                                        <textarea
                                            name="message"
                                            rows="1"
                                            placeholder="Escribe tu mensaje aquí"
                                        ></textarea>
                                    </div>
                                    <div className="ud-form-group mb-0">
                                        <button type="submit" className="ud-main-btn">
                                            Enviar Mensaje
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div classNameName="col-12" style={{ margin: 0, padding: 0, overflow: "hidden" }}>
                {/* <video classNameName="myVideo" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                </video> */}

                <footer className="ud-footer wow fadeInUp" data-wow-delay=".15s">
                    <div className="ud-footer-bottom">
                        <div className="container">
                            <div className="row">
                                <Typography style={{ fontSize: "18px", margin: "auto", textAlign: "center", color: "black" }}>
                                    © 2024 todos los derechos reservados. Laboratorio Farma Comercial
                                </Typography>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            {/* <div classNameName="video-container">
                <video classNameName="myVideo" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                </video>
            </div> */}


            {/* <footer classNameName="footer-text">
                <Typography style={{ fontSize: "18px", textAlign: "center", color: "black" }}>
                    © 2024 todos los derechos reservados. Laboratorio Farma Comercial
                </Typography>
            </footer> */}


        </div>
    )
}

export default Footer