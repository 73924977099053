import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import newRequest from '../../../../utils/newRequest';

const useIndicadores = (selectedProceso, value) => {
    const [indicadores, setIndicadores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState(null);

    useEffect(() => {
        if (selectedProceso) {
            const fetchIndicadores = async () => {
                try {
                    setLoading(true);
                    const res = await newRequest.post(
                        '/api/Calidad/InsertarIndicadores/ObtenerIndicador',
                        {
                            proceso: selectedProceso,
                            año: value ? dayjs(value).year() : null,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    if (res.status === 204) {
                        setIndicadores([]);
                        setDatos(false);
                        toast.warn("Este indicador esta vacio", {
                            position: "bottom-center",
                            autoClose: 3000,
                            theme: "dark",
                            hideProgressBar: true,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        setIndicadores(res.data);
                        setDatos(true);
                    }
                } catch (error) {
                    console.log("error", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchIndicadores();
        }
    }, [selectedProceso, value]);

    return { indicadores, loading, datos };
};

export default useIndicadores;
