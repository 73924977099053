import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Grid, CircularProgress, Typography, Box } from '@mui/material';
import GraficoLinea from './GraficoLinea';
import TablaLinea from './TablaLinea';
import newRequest from '../../../../utils/newRequest';

const PorLinea = ({
    selectedMesFinal,
    selectedMesInicial,
    selectAñoIncial,
    selectedProduct,
    selectedCliente,
    selectedLineas,
    selectedVendedor,
    selectedTercero,
    checked,
    Excluir,
    selectedZona
}) => {
    const [rows, setRows] = useState([]); // Estado para almacenar las filas
    const [presupuestoTotal, setPresupuestoTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const converAñoAnt = useMemo(() => selectAñoIncial - 1, [selectAñoIncial]); // Memoizado para evitar recalcular
    const [noDataMessage, setNoDataMessage] = useState('');
    const [cambioTabla, setCambioTabla] = useState(true);

    const handleCambioTablaChange = useCallback((newCambioTabla) => {
        setCambioTabla(newCambioTabla);
    }, []);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true); // Mostrar el loader mientras se cargan los datos
            const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasLinea', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                CambioDato: cambioTabla,
                zona: selectedZona
            });

            const data = res.data;
            if (data.length > 0) {
                const rowsWithId = data.map((row, index) => ({ id: index + 1, ...row }));
                setRows(rowsWithId);
                setNoDataMessage('Ventas por línea');
            } else {
                setRows([]);
                setNoDataMessage('No hay datos disponibles');
            }
        } catch (error) {
            console.error('Error al obtener datos:', error);
        } finally {
            setLoading(false);
        }
    }, [
        converAñoAnt,
        selectAñoIncial,
        selectedMesInicial,
        selectedMesFinal,
        selectedProduct,
        selectedCliente,
        selectedLineas,
        selectedVendedor,
        selectedTercero,
        checked,
        Excluir,
        cambioTabla,
        selectedZona
    ]);

    useEffect(() => {
        const getPresupuestoAnual = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialTotalPresupuestoAnual', {
                    total: selectAñoIncial
                });
                setPresupuestoTotal(res.data);
            } catch (error) {
                console.error('Error al obtener el presupuesto anual:', error);
            } finally {
                setLoading(false);
            }
        };
        getPresupuestoAnual();
        fetchData();
    }, [fetchData, selectAñoIncial]);

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                {/* Gráfico de Línea */}
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: "25px" }}>
                    {loading ? (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="350px">
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Cargando grafica de líneas...</Typography>
                        </Box>
                    ) : (
                        <GraficoLinea
                            selectAñoIncial={selectAñoIncial}
                            selectedMesInicial={selectedMesInicial}
                            selectedMesFinal={selectedMesFinal}
                            rows={rows}
                        />
                    )}
                </Grid>

                {/* Tabla de Línea */}
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ marginBottom: "25px" }}>
                    {loading ? (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="350px">
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Cargando tabla de líneas...</Typography>
                        </Box>

                    ) : (
                        <TablaLinea
                            selectAñoIncial={selectAñoIncial}
                            selectedMesInicial={selectedMesInicial}
                            selectedMesFinal={selectedMesFinal}
                            rows={rows}
                            presupuestoTotal={presupuestoTotal}
                            noDataMessage={noDataMessage}
                            cambioTabla={cambioTabla}
                            onChangeCambioTabla={handleCambioTablaChange}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default PorLinea;
