import React, { useCallback, useMemo } from 'react'
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Box,
    TableHead
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';


export const TablaPyG = React.memo(({ ejecutarSp, data, costoVenta, otrosGastos }) => {

    const formatCurrency = useCallback((value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
        }).format(value);
    }, []);

    const calculateTotal = (rows, prefix) => {
        return rows
            .filter(row => row.puccod.startsWith(prefix))
            .reduce((sum, row) => sum + row.Total, 0);
    };

    const renderSection = (rows, label, prefix) => {
        let total = 0;
        const sectionRows = rows.filter(row => row.puccod.startsWith(prefix));

        const rowElements = sectionRows.map((row) => {
            total += row.Total;
            return (
                <TableRow key={row.puccod}>
                    <TableCell align='left'> {row.puccod}</TableCell>
                    <TableCell align="left">
                        <Typography noWrap width={"250px"} title={row.pucNom}>
                            {row.pucNom}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">{formatCurrency(row.Total)}</TableCell>
                </TableRow>
            );
        });

        return (
            <>
                <TableRow>
                    <TableCell align="left" colSpan={2} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                        {label}
                    </TableCell>
                    <TableCell align="right" colSpan={1} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                        {formatCurrency(total)}
                    </TableCell>
                </TableRow>
                {rowElements}
            </>
        );
    };

    const meses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const dia = new Date();
    const mesActual = dia.getMonth() + 1;
    const añoActual = dia.getFullYear();
    const nombreMes = mesActual ? meses[mesActual - 1] : ''; // Restamos 1 porque los índices del array empiezan en 0

    // Calcular totales
    const totalIngresos = useMemo(() => Math.abs(calculateTotal(data, "41")), [data]);
    const totalCostoVenta = useMemo(() => Math.abs(calculateTotal(costoVenta, "61")), [costoVenta]);

    // Calcular la Utilidad Bruta
    const utilidadBruta = totalIngresos - totalCostoVenta;

    // Calcular gastos de Administración y Ventas
    const totalAdministracion = useMemo(() => Math.abs(calculateTotal(otrosGastos, "51")), [otrosGastos]);
    const totalVentas = useMemo(() => Math.abs(calculateTotal(otrosGastos, "52")), [otrosGastos]);

    // Calcular la Utilidad Operacional
    const utilidadOperacional = utilidadBruta - totalAdministracion - totalVentas;

    // Calcular Ingresos y Gastos no Operacionales
    const totalGastosNoOperacionales = useMemo(() => Math.abs(calculateTotal(otrosGastos, "53")), [otrosGastos]);
    const totalIngresosNoOperacionales = useMemo(() => Math.abs(calculateTotal(otrosGastos, "42")), [otrosGastos]);

    // Calcular la Utilidad Neta
    const utilidadNeta = utilidadOperacional + totalIngresosNoOperacionales - totalGastosNoOperacionales;

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Combinar todas las secciones de la tabla en un solo array con la misma estructura que la tabla
        const combinedData = [
            { 'Categoría': 'Ingresos Operacionales', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.Total,
            })),
            { 'Categoría': 'Total Ingresos Operacionales', 'Código': '', 'Nombre': '', 'Total': totalIngresos },

            { 'Categoría': 'Costo de Ventas', 'Código': '', 'Nombre': '', 'Total': '' },
            ...costoVenta.map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.Total,
            })),
            { 'Categoría': 'Total Costo de Ventas', 'Código': '', 'Nombre': '', 'Total': totalCostoVenta },

            { 'Categoría': 'Utilidad Bruta', 'Código': '', 'Nombre': '', 'Total': utilidadBruta },

            { 'Categoría': 'Administración', 'Código': '', 'Nombre': '', 'Total': '' },
            ...otrosGastos.filter(row => row.puccod.startsWith("51")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.Total,
            })),
            { 'Categoría': 'Total Administración', 'Código': '', 'Nombre': '', 'Total': totalAdministracion },

            { 'Categoría': 'Ventas', 'Código': '', 'Nombre': '', 'Total': '' },
            ...otrosGastos.filter(row => row.puccod.startsWith("52")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.Total,
            })),
            { 'Categoría': 'Total Ventas', 'Código': '', 'Nombre': '', 'Total': totalVentas },

            { 'Categoría': 'Utilidad Operacional', 'Código': '', 'Nombre': '', 'Total': utilidadOperacional },

            { 'Categoría': 'Gastos no Operacionales', 'Código': '', 'Nombre': '', 'Total': '' },
            ...otrosGastos.filter(row => row.puccod.startsWith("53")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.Total,
            })),
            { 'Categoría': 'Total Gastos no Operacionales', 'Código': '', 'Nombre': '', 'Total': totalGastosNoOperacionales },

            { 'Categoría': 'Ingresos no Operacionales', 'Código': '', 'Nombre': '', 'Total': '' },
            ...otrosGastos.filter(row => row.puccod.startsWith("42")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.Total,
            })),
            { 'Categoría': 'Total Ingresos no Operacionales', 'Código': '', 'Nombre': '', 'Total': totalIngresosNoOperacionales },

            { 'Categoría': 'Utilidad Neta', 'Código': '', 'Nombre': '', 'Total': utilidadNeta }
        ];

        // Convertir el array combinado en una hoja de Excel
        const worksheet = XLSX.utils.json_to_sheet(combinedData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Informe Completo');

        // Guardar el archivo Excel
        XLSX.writeFile(workbook, `Informe_PyG_${nombreMes}_${añoActual}.xlsx`);
    };

    return (
        <div>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                <Typography variant='h6' sx={{ flexGrow: 1, }}>
                    {nombreMes} - {añoActual}
                </Typography>
                <Box sx={{ flexGrow: 0 }} >

                    <Tooltip title="Descargar tabla">
                        <IconButton onClick={exportToExcel}>
                            <DownloadIcon sx={{ width: "20px", height: "20px", }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Table size="small">

                <TableHead>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} >Cuenta</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }}>Descripción</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} align='right' >Valor</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {renderSection(data, "Ingresos Operacionales", "41")}
                    {renderSection(costoVenta, "Costo de Ventas", "61")}

                    {/* Mostrar la Utilidad Bruta */}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Utilidad Bruta
                                <Tooltip title={`Ingresos Operacionales MENOS Costo de Ventas`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1'
                        }}>
                            {formatCurrency(utilidadBruta)}
                        </TableCell>
                    </TableRow>

                    {renderSection(otrosGastos, "Administración", "51")}
                    {renderSection(otrosGastos, "Ventas", "52")}

                    {/* Mostrar la Utilidad Operacional */}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Utilidad Operacional
                                <Tooltip title={`Utilidad Bruta MENOS Administración  y Ventas`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1'
                        }}>
                            {formatCurrency(utilidadOperacional)}
                        </TableCell>
                    </TableRow>


                    {renderSection(otrosGastos, "Gastos no Operacionales", "53")}
                    {renderSection(otrosGastos, "Ingresos no Operacionales", "42")}

                    {/* Mostrar la Utilidad Neta */}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#ffcd7d', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Utilidad Neta
                                <Tooltip title={`utilidad Operacional + Ingresos No Operacionales - Gastos No Operacionales`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#ffcd7d'
                        }}>
                            {formatCurrency(utilidadNeta)}
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </div>
    )
});
