import React, { Suspense, useEffect, useState } from 'react'
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Typography } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import newRequest from '../../../../utils/newRequest';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import PaidIcon from '@mui/icons-material/Paid';
import ModalDocumentoPendiente from './ModalDocumentoPendiente';
import ModalRecaudos from './ModalRecaudos';


const TablaEdades = ({ datos }) => {
    const campos = datos.length > 0 ? Object.keys(datos[0]) : [];

    const [documentos, setDocumentos] = useState([]);
    const [recaudo, setRecaudo] = useState([]);

    const [nit, setNit] = useState("");
    const [zona, setZona] = useState("");

    const [recaudoNit, setRecaudoNit] = useState("");

    // Estado del loading de los documentos
    const [loading, setLoading] = useState(true);

    // Estado del modal
    const [open, setOpen] = useState(false);
    const [openRecaudos, setOpenRecaudos] = useState(false);

    const fetchData = async (url, body, setData, setLoadingState) => {
        try {
            setLoadingState && setLoadingState(true);
            const res = await newRequest.post(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            setData(res.data.length ? res.data : []);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingState && setLoadingState(false);
        }
    };

    useEffect(() => {
        if (open && nit && zona) {
            fetchData('/api/GerenciaVisual/Cartera/ObtenerDocumenos', { zona, nit }, setDocumentos, setLoading);
        }
    }, [open, nit, zona]);

    useEffect(() => {
        if (openRecaudos && recaudoNit) {
            fetchData('/api/GerenciaVisual/Cartera/ObtenerRecaudo', { nitCliente: recaudoNit }, setRecaudo);
        }
    }, [openRecaudos, recaudoNit]);

    const handleOpenDocumentos = (row) => {
        setZona(row?.codigoZona);
        setNit(row?.Nit);
        setOpen(true);
    };

    const handleCloseDocumentos = () => {
        setOpen(false);
        setDocumentos([]);
        setNit("");
        setZona("");
    };

    const handleOpenRecaudos = (row) => {
        setRecaudoNit(row?.Nit);
        setOpenRecaudos(true);
    };

    const handleCloseRecaudos = () => {
        setOpenRecaudos(false);
        setRecaudo([]);
        setRecaudoNit("");
    };

    const columns = [
        {
            accessorKey: 'Zona',
            header: 'Zona',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#E74C3C',
                    color: "white",
                    width: "8rem",
                    // maxWidth: "8rem",
                }
            },
            size: 120,
            // muiTableBodyCellProps: {
            //     sx: {
            //         border: '1px solid rgba(224, 224, 224, 1)',
            //         width: "8rem",
            //         maxWidth: "8rem",
            //         fontWeight: 'normal',
            //     },
            // },
        },
        {
            accessorKey: 'Vendedor',
            header: 'Vendedor',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    // width: "8rem",
                }
            },
            size: 180,
            muiTableBodyCellProps: {
                sx: {
                    width: "8rem",
                    maxWidth: "8rem",
                    fontWeight: 'normal',
                },
            },
            Cell: ({ cell }) => (
                <Tooltip title={`${cell.row.original.Vendedor}`} arrow>
                    <Box>
                        <Typography variant='subtitle2' noWrap>
                            {cell.row.original.Vendedor}
                        </Typography>
                    </Box>
                </Tooltip>
            ),
        },
        {
            accessorKey: 'Nit',
            header: 'Nit',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#E74C3C',
                    color: "white",
                    width: "1rem",
                    maxWidth: "1rem",
                    fontWeight: 'normal',
                }
            },
            size: 110,
            muiTableBodyCellProps: {
                sx: {
                    width: "1rem",
                    maxWidth: "1rem",
                    fontWeight: 'normal',
                }
            },
        },
        {
            accessorKey: 'Cliente',
            header: 'Cliente',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    width: "1rem",
                    maxWidth: "1rem",
                    fontWeight: 'normal',
                }
            },
            muiTableBodyCellProps: {
                sx: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    width: "1rem",
                    maxWidth: "1rem",
                    fontWeight: 'normal',
                }
            },
            Cell: ({ cell }) => (
                <Tooltip title={`${cell.row.original.Cliente}`} arrow>
                    <Box>
                        <Typography variant='subtitle2' noWrap >
                            {cell.row.original.Cliente}
                        </Typography>
                    </Box>
                </Tooltip>
            ),
        },
        {
            accessorKey: 'Doc',
            header: 'Doc',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    width: "1rem",
                    maxWidth: "1rem",
                }
            },
            size: 80,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => (
                <Box sx={{
                    alignItems: "center",
                    justifyContent: "center", display: "flex"
                }}>
                    <Typography variant='subtitle2' noWrap >
                        {cell.row.original.Doc}
                    </Typography>
                    <Box sx={{ position: "relative", left: 10 }}>
                        <IconButton size="small" onClick={() => handleOpenDocumentos(cell.row.original)}>
                            <FindInPageIcon />
                        </IconButton>
                    </Box>
                </Box>
            ),
        },
        ...campos
            .filter((campo) => !['TotalCartera', 'sinVencer', 'Doc', 'codigoZona', 'Zona', 'Vendedor', 'Nit', 'Cliente'].includes(campo))
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                enableColumnActions: false,
                header: campo,
                muiTableHeadCellProps: {
                    sx: {
                        backgroundColor: index % 2 === 0 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    }
                },
                size: 120,
                Cell: ({ cell }) => (
                    cell.getValue() ? (
                        cell.getValue().toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                    ) : (
                        '$0'
                    )
                ),
            })),
        ...campos
            .filter((campo) => campo === 'sinVencer')
            .map((column) => ({
                accessorKey: column,
                enableColumnActions: false,
                header: 'sin Vencer',
                muiTableHeadCellProps: {
                    align: "center",
                    sx: {
                        backgroundColor: '#FF5722',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: "center"
                },
                size: 130,
                Cell: ({ cell }) => (
                    cell.getValue() ? (
                        cell.getValue().toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                    ) : (
                        '$0'
                    )
                ),
            })),
        ...campos
            .filter((campo) => campo === 'TotalCartera')
            .map((column) => ({
                enableColumnActions: false,
                accessorKey: column,
                header: 'Total Cartera',
                muiTableHeadCellProps: {
                    align: "center",
                    sx: {
                        backgroundColor: '#E74C3C',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: "center"
                },
                // size: 130,
                Cell: ({ cell }) => (
                    cell.getValue() ? (
                        cell.getValue().toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                    ) : (
                        '$0'
                    )
                ),
            })),
        {
            accessorKey: 'Recaudo',
            header: 'Recaudo',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    // width: "1rem",
                    // maxWidth: "1rem",
                }
            },
            size: 80,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => (
                <Box sx={{
                    alignItems: "center",
                    justifyContent: "center", display: "flex"
                }}>
                    <Box sx={{ position: "relative" }}>
                        <IconButton size="small" onClick={() => handleOpenRecaudos(cell.row.original)}>
                            <PaidIcon />
                        </IconButton>
                    </Box>
                </Box>
            ),
        },
    ];


    const table = useMaterialReactTable({
        columns,
        data: datos,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            columnPinning: { left: ['Nit', 'Cliente'], right: ['sinVencer', 'TotalCartera'] },
            density: 'compact'
        },
        muiTableContainerProps: {
            sx: {
                height: {
                    xs: '20vh', // Altura en teléfonos móviles
                    sm: '30vh', // Altura en tablets
                    md: '30vh', // Altura en pantallas pequeñas de escritorio
                    lg: "50vh"
                },
                width: " 100%"
            }
        },
        localization: MRT_Localization_ES
    });

    return (
        <Box sx={{ width: '100%', overflowX: 'auto', padding: '5px' }}>
            <Suspense fallback={<div>Cargando...</div>}>
                <ModalDocumentoPendiente open={open} handleClose={handleCloseDocumentos}
                    datos={documentos} loading={loading} />
                <ModalRecaudos openRecaudos={openRecaudos} cerrarModal={handleCloseRecaudos}
                    datos={recaudo}
                />
            </Suspense>

            <MaterialReactTable
                table={table}
            />
        </Box >
    )
}

export default TablaEdades