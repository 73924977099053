import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, CircularProgress, Box, Modal } from '@mui/material';
import newRequest from '../../../../utils/newRequest';
import TablaVentasLinea from './TablaVentasLinea';
import GraficaVentasLinea from './GraficaVentasLinea';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "8px"
};

const VentasLinea = ({ selectAñoIncial, selectedTercero, selectedCliente, selectedZona,
    selectedProduct, selectedLineas, selectedVendedor }) => {

    const [loading, setLoading] = useState(false);
    const [dataZona, setDataZona] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("a");

    // Almacena los resultados en caché
    const cache = useRef({});

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOptionChange = (newValue) => {
        setSelectedOption(newValue);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getKey = () => `${selectAñoIncial}-${selectedTercero}-${selectedCliente}-${selectedZona}-${selectedProduct}-${selectedLineas}-${selectedVendedor}-${selectedOption}`;

    useEffect(() => {
        const getVentasZonas = async () => {
            const cacheKey = getKey();

            if (cache.current[cacheKey]) {
                // Si los datos ya están en caché, úsalos
                setDataZona(cache.current[cacheKey]);
                return;
            }

            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/Ventas/VentasgetLineaVentas', {
                    datoCheck: selectedOption,
                    año: selectAñoIncial,
                    tercero: selectedTercero,
                    cliente: selectedCliente,
                    zona: selectedZona,
                    producto: selectedProduct,
                    linea: selectedLineas,
                    vendedor: selectedVendedor
                });
                const fetchedData = Array.isArray(res.data) ? res.data : [];
                setDataZona(fetchedData);

                // Almacenar en caché los resultados
                cache.current[cacheKey] = fetchedData;
            } catch (error) {
                console.error("Error en la consulta de ->getVentasZonas<-");
            } finally {
                setLoading(false);
            }
        };

        getVentasZonas();
    }, [selectedOption, selectAñoIncial, selectedTercero, selectedCliente, selectedZona,
        selectedProduct, selectedLineas, selectedVendedor, getKey]);

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {loading ? (
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "396px" }}>
                            <CircularProgress sx={{ marginRight: "25px" }} />
                            <Typography>
                                Ventas por Linea
                            </Typography>
                        </div>
                    ) : (
                        <TablaVentasLinea dataZona={dataZona} handleOptionChange={handleOptionChange}
                            selectedOption={selectedOption} handleOpen={handleOpen} />
                    )}
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <GraficaVentasLinea dataZona={dataZona} />
                </Box>
            </Modal>
        </div>
    );
};

export default VentasLinea;
