import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
    Typography, Button, Dialog, ButtonGroup, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@mui/material';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import newRequest from '../../../../utils/newRequest';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const TablaParametrosCostos = ({ costos, setOpenDrawerCosto, ObtenerCostos, onIdCosto }) => {

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const crearCosto = () => {
        setId("")
        setIdDos("")
        setOpenDrawerCosto(true)
        onIdCosto("")
    }

    const handleButtonClick = (row) => {
        try {
            setId(row.ImpCosId)
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.ImpCosId)
        //Abrimos el modal
        setOpenConfirmDialog(true);
        // Limpiamos el id
    };

    const EliminarCosto = async () => {
        try {
            // Aqui recibimos el estado
            await newRequest.delete(`/api/Importaciones/parametros/EliminarCosto/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setId("")
            setIdDos("")
            ObtenerCostos();
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (id) {
            const ObtenerUnCosto = async () => {
                const res = await newRequest.post(`/api/Importaciones/parametros/ObtenerUnCosto`, {
                    id: id
                })
                if (res.status === 200) {
                    onIdCosto(res.data)
                    setOpenDrawerCosto(true)
                }
            }

            ObtenerUnCosto();
        }
    }, [id, idDos, setOpenDrawerCosto, onIdCosto])

    const columns = [
        {
            accessorKey: 'ImpCosDes', //access nested data with dot notation
            header: 'Descripción del costo',
            size: 200,
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: '16px !important',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorKey: 'ImpCosVal',
            header: 'Cop',
            size: 70,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: '16px !important',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            enableColumnActions: false,
            Cell: ({ cell }) => {
                // console.log("Cell, aqui cell Costos", cell.row.original)
                return (
                    < div >
                        <div style={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <span>
                                $
                            </span>
                            <span>
                                {cell.row.original.ImpCosVal}
                            </span>
                        </div>
                    </div >
                )
            },
        },
        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            size: 70,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: '16px !important',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            enableColumnActions: false,
            Cell: ({ cell }) => {
                // console.log("Cell, aqui cell", cell.row.original)
                return (
                    < div >
                        {/* <Tooltip title="Editar" >
                            <IconButton sx={{
                                width: "30px", height: "30px",
                            }}
                                onClick={() => handleButtonClick(cell.row.original)}
                            >
                                <i className="fa-solid fa-pencil" style={{
                                    fontSize: "18px", color: "#118DF0"
                                }} ></i>
                            </IconButton>
                        </Tooltip>


                        <Tooltip title="Eliminar" >
                            <IconButton sx={{
                                width: "30px", height: "30px",
                            }}
                                // onClick={() => EliminarunProceso(cell.row.original)}
                                onClick={() => handleOpenConfirmDialog(cell.row.original)}
                            >
                                <i className="fa-solid fa-trash" style={{
                                    fontSize: "18px",
                                    color: "#FF2442"
                                    // color: "#FF6969"
                                }} ></i>
                            </IconButton>
                        </Tooltip> */}
                        <ButtonGroup variant="text" aria-label="outlined button group">
                            <Button onClick={() => handleButtonClick(cell.row.original)} startIcon={<i className="fa-solid fa-pencil" style={{
                                fontSize: "16px", color: "#118DF0", marginLeft: 5
                            }} ></i>}>
                            </Button>

                            <Button onClick={() => handleOpenConfirmDialog(cell.row.original)} startIcon={<i className="fa-solid fa-trash" style={{
                                fontSize: "16px", color: "#FF2442", marginLeft: 10
                            }} ></i>}>
                            </Button>
                        </ButtonGroup>
                    </div >
                )
            },
        },
    ];

    return (
        <div>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este dato?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarCosto();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            <MaterialReactTable columns={columns} data={costos}
                muiTablePaperProps={{
                    elevation: 0,
                }}
                muiTableHeadCellProps={{
                    sx: {
                        // borderRight: '2px solid #e0e0e0', //add a border between columns
                        fontWeight: 'normal',
                        fontSize: '16px !important',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontWeight: 'normal',
                        // fontSize: '12px !important', // Reducir el tamaño de fuente para las celdas de cuerpo
                        // border: '1px solid rgba(224, 224, 224, 1)',
                        // whiteSpace: "normal", // Agregar esta línea
                    },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                    // <Box sx={{ display: 'flex', gap: '1rem', p: '4px', justifyContent: "space-between" }}>
                    <Button variant="outlined" size="large" onClick={crearCosto}>
                        <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                        <Typography sx={{ fontSize: "13px" }}>
                            Crear Costo
                        </Typography>
                    </Button>
                    // </Box>
                )}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFilters={false}
                enableStickyHeader
                localization={MRT_Localization_ES}
                muiTableContainerProps={{ sx: { maxHeight: '450px', height: "420px", width: "100%" } }}
            />
        </div>
    );
}

export default TablaParametrosCostos