import React from 'react';
import { FormControlLabel, Radio, FormControl, RadioGroup } from '@mui/material';

const OpcionesTabla = ({ onChangeCambioTabla, cambioTablaFromParent }) => {

    const handleSwitchChange = (event) => {
        const newCambioTabla = event.target.value === 'true';
        onChangeCambioTabla(newCambioTabla);
    };

    return (
        <div>
            <FormControl sx={{ marginLeft: 5 }}>
                <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    value={cambioTablaFromParent.toString()} // Convertir a string
                    onChange={handleSwitchChange}
                >
                    <FormControlLabel
                        value="true" // Cambiar a strings
                        control={<Radio />}
                        label="Valores"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="false" // Cambiar a strings
                        control={<Radio />}
                        label="Cantidad"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
}

export default OpcionesTabla;
