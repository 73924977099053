import React, { useEffect, useState } from 'react'
import {
    Box, Grid, Paper, Stack, Typography, TextField, Alert,
    // Skeleton
} from '@mui/material';

import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import DrawIcon from '@mui/icons-material/Draw';
import EditIcon from '@mui/icons-material/Edit';

const defaultTheme = createTheme();

const ModalTipoProceso = ({ obtenerTipoProcesos, selectedID, setOpenDrawer, openDrawer }) => {

    const [nombre, setNombre] = useState("");
    const [observacion, setObservacion] = useState("");

    // Maneja el clic en el botón "Crear"
    const handleCreateClick = async () => {
        try {

            // Validar que los campos obligatorios no estén vacíos
            if (!nombre || !observacion) {
                // Mostrar un mensaje de error si algún campo está vacío
                toast.error("Por favor, complete todos los campos obligatorios.", {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                return; // No continuar si hay campos vacíos
            }

            const procesoData = {
                nombre: nombre,
                observacion: observacion,
            };

            let response;

            if (selectedID.length > 0) {
                // Si selectedID tiene datos, realiza una solicitud PUT para actualizar
                response = await newRequest.put(
                    `/api/Calidad/TipoProceso/ActualizarTipoProceso/${selectedID[0].TipProEmpId}`,
                    procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                // Si selectedID está vacío, realiza una solicitud POST para crear
                response = await newRequest.post("/api/Calidad/TipoProceso/CrearTipoProceso", procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            }

            if (response.status === 200) {
                toast.success(
                    `Tipo ${selectedID.length > 0 ? "actualizado" : "creado"} correctamente`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setNombre("");
                setObservacion("");
                setOpenDrawer(false)
                obtenerTipoProcesos();
            } else {
                toast.warning("Error al interactuar con el servidor", {
                    position: "bottom-center",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            // alert("Error en la solicitud POST:", error);
            toast.warning(`Error en la solicitud POST:", ${error}`, {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            // Maneja los errores de la solicitud aquí
        }
    };

    useEffect(() => {
        if (openDrawer === false) {
            setNombre("");
            setObservacion("");
        }
    }, [openDrawer])


    // Efecto para prellenar los campos si selectedID tiene datos
    useEffect(() => {
        if (selectedID) {
            setNombre(selectedID[0].TipProEmpNom || ""); // Usar valores predeterminados si TipProEmpNom está indefinido
            setObservacion(selectedID[0].TipProEmpObs || ""); // Usar valores predeterminados si TipProEmpObs está indefinido
        } else {
            // Si selectedID está vacío, asegúrate de limpiar los campos
            setNombre("");
            setObservacion("");
        }
    }, [selectedID]);

    return (
        <div>
            <Paper elevation={0} sx={{ p: { xs: 2, md: 3 } }}>
                <Box sx={{
                    marginBottom: "5px", display: "flex", alignContent: "right",
                    justifyContent: "right", marginTop: "2.5rem"
                }}>
                </Box>

                <Box sx={{
                    display: "flex", alignContent: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? "Actualiza un tipo" : " Crea un tipo"}
                    </Typography>
                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? (
                            <span style={{ marginLeft: "3px" }}>  id: {selectedID[0]?.TipProEmpId}</span>
                        ) : ""}
                    </Typography>
                </Box>
                <div style={{ height: "00px" }} />
                <Divider />
                <Stack sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} spacing={2}>
                    <Alert severity="warning">
                        Los campos con * son requeridos
                    </Alert>
                </Stack>
                <ThemeProvider theme={defaultTheme}>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate sx={{ width: "100%" }} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    {/* <FormControl > */}
                                    <Typography variant="subtitle2" gutterBottom>
                                        Nombre del proceso *
                                    </Typography>
                                    <TextField fullWidth size='small'
                                        type="text" required
                                        name="nombre" autoComplete='off'
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        // label=""
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                        InputProps={{
                                            // sx:{marginTop:"21px"},
                                            startAdornment: (
                                                // <i  className="fa-solid fa-percent"></i>
                                                <i style={{ marginRight: "10px" }} className="fa-solid fa-building"></i>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Observación *
                                    </Typography>
                                    <TextField fullWidth size='small'
                                        name="observacion" required
                                        value={observacion} autoComplete='off'
                                        onChange={(e) => setObservacion(e.target.value)}
                                        multiline
                                        rows={6}
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained" size="large" fullWidth
                                    sx={{
                                        background: "#003da5"
                                    }}
                                    onClick={handleCreateClick}>
                                    {selectedID.length > 0 ?
                                        (<div>
                                            <i className="fa-solid fa-pencil" style={{ marginRight: "8px" }}></i>
                                        </div>)
                                        :
                                        (<div>
                                            <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                                        </div>)
                                    }
                                    <Typography sx={{ fontSize: "13px" }}>
                                        {selectedID.length > 0 ? "Actualizar" : " Crear"}
                                    </Typography>
                                </Button>
                            </Box> */}

                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained"
                                    onClick={handleCreateClick} sx={{ background: "#1C64F2" }}
                                    fullWidth
                                >
                                    <div style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <span>{selectedID.length > 0 ? "Actualizar" : " Crear"}</span>
                                            <span> {selectedID.length > 0 ?
                                                (
                                                    <EditIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                ) : (
                                                    <AddIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                )}
                                            </span>
                                        </Typography>
                                    </div>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ThemeProvider>
            </Paper>
        </div>
    )
}

export default ModalTipoProceso