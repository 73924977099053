import React from 'react'
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import PaymentsIcon from '@mui/icons-material/Payments';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomGridItem from '../../components/CustomGridItem';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Grid from '@mui/material/Grid2';

export const MenuFinanciera = () => {

    const gridItems = [
        {
            icon: <PaymentsIcon style={{ fontSize: "45px" }} />,
            title: "PyG (Perdida y Ganancia)",
            link: "/Farmanet/Financiera/Pyg",
            ruta: "Financiera"
        },
        {
            icon: <ImportExportIcon style={{ fontSize: "45px" }} />,
            title: "Balance de prueba",
            link: "/Farmanet/Financiera/ActivoPasivos",
            ruta: "Financiera"
        },
        // Add more grid items as needed
    ];

    return (
        <Box>
            <Grid size={{ xs: 12, sm: 6, md: 12 }} >
                <Box sx={{ alignContent: "center", justifyContent: "start", display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                        marginBottom: "1em",
                    }}>
                        <AttachMoneyIcon style={{ marginRight: "5px", color: "#007aff" }} />
                        Financiera
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item} />
                ))}
            </Grid>
        </Box >
    )
}
