import React, { useEffect, useState } from 'react'
import {
    Box, Grid, Button, Typography,
    TextField, Divider, FormControl,
} from '@mui/material';
import { toast } from 'react-toastify';

import newRequest from '../../../../utils/newRequest';

const ModalActividad = ({ ObtenerActividadImportacion, setOpenDrawerActividad,
    selectedIDActividad }) => {

    const [ImpCanNom, setImpCanNom] = useState("")
    const [ImpCanDia, setImpCanDia] = useState("")

    const EventoClick = async () => {
        try {
            const procesoData = {
                ImpCanNom: ImpCanNom,
                ImpCanDia: ImpCanDia.toString()
            };
            let response;

            if (selectedIDActividad.length > 0) {
                response = await newRequest.put(
                    `/api/Importaciones/parametros/ActualizarActividad/${selectedIDActividad[0].ImpCanId}`, procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                response = await newRequest.post(`/api/Importaciones/parametros/CrearActividad`, procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
            }

            if (response.status === 200) {
                setOpenDrawerActividad(false)
                ObtenerActividadImportacion();
                toast.success(
                    `Dato creado correctamente`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (selectedIDActividad) {
            setImpCanNom(selectedIDActividad[0].ImpCanNom || "")
            setImpCanDia(selectedIDActividad[0].ImpCanDia || 0)
        } else {
            setImpCanNom("")
            setImpCanDia("")
        }
    }, [selectedIDActividad])

    return (
        <div>

            <Box sx={{
                marginBottom: "5px", display: "flex", alignContent: "right",
                justifyContent: "right", marginTop: "-15px"
            }}>
            </Box>

            <Box sx={{
                display: "flex", alignContent: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {selectedIDActividad.length > 0 ? "Actualiza Actividad" : "Crear Actividad"}
                </Typography>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {selectedIDActividad.length > 0 ? (
                        <span style={{ marginLeft: "3px" }}>  id: {selectedIDActividad[0]?.ImpCanId}</span>
                    ) : ""}
                </Typography>
            </Box>

            <Divider />

            <Box
                sx={{
                    marginTop: 2,
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component="form" noValidate sx={{ width: "100%" }} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2" gutterBottom>
                                    Nombre de la actividad
                                </Typography>
                                <TextField fullWidth autoComplete='off' margin='dense'
                                    size='small' required placeholder="Nombre de la actividad"
                                    variant="outlined" value={ImpCanNom}
                                    onChange={(e) => setImpCanNom(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <i style={{ marginRight: "10px" }} className="fa-solid fa-bars"></i>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2" gutterBottom>
                                    Dias de la actividad
                                </Typography>
                                <TextField fullWidth autoComplete='off' type='number'
                                    size='small' required margin='dense'
                                    placeholder="Dias (Sugeridos) de la actividad"
                                    variant="outlined" value={ImpCanDia}
                                    onChange={(e) => setImpCanDia(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            // <i style={{ marginRight: "10px" }} className="fa-solid fa-coins"></i>
                                            <i style={{ marginRight: "10px" }} className="fa-solid fa-calendar"></i>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Box sx={{ display: "grid", marginTop: 2, alignItems: "end", justifyContent: "end" }}>
                                <Button variant="contained" onClick={EventoClick}>
                                    <Typography sx={{ fontSize: "13px" }}>
                                        {/* Crear actividad */}
                                        {selectedIDActividad.length > 0 ?
                                            "Actualizar Actividad"
                                            :
                                            "Crear Actividad"
                                        }

                                    </Typography>
                                    {selectedIDActividad.length > 0 ?
                                        (<span>
                                            <i className="fa-solid fa-pencil" style={{ marginLeft: "5px" }} ></i>
                                        </span>)
                                        :
                                        (<span>
                                            <i className="fa-solid fa-plus" style={{ marginLeft: "5px" }}></i>
                                        </span>)
                                    }
                                </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </div >
    )
}

export default ModalActividad