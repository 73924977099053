import React, { useCallback, useEffect, useState } from 'react';
import newRequest from '../../../../utils/newRequest';
import { TablaPyG } from './TablaPyG';

const PorPyG = () => {

    const [data, setdata] = useState([]);
    const [costoVenta, setCostoVenta] = useState([]);
    const [otrosGastos, setotrosGastos] = useState([]);

    // Obtenemos los datos
    const getPyGData = useCallback(async () => {
        try {
            const [responsePyG, responseCostoVenta, responseOtrosGastos] = await Promise.all([
                newRequest.post('/api/Financiera/financieraRoutes/FinancieraPyG', {}),
                newRequest.post('/api/Financiera/financieraRoutes/financieraPyGCostoVentas', {}),
                newRequest.post('/api/Financiera/financieraRoutes/financieraPyGOtro', {})
            ]);
            setdata(Array.isArray(responsePyG.data) ? responsePyG.data : []);
            setCostoVenta(Array.isArray(responseCostoVenta.data) ? responseCostoVenta.data : []);
            setotrosGastos(Array.isArray(responseOtrosGastos.data) ? responseOtrosGastos.data : []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);


    useEffect(() => {
        getPyGData();
    }, [getPyGData]);

    const ejecutarSp = () => {
    }

    return (
        <div>
            <TablaPyG ejecutarSp={ejecutarSp} data={data} costoVenta={costoVenta} otrosGastos={otrosGastos} />
        </div>
    );
};

export default PorPyG;
