import React, { useEffect, useState, lazy, Suspense } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Typography,
    Dialog, DialogContent
    // Skeleton
} from '@mui/material';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import newRequest from '../../../utils/newRequest';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';

// import Calendario from './Calendario/Calendario';
// import FormularioContenedores from './Calendario/FormularioContenedores';
// import TablaContenedores from './TablaContenedores/TablaContenedores';

const Calendario = lazy(() => import('./Calendario/Calendario'));
const TablaContenedores = lazy(() => import('./TablaContenedores/TablaContenedores'));
const FormularioContenedores = lazy(() => import('./Calendario/FormularioContenedores'));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const Contenedores = () => {

    const [value, setValue] = React.useState(0);
    const [contenedores, setSontenedores] = useState([])
    // const [proveedores, setProveedores] = useState([])

    // Estado para abrir y cerrar el modal
    const [openDrawerContenedores, setOpenDrawerContenedores] = useState(false);


    const handleFormCloseContenedores = () => {
        setOpenDrawerContenedores(false)
    }
    const handleFormOpenContenedores = () => {
        setOpenDrawerContenedores(true)
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const obtenerContenedores = async () => {
        try {
            const res = await newRequest.get("api/Importaciones/Contenedores/ObtenerContenedores");

            if (res.status !== 200) {
                throw new Error('Error en la respuesta del servidor')
            } else {
                setSontenedores(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        obtenerContenedores();
    }, [])


    return (
        <div>
            <Box sx={{ marginTop: -6 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12} >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" to="/Farmanet/Importaciones">Importaciones</Link>
                                <Typography color="text.primary">Contenedores</Typography>
                            </Breadcrumbs>
                            <Typography variant="h6" color="black" gutterBottom style={{
                                display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                                marginBottom: "25px", marginTop: "10px"
                            }}>
                                <DirectionsBoatFilledIcon style={{ fontSize: "20px", marginRight: "10px", color: "#FF2442" }} />
                                Contenedores
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Box>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Contenedores" {...a11yProps(0)} />
                            <Tab label="Calendario" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "15px" }}>
                            <Box sx={{ marginTop: 2, flexDirection: 'column', alignItems: 'center' }}>
                                <TablaContenedores ModalContenedores={handleFormOpenContenedores}
                                    contenedores={contenedores}
                                />
                            </Box>
                        </Paper>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Suspense fallback={<div>Cargando...</div>}>
                            <Calendario contenedores={contenedores} />
                        </Suspense>
                    </CustomTabPanel>

                </Box>
            </Box>


            <Dialog open={openDrawerContenedores}
                maxWidth="md" // Puedes ajustar este valor según tus necesidades
                sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                {/* <DialogTitle>Crear nuevo Contenedor</DialogTitle> */}
                <DialogContent>
                    <Suspense fallback={<div>Cargando...</div>}>
                        <FormularioContenedores
                            // proveedores={proveedores}
                            handleFormCloseContenedores={handleFormCloseContenedores} />
                    </Suspense>
                </DialogContent>
            </Dialog>

        </div>
    )
}

export default Contenedores