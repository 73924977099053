import React, { useEffect, useState } from 'react'
import {
    Box, Typography, Tooltip, ButtonGroup, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import newRequest from '../../../../utils/newRequest';

import { useTheme } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TablaPoliticasEmpresa = ({ datosPolitica, setOpenDrawer,
    onIDSelected, ObtenerPoliticas }) => {

    // const globalTheme = useTheme();

    // console.log("datosPolitica", datosPolitica)

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const crearPolitica = () => {
        setId("") //Devolvemos el id vacio para que no abra el modal solo
        onIDSelected("")
        setOpenDrawer(true)
    }

    const handleButtonClick = (row) => {
        try {
            setId(row.PolEmpSec)
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.PolEmpSec)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const EliminarPolitica = async (row) => {
        try {
            // console.log("Aqui pasa el row ELIMINAR", row.TipProEmpId)
            await newRequest.delete(`/api/Calidad/Politicas//EliminarPolitica/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            ObtenerPoliticas()
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            const ObtenerUnaPolitica = async () => {
                const res = await newRequest.post(`/api/Calidad/Politicas/ObtenerUnaPolitica`, {
                    id: id
                });
                setOpenDrawer(true) // Abre el modal
                onIDSelected(res.data)
                setId("")
            }
            ObtenerUnaPolitica();
        }
    }, [id])

    const columns = [
        {
            accessorKey: 'PolEmpDes',
            header: 'Descripción',
            // size: 150,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.PolEmpDes}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.PolEmpDes}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'PolEmpUsuCrea',
            header: 'Usuario',
            // size: 150,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.PolEmpUsuCrea}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.PolEmpUsuCrea}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'PolEmpFecCrea',
            header: 'Fecha de creación',
            // size: 150,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell, row }) => (
                <Typography variant='subtitle2' style={{
                    textTransform: 'lowercase', whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}>
                    {row.original.PolEmpFecCrea.substring(0, 10)}
                </Typography>
            ),
        },
        {
            accessorKey: "Opciones",
            header: "Opciones",
            muiTableHeadCellProps: {
                align: 'center',
            },
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            Cell: ({ cell }) => {
                return (
                    < div >
                        <ButtonGroup variant="text" >
                            <Tooltip title="Editar">
                                <Button onClick={() => handleButtonClick(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EditIcon sx={{ width: '25px', height: '25px', color: '#118DF0' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Eliminar">
                                <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <DeleteIcon sx={{ width: '25px', height: '25px', color: '#FF2442' }} />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div >
                )
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
    ];

    return (
        <div>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este dato?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarPolitica();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <ThemeProvider theme={tableTheme}> */}
            <MaterialReactTable
                columns={columns}
                data={datosPolitica}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        // borderRadius: '20px',
                        // border: '1px solid  #e0e0e0',
                    },
                }}
                localization={MRT_Localization_ES}
                enableStickyHeader
                // initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFilters={false}
                muiTableHeadCellProps={{
                    sx: {
                        // borderRight: '2px solid #e0e0e0', //add a border between columns
                        fontWeight: 'normal',
                        fontSize: '16px !important',
                    },
                }}
                defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    // size: 150, //default size is usually 180
                }}
                muiTableBodyCellProps={{
                    sx: {
                        // fontSize: '0.9rem !important',
                        whiteSpace: "nowrap",
                        // height: "45px !important",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Button variant="outlined"
                        onClick={crearPolitica}
                    >
                        <Typography sx={{ fontSize: "13px" }}>
                            Crear Politica
                        </Typography>
                        <AddCircleIcon style={{ marginLeft: "5px", fontSize: "19px" }} />
                    </Button>
                )}
                initialState={{ density: 'compact' }}
                muiTableContainerProps={{
                    sx: {
                        height: 450, width: "100%",
                    }
                }}
            />
            {/* </ThemeProvider> */}
        </div>
    )
}

export default TablaPoliticasEmpresa