import React, { useCallback, useEffect, useState, useMemo, Suspense } from 'react';
import newRequest from '../../../../utils/newRequest';
import { Grid, CircularProgress, Typography, Box } from '@mui/material';
import TablaProducto from './TablaProducto';
import GraficoProducto from './GraficoProducto';

// Optimización de memoización para evitar renders innecesarios de componentes hijos
const MemoizedGraficoProducto = React.memo(GraficoProducto);
const MemoizedTablaProducto = React.memo(TablaProducto);

const PorProducto = ({
    selectedMesFinal,
    selectedMesInicial,
    selectAñoIncial,
    selectedProduct,
    selectedCliente,
    selectedLineas,
    selectedVendedor,
    selectedTercero,
    checked,
    Excluir,
    selectedZona
}) => {

    const [rows, setRows] = useState([]);
    const [presupuestoTotal, setPresupuestoTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const converAñoAnt = useMemo(() => selectAñoIncial - 1, [selectAñoIncial]);  // Use useMemo to memoize this calculation
    const [noDataMessage, setNoDataMessage] = useState('');
    const [cambioTabla, setCambioTabla] = useState(true);

    const handleCambioTablaChange = useCallback((newCambioTabla) => {
        setCambioTabla(newCambioTabla);
    }, []);

    // Optimización del fetchData: memorizar y usar async/await
    const fetchData = useCallback(async () => {
        try {
            setLoading(true); // Mostrar el loader
            const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasProducto', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                CambioDato: cambioTabla,
                zona: selectedZona
            });

            const data = res.data;
            if (data.length > 0) {
                const rowsWithId = data.map((row, index) => ({ id: index + 1, ...row }));
                setRows(rowsWithId);
                setNoDataMessage('Ventas por producto');
            } else {
                setRows([]);
                setNoDataMessage('No hay datos disponibles');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Ocultar el loader después de que se completen los datos
        }
    }, [
        converAñoAnt,
        selectAñoIncial,
        selectedMesInicial,
        selectedMesFinal,
        selectedProduct,
        selectedCliente,
        selectedLineas,
        selectedVendedor,
        selectedTercero,
        checked,
        Excluir,
        cambioTabla,
        selectedZona
    ]);

    // useEffect para controlar la carga inicial de los datos
    useEffect(() => {
        const getPresupuestoAnual = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialTotalPresupuestoAnual', {
                    total: selectAñoIncial
                });
                setPresupuestoTotal(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        getPresupuestoAnual();
        fetchData(); // Obtener datos al cargar el componente
    }, [fetchData, selectAñoIncial]); // fetchData está memorizada, por lo que no se recalcula en cada render

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                {/* Renderización del gráfico de productos */}
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: "25px" }}>
                    {loading ? (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="350px">
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Cargando grafica por productos...</Typography>
                        </Box>
                    ) : (
                        <Suspense fallback={<CircularProgress />}>
                            <MemoizedGraficoProducto
                                selectAñoIncial={selectAñoIncial}
                                selectedMesInicial={selectedMesInicial}
                                selectedMesFinal={selectedMesFinal}
                                rows={rows}
                            />
                        </Suspense>
                    )}
                </Grid>

                {/* Renderización de la tabla de productos */}
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ marginBottom: "25px" }}>
                    {loading ? (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="350px">
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Cargando tabla por productos...</Typography>
                        </Box>
                    ) : (
                        <div>
                            <Suspense fallback={<CircularProgress />}>
                                <MemoizedTablaProducto
                                    selectAñoIncial={selectAñoIncial}
                                    selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal}
                                    rows={rows}
                                    noDataMessage={noDataMessage}
                                    presupuestoTotal={presupuestoTotal}
                                    onChangeCambioTabla={handleCambioTablaChange}
                                    cambioTabla={cambioTabla}
                                    loading={loading}
                                />
                            </Suspense>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default PorProducto;
