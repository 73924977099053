import React, { useEffect, useState } from 'react'
import newRequest from '../../../../utils/newRequest.js';
import { CircularProgress, Grid, Typography } from '@mui/material';
import VentasTablaDispostivosM from './VentasTablaDispostivosM';
// import OpcionesTabla from './OpcionesTabla';

const VentasDispostivosM = ({ selectAñoIncial, selectedTercero, selectedCliente, selectedZona,
    selectedProduct, selectedLineas, selectedVendedor }) => {

    const [loading, setLoading] = useState(false);
    // const [noDataMessage, setNoDataMessage] = useState('Ventas por dispositivos medicos');
    const [dataZona, setDataZona] = useState([])
    const [selectedOption, setSelectedOption] = useState("a"); // Estado para el valor seleccionado

    // Función para manejar el cambio de opción
    const handleOptionChange = (newValue) => {
        setSelectedOption(newValue);
        // Realiza cualquier otra acción que desees con el nuevo valor seleccionado.
    }

    useEffect(() => {
        const ventasDispositivosM = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/Ventas/VentasDispositivosMedicos', {
                    // datoCheck: selectedValue,
                    datoCheck: selectedOption,
                    año: selectAñoIncial,
                    tercero: selectedTercero,
                    cliente: selectedCliente,
                    zona: selectedZona,
                    producto: selectedProduct,
                    linea: selectedLineas,
                    vendedor: selectedVendedor
                });
                // setdataZona(res.data)
                setDataZona(Array.isArray(res.data) ? res.data : []);
                setLoading(false);
            } catch (error) {
                return console.log("Error en la la consulta de ->ventasDispositivosM<-")
            }
        }
        ventasDispositivosM();
    }, [selectedOption, selectAñoIncial, selectedTercero, selectedCliente,
        selectedZona, selectedProduct, selectedLineas, selectedVendedor])

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {loading ? (
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "396px" }} >
                            <CircularProgress sx={{ marginRight: "25px" }} />
                            <Typography variant='h6' fontSize={16} fontWeight="bold" sx={{ paddingTop: "10px", marginRight: "30px", }}>
                                Ventas por Producto
                            </Typography>
                        </div>
                    ) : (

                        < div>
                            <VentasTablaDispostivosM dataZona={dataZona} handleOptionChange={handleOptionChange} 
                                selectedOption={selectedOption} />
                        </div>
                    )}
                </Grid>
            </Grid>
        </div >
    )
}

export default VentasDispostivosM