import React, { useEffect, useState } from 'react'
import {
    Box, Grid, Paper, CircularProgress, LinearProgress
    // Skeleton
} from '@mui/material';
import ModalProcesoEmpresa from './ModalProcesoEmpresa';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import TablaProcesoEmpresa from './TablaProcesoEmpresa';
import newRequest from '../../../../utils/newRequest';

const ProcesoEmpresa = () => {
    // console.log("procesosEmpresa", procesosEmpresa);
    const [isLoading, setIsLoading] = useState(true);

    const [openDrawer, setOpenDrawer] = useState(false); // Estado para abrir y cerrar el modal
    const [procesosEmpresa, setprocesosEmpresa] = useState([])
    const [tipoProcesos, setTipoProcesos] = useState([]);
    const [jefeProceso, setJefeProceso] = useState([])
    const [sumaPeso, setsumaPeso] = useState([])

    const [alignment, setAlignment] = useState(0);


    const [alignmentTipo, setAlignmentTipo] = useState(0); // Opciones para el cambio

    const [selectedID, setSelectedID] = useState(""); // Estado para almacenar el ID seleccionado

    // Función para manejar el ID seleccionado
    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };


    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleChangeTipo = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignmentTipo(newAlignment);
        }
    };


    const DataAct = {
        dato: alignment,
        tipo: alignmentTipo
    }

    const getProcesos = async () => {
        setIsLoading(true)
        try {
            const res = await newRequest.post('/api/Calidad/Procesos/procesoEmpresa', DataAct, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (res.data.length) {

                const resTipo = await newRequest.get('api/Calidad/TipoProceso/ObtenerTipoProcesos');
                setTipoProcesos(resTipo.data)

                setprocesosEmpresa(res.data);
            } else {
                setprocesosEmpresa([]); // Establecer procesosEmpresa como un array vacío
                console.log("La respuesta no contiene un array de indicadores:", res.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }

    const SumaPeso = async () => {
        try {
            const res = await newRequest.get('/api/Calidad/Procesos/sumaPeso')
            if (res.data.length) {
                setsumaPeso(res.data[0]);
            } else {
                setsumaPeso([]);
            }
        } catch (error) {
            console.log("error SumaPeso", error)
        }
    }

    const SelectJefeProceso = async () => {
        try {
            const res = await newRequest.get("/api/Calidad/Procesos/SelectJefeProceso");
            setJefeProceso(res.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getProcesos();
        SelectJefeProceso()
        SumaPeso();
        // SelectTipoProceso();

    }, [alignment, alignmentTipo])


    useEffect(() => {
    }, [tipoProcesos, selectedID])



    const list = (
        <Box sx={{ width: 550 }}>
            <List>
                <ModalProcesoEmpresa selectedID={selectedID}
                    SumaPeso={SumaPeso} openDrawer={openDrawer}
                    getProcesos={getProcesos} setOpenDrawer={setOpenDrawer}
                    jefeProceso={jefeProceso} tipoProcesos={tipoProcesos} />
            </List>
        </Box>
    );

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    {isLoading ? ( // Muestra el loader si isLoading es true
                        <LinearProgress />
                    ) : (
                        <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px" }}>
                            <Box
                                sx={{
                                    marginTop: 2,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <TablaProcesoEmpresa setOpenDrawer={setOpenDrawer}
                                    procesosEmpresa={procesosEmpresa} getProcesos={getProcesos}
                                    onIDSelected={handleIDSelected}
                                    selectedOptionPadre={selectedID}
                                    alignment={alignment}
                                    handleChange={handleChange}
                                    alignmentTipo={alignmentTipo}
                                    handleChangeTipo={handleChangeTipo}
                                    sumaPeso={sumaPeso}
                                    tipoProcesos={tipoProcesos}
                                />
                            </Box>
                        </Paper>
                    )}
                </Grid>
                <SwipeableDrawer
                    anchor="right"
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onOpen={() => setOpenDrawer(true)}
                // SumaPeso={SumaPeso()}
                >
                    {list}
                </SwipeableDrawer>
            </Grid>
        </Box>
    )
}

export default ProcesoEmpresa