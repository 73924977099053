import React from 'react';
import { Box, Button, Card, CardContent, Divider, Grid, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CardInfo = ({ color, icon: Icon, title, value, secondaryText, link }) => (
    <Grid item xs={12} sm={6} md={6} lg={3}>
        <Paper elevation={0} style={{ borderRadius: "12px" }}>
            <Card sx={{ position: 'relative', display: 'flex', flexDirection: 'column', height: "auto", borderRadius: "12px" }}>
                <Grid container spacing={2} sx={{ flex: 1 }}>
                    <Grid item md={3}
                        style={{
                            display: "flex", alignItems: "center", justifyContent: "center",
                        }}
                    >
                        <div style={{
                            background: color, height: "50px", width: "50px", borderRadius: "13px",
                            display: "flex", alignItems: "center", justifyContent: "center",
                            boxShadow: `0px 2px 10px 2px ${color}`, margin: "10px"
                        }}>
                            <Icon style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center", color: "white", 
                            }} />
                        </div>
                    </Grid>
                    <Divider orientation='vertical' style={{
                        height: 70, display: "flex", marginTop: 40, alignItems: "center", justifyContent: "center"
                    }} />
                    <Grid item md={6} style={{ display: 'flex', justifyContent: "start", alignItems: "center" }}>
                        <Box sx={{ flex: 1 }}>
                            <CardContent sx={{ flex: '1 0 auto', }}>
                                <Typography variant="h5" component="div" style={{
                                    overflow: 'hidden', paddingTop: "1rem",
                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                }}>
                                    {value}
                                </Typography>
                                <Typography sx={{ fontSize: 14, color: "#d4d4d4", }}
                                    color="text.secondary" gutterBottom>
                                    {secondaryText}
                                </Typography>
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
                <Button component={Link} to={link} size="small">Ver más</Button>
                </Box>
            </Card>
        </Paper>
    </Grid>
);

export default CardInfo;
