export const validacionData = (datosInd) => {
    let c = 0;
    let desempeño = 0;
    let cummeta = 0;
    let cummetasindat = 0;
    let f = 0;
    let pf = 0;
    let rojo = '';
    let amarillo = '';
    let verde = '';
    let recalculopeso = 0;
    let desempeño2 = 0;

    let meta;
    let result;
    let cumplimiento;
    let pesofinal;
    let pesofinal2;
    let meta1, meta2, meta_1, meta_2;

    let resultado;

    // Formatear el número con dos decimales, ',' como separador de decimales, y '.' como separador de miles

    // const formatoNumero = (numero) => {
    //     // Verificar si el número es NaN o no es un número válido
    //     if (isNaN(numero)) {
    //         return '0%'; // Devolver 0% si el número es NaN
    //     }
    //     return numero.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%';
    // };

    // const formatoNumero = (numero) => {
    //     // Verificar si el número es NaN o no es un número válido
    //     if (isNaN(numero)) {
    //         return '0%'; // Devolver 0% si el número es NaN
    //     }
    //     // Redondear el número al entero más cercano y aplicar el formato
    //     return Math.trunc(numero).toLocaleString('es-ES') + '%';
    // };

    // const formatoNumero = (numero) => {
    //     if (isNaN(numero)) return '0%'; // Si el número es inválido, retorna '0%'

    //     // Limitar el valor máximo a 100%, pero permitir valores negativos
    //     if (numero > 100) {
    //         numero = 100;
    //     }

    //     // Mantener los valores negativos
    //     return numero.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'; // Formateo con separador
    // };

    // Función para formatear números como en PHP
    function formatoNumero(numero) {
        // Si el número es NaN, retorna '0%'
        if (isNaN(numero)) {
            return '0%';
        }
    
        // Formatea el número con dos decimales, pero quita los decimales si son innecesarios (para negativos también)
        return new Intl.NumberFormat('es-ES', {
            minimumFractionDigits: 0,  // Ajusta a 0 si quieres quitar decimales innecesarios
            maximumFractionDigits: 2   // Máximo 2 decimales si son necesarios
        }).format(Math.round(numero)) + '%';
    }


    // Verificación para asegurarse de que datosInd es un array de objetos válido y tiene elementos
    if (Array.isArray(datosInd) && datosInd.length > 0) {
        console.log("datosInd tiene datos válidos");

        // Verificamos si 'IndProIcoNoRep' es undefined, 0 o mayor a 1
        if (typeof datosInd['IndProIcoNoRep'] === 'undefined' ||
            datosInd['IndProIcoNoRep'] === 0 || datosInd['IndProIcoNoRep'] > 1) {
            // Asignar el valor 2 a 'IndProIcoNoRep'
            datosInd['IndProIcoNoRep'] = 2;
        }

        const replacements = {
            'Á': 'A',
            'á': 'a',
            'Í': 'I',
            'í': 'i'
        };

        switch (datosInd['IndProIcoNoRep']) {
            case 1:
                console.log("hola")
                break;
            case 2:

                datosInd.forEach(row => {
                    if (!isNaN(row['Resultado Indicador'])) { // Verificar si 'Resultado Indicador' es numérico
                        console.log("mundo", row)
                        recalculopeso = recalculopeso + row['Ponderacion']; // Sumar la ponderación
                    } // fin condicion
                }); // fin forEach

                console.log("recalculopeso", recalculopeso)

                datosInd.forEach(row => {

                    if (row['Meta'] !== '') {
                        console.log("no está vacío");
                    }


                    if (row['Meta'] !== '' && row['Resultado Indicador'] !== '' && !isNaN(row['Resultado Indicador'])) {
                        // deseas reemplazar y los valores son los caracteres que los reemplazarán
                        const Criterioo = row['Criterio'].replace(/[ÁáÍí]/g, (match) => replacements[match] || match);

                        // Convertir 'Criterioo' a minúsculas como strtolower en PHP
                        const CriteriooLower = Criterioo.toLowerCase();

                        // Usar el valor convertido en minúsculas en el switch
                        switch (CriteriooLower) {
                            // case 'minimo':
                            //     if (row['Meta'].includes('%')) {
                            //         // Elimina el símbolo '%' y divide el resultado por 100
                            //         meta = parseFloat(row['Meta'].replace('%', '')) / 100;
                            //         result = row['Resultado Indicador'] / meta;
                            //         if (meta <= row['Resultado Indicador']) {
                            //             cumplimiento = 1;
                            //         } else {
                            //             cumplimiento = result;
                            //         }
                            //     } else {
                            //         result = row['Resultado Indicador'] / row['Meta'];

                            //         console.log("result result result✅✅✅✅", result) // 4.95

                            //         if (row['Meta'] <= row['Resultado Indicador']) {
                            //             cumplimiento = 1;
                            //         } else {
                            //             cumplimiento = result;
                            //         }

                            //         // console.log("cumplimiento cumplimiento cumplimiento✅✅✅✅", row['Meta'], row['Resultado Indicador']) // 4.95
                            //     }// fin condición
                            //     break;

                            case 'minimo':
                                if (row['Meta'].includes('%')) {
                                    meta = parseFloat(row['Meta'].replace('%', '')) / 100;
                                    result = row['Resultado Indicador'] / meta;
                                    cumplimiento = meta <= row['Resultado Indicador'] ? 1 : result;
                                } else {
                                    result = row['Resultado Indicador'] / row['Meta'];
                                    cumplimiento = row['Meta'] <= row['Resultado Indicador'] ? 1 : result;
                                }
                                break;

                            // case 'maximo':
                            //     if (row['Meta'].includes('%')) {
                            //         // Elimina el símbolo '%' y divide el resultado por 100
                            //         meta = parseFloat(row['Meta'].replace('%', '')) / 100;
                            //         if (row['Resultado Indicador'] === 0 || row['Resultado Indicador'] === null) {
                            //             result = 0;
                            //         } else {
                            //             result = meta / row['Resultado Indicador'];
                            //         } // fin condición

                            //         if (meta >= row['Resultado Indicador']) {
                            //             cumplimiento = 1;
                            //         } else {
                            //             cumplimiento = result;
                            //         } // fin condición
                            //     } else {
                            //         if (row['Resultado Indicador'] === 0 || row['Resultado Indicador'] === null) {
                            //             result = 0;
                            //         } else {
                            //             result = row['Meta'] / row['Resultado Indicador'];
                            //         }// fin condición

                            //         if (row['Meta'] >= row['Resultado Indicador']) {
                            //             cumplimiento = 1;
                            //         } else {
                            //             cumplimiento = result;
                            //         }// fin condición
                            //     }// fin condición
                            //     break;

                            case 'maximo':
                                if (row['Meta'].includes('%')) {
                                    meta = parseFloat(row['Meta'].replace('%', '')) / 100;
                                    result = meta / (row['Resultado Indicador'] || 1);
                                    cumplimiento = meta >= row['Resultado Indicador'] ? 1 : result;
                                } else {
                                    result = row['Meta'] / (row['Resultado Indicador'] || 1);
                                    cumplimiento = row['Meta'] >= row['Resultado Indicador'] ? 1 : result;
                                }
                                break;


                            case 'rango':
                                if (row['Resultado Indicador'] === 0 || row['Resultado Indicador'] == null) {
                                    cumplimiento = 'Cum sin Datos';
                                } else {
                                    [meta1, meta2] = row['Meta'].split("-").map(parseFloat);

                                    if (row['Meta'].includes('%')) {
                                        meta_1 = meta1 / 100;
                                        meta_2 = meta2 / 100;
                                    }

                                    if (row['Resultado Indicador'] < meta1) {
                                        result = row['Resultado Indicador'] / meta1;
                                        cumplimiento = meta1 <= row['Resultado Indicador'] ? 1 : result;
                                    }

                                    if (row['Resultado Indicador'] > meta2) {
                                        result = meta2 / row['Resultado Indicador'];
                                        cumplimiento = meta2 >= row['Resultado Indicador'] ? 1 : result;
                                    }

                                    if (row['Resultado Indicador'] >= meta1 && row['Resultado Indicador'] <= meta2) {
                                        cumplimiento = 1;
                                    }
                                }
                                break;

                            default:
                                cumplimiento = 'Criterio erroneo';
                                break;
                        }
                    } else {
                        cumplimiento = 'Cum Sin Datos';
                    }


                    if (!isNaN(cumplimiento)) {
                        cummeta += cumplimiento * 100;
                    } else {
                        cummetasindat++;
                    }

                    if (row['Ponderacion'] === null || row['Ponderacion'] === '' || row['Ponderacion'] === 0) {
                        pf++;
                    } // fin condición


                    // if (row['Ponderacion'] !== '' && cumplimiento !== '' && !isNaN(row['Ponderacion']) && !isNaN(cumplimiento)) {
                    //     pesofinal = row['Ponderacion'] * cumplimiento;

                    //     if (!isNaN(row['Resultado Indicador'])) {
                    //         pesofinal2 = row['Ponderacion'] / recalculopeso * 100 * cumplimiento;
                    //         desempeño2 = desempeño2 + pesofinal2
                    //     } else {
                    //         pesofinal = 0
                    //     }// fin condición
                    // }// fin condición

                    // if (row['Ponderacion'] && !isNaN(row['Ponderacion']) && !isNaN(cumplimiento)) {
                    if (row['Ponderacion'] !== '' && cumplimiento !== '' && !isNaN(Number(row['Ponderacion'])) && !isNaN(Number(cumplimiento))) {
                        pesofinal = row['Ponderacion'] * cumplimiento;

                        if (!isNaN(row['Resultado Indicador'])) {
                            pesofinal2 = (row['Ponderacion'] / recalculopeso) * 100 * cumplimiento;
                            desempeño2 = desempeño2 + pesofinal2;
                        } else {
                            pesofinal = 0;
                        }
                    }

                    desempeño = desempeño + pesofinal

                    c++
                    f++

                    // Lógica para asignar valor a `resultado` basado en `cummetasindat`
                    // if (cummetasindat > 0) {
                    //     resultado = formatoNumero(desempeño2);
                    // } else {
                    //     resultado = formatoNumero(desempeño);
                    // }

                    // Lógica para asignar valor a `resultado` basado en `cummetasindat`
                    if (cummetasindat > 0) {
                        resultado = formatoNumero(desempeño2);  // Formatear desempeño2
                    } else {
                        resultado = formatoNumero(desempeño);   // Formatear desempeño
                    }
                })


                break;
            default:
                console.log("Ninguno")
                break;
        }
    } else {
        console.log("datosInd no tiene la estructura esperada");
        return (
            <p>
                sin indicadores
            </p>
        );
    }

    desempeño = desempeño2

    if ((desempeño) >= 95) {
        verde = 'color:lime;box-shadow: 0px 0px 10px 0px lime;';
    } else if ((desempeño) >= 80 && (desempeño) < 95) {
        amarillo = 'color:#fbff00;box-shadow: 0px 0px 10px 0px #fbff00;';
    } else {
        rojo = 'color:#ff0000;box-shadow: 0px 0px 10px 0px #ff0000;';
    }

    // Retorna las variables para debugging si es necesario
    return { verde, amarillo, rojo, resultado, recalculopeso, c, f, pf };
};
