import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';

const GraficaVentasLinea = ({ dataZona }) => {

    const zonaColors = ['#FF6D60', '#0A6EBD'];

    const nombresMeses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    console.log("dataZona", dataZona)

    const transformDataForChart = (dataZona) => {
        // Crear un array de meses del 1 al 12
        const meses = Array.from({ length: 12 }, (_, index) => index + 1);

        // Mapear los datos de acuerdo al formato necesario para Recharts
        const data = meses.map((mes) => {
            const monthData = {
                meses: nombresMeses[mes - 1], // Mes en el eje X
            };

            // Mapear las zonas y agregar sus valores
            dataZona.forEach((zona, index) => {
                const zonaKey = zona.linea === "MEDICAMENTOS" ? "Medicamentos" : "Dispositivos Médicos";
                const valorMes = zona[mes];

                // Asignar cero si el valor del mes es nulo
                monthData[zonaKey] = valorMes !== null ? valorMes : 0;
            });

            return monthData;
        });

        return data;
    };

    // Llamar a la función para obtener los datos transformados
    const dataForChart = transformDataForChart(dataZona);

    console.log("dataZona LINEA", dataForChart)

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Typography variant="h6" gutterBottom >
                Ventas de Medicamentos y Dispositivos Medicos</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={dataForChart}
                    margin={{
                        top: 30,
                        right: 30,
                        left: 20,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="meses" />
                    <YAxis tickFormatter={(value) => {
                        if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                        return value; // Valor original si no supera los miles
                    }}
                        tick={{ fontSize: 14 }}
                    />
                    <Tooltip
                        formatter={(value) => new Intl.NumberFormat().format(value)}
                    />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="Medicamentos"
                        name="Medicamentos"
                        stroke={zonaColors[1]}
                    />
                    <Line
                        type="monotone"
                        dataKey="Dispositivos Médicos"
                        name="Dispositivos Médicos"
                        stroke={zonaColors[0]}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>

    )
}

export default GraficaVentasLinea