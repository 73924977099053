import React, { useContext, useEffect, useState } from 'react'
import {
    Box, Grid, Paper, Stack, Typography, TextField,
    Alert,
    // Skeleton
} from '@mui/material';

import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../loginContext';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const defaultTheme = createTheme();


const ModalPerspectivaEmpresa = ({ selectedID, setOpenDrawer,
    ObtenerPerspectivaEmpresa, openDrawer }) => {
    const { currentUser } = useContext(AuthContext);

    const fecha = new Date();
    // Formatear la fecha como 'YYYY-MM-DDTHH:mm:ss'
    const formattedFecha = fecha.toISOString().slice(0, 19).replace('T', ' ');


    const [perEmpNom, setPerEmpNom] = useState("")
    const [perEmpDes, setPerEmpDes] = useState("")
    const [perEmpPor, setPerEmpPor] = useState("")
    const [perEmpObs, setPerEmpObs] = useState("")
    const [perEmpUsu, setPerEmpUsu] = useState(currentUser.userData?.UsuCod)
    const [perEmpFec, setPerEmpFec] = useState(null)

    // console.log("selectedID", selectedID)

    const EventoClick = async () => {
        try {
            // Validar que los campos obligatorios no estén vacíos
            if (!perEmpNom || !perEmpDes || !perEmpObs) {
                // Mostrar un mensaje de error si algún campo está vacío
                toast.error("Por favor, complete todos los campos obligatorios.", {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                return; // No continuar si hay campos vacíos
            }

            const procesoData = {
                perEmpNom: perEmpNom,
                perEmpDes: perEmpDes,
                perEmpPor: "0",
                perEmpObs: `${perEmpObs}`,
                perEmpUsu: perEmpUsu,
                perEmpFec: formattedFecha
            };
            let res;

            if (selectedID.length > 0) {
                res = await newRequest.put(
                    `/api/Calidad/PerspectivaEmpresa/ActualizarPerspectivaEmpresa/${selectedID[0].perEmpSec}`, procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                res = await newRequest.post("/api/Calidad/PerspectivaEmpresa/CrearPerspectivaEmpresa", procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            }
            if (res.status === 200) {
                toast.success(
                    `Perspectiva ${selectedID.length > 0 ? "actualizada" : "creada"} correctamente`,
                    {
                        position: "bottom-left",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                ObtenerPerspectivaEmpresa();
                setOpenDrawer(false)
            }
        } catch (error) {
            console.log("error", error)
            toast.error(
                `Error ${selectedID.length > 0 ? "al actualizar" : "crear"} `,
                {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    }

    useEffect(() => {
        if (!openDrawer) {
            setPerEmpNom("")
            setPerEmpDes("")
            setPerEmpObs("")
            setPerEmpPor("")
        }
    }, [openDrawer]);

    useEffect(() => {
        if (selectedID) {
            setPerEmpNom(selectedID[0].perEmpNom || "");
            setPerEmpDes(selectedID[0].perEmpDes || "");
            setPerEmpObs(selectedID[0].perEmpObs || "")
            setPerEmpPor(selectedID[0].perEmpPor || "0")
            setPerEmpFec(selectedID[0].perEmpFec || "")
        } else {
            // Si selectedID está vacío, asegúrate de limpiar los campos
            setPerEmpNom("")
            setPerEmpDes("")
            setPerEmpObs("")
            setPerEmpPor("")
        }
    }, [selectedID]);

    return (
        <div>
            <Paper elevation={0} sx={{ p: { xs: 2, md: 3 } }}>
                <Box sx={{
                    marginBottom: "5px", display: "flex", alignContent: "right",
                    justifyContent: "right", marginTop: "2.5rem"
                }}>

                </Box>

                <Box sx={{
                    display: "flex", alignContent: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {/* {selectedID.length > 0 ? "Actualiza un proceso" : " Crear un proceso"} */}
                        {selectedID.length > 0 ? "Actualiza una Perspectiva" : "Crear una Perspectiva"}
                    </Typography>
                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? (
                            <span style={{ marginLeft: "3px" }}>  id: {selectedID[0]?.perEmpSec}</span>
                        ) : ""}
                    </Typography>
                </Box>
                <div style={{ height: "10px" }} />
                <Divider />
                <Stack sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} spacing={2}>
                    <Alert severity="warning">
                        Los campos con * son requeridos
                    </Alert>
                </Stack>
                <ThemeProvider theme={defaultTheme}>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <Box component="form" noValidate sx={{ width: "100%" }}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography variant="subtitle2">
                                            Usuario:
                                        </Typography>
                                        <span style={{ marginLeft: "8px" }}>{currentUser.userData?.UsuNom}</span>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={7} sx={{ marginTop: "10px" }}>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography variant="subtitle2">
                                            Fecha:
                                        </Typography>
                                        {selectedID.length > 0 ?
                                            <span style={{ marginLeft: "8px" }}>{perEmpFec}</span>
                                            :
                                            <span style={{ marginLeft: "8px" }}>{fecha.toLocaleDateString()}</span>
                                        }
                                        {/* {perEmpFec} */}
                                        {/* <span style={{ marginLeft: "8px" }}>{fecha.toLocaleDateString()}</span> */}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Nombre *
                                    </Typography>
                                    <TextField size='small'
                                        value={perEmpNom}
                                        onChange={(e) => setPerEmpNom(e.target.value)}
                                        fullWidth autoComplete='off'
                                        id="outlined-multiline-static"
                                        InputLabelProps={{
                                            style: { color: '#222831', }, // Change label color here
                                        }}
                                        inputProps={{
                                            maxLength: 100 // Limitar a 100 caracteres
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Descripción *
                                    </Typography>
                                    <TextField fullWidth multiline autoComplete='off'
                                        value={perEmpDes} size='small'
                                        onChange={(e) => setPerEmpDes(e.target.value)}
                                        rows={3} id="outlined-multiline-static"
                                        InputLabelProps={{
                                            style: { color: '#222831', }, // Change label color here
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Observación *
                                    </Typography>
                                    <TextField fullWidth multiline autoComplete='off'
                                        value={perEmpObs} size='small'
                                        onChange={(e) => setPerEmpObs(e.target.value)}
                                        rows={6} id="outlined-multiline-static"
                                        InputLabelProps={{
                                            style: { color: '#222831', }, // Change label color here
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained"
                                    onClick={EventoClick} sx={{ background: "#1C64F2" }}
                                    fullWidth
                                >
                                    <div style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <span>{selectedID.length > 0 ? "Actualizar" : " Crear"}</span>
                                            <span> {selectedID.length > 0 ?
                                                (
                                                    <EditIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                ) : (
                                                    <AddIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                )}
                                            </span>
                                        </Typography>
                                    </div>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ThemeProvider>
            </Paper>
        </div>
    )
}

export default ModalPerspectivaEmpresa