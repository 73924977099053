import React, { useMemo } from 'react';
import CartaIndicador from './CartaIndicador/CartaIndicador';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid2';

const VistaEmpresa = React.memo(({ indicadores, alignment, selectAñoIncial, mes }) => {

    // Memoizar el mapeo de indicadores para evitar renders innecesarios
    const renderIndicadores = useMemo(() => (
        indicadores.map((item) => (
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 3 }}
                key={item.ProEmpSec}>
                <Link
                    to={`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${item.ProEmpSec}/${alignment}/${selectAñoIncial}/${mes}`}
                    style={{ textDecoration: 'none' }}
                >
                    <CartaIndicador
                        mes={mes}
                        item={item}
                        alignment={alignment}
                        selectAñoIncial={selectAñoIncial}
                        chartId={`chart-${item.ProEmpSec}`}
                    />
                </Link>
            </Grid>
        ))
    ), [indicadores, alignment, selectAñoIncial, mes]);

    return (
        <Grid container spacing={2} >
            {renderIndicadores}
        </Grid>
    );
});

export default VistaEmpresa;
