/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from 'material-react-table';
import OpcionesTabla from '../VentasDispostivosM/OpcionesTabla';
import * as XLSX from 'xlsx'; // Importar XLSX
import { saveAs } from 'file-saver'; // Importar file-saver para guardar el archivo
import DownloadIcon from '@mui/icons-material/Download';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP', // Código de moneda para el peso colombiano
    }).format(value);
};

const TablaVentasProductos = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {

    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'Codigo' && key !== 'Producto') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    const dataWithTotals = useMemo(() => dataZona.map(row => ({
        ...row,
        Total: calculateRowTotal(row),
    })), [dataZona]);

    const sumaTotal = useMemo(() => {
        return dataZona.reduce((total, row) => {
            return total + calculateRowTotal(row);
        }, 0);
    }, [dataZona]);

    // Precalcular los totales por columna
    const totalValues = {};
    const totalQuantities = {};
    meses.forEach((mes, i) => {
        const valueField = `${i + 1}V`;
        const quantityField = `${i + 1}C`;
        totalValues[valueField] = dataZona.reduce((total, row) => total + (parseFloat(row[valueField]) || 0), 0);
        totalQuantities[quantityField] = dataZona.reduce((total, row) => total + (parseFloat(row[quantityField]) || 0), 0);
    });

    const columns = [
        {
            id: 'Codigo',
            accessorKey: 'Codigo',
            header: 'Codigo',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 90,
            enableColumnActions: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <div>
                    {cell.row.original.Codigo}
                </div>
            ),
            footer: 'Total',
        },
        {
            id: 'Producto',
            accessorKey: 'Producto',
            header: 'Producto',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <div style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap", overflow: "hidden", width: "130px"
                }}>
                    {cell.row.original.Producto}
                </div>
            ),
        },
    ];

    for (let i = 0; i < meses.length; i++) {
        const valueField = `${i + 1}V`;
        const quantityField = `${i + 1}C`;

        columns.push({
            accessorKey: valueField,
            header: `${selectedOption === "b" ? meses[i] : `$${meses[i]}`}`,
            Cell: ({ cell }) => (
                <div>
                    {selectedOption === "b" ?
                        cell.getValue()?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }) :
                        cell.getValue()?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                    }
                </div>
            ),
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#1C64F2',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            size: 10,
            footer: selectedOption === "b" ?
                totalValues[valueField].toLocaleString() :
                formatCurrency(totalValues[valueField]),
        });

        if (selectedOption !== "b") {
            columns.push({
                accessorKey: quantityField,
                header: `# ${meses[i]}`,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#27AE60',
                        color: "white"
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                footer: totalQuantities[quantityField].toLocaleString(),
            });
        }
    }

    // Función para exportar a Excel
    const exportToExcel = () => {
        const orderedData = dataWithTotals.map((row) => {
            const orderedRow = {
                Codigo: row.Codigo,
                Producto: row.Producto,
            };

            meses.forEach((mes, index) => {
                const valueField = `${index + 1}V`;
                const quantityField = `${index + 1}C`;

                orderedRow[mes] = row[valueField] != null && row[valueField] !== '' ? row[valueField] : 0;
                if (selectedOption !== "b") {
                    orderedRow[`# ${mes}`] = row[quantityField] != null && row[quantityField] !== '' ? row[quantityField] : 0;
                }
            });

            orderedRow.Total = row.Total != null && row.Total !== '' ? row.Total : 0;
            return orderedRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(orderedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ventas por Producto');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Ventas_por_Producto.xlsx');
    };

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnActions: false,

        initialState: {
            columnPinning: { left: ['Codigo', 'Producto'], right: ['Total'] },
            density: 'compact'
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold">  Ventas por medicamento</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="body2" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total de los meses :
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {selectedOption === "a" || selectedOption === "b" ?
                            sumaTotal.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }) :
                            formatCurrency(sumaTotal)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <MRT_GlobalFilterTextInput table={table}  /> */}
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <IconButton onClick={exportToExcel}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        ),
        localization: MRT_Localization_ES,


        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TablaVentasProductos;
