import { Box, Skeleton } from '@mui/material'
import React from 'react'

const ImageComponent = ({ imageUrl, isLoading }) => {
    return (
        <div>
        {isLoading ? (
            <Skeleton
                sx={{
                    borderRadius: '8px',
                    position: 'relative',
                    width: '240px',
                    height: '210px',
                    overflow: 'hidden',
                }}
                animation="wave"
                variant="rectangular"
            />
        ) : (
            <Box
                sx={{
                    borderRadius: '8px',
                    position: 'relative',
                    marginLeft: '15px',
                    width: '200px',
                    height: '210px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={imageUrl}
                    alt="Imagen"
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        mixBlendMode: "darken"
                    }}
                />
            </Box>
        )}
    </div>
    )
}

export default ImageComponent