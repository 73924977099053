import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid, Paper, Stack, Typography, TextField, Alert, Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../loginContext';


import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const defaultTheme = createTheme();

const ModalObjetivo = ({ objetivosData, ObtenerObjetivo,
    setOpenDrawer, selectedID, selectPer, openDrawer }) => {

    const { currentUser } = useContext(AuthContext);

    // console.log("selectPer", selectPer)
    // console.log("selectedID", selectedID)

    // ? Opciones de la perspectiva
    const [selectedPerspectiva, setSelectedPerspectiva] = useState(null);

    const fecha = new Date();
    // Formatear la fecha como 'YYYY-MM-DDTHH:mm:ss'
    const formattedFecha = fecha.toISOString().slice(0, 19).replace('T', ' ');

    const [perEmpSec, setperEmpSec] = useState("")
    const [ObjNom, setObjNom] = useState("")
    const [ObjDesc, setObjDesc] = useState("")
    const [ObjPorParUno, setObjPorParUno] = useState("")
    const [ObjPorParDos, setObjPorParDos] = useState("")
    const [ObjObs, setObjObs] = useState("")
    const [ObjUsu, setObjUsu] = useState(currentUser.userData?.UsuCod)
    const [ObjFec, setObjFec] = useState("")


    const EventoClick = async () => {
        try {

            if (!ObjNom || !ObjDesc || !ObjObs
                || !selectedPerspectiva) {
                // Mostrar un mensaje de error si algún campo está vacío
                toast.error("Por favor, complete todos los campos obligatorios.", {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                return; // No continuar si hay campos vacíos
            }

            const procesoData = {
                perEmpSec: `${selectedPerspectiva}`, //Aqui viene el select
                ObjNom: ObjNom,
                ObjDesc: ObjDesc,
                ObjPorParUno: "0",
                // ObjPorParUno: ObjPorParUno,
                // ObjPorParDos: ObjPorParDos,
                ObjPorParDos: "0",
                ObjObs: ObjObs,
                ObjUsu: ObjUsu,
                ObjFec: formattedFecha
            };

            let response;

            if (selectedID.length > 0) {
                response = await newRequest.put(
                    `/api/Calidad/Objetivos/ActualizarObjetivo/${selectedID[0].ObjSec}`, procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                response = await newRequest.post("api/Calidad/Objetivos/CrearObjetivo", procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            }

            if (response.status === 200) {
                toast.success(
                    `Objeto ${selectedID.length > 0 ? "actualizado" : "creado"} correctamente`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setOpenDrawer(false)
                ObtenerObjetivo();
            }
        } catch (error) {
            console.log("error", error)
            toast.error(
                `Error al ${selectedID.length > 0 ? "actualizar" : "crear"} `,
                {
                    position: "bottom-center",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    }

    useEffect(() => {
        if (!openDrawer) {
            setObjNom("")
            setObjDesc("")
            setObjPorParUno("")
            setObjPorParDos("")
            setSelectedPerspectiva(null)
            setObjObs("")
        }
    }, [openDrawer]);

    useEffect(() => {
        if (selectedID) {
            setObjNom(selectedID[0].ObjNom)
            setObjDesc(selectedID[0].ObjDesc)
            setObjPorParUno(selectedID[0].ObjPorParUno || "0")
            setObjPorParDos(selectedID[0].ObjPorParDos || "0")
            setObjObs(selectedID[0].ObjObs)
            setSelectedPerspectiva(selectedID[0].perEmpSec || "")
            setObjFec(selectedID[0].ObjFec || "")
        } else {
            // Si selectedID está vacío, asegúrate de limpiar los campos
            setObjNom("")
            setObjDesc("")
            setObjPorParUno("")
            setObjPorParDos("")
            setSelectedPerspectiva(null)
            setObjObs("")
        }
    }, [selectedID]);

    return (
        <div>
            <Paper elevation={0} sx={{ p: { xs: 2, md: 3 } }}>
                <Box sx={{
                    marginBottom: "5px", display: "flex", alignContent: "right",
                    justifyContent: "right", marginTop: "2.5rem"
                }}>
                </Box>

                <Box sx={{
                    display: "flex", alignContent: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? "Actualiza un objetivo" : " Crea un objetivo"}
                    </Typography>
                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? (
                            <span style={{ marginLeft: "3px" }}>  id: {selectedID[0]?.ObjSec}</span>
                        ) : ""}
                    </Typography>
                </Box>
                {/* <div style={{ height: "00px" }} /> */}
                <Divider />
                <Stack sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} spacing={2}>
                    <Alert severity="warning">
                        Los campos con * son requeridos
                    </Alert>
                </Stack>
                <ThemeProvider theme={defaultTheme}>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate sx={{ width: "100%" }} >
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography variant="subtitle2">
                                            Usuario:
                                        </Typography>
                                        <span style={{ marginLeft: "8px" }}>{currentUser.userData?.UsuNom}</span>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography variant="subtitle2">
                                            Fecha:
                                        </Typography>
                                        {selectedID.length > 0 ?
                                            <span style={{ marginLeft: "10px" }}>{ObjFec}</span>
                                            :
                                            <span style={{ marginLeft: "10px" }}>{fecha.toLocaleDateString()}</span>}
                                    </Box>
                                </Grid>


                                {/*  Perspectiva Empresa * */}
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Perspectiva Empresa *
                                    </Typography>
                                    <Autocomplete size='small'
                                        autoComplete={false}
                                        options={selectPer}
                                        getOptionLabel={(option) => option.perEmpNom}
                                        fullWidth
                                        value={selectPer.find((option) => option.perEmpSec === selectedPerspectiva) || null}
                                        onChange={(event, newValue) => {
                                            setSelectedPerspectiva(newValue?.perEmpSec || '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField placeholder='Perspectiva '
                                                {...params}
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <div> seleccionado: {selectedPerspectiva}</div> */}
                                </Grid>
                                {/*  Nombre   */}
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Nombre *
                                    </Typography>
                                    <TextField fullWidth size='small' placeholder='Nombre del Objetivo'
                                        type="text" required value={ObjNom}
                                        name="nombre" autoComplete='off'
                                        onChange={(e) => setObjNom(e.target.value)}
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                        inputProps={{
                                            maxLength: 60 // Limitar a 100 caracteres
                                        }}
                                    />
                                </Grid>
                                {/* Descripción */}
                                <Grid item xs={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Descripción *
                                    </Typography>
                                    <TextField fullWidth size='small' placeholder='Descripción del Objetivo '
                                        name="observacion" required autoComplete='off'
                                        value={ObjDesc} onChange={(e) => setObjDesc(e.target.value)}
                                        multiline
                                        rows={3}
                                        InputLabelProps={{
                                            style: { color: '#222831' },
                                        }}
                                        inputProps={{
                                            maxLength: 200 // Limitar a 100 caracteres
                                        }}
                                    />
                                </Grid>
                                {/* Observación */}
                                <Grid item xs={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Observación *
                                    </Typography>
                                    <TextField fullWidth size='small'
                                        name="observacion" required placeholder='Observación del Objetivo '
                                        value={ObjObs} autoComplete='off'
                                        onChange={(e) => setObjObs(e.target.value)}
                                        multiline
                                        rows={6}
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained" size="large" fullWidth
                                    sx={{
                                        background: "#003da5"
                                    }}
                                    onClick={EventoClick}
                                >
                                    {selectedID.length > 0 ?
                                        (<div>
                                            <i className="fa-solid fa-pencil" style={{ marginRight: "8px" }}></i>
                                        </div>)
                                        :
                                        (<div>
                                            <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                                        </div>)
                                    }
                                    <Typography sx={{ fontSize: "13px" }}>
                                        {selectedID.length > 0 ? "Actualizar" : " Crear"}
                                    </Typography>
                                </Button>
                            </Box> */}

                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained"
                                    onClick={EventoClick} sx={{ background: "#1C64F2" }}
                                    fullWidth
                                >
                                    <div style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <span>{selectedID.length > 0 ? "Actualizar" : " Crear"}</span>
                                            <span> {selectedID.length > 0 ?
                                                (
                                                    <EditIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                ) : (
                                                    <AddIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                )}
                                            </span>
                                        </Typography>
                                    </div>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ThemeProvider>
            </Paper>
        </div>
    )
}

export default ModalObjetivo