import React, { useEffect, useState } from 'react'
import {
    Box, Grid,  Button, Typography,
    TextField,  Divider, FormControl, 
} from '@mui/material';
import { toast } from 'react-toastify';
import newRequest from '../../../../utils/newRequest';

const ModalCostos = ({ ObtenerCostos, selectedIDCosto, setOpenDrawerCosto }) => {

    const [ImpCosDes, setImpCosDes] = useState("")
    const [ImpCosVal, setImpCosVal] = useState("")

    const EventoClick = async () => {
        try {
            const procesoData = {
                ImpCosDes: ImpCosDes,
                ImpCosVal: ImpCosVal.toString(), // Asegúrate de que ImpCosVal sea una cadena

            };
            let response;

            if (selectedIDCosto.length > 0) {
                response = await newRequest.put(
                    `/api/Importaciones/parametros/ActualizarCosto/${selectedIDCosto[0].ImpCosId}`, procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                response = await newRequest.post("/api/Importaciones/parametros/CrearCosto/", procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
            }
            if (response.status === 200) {
                setOpenDrawerCosto(false)
                ObtenerCostos()
                toast.success(
                    `Dato ${selectedIDCosto.length > 0 ? "actualizado" : "creado"} correctamente`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (selectedIDCosto) {
            setImpCosDes(selectedIDCosto[0].ImpCosDes || "")
            setImpCosVal(selectedIDCosto[0].ImpCosVal || 0)
        } else {
            setImpCosVal("")
            setImpCosDes("")
        }
    }, [selectedIDCosto])

    return (
        <div>
            <Box sx={{
                marginBottom: "5px", display: "flex", alignContent: "right",
                justifyContent: "right", marginTop: "-15px"
            }}>
            </Box>

            <Box sx={{
                display: "flex", alignContent: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {selectedIDCosto.length > 0 ? "Actualiza Costo" : "Crear Costo"}
                </Typography>
                <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {selectedIDCosto.length > 0 ? (
                        <span style={{ marginLeft: "3px" }}>  id: {selectedIDCosto[0]?.ImpCosId}</span>
                    ) : ""}
                </Typography>
            </Box>

            <Divider />

            {/*  */}

            <Box
                sx={{
                    marginTop: 2,
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component="form" noValidate sx={{ width: "100%" }} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2" gutterBottom>
                                    Nombre de costo
                                </Typography>
                                <TextField fullWidth autoComplete='off' margin='dense'
                                    value={ImpCosDes} size='small' required
                                    onChange={(e) => setImpCosDes(e.target.value)}
                                    placeholder="Ingrese el nombre del costo"
                                    variant="outlined"
                                    InputProps={{
                                        // sx:{marginTop:"21px"},
                                        startAdornment: (
                                            <i style={{ marginRight: "10px" }} className="fa-solid fa-bars"></i>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2" gutterBottom>
                                    Valor del Costos
                                </Typography>
                                <TextField fullWidth autoComplete='off' type='number'
                                    value={ImpCosVal} size='small' required margin='dense'
                                    onChange={(e) => setImpCosVal(e.target.value)}
                                    placeholder="Ingrese el valor del costo"
                                    variant="outlined"
                                    InputProps={{
                                        // sx:{marginTop:"21px"},
                                        startAdornment: (
                                            <i style={{ marginRight: "10px" }} className="fa-solid fa-coins"></i>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Box sx={{ display: "grid", marginTop: 2, alignItems: "end", justifyContent: "end" }}>
                                <Button variant="contained" onClick={EventoClick}>
                                    <Typography sx={{ fontSize: "13px" }}>
                                        {/* Crear costo */}
                                        {selectedIDCosto.length > 0 ? "Actualizar Costo" : "Crear Costo"}

                                    </Typography>
                                    {selectedIDCosto.length > 0 ?
                                        (<span>
                                            <i className="fa-solid fa-pencil" style={{ marginLeft: "5px" }} ></i>
                                        </span>)
                                        :
                                        (
                                            <span>
                                                <i className="fa-solid fa-plus" style={{ marginLeft: "5px" }}></i>
                                            </span>
                                        )}

                                </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </Box>


        </div>
    )
}

export default ModalCostos