import React, { useEffect, useState } from 'react'
import TablaParametrosActividad from './TablaParametrosActividad/TablaParametrosActividad';
import TablaParametrosCostos from './TablaParametrosCostos/TablaParametrosCostos';

import {
    Box, Breadcrumbs, Grid, Typography,
    // Skeleton
} from '@mui/material';
import { Dialog, DialogContent } from '@mui/material';


import { Link } from 'react-router-dom';
import newRequest from '../../../utils/newRequest';
import ModalCostos from './TablaParametrosCostos/ModalCostos';
import ModalActividad from './TablaParametrosActividad/ModalActividad';
import SettingsIcon from '@mui/icons-material/Settings';

const Parametros = () => {

    const [openDrawerCosto, setOpenDrawerCosto] = useState(false); // Estado para abrir y cerrar el modal
    const [openDrawerActividad, setOpenDrawerActividad] = useState(false); // Estado para abrir y cerrar el modal


    const [costos, setcostos] = useState([])
    const [selectedIDCosto, setSelectedIDCosto] = useState(""); // Estado para almacenar el ID seleccionado
    const [selectedIDActividad, setSelectedIDActividad] = useState(""); // Estado para almacenar el ID seleccionado

    // Función para manejar el ID seleccionado
    const handleIDSelectedCosto = (datos) => {
        setSelectedIDCosto(datos);
    };

    // Función para manejar el ID seleccionado
    const handleIDSelectedActividad = (datos) => {
        setSelectedIDActividad(datos);
    };

    // console.log("selectedIDCosto", selectedIDCosto)

    const [actividad, setactividad] = useState([])

    const ObtenerCostos = async () => {
        try {
            const res = await newRequest.get("api/Importaciones/parametros/ObtenerCostoImportacion");
            setcostos(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const ObtenerActividadImportacion = async () => {
        try {
            const res = await newRequest.get("api/Importaciones/parametros/ObtenerActividadImportacion");
            setactividad(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        ObtenerCostos();
        ObtenerActividadImportacion();
    }, [])

    return (
        <div>
            <Box sx={{ marginTop: -6 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12} >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" to="/Farmanet/Importaciones">Importaciones</Link>
                                <Typography color="text.primary">Parametros</Typography>
                            </Breadcrumbs>
                            <Typography variant="h6" color="black" gutterBottom style={{
                                display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                                marginBottom: "25px", marginTop: "10px"
                            }}>
                                <SettingsIcon style={{ fontSize: "20px", marginRight: "10px", color: "#FF2442" }} />
                                Parametros
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} >
                    <TablaParametrosActividad setOpenDrawerActividad={setOpenDrawerActividad}
                        actividad={actividad} onIdActividad={handleIDSelectedActividad}
                        ObtenerActividadImportacion={ObtenerActividadImportacion}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} >
                    <TablaParametrosCostos costos={costos} ObtenerCostos={ObtenerCostos}
                        setOpenDrawerCosto={setOpenDrawerCosto} onIdCosto={handleIDSelectedCosto} />
                </Grid>
            </Grid>

            <Box>
                <Dialog
                    open={openDrawerCosto}
                    onClose={() => setOpenDrawerCosto(false)}
                    maxWidth="xs" // Puedes ajustar este valor según tus necesidades
                    sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <DialogContent>
                        <ModalCostos selectedIDCosto={selectedIDCosto} setOpenDrawerCosto={setOpenDrawerCosto}
                            ObtenerCostos={ObtenerCostos} />
                    </DialogContent>
                </Dialog>
            </Box>


            <Box>
                <Dialog
                    open={openDrawerActividad}
                    onClose={() => setOpenDrawerActividad(false)}
                    maxWidth="xs" // Puedes ajustar este valor según tus necesidades
                    sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <DialogContent>
                        <ModalActividad selectedIDActividad={selectedIDActividad}
                            ObtenerActividadImportacion={ObtenerActividadImportacion}
                            setOpenDrawerActividad={setOpenDrawerActividad} />
                    </DialogContent>
                </Dialog>
            </Box>

        </div>
    )
}

export default Parametros