import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts';


const PorRecaudoGrafica = ({ recaudo }) => {
    const chartRef = useRef(null);

    // console.log("recaudo", recaudo)
    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        // const colors = ['#2666CF', '#E74C3C'];

        const option = {
            // color: colors,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            grid: {
                right: '10%'
            },
            legend: {
                data: ['Ventas', 'Recaudo']
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        interval: 0, rotate: 60,
                        formatter: function (value) {
                            // Truncar si el nombre es más largo de 10 caracteres y agregar "..."
                            return value.length > 10 ? value.slice(0, 5) + '...' : value;
                        }
                    },
                    data: recaudo.map((vend) => vend['vendedor'])
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Ventas',
                    position: 'left',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        // lineStyle: {
                        //     color: colors[0]
                        // }
                    },
                    axisLabel: {
                        formatter: function (value) {
                            // Convertir el valor a millones si es mayor o igual a 1,000,000
                            if (value >= 1000000) {
                                return (value / 1000000).toFixed(1) + 'M'; // Redondea a un decimal
                            } else if (value >= 1000) {
                                return (value / 1000).toFixed(1) + 'K'; // Redondea a un decimal para miles
                            }
                            return value.toFixed(1); // Para valores menores a 1000, solo muestra el valor con un decimal
                        }
                    }
                },
                {
                    type: 'value',
                    name: 'Recaudo',
                    position: 'right',
                    alignTicks: true,
                    // offset: 80,
                    axisLine: {
                        show: true,
                        // lineStyle: {
                        //     color: colors[1]
                        // }
                    },
                    // axisLabel: {
                    //     formatter: '{value} ml'
                    // }
                    axisLabel: {
                        formatter: function (value) {
                            // Convertir el valor a millones si es mayor o igual a 1,000,000
                            if (value >= 1000000) {
                                return (value / 1000000).toFixed(1) + 'M'; // Redondea a un decimal
                            } else if (value >= 1000) {
                                return (value / 1000).toFixed(1) + 'K'; // Redondea a un decimal para miles
                            }
                            return value.toFixed(1); // Para valores menores a 1000, solo muestra el valor con un decimal
                        }
                    }
                },
            ],
            series: [
                {
                    name: 'Ventas',
                    type: 'bar',
                    data: recaudo.map((vend) => vend['ventas']),
                    smooth: true

                },
                {
                    name: 'Recaudo',
                    type: 'line',
                    yAxisIndex: 1,
                    data: recaudo.map((vend) => vend['Recaudo']),
                    smooth: true
                }
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [recaudo])

    return (
        <div>
            <div ref={chartRef} style={{ width: '100%', height: '480px' }}></div>
        </div>
    )
}

export default PorRecaudoGrafica