import React, { useEffect, useState, useContext } from 'react'
import {
    Box, Grid, Stack, Button, Typography, Select,
    TextField, Alert, Autocomplete, Divider, FormControl, InputLabel, MenuItem,
    FormHelperText, Checkbox, FormControlLabel
} from '@mui/material';

import { AuthContext } from '../../../../loginContext';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import newRequest from '../../../../utils/newRequest';

import AddIcon from '@mui/icons-material/Add';
import DrawIcon from '@mui/icons-material/Draw';

const ModalIndicadoresEmpresa = ({ setOpenDrawer, procesoEmpresa, objetivoIndicador,
    selectedID, ObtenerIndicador, obtenerProceso, obtenerCantidadIndicador }) => {

    var d = new Date();
    var n = d.getFullYear();

    const { currentUser } = useContext(AuthContext);
    // Proceso
    const [selectedProceso, setSelectedProceso] = useState(null)
    // Objeto
    const [selectedObjetico, setSelectedObjetico] = useState(null)
    const [TipoIndicador, setTipoIndicador] = useState("");
    const [PesoUno, setPesoUno] = useState("0")
    const [frecuenciaIndicador, setFrecuenciaIndicador] = useState("")
    const [resultadoIndicador, setResultadoIndicador] = useState("")
    const [medicionIndicador, setMedicionIndicador] = useState("")
    const [metaIndicador, setMetaIndicador] = useState("")
    const [signo, setSigno] = useState("")

    const [indProceso, setIndProceso] = useState("")
    const [indObjeto, setIndObjeto] = useState("")
    const [indNombre, setIndNombre] = useState("")
    const [indTipoInd, setTipoInd] = useState("")
    const [indTipoFrec, setTipoFrec] = useState("")
    const [indTipoResul, setTipoResul] = useState("")
    const [indTipoMedic, setTipoMedic] = useState("")
    const [indTipoMeta, setTipoMeta] = useState("")
    const [indMetaUno, setMetaUno] = useState("") //Recibe % o numero
    const [indMetaDos, setMetaDos] = useState("") //Recibe % o numero
    const [indDatoUno, setindDatoUno] = useState("")
    const [indDatoDos, setindDatoDos] = useState("")
    // const [IndProSPSUC, setIndProSPSUC] = useState("")
    // const [indProUrl, setindProUrl] = useState("")
    const [tipoProCod, setTipoProCod] = useState("")
    const [checkIndProFSUC, setCheckIndProFSUC] = useState('N'); // Inicialmente configurado en 'S'



    const [camboSigno, setcamboSigno] = useState(null)

    const handleChangeTipo = (event) => {
        setTipoIndicador(event.target.value)
    }

    const hanleChangeFrecuencia = (event) => {
        setFrecuenciaIndicador(event.target.value)
    }

    const hanleChangeResultado = (event) => {
        setResultadoIndicador(event.target.value)
    }

    const hanleChangeMedicion = (event) => {
        setMedicionIndicador(event.target.value)
    }

    const hanleChangeMeta = (event) => {
        setMetaIndicador(event.target.value)
    }


    const hanleChangeSigno = async (event) => {
        setSigno(event.target.value)
        // setguardar(true)
        // Valida si hay datos en ese indicador

        try {
            const res = await
                newRequest.get(`/api/Calidad/Indicadores/ValdiarCambioData/${selectedID[0]?.IndProEmpSec}/${selectedID[0].ProEmpSec}`);
            // console.log("res.data", res.data);

            if (res.status === 200 || res.status === 304) {
                // console.log("Hay datos asociados al indicador.");
                setcamboSigno(false)
            } else {
                // console.log("No hay datos asociados al indicador.");
                setcamboSigno(false)
            }
        } catch (error) {
            // Manejar errores de solicitud, incluyendo errores 404
            // console.error("Error:", error);
            setcamboSigno(true)
            // alert("No hay datos asociados al indicador.");
        }
    }
    // console.log("selectedObjetico", selectedObjetico)


    const handleChangeProFSUC = (event) => {
        // Cambia el estado a 'S' si está marcado, 'N' si no está marcado
        setCheckIndProFSUC(event.target.checked ? 'S' : 'N');
        // setCheckIndProFSUC(event.target.checked);
    };

    const handleMetaChange = (e) => {
        const value = e.target.value;
        // Utiliza una expresión regular para validar el formato
        const regex = /^\d+(\.\d+)?%?$/;
        if (value === '' || regex.test(value)) {
            setMetaUno(value);
        }
    };

    const handleMetaChangeDos = (e) => {
        const value = e.target.value;
        // Utiliza una expresión regular para validar el formato
        const regex = /^\d+(\.\d+)?%?$/;
        if (value === '' || regex.test(value)) {
            setMetaDos(value);
        }
    };

    const EventoClick = async () => {
        if (metaIndicador === "Rango") {

            if (indMetaUno !== indMetaDos) {
                // Si los campos son diferentes, verifica si alguno de los campos contiene un porcentaje
                if (
                    (indMetaUno.includes('%') || indMetaDos.includes('%')) &&
                    (!indMetaUno.includes('%') || !indMetaDos.includes('%'))
                ) {
                    // Muestra un mensaje de error si no se cumple la condición
                    toast.error(
                        'Los valores de la meta deben coincidir: ambos campos deben ser porcentuales o numéricos.',
                        {
                            position: 'top-center',
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'dark',
                        }
                    );
                    return; // No permite guardar
                }
            }
        }

        try {


            if (!indNombre || !indMetaUno || !selectedProceso || !metaIndicador || !indDatoUno 
                || !indDatoDos || !frecuenciaIndicador || !selectedObjetico || !signo 
                || !medicionIndicador || !TipoIndicador || !resultadoIndicador) {
                toast.error("Por favor, complete todos los campos obligatorios.", {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                return; // No continuar si hay campos vacíos
            }

            const procesoData = {
                IndProAno: `${n.toString()}`,
                IndVersion: "1",
                IndProEmpNom: indNombre, //Nombre del indicador
                IndProEmpCod: "1", // El numero de la empresa
                IndProEmpresa: indMetaUno, // Meta uno del indicador 
                IndProEmpresaDos: indMetaDos, // Meta dos del indicador 
                ProEmpSec: selectedProceso, // Proceso de la empresa
                IndProMetCri: metaIndicador, // Meta indicador
                IndProRel: indDatoUno, // Dato uno
                IndProRelDos: indDatoDos, // Dato dos
                IndProFre: frecuenciaIndicador, //Frecuencia del indicador
                IndProIcoNoRep: "",
                IndProPesoUno: "0", // Se guarda con 0 pero se actualiza
                // IndProPesoUno: PesoUno,
                IndProPesoDos: "0",
                ObjSec: selectedObjetico, //Objeto 
                IndProPesoObj: "0", // ? 
                IndProSig: signo, // Signo de la operación
                IndProTipCod: medicionIndicador, // Tipo de medición
                tipoProCod: TipoIndicador, //Tipo de indicador
                IndProFSUC: checkIndProFSUC,
                IndProSPSUC: "",
                indProObj: "",
                indProUrl: "",
                IndProTipCod2: resultadoIndicador, //Tipo de resultado
            }

            let response;


            if (camboSigno === true || camboSigno === null) {
                if (selectedID.length > 0) {
                    response = await newRequest.put(
                        `/api/Calidad/Indicadores/ActuIndicador/${selectedID[0].IndProEmpSec}`, procesoData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );

                } else {
                    response = await newRequest.post("/api/Calidad/Indicadores/CrearIndicador", procesoData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
                }
            }
            else {
                toast.warn('No se puede modificar porque ya el indicador tiene datos !', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }

            if (response.status === 200) {
                toast.success(
                    `indicador ${selectedID.length > 0 ? "actualizado" : "creado"} correctamente`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                obtenerProceso();
                ObtenerIndicador();
                setOpenDrawer(false)
                obtenerCantidadIndicador() // Actualiza la cantidad de indicadores del proceso
            }
        } catch (error) {
            console.error("Error ->", error)
        }
    }

    useEffect(() => {
        if (selectedID) {
            setIndNombre(selectedID[0]?.IndProEmpNom || "")
            setMetaUno(selectedID[0]?.IndProEmpresa || "")
            setMetaDos(selectedID[0]?.IndProEmpresaDos || "")
            setSelectedProceso(selectedID[0]?.ProEmpSec || "")
            setSelectedObjetico(selectedID[0]?.ObjSec || "")
            setTipoIndicador(selectedID[0]?.tipoProCod || "")
            setFrecuenciaIndicador(selectedID[0]?.IndProFre || "")
            setResultadoIndicador(selectedID[0]?.IndProTipCod2 || "")
            setMedicionIndicador(selectedID[0]?.IndProTipCod || "")
            setMetaIndicador(selectedID[0]?.IndProMetCri || "")
            // setindProUrl(selectedID[0]?.indProUrl || "")
            // setIndProSPSUC(selectedID[0]?.IndProSPSUC || "")
            setindDatoUno(selectedID[0]?.IndProRel || "")
            setindDatoDos(selectedID[0]?.IndProRelDos || "")
            setSigno(selectedID[0]?.IndProSig || "")
            setCheckIndProFSUC(selectedID[0]?.IndProFSUC || "")
            setPesoUno(selectedID[0]?.IndProPesoUno || "")
        } else {
            // Si selectedID está vacío, asegúrate de limpiar los campos
            setIndNombre("")
            setMetaUno("")
            setMetaDos("")
            setSelectedProceso("")
            setSelectedObjetico("")
            setTipoIndicador("")
            setFrecuenciaIndicador("")
            setResultadoIndicador("")
            setMedicionIndicador("")
            setMetaIndicador("")
            // setindProUrl("")
            // setIndProSPSUC("")
            setindDatoUno("")
            setindDatoDos("")
            setSigno("")
            setCheckIndProFSUC("")
            setPesoUno("")
        }
    }, [selectedID]);


    return (
        <Box>
            <Box sx={{
                marginBottom: "5px", display: "flex", alignContent: "right",
                justifyContent: "right", marginTop: "-15px"
            }}>
            </Box>

            <Box sx={{
                display: "flex", alignContent: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    Crear indicador
                </Typography>
            </Box>

            <Divider />

            {/* <Stack sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} spacing={2}>
                <Alert severity="warning">
                    Los campos con * son requeridos
                </Alert>
            </Stack> */}
            <Box
                sx={{
                    marginTop: 2,
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component="form" noValidate sx={{ width: "100%" }} >
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Autocomplete size='small' autoComplete='off'
                                    options={procesoEmpresa}
                                    fullWidth
                                    value={procesoEmpresa.find((option) => option.ProEmpSec === selectedProceso) || null}
                                    getOptionLabel={(option) => option.ProEmpNom}
                                    onChange={(event, newValue) => {
                                        setSelectedProceso(newValue?.ProEmpSec || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined" label='Seleccione el proceso'
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Autocomplete size='small' autoComplete='off'
                                    options={objetivoIndicador}
                                    fullWidth
                                    value={objetivoIndicador.find((option) => option.ObjSec === selectedObjetico) || null}
                                    getOptionLabel={(option) => option.ObjNom}
                                    onChange={(event, newValue) => {
                                        setSelectedObjetico(newValue?.ObjSec || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined" label="Seleccione el objetivo"
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                        />
                                    )}
                                />
                                {/* <div>Objetivo seleccionado: {selectedObjetico}</div> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            {/* IndProEmpNom */}
                            <FormControl fullWidth>
                                {/* <Typography variant="body1" gutterBottom>
                                    Nombre Indicador  *
                                </Typography> */}
                                <TextField type="text" label="Nombre"
                                    fullWidth autoComplete='off' required name='indNombre'
                                    size='small' value={indNombre}
                                    onChange={(e) => setIndNombre(e.target.value)}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* tipoProCod */}
                            <FormControl fullWidth margin='dense' size="small" >
                                <InputLabel >Tipo de Indicador *</InputLabel>
                                <Select
                                    value={TipoIndicador}
                                    label="Tipo de Indicador"
                                    onChange={handleChangeTipo}
                                >
                                    <MenuItem value="ESTRA">Estrategico</MenuItem>
                                    <MenuItem value="OPERA">Operativo</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <div>Tipo seleccionado: {TipoIndicador}</div> */}
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* IndProFre */}
                            <FormControl fullWidth margin='dense' size="small" >
                                <InputLabel>Frecuencia</InputLabel>
                                <Select size='small'
                                    value={frecuenciaIndicador}
                                    label="Frecuencia"
                                    onChange={hanleChangeFrecuencia}
                                >
                                    <MenuItem value="Mensual">Mensual</MenuItem>
                                    <MenuItem value="Bimestral">Bimestral</MenuItem>
                                    <MenuItem value="Trimestral">Trimestral</MenuItem>
                                    <MenuItem value="Cuatrimestral">Cuatrimestral</MenuItem>
                                    <MenuItem value="Semestral">Semestral</MenuItem>
                                    <MenuItem value="Anual">Anual</MenuItem>
                                </Select>
                                {/* <div>Tipo seleccionado: {frecuenciaIndicador}</div> */}
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={3}>
                            {/* IndProTipCod2 */}
                            <FormControl fullWidth margin='dense' size="small" >
                                <InputLabel>Tipo Resultado</InputLabel>
                                <Select size='small'
                                    value={resultadoIndicador}
                                    label="Tipo Resultado"
                                    onChange={hanleChangeResultado}
                                >
                                    <MenuItem value="ULT">Último</MenuItem>
                                    <MenuItem value="ACU">Acumulado</MenuItem>
                                    <MenuItem value="PRO">Promedio</MenuItem>

                                </Select>
                                {/* <div>Tipo seleccionado: {resultadoIndicador}</div> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {/* IndProTipCod */}
                            <FormControl fullWidth margin='dense' size="small" >
                                <InputLabel>Tipo Medición</InputLabel>
                                <Select
                                    value={medicionIndicador}
                                    label="Tipo Medición"
                                    onChange={hanleChangeMedicion}
                                >
                                    <MenuItem value="ULT">Último</MenuItem>
                                    <MenuItem value="ACU">Acumulado</MenuItem>
                                    <MenuItem value="PRO">Promedio</MenuItem>
                                </Select>
                                {/* <div>Tipo seleccionado: {medicionIndicador}</div> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth size="small" sx={{ marginTop: 1 }}>
                                <InputLabel>Tipo de Meta  *</InputLabel>
                                <Select
                                    value={metaIndicador}
                                    label="Tipo de Meta  *"
                                    onChange={hanleChangeMeta}
                                >
                                    <MenuItem value="Maximo">Maximo</MenuItem>
                                    <MenuItem value="Minimo">Minimo</MenuItem>
                                    <MenuItem value="Rango">Rango</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText id="component-helper-text">
                                Seleccione la meta
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={metaIndicador === 'Rango' ? 4 : 8}>
                            <FormControl fullWidth margin='dense'>
                                <TextField
                                    type='text'
                                    value={indMetaUno}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    label="Meta, ejemplo 80% o 80"
                                    name='indMetaUno'
                                    variant="outlined"
                                    onChange={handleMetaChange}
                                />
                                <FormHelperText id="component-helper-text">
                                    meta del indicador (números o porcentaje)
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        {metaIndicador === 'Rango' ? (
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth margin='dense'>
                                    <TextField
                                        type='text'
                                        value={indMetaDos}
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        label="Segunda Meta, ejemplo 60% o 60"
                                        name='indMetaDos'
                                        variant="outlined"
                                        onChange={handleMetaChangeDos}
                                    />
                                    <FormHelperText id="component-helper-text">
                                        segunda meta (números o porcentaje)
                                    </FormHelperText>
                                </FormControl>

                            </Grid>
                        ) : null}
                    </Grid>

                    <Divider sx={{ marginBottom: "20px", marginTop: "20px" }} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <TextField fullWidth autoComplete='off'
                                label="Dato uno" multiline variant="outlined"
                                rows={4} size='small' placeholder="Descripcion del dato uno"
                                value={indDatoUno} name='indDatoUno'
                                onChange={(e) => setindDatoUno(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            {/* IndProSig */}
                            <Typography variant="body1" gutterBottom
                                sx={{ textAlign: "center", marginRight: "10px" }}>
                                Operador *
                            </Typography>
                            <FormControl fullWidth margin='dense'>
                                <Select size='small'
                                    value={signo} sx={{ marginTop: "-8px" }}
                                    onChange={hanleChangeSigno}
                                >
                                    <MenuItem value="+">Suma</MenuItem>
                                    <MenuItem value="-">Resta</MenuItem>
                                    <MenuItem value="*">Multiplicación</MenuItem>
                                    <MenuItem value="/">División</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <TextField fullWidth autoComplete='off'
                                label="Dato dos" multiline variant="outlined"
                                rows={4} size='small' placeholder="Descripcion del dato dos"
                                value={indDatoDos} name='indDatoDos'
                                onChange={(e) => setindDatoDos(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    {/* {selectedID.length > 0 ?
                        (
                            <Grid item xs={12} sm={12}>
                                <Box sx={{ marginTop: "10px" }}>
                                    <FormControlLabel
                                        checked={checkIndProFSUC === 'S'}
                                        onChange={handleChangeProFSUC}

                                        control={<Checkbox
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label="Para ver indicadores por sucursal seleccionar."
                                        labelPlacement="end"
                                    />
                                </Box>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={12}>
                            </Grid>
                        )} */}

                    <Grid item xs={12} sm={12}>
                        <Box sx={{
                            marginBottom: "5px", display: "flex", alignContent: "right",
                            justifyContent: "right", marginTop: "15px",
                        }}>
                            <Button variant="contained"
                                onClick={EventoClick} sx={{ background: "#1C64F2" }}
                                fullWidth
                            >
                                <div style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                        <span>{selectedID.length > 0 ? "Actualizar" : " Insertar"}</span>
                                        <span> {selectedID.length > 0 ?
                                            (
                                                <DrawIcon sx={{ marginLeft: "4px", width: "20px", height: "20px" }} />
                                            ) : (
                                                <AddIcon sx={{ marginLeft: "4px", width: "20px", height: "20px" }} />
                                            )}
                                        </span>
                                    </Typography>
                                </div>
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default ModalIndicadoresEmpresa