import React, { useEffect, useState } from 'react'
import { FormControlLabel, Radio } from '@mui/material';
const OpcionesTabla = ({ onOptionChange, selectedOptionPadre }) => {
    // ? Select de los Radio Buttons
    const [selectedValue, setSelectedValue] = useState("a");

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onOptionChange(newValue); // Llama a la función proporcionada por el padre
    };

    // Aqui traemos el valor desde el padre para que se guarde en el estado
    useEffect(() => {
        setSelectedValue(selectedOptionPadre)
    }, [selectedOptionPadre])

    return (
        <div>
            <FormControlLabel
                control={<Radio
                    checked={selectedValue === "a"}
                    onChange={handleChange}
                    value="a"
                    sx={{
                        fontWeight: 'normal',
                    }}
                />}
                label="Valor y Cantidad"
            />
            <FormControlLabel
                control={<Radio
                    checked={selectedValue === "b"}
                    onChange={handleChange}
                    value="b"
                    sx={{
                        fontWeight: 'normal',
                    }}
                />}
                label="Costo"
            />
            {/* <FormControlLabel sx={{ marginBottom: "-10px" }}
                control={<Radio
                    checked={selectedValue === "c"}
                    onChange={handleChange}
                    value="c"
                    sx={{
                        align: 'center',
                        fontWeight: 'normal',
                    }}
                />}
                label="Cantidad"
            /> */}
        </div>
    )
}

export default OpcionesTabla