import React, { useContext, useEffect, useState } from 'react'
import {
    Box, Grid, Paper, Stack, Typography, TextField, Alert,
} from '@mui/material';

import Divider from '@mui/material/Divider';
import newRequest from '../../../../utils/newRequest';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify'
import Button from '@mui/material/Button';
import { AuthContext } from '../../../../loginContext';


import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';



const ModalPoliticasEmpresa = ({ selectedID, setOpenDrawer, ObtenerPoliticas }) => {

    const { currentUser } = useContext(AuthContext);

    const fecha = new Date();
    // Formatear la fecha como 'YYYY-MM-DDTHH:mm:ss'
    const formattedFecha = fecha.toISOString().slice(0, 19).replace('T', ' ');

    const [selectedFile, setSelectedFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    const [PolEmpDes, SetPolEmpDes] = useState("")
    const [PolEmpBlob, SetPolEmpBlob] = useState("")
    const [PolEmpBlobName, SetPolEmpBlobName] = useState("")
    const [PolEmpBlobExt, SetPolEmpBlobExt] = useState("")
    const PolEmpUsuCrea = currentUser.userData?.UsuCod
    const [PolEmpFecCrea, SetPolEmpFecCrea] = useState(formattedFecha)

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // if (file.size <= 5 * 1024 * 1024) { // Verificar si el tamaño es menor o igual a 5MB
            if (file.size <= 5 * 1024 * 1024) { // Verificar si el tamaño es menor o igual a 3MB
                // setSelectedFile(file);
                // SetPolEmpBlob(file);
                // console.log("PolEmpBlob", file.type)

                SetPolEmpBlobExt(file.type)
                setSelectedFile(file);
                SetPolEmpBlob(file);

                // console.log("file", file.size)
                // Leer el contenido del archivo como un ArrayBuffer
                const reader = new FileReader();
                reader.onload = function (e) {
                    const buffer = e.target.result;
                    // console.log("buffer", buffer);
                    const imageUrl = URL.createObjectURL(file);
                    // console.log("imageUrl", imageUrl); // Verifica la URL de la imagen
                    setImageSrc(imageUrl);
                };
                reader.readAsArrayBuffer(file);
            } else {
                // El archivo es demasiado grande, muestra un mensaje de error o realiza la acción que desees.
                alert('El archivo es demasiado grande. El tamaño máximo permitido es de 3MB.');
                event.target.value = ''; // Borra el archivo seleccionado para que el usuario pueda elegir otro.
            }

            // Resto de tu código para guardar el nombre y la extensión del archivo
        } else {
            setSelectedFile(null);
            SetPolEmpBlob(null);
            SetPolEmpBlobName('');
            SetPolEmpBlobExt('');
            setImageSrc(null); // Limpia la previsualización de la imagen

        }
    };

    // Función para convertir bytes en una URL de imagen
    const convertBytesToUrl = (bytes, mimeType) => {
        if (bytes && bytes.length > 0) {
            // const blob = new Blob([new Uint8Array(bytes)], { type: 'image/jpeg' }); // Cambia 'image/jpeg' al tipo de imagen correcto si es diferente
            const blob = new Blob([new Uint8Array(bytes)], { type: mimeType }); // Cambia 'image/jpeg' al tipo de imagen correcto si es diferente
            const imageUrl = URL.createObjectURL(blob);
            return imageUrl;
        }
        return null;
    };

    useEffect(() => {
        // Cuando selectedID cambie, puedes actualizar la imagen si hay datos binarios disponibles
    }, [selectedID, imageSrc]);

    const EventoClick = async () => {
        try {
            const formData = new FormData();
            formData.append('PolEmpDes', PolEmpDes);
            formData.append('PolEmpBlob', PolEmpBlob); // Agrega el archivo al FormData
            formData.append('PolEmpBlobName', PolEmpBlobName);
            formData.append('PolEmpBlobExt', PolEmpBlobExt);
            formData.append('PolEmpUsuCrea', PolEmpUsuCrea);
            formData.append('PolEmpFecCrea', PolEmpFecCrea);

            let response;

            if (selectedID.length > 0) {
                response = await newRequest.put(
                    `/api/Calidad/Politicas/ActualizarPolitica/${selectedID[0].PolEmpSec}`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Utiliza este encabezado para enviar datos binarios
                        },
                    }
                );
            } else {
                response = await newRequest.post(
                    "/api/Calidad/Politicas/CrearPolitica", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Utiliza este encabezado para enviar datos binarios
                    },
                });
            }

            if (response.status === 200) {
                toast.success(
                    `Tipo ${selectedID.length > 0 ? "actualizado" : "creado"} correctamente`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setOpenDrawer(false)
                ObtenerPoliticas();
            }

        } catch (error) {
            // Manejo de errores
            console.error("Error en EventoClick:", error);
        }
    };

    useEffect(() => {
        if (selectedID) {
            SetPolEmpDes(selectedID[0].PolEmpDes || '');
            const mimeType = selectedID[0]?.PolEmpBlobExt
            setImageSrc(convertBytesToUrl(selectedID[0]?.PolEmpBlob.data, mimeType));
        } else {
            // Si selectedID está vacío, asegúrate de limpiar los campos y la imagen
            SetPolEmpDes('');
            setImageSrc(null);
        }
    }, [selectedID]);


    return (
        <div>
            <Paper elevation={0} sx={{ p: { xs: 1, md: 3 } }}>
                <Box sx={{
                    marginBottom: "5px", display: "flex", alignContent: "right",
                    justifyContent: "right", marginTop: "2.5rem"
                }}>

                </Box>
                <Box sx={{
                    display: "flex", alignContent: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? "Actualiza una politica" : "Crear una Politica"}
                    </Typography>
                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? (
                            <span style={{ marginLeft: "3px" }}>  id: {selectedID[0]?.PolEmpSec}</span>
                        ) : ""}
                    </Typography>
                </Box>
                <div style={{ height: "10px" }} />
                <Divider />
                <Stack sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} spacing={2}>
                    <Alert severity="warning">
                        Los campos con * son requeridos
                    </Alert>
                </Stack>

                <Box
                    sx={{
                        marginTop: 2,
                        width: "100%",
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" noValidate  >
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <Box sx={{ display: "flex" }}>
                                    <Typography variant="subtitle2">
                                        Usuario:
                                    </Typography>
                                    <span style={{ marginLeft: "8px" }}>{currentUser.userData?.UsuNom}</span>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Box sx={{ display: "flex" }}>
                                    <Typography variant="subtitle2">
                                        Fecha:
                                    </Typography>
                                    {selectedID.length > 0 ?
                                        <span style={{ marginLeft: "10px" }}>{PolEmpFecCrea}</span>
                                        :
                                        <span style={{ marginLeft: "10px" }}>{fecha.toLocaleDateString()}</span>}
                                    {/* <span style={{ marginLeft: "8px" }}>{fecha.toLocaleDateString()}</span> */}
                                </Box>
                            </Grid>


                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Descripción *
                                </Typography>
                                <TextField fullWidth autoComplete='off' size='small'
                                    rows={3} multiline value={PolEmpDes}
                                    onChange={(e) => SetPolEmpDes(e.target.value)}
                                    required placeholder='Agregue una descripción de la politica'
                                    InputLabelProps={{
                                        style: { color: '#222831' }, // Change label color here
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#EEE2DE', // Cambiar el color del borde aquí
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#61677A', // Color del borde cuando el TextField está enfocado
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                {selectedID.length > 0 ? (
                                    <div>
                                        <Typography variant="subtitle2" gutterBottom sx={{ marginBottom: 1 }}>
                                            Previsualización *
                                        </Typography>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '250px',
                                                objectFit: "cover",
                                                display: "flex", justifyContent: "center", alignItems: "center",
                                                overflowX: "visible", 
                                            }}
                                        >
                                            <div>
                                                <object
                                                    data={imageSrc}
                                                    alt="Previsualización"
                                                    width="100%"
                                                    height="250px"
                                                    style={{
                                                        width: '100%',
                                                        height: '250px',
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Typography variant="subtitle2" gutterBottom sx={{ marginBottom: 1 }}>
                                            Previsualización *
                                        </Typography>
                                        <div
                                            style={{
                                                // position: "static",
                                                width: '100%',
                                                height: '250px',
                                                objectFit: "cover",
                                                display: "flex", justifyContent: "center", alignItems: "center",
                                                // overflowX: "hidden"
                                            }}
                                        >
                                            <div>
                                                <object
                                                    data={imageSrc}
                                                    alt="Previsualización"
                                                    width="100%"
                                                    height="250px"
                                                    style={{
                                                        width: '100%',
                                                        height: '250px',
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Carga un archivo *
                                </Typography>
                                <TextField fullWidth autoComplete='off' size='small'
                                    type="file"
                                    name="PolEmpBlob" // Debe coincidir con el nombre en formData.append
                                    onChange={handleFileChange}
                                />
                            </Grid>
                        </Grid>

                        {/* <Box sx={{
                            marginBottom: "5px", display: "flex", alignContent: "right",
                            justifyContent: "right", marginTop: 3,
                        }}>
                            <Button variant="contained" size="large" fullWidth
                                sx={{ background: "#003da5" }}
                                onClick={EventoClick}
                            >
                                {selectedID.length > 0 ?
                                    (<div>
                                        <i className="fa-solid fa-pencil" style={{ marginRight: "8px" }}></i>
                                    </div>)
                                    :
                                    (<div>
                                        <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                                    </div>)
                                }
                                <Typography sx={{ fontSize: "13px" }}>
                                    {selectedID.length > 0 ? "Actualiza el proceso" : " Crear"}
                                </Typography>
                            </Button>
                        </Box> */}

                        <Box sx={{
                            marginBottom: "5px", display: "flex", alignContent: "right",
                            justifyContent: "right", marginTop: "15px",
                        }}>
                            <Button variant="contained"
                                onClick={EventoClick} sx={{ background: "#1C64F2" }}
                                fullWidth
                            >
                                <div style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                        <span>{selectedID.length > 0 ? "Actualizar" : " Crear"}</span>
                                        <span> {selectedID.length > 0 ?
                                            (
                                                <EditIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                            ) : (
                                                <AddIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                            )}
                                        </span>
                                    </Typography>
                                </div>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </div >
    )
}

export default ModalPoliticasEmpresa