import React, { useEffect, useState } from 'react'
import {
    Box, Typography, ButtonGroup, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';

import { MaterialReactTable } from 'material-react-table';
import newRequest from '../../../../utils/newRequest';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TablaPerspectivaEmpresa = ({ datosPerspectiva, setOpenDrawer, onIDSelected, ObtenerPerspectivaEmpresa }) => {

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const crearPerspectivaEmpresa = () => {
        setId("") //Devolvemos el id vacio para que no abra el modal solo
        onIDSelected("")
        setOpenDrawer(true)
    }

    const handleButtonClick = (row) => {
        try {
            setId(row.perEmpSec)
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.perEmpSec)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const EliminarunProceso = async () => {
        try {
            // Aqui recibimos el estado
            await newRequest.delete(`/api/Calidad/PerspectivaEmpresa/EliminarPerspectivaEmpresa/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-left",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            ObtenerPerspectivaEmpresa();
            // getProcesos()
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (id) {
            const ObtenerUnDatoProceso = async () => {
                try {
                    const res = await newRequest.post(`/api/Calidad/PerspectivaEmpresa/UnDatoPerspectivaEmpresa`, {
                        id: id
                    });
                    setOpenDrawer(true) // Abre el modal
                    onIDSelected(res.data); // Llama a la función con el ID como argumento
                    setId("")
                } catch (error) {
                    console.error(error);
                }
            }

            ObtenerUnDatoProceso()
        } else {
            // console.log("creando")
        }
    }, [id, idDos, onIDSelected, setOpenDrawer])


    const columns = [
        {
            accessorKey: 'perEmpNom',
            header: 'Nombre',
            // size: 150,
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.perEmpNom}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.perEmpNom}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'perEmpDes', //normal accessorKey
            header: 'Descripción',
            // size: 200,
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.perEmpDes}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.perEmpDes}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: "perEmpObs",
            header: "Observaciones",
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.perEmpObs}`} arrow>
                        <Box>
                        <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.perEmpObs}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'perEmpUsu',
            header: 'Usuario',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white",
                    width: "60px", maxWidth: "60px"
                }
            },
            size: 90,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    // height: "40px",  // Ajusta este valor según tus necesidades
                    maxHeight: "40px",
                    width: "60px", maxWidth: "60px"
                }
            },
        },
        {
            accessorKey: 'perEmpFec',
            header: 'Fecha de creación',
            // size: 150,
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            Cell: ({ cell }) => {
                return (
                    < div >

                        <ButtonGroup variant="text" >
                            <Tooltip title="Editar">
                                <Button onClick={() => handleButtonClick(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EditIcon sx={{ width: '25px', height: '25px', color: '#118DF0' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Eliminar">
                                <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <DeleteIcon sx={{ width: '25px', height: '25px', color: '#FF2442' }} />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div >
                )
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
    ];


    return (
        <div>

            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este perspectiva?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarunProceso();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>


            {/* <ThemeProvider theme={tableTheme}> */}

            <MaterialReactTable
                columns={columns}
                data={datosPerspectiva}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        // borderRadius: '20px',
                        // border: '1px solid  #e0e0e0',
                    },
                }}
                localization={MRT_Localization_ES}
                enableStickyHeader
                renderTopToolbarCustomActions={({ table }) => (
                    // <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    <Button variant="outlined"
                        onClick={crearPerspectivaEmpresa}
                    >
                        <Typography sx={{ fontSize: "13px" }}>
                            Crear Perspectiva
                        </Typography>

                        <AddCircleIcon style={{ marginLeft: "5px", fontSize: "19px" }} />
                    </Button>
                    // </Box>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        // borderRight: '2px solid #e0e0e0', //add a border between columns
                        fontWeight: 'normal',
                        fontSize: '16px !important',
                    },
                }}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFilters={false}
                muiTableBodyCellProps={{
                    sx: {
                        // fontSize: '0.9rem !important',
                        // height: "45px !important",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                }}
                muiTableContainerProps={{
                    sx: { height: 450, width: "100%", }
                }}
            />
        </div>
    )
}

export default TablaPerspectivaEmpresa