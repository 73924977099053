import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

import Grid from '@mui/material/Grid2';

const SelectorPanel = ({
    añoSelect, selectAñoIncial, añoInicial, meses, mes, handleChange,
    indicadoresNombre, selectedIndicador, handleChangeNombre, alignment, handleChangeTipo, nombreInd
}) => {
    return (
        <>
            <Box sx={{ justifyContent: "space-between" }}>
                <Grid container spacing={2} >
                    {/* Selección de año */}
                    <Grid size={{ xs: 8 }} sx={{ display: "flex", gap: 2 }} >
                        <FormControl
                            sx={{
                                width: { xs: "100%", md: "200px", borderRadius: "8px", }, // 100% para xs y 200px para md y superior
                                background: "white",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#e0e0e0", // Borde verde
                                        borderRadius: "8px",
                                    },
                                },
                            }}
                            size="small">
                            <InputLabel>Año</InputLabel>
                            <Select
                                label="Mes"
                                value={selectAñoIncial}
                                onChange={(event) => añoInicial(event.target.value)}
                            >
                                {añoSelect.map((año) => (
                                    <MenuItem value={año.PerMesano} key={año.PerMesano}>
                                        {año.PerMesano}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{
                            width: { xs: "100%", md: "300px", borderRadius: "8px", }, // 100% para xs y 200px para md y superior
                            background: "white",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#e0e0e0", // Borde verde
                                    borderRadius: "8px",
                                },
                            },
                        }} size="small">
                            <InputLabel>Mes Inicial</InputLabel>
                            <Select
                                label="Mes Inicial"
                                value={mes}
                                onChange={handleChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250,
                                        },
                                    }
                                }}
                            >
                                {meses.map((item, index) => (
                                    <MenuItem value={index + 1} key={index}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid
                        size={{ xs: 4 }}
                        sx={{
                            display: "flex", justifyContent: "flex-end",  // Alinea hacia la derecha
                            alignItems: "center"  // Centra verticalmente
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "flex-end",
                                marginTop: { xs: "1em", md: 0 } // Añade margen solo en pantallas pequeñas
                            }}
                        >
                            <Button
                                // size='small'
                                variant="outlined"
                                onClick={() => handleChangeTipo(null, 'ESTRA')}
                                sx={{
                                    width: { xs: "100%", md: "200px", }, // 100% para xs y 200px para md y superior
                                    borderRadius: "8px",
                                    borderColor: "#e4e4e7",
                                    borderWidth: "2px",
                                    backgroundColor: alignment === 'ESTRA' ? "#1976d2" : "white",
                                    color: alignment === 'ESTRA' ? "white" : "black",
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    '&:hover': {
                                        borderColor: "#e4e4e7",
                                        backgroundColor: alignment === 'ESTRA' ? "#115293" : "transparent",
                                        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                                    }
                                }}
                            >
                                Estrategicos
                            </Button>

                            <Button
                                // size='small'
                                variant="outlined"
                                onClick={() => handleChangeTipo(null, 'OPERA')}
                                sx={{
                                    width: { xs: "100%", md: "200px", }, // 100% para xs y 200px para md y superior
                                    borderRadius: "8px",
                                    borderColor: "#e4e4e7",
                                    borderWidth: "2px",
                                    backgroundColor: alignment === 'OPERA' ? "#1976d2" : "white",
                                    color: alignment === 'OPERA' ? "white" : "black",
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    '&:hover': {
                                        borderColor: "#e4e4e7",
                                        backgroundColor: alignment === 'OPERA' ? "#115293" : "transparent",
                                        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                                    }
                                }}
                            >
                                Operativos
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </>
    );
};

export default SelectorPanel;

