import React, { useEffect, useState } from 'react'
import HTMLFlipBook from 'react-pageflip';
import uno from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0001.jpg"
import dos from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0002.jpg"
import tres from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0003.jpg"
import cuatro from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0004.jpg"
import cinco from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0005.jpg"
import seis from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0006.jpg"
import siete from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0007.jpg"
import ocho from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0008.jpg"
import nueve from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0009.jpg"
import diez from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0010.jpg"
import once from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0011.jpg"
import doce from "../../../img/Vademecum/VADEMECUM FARMA COMERCIAL_page-0012.jpg"

import { Box } from '@mui/material'

const imagenes = [uno, dos, tres, cuatro, cinco, seis, siete, ocho, nueve, diez, once, doce]

const Vademecum = () => {

    return (
        <Box sx={{
            display: "flex", justifyContent: "center",
            alignContent: "center",
            width: "100%",
            marginTop: '2em',
        }}>
            <HTMLFlipBook
                width={550}
                height={685}
                size="stretch"
                minWidth={315}
                maxWidth={1000}
                minHeight={400}
                maxHeight={1533}
                maxShadowOpacity={0.5}
                mobileScrollSupport={true}
            >
                {imagenes.map((imageSrc, index) => (
                    <Box key={index} >
                        <div style={{ background: "white", }}>
                            <img src={imageSrc} style={{
                                objectFit: "contain",
                                width: "100%", height: "100%"
                            }} alt={`page-${index + 1}`} />
                        </div>
                    </Box>
                ))}
            </HTMLFlipBook>
        </Box>

    )
}

export default Vademecum