import React, { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate, Link } from 'react-router-dom';
import {
    Box, Breadcrumbs, Paper, Stack, Typography, Button, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel, InputAdornment, FormControl, InputLabel,
    Select, MenuItem, Fab, LinearProgress,
    IconButton,
    Skeleton
    // Skeleton
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from '@mui/material/Grid2';


import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TablaResultadoIndicador from './TablaResultadoIndicador';
import GraficasIndicador from './GraficasIndicador';
import newRequest from '../../../../../../utils/newRequest';

const ResultadoIndicador = () => {

    const { indProEmpSec, tipo, indicador } = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingDos, setLoadingDos] = useState(true);

    const [datos, setdatos] = useState([])
    const [datosPorIndicador, setdatosPorIndicador] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        const ObtenerDatosIndicador = async () => {
            try {
                setLoading(true); // Marcar como cargando al iniciar la solicitud

                const res = await newRequest.get(`/api/GerenciaVisual/Indicadores/ObtenerIndicador/${indicador}/${indProEmpSec}`)

                if (res.data.length) {
                    setdatos(res.data[0])
                } else {
                    setdatos([])
                    // console.log("La respuesta no contiene datos :", res.data);
                }
            } catch (error) {
                console.log("error", error)
            }
            finally {
                setLoading(false); // Marcar como no cargando cuando la solicitud termina (ya sea éxito o error)
            }
        }

        const ResultadoPorIndicador = async () => {
            try {
                setLoadingDos(true); // Marcar como cargando al iniciar la solicitud
                const res = await newRequest.get(`/api/GerenciaVisual/Indicadores/ResultadoPorIndicador/${indProEmpSec}/${indicador}`)
                if (res.data.length) {
                    setdatosPorIndicador(res.data);
                } else {
                    setdatosPorIndicador([]);
                    // console.log("La respuesta no contiene un array de indicadores:", res.data);
                }
            } catch (error) {
                console.log("error", error)
            }
        }

        ResultadoPorIndicador()
        ObtenerDatosIndicador();
    }, [indProEmpSec, tipo, indicador])

    const handleBack = () => {
        navigate(-1)
    }

    console.log("datos datos datos", datos)

    return (
        <div>
            <Box>
                <Grid container spacing={2}>

                    <Grid size={12} >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <Box style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center",
                            }}>

                                <Fab onClick={handleBack} size='small'
                                    style={{ background: "white", borderRadius: 13 }}>
                                    <ChevronLeftIcon />
                                </Fab>


                                <Breadcrumbs maxItems={3} aria-label="breadcrumb" style={{ paddingLeft: "15px" }} >
                                    <Typography color="text.primary">Gerencia visual</Typography>
                                    <Typography color="text.primary">Indicadores</Typography>
                                    <Typography color="text.primary">Linea de tiempo</Typography>
                                    <Typography color="text.primary">Resultado por Indicador</Typography>
                                </Breadcrumbs>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* {loading ? (
                    // <LinearProgress />
                    <Box sx={{ height: "77vh", backgroundColor: "red", width: "100%", marginTop: "1em" }}></Box>
                ) : ( */}
                <Grid container spacing={2}>
                    <Grid size={12} >

                        <Box sx={{ marginTop: "1em" }}>
                            <Grid container spacing={2} >
                                <Grid size={12} sx={{ display: "flex", gap: 5 }}>

                                    <Box sx={{ width: "250px" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="auto"
                                            />
                                        ) : (
                                            <Typography noWrap>
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Nombre indicador:
                                                </span>
                                                {datos?.IndProEmpNom}
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box sx={{ width: "380px" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="auto"
                                            />
                                        ) : (
                                            <Typography sx={{
                                                fontSize: "1.0rem", textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap', overflow: 'hidden',
                                            }} >
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Relación indicador
                                                </span>

                                                {datos?.IndProRel} {datos?.IndProSig} {datos?.IndProRelDos}
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box sx={{ width: "150px" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="150px "
                                            />
                                        ) : (
                                            <Typography sx={{ fontSize: "1.0rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Frecuencia:
                                                </span>
                                                {datos?.IndProFre}
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box sx={{ width: "150px" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="150px"
                                            />
                                        ) : (
                                            <Typography noWrap >
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Meta:
                                                </span>
                                                {datos?.IndProEmpresa} {datos?.IndProEmpresaDos ? ` - ${datos?.IndProEmpresaDos}` : ''}
                                            </Typography>
                                        )}
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2} sx={{ marginTop: "1rem", }}>
                                <Grid size={5}>
                                    <Paper elevation={0} sx={{
                                        borderRadius: "13px", padding: 1
                                    }}>
                                        <GraficasIndicador datos={datos}
                                            datosPorIndicador={datosPorIndicador} />
                                    </Paper>
                                </Grid>
                                <Grid size={7}  >
                                    <Paper elevation={0} sx={{
                                        borderRadius: "13px",
                                        padding: 1
                                    }}>
                                        <TablaResultadoIndicador
                                            datosPorIndicador={datosPorIndicador}
                                            datos={datos} loading={loading} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {/* )} */}
            </Box>



        </div>
    )
}

export default ResultadoIndicador