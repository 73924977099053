/* eslint-disable react/jsx-pascal-case */
// /* eslint-disable react/jsx-pascal-case */
// import React, { useMemo } from 'react';
// import { Box } from '@mui/material';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import Tooltip from '@mui/material/Tooltip';
// import {
//     MaterialReactTable,
//     MRT_ShowHideColumnsButton,
//     useMaterialReactTable,
// } from 'material-react-table';
// import OpcionesTabla from '../VentasPorZona/OpcionesTabla';

// const TablaVentasClientes = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {
//     const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];
//     const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

//     const formatNumber = (value) => {
//         if (selectedOption === 'a' || selectedOption === 'b') {
//             return new Intl.NumberFormat('es-CO', {
//                 style: 'currency',
//                 currency: 'COP', // Colombian peso
//                 maximumFractionDigits: 0,
//             }).format(value);
//         } else if (selectedOption === 'c') {
//             return new Intl.NumberFormat(undefined, {
//                 minimumFractionDigits: 0,
//                 maximumFractionDigits: 0,
//             }).format(value);
//         }
//         return value;
//     };

//     // Función para calcular la suma de cada fila
//     const calculateRowTotal = (row) => {
//         return Object.keys(row).reduce((total, key) => {
//             if (key !== 'Cliente' && key !== 'Total') {
//                 return total + (parseFloat(row[key]) || 0);
//             }
//             return total;
//         }, 0);
//     };

//     // Agregar el total a cada fila
//     const dataWithTotals = useMemo(() => {
//         return dataZona.map(row => ({
//             ...row,
//             Total: calculateRowTotal(row),
//         }));
//     }, [dataZona]);

//     // Calcula la suma total de todos los totales
//     const sumaTotal = useMemo(() => {
//         return dataWithTotals.reduce((total, row) => total + row.Total, 0);
//     }, [dataWithTotals]);

//     const columns = [
//         {
//             id: 'Cliente',
//             accessorKey: 'Cliente',
//             header: 'Cliente',
//             muiTableHeadCellProps: {
//                 align: 'center',
//                 sx: {
//                     fontWeight: 'normal',
//                     backgroundColor: '#E74C3C',
//                     color: "white"
//                 }
//             },
//             size: 10,
//             enableColumnActions: false,
//             muiTableBodyCellProps: {
//                 align: 'left',
//                 sx: {
//                     width: "10rem",
//                     maxWidth: "10rem",
//                 },
//             },
//             Cell: ({ cell }) => (
//                 <Tooltip title={cell.row.original.Cliente}>
//                     <div>
//                         {cell.row.original.Cliente}
//                     </div>
//                 </Tooltip>
//             ),
//             footer: 'Total',
//         },
//         ...campos
//             .filter(campo => campo !== 'Cliente' && campo !== 'Total')
//             .map((campo, index) => ({
//                 id: campo,
//                 accessorKey: campo,
//                 header: meses[index % meses.length],
//                 enableColumnActions: false,
//                 muiTableHeadCellProps: {
//                     align: 'center',
//                     sx: {
//                         fontWeight: 'normal',
//                         backgroundColor: index % 2 === 0 ? '#1C64F2' : '#27AE60',
//                         color: "white",
//                     }
//                 },
//                 muiTableBodyCellProps: {
//                     align: 'left',
//                 },
//                 size: 10,
//                 Cell: ({ cell }) => (
//                     <div>
//                         {formatNumber(cell.getValue())}
//                     </div>
//                 ),
//                 Footer: ({ column }) => {
//                     const sum = dataWithTotals.reduce((total, row) => total + (parseFloat(row[column.id]) || 0), 0);
//                     return (
//                         <div style={{ alignItems: "left", display: "flex", justifyContent: "left" }}>
//                             {formatNumber(sum)}
//                         </div>
//                     );
//                 },
//             })),
//         {
//             id: 'Total',
//             accessorKey: 'Total',
//             header: 'Total',
//             enableColumnActions: false,
//             muiTableHeadCellProps: {
//                 align: 'center',
//                 sx: {
//                     fontWeight: 'normal',
//                     backgroundColor: '#E74C3C',
//                     color: "white"
//                 }
//             },
//             muiTableBodyCellProps: {
//                 align: 'left',
//             },
//             size: 10,
//             Cell: ({ cell }) => (
//                 <div>
//                     {formatNumber(cell.getValue())}
//                 </div>
//             ),
//         },
//     ];


//     const table = useMaterialReactTable({
//         columns,
//         data: dataWithTotals,
//         positionToolbarAlertBanner: 'bottom',
//         muiTablePaperProps: {
//             elevation: 0
//         },
//         enableFullScreenToggle: false,
//         enableColumnFilters: false,
//         enableDensityToggle: false,
//         enableStickyHeader: true,
//         enableStickyFooter: true,
//         initialState: {
//             columnPinning: { left: ['Cliente'], right: ['Total'] },
//             density: 'compact'
//         },
//         localization: {
//             toggleDensity: "Alternar densidad",
//             rowsPerPage: "Filas por página",
//             toggleFullScreen: "Alternar pantalla completa",
//             search: "Buscar",
//             columnActions: "mostrar/ocultar columnas",
//             showHideSearch: "Filtro de busqueda",
//             showHideColumns: "mostrar / ocultar columnas"
//         },
//         renderTopToolbarCustomActions: ({ table }) => (
//             <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
//                 <Typography variant='lg' fontWeight="bold">Ventas por cliente</Typography>
//                 <OpcionesTabla onOptionChange={handleOptionChange} //aqui pasa el hijo
//                     selectedOptionPadre={selectedOption} />
//             </div>
//         ),
//         renderBottomToolbarCustomActions: ({ table }) => (
//             <Box>
//                 <Typography variant="subtitle1" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
//                     Total General:
//                     <span style={{ fontSize: "17px", marginLeft: "10px" }}>
//                         {formatNumber(sumaTotal)}
//                     </span>
//                 </Typography>
//             </Box>
//         ),
//         renderToolbarInternalActions: ({ table }) => (
//             <Box>
//                 <MRT_ShowHideColumnsButton table={table} />
//                 <Tooltip title="Gráfica">
//                     <IconButton onClick={handleOpen}>
//                         <BarChartIcon />
//                     </IconButton>
//                 </Tooltip>
//             </Box>
//         ),
//         muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
//     });

//     return (
//         <Box sx={{ height: "100%", width: "100%" }}>
//             <MaterialReactTable table={table} />
//         </Box>
//     );
// };

// export default TablaVentasClientes;



import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import {
    MaterialReactTable,
    MRT_ShowHideColumnsButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import OpcionesTabla from '../VentasPorZona/OpcionesTabla';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';

const TablaVentasClientes = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {
    const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];
    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    const formatNumber = (value) => {
        if (selectedOption === 'a' || selectedOption === 'b') {
            return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP', // Colombian peso
                maximumFractionDigits: 0,
            }).format(value);
        } else if (selectedOption === 'c') {
            return new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
        }
        return value;
    };

    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'Cliente' && key !== 'Total') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    const dataWithTotals = useMemo(() => {
        return dataZona.map(row => ({
            ...row,
            Total: calculateRowTotal(row),
        }));
    }, [dataZona]);

    const sumaTotal = useMemo(() => {
        return dataWithTotals.reduce((total, row) => total + row.Total, 0);
    }, [dataWithTotals]);

    const columns = [
        {
            id: 'Cliente',
            accessorKey: 'Cliente',
            header: 'Cliente',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 10,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    width: "10rem",
                    maxWidth: "10rem",
                },
            },
            Cell: ({ cell }) => (
                <Tooltip title={cell.row.original.Cliente}>
                    <div>{cell.row.original.Cliente}</div>
                </Tooltip>
            ),
            footer: 'Total',
        },
        ...campos
            .filter(campo => campo !== 'Cliente' && campo !== 'Total')
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                header: meses[index % meses.length],
                enableColumnActions: false,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: index % 2 === 0 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                Cell: ({ cell }) => (
                    <div>{formatNumber(cell.getValue())}</div>
                ),
                Footer: ({ column }) => {
                    const sum = dataWithTotals.reduce((total, row) => total + (parseFloat(row[column.id]) || 0), 0);
                    return (
                        <div style={{ alignItems: "left", display: "flex", justifyContent: "left" }}>
                            {formatNumber(sum)}
                        </div>
                    );
                },
            })),
        {
            id: 'Total',
            accessorKey: 'Total',
            header: 'Total',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            size: 10,
            Cell: ({ cell }) => (
                <div>{formatNumber(cell.getValue())}</div>
            ),
        },
    ];

    // Función para exportar a Excel
    const exportToExcel = () => {
        const orderedData = dataWithTotals.map((row) => {
            const orderedRow = {
                Cliente: row.Cliente,
            };

            meses.forEach((mes, index) => {
                const campo = campos[index];
                orderedRow[mes] = row[campo] != null && row[campo] !== '' ? row[campo] : 0;
            });

            orderedRow.Total = row.Total != null && row.Total !== '' ? row.Total : 0;
            return orderedRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(orderedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ventas por Cliente');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Ventas_por_Cliente.xlsx');
    };

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            columnPinning: { left: ['Cliente'], right: ['Total'] },
            density: 'compact'
        },
        localization: MRT_Localization_ES,
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold">Ventas por cliente</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total General:
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {formatNumber(sumaTotal)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <MRT_GlobalFilterTextInput table={table}  /> */}
                <MRT_ToggleGlobalFilterButton table={table} />
                <Tooltip title="Gráfica">
                    <IconButton onClick={handleOpen}>
                        <BarChartIcon />
                    </IconButton>
                </Tooltip>
                <MRT_ShowHideColumnsButton table={table} />
                <IconButton onClick={exportToExcel}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        ),
        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TablaVentasClientes;
