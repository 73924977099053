import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const SeguimientoAseGrafico = ({ seguimiento }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        // Datos generados para el gráfico
        // let xAxisData = [];
        // let data1 = [];
        // let data2 = [];
        // let data3 = [];
        // let data4 = [];

        // Datos generados para el gráfico
        let xAxisData = seguimiento.map(item => item.Vendedor || 'NULL');
        let data1 = seguimiento.map(item => item['0-30']);
        let data2 = seguimiento.map(item => item['30-60']);
        let data3 = seguimiento.map(item => item['60-90']);
        let data4 = seguimiento.map(item => item['90-180']);
        let data5 = seguimiento.map(item => item['180-360']);
        let data6 = seguimiento.map(item => item['MAYOR DE 360']);

        // Opciones del gráfico
        const option = {
            legend: {
                data: ['0-30', '30-60', '60-90', '90-180', '180-360', 'MAYOR DE 360'],
                bottom: 0
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '5%',
                containLabel: true
            },
            brush: {
                toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
                xAxisIndex: 0
            },
            toolbox: {
                feature: {
                    magicType: {
                        type: ['stack']
                    },
                    dataView: {}
                }
            },
            tooltip: {},
            xAxis: {
                type: 'category',
                axisLabel: {
                    interval: 0, rotate: 60,
                    formatter: function (value) {
                        // Truncar si el nombre es más largo de 10 caracteres y agregar "..."
                        return value.length > 10 ? value.slice(0, 10) + '...' : value;
                    }
                },
                data: xAxisData
                
            },
            yAxis: {
                type: 'value'
            },
            series: [

                {
                    name: '0-30',
                    type: 'bar',
                    stack: 'total',
                    data: data1
                },
                {
                    name: '30-60',
                    type: 'bar',
                    stack: 'total',
                    data: data2
                },
                {
                    name: '60-90',
                    type: 'bar',
                    stack: 'total',
                    data: data3
                },
                {
                    name: '90-180',
                    type: 'bar',
                    stack: 'total',
                    data: data4
                },
                {
                    name: '180-360',
                    type: 'bar',
                    stack: 'total',
                    data: data5
                },
                {
                    name: 'MAYOR DE 360',
                    type: 'bar',
                    stack: 'total',
                    data: data6
                }
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [seguimiento]);

    console.log("Seguimiento", seguimiento)

    return (
        <div>
            <div ref={chartRef} style={{ width: '100%', height: '500px' }}></div>
        </div>
    );
};

export default SeguimientoAseGrafico;
