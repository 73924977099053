import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts';
import { Link } from 'react-router-dom';

export const GraficaTorta = ({ datVendedorGrafica }) => {

    const chartRefPie = useRef(null);


    useEffect(() => {
        if (datVendedorGrafica.length === 0) return; // Esperar a que los datos estén disponibles

        const chartInstancePie = echarts.init(chartRefPie.current);

        const chartData = datVendedorGrafica.map(item => ({
            value: item.TotalCartera,
            name: item.Cliente
        }));

        const option = {
            // title: {
            //     text: 'Cliente con mayor cartera en mora',
            //     left: 'left',
            //     textStyle: {
            //         fontFamily: 'Roboto', // Cambia la fuente a la que desees
            //         fontSize: "16px", // Cambia el tamaño de la fuente
            //         fontWeight: 'bold', // Puedes ajustar esto a 'normal', 'bold', 'bolder', 'lighter' o un número
            //         color: 'black' // Cambia el color del texto
            //     }
            // },
            tooltip: {
                confine: true,  // Mantener el tooltip dentro del contenedor
                trigger: 'item',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['10%', '70%'],
                    padAngle: 5,
                    itemStyle: {
                        borderRadius: 10
                    },
                    roseType: 'radius',
                    data: chartData
                }
            ]
        };

        chartInstancePie.setOption(option);

        function handleResize() {
            chartInstancePie.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chartInstancePie.dispose();
        };
    }, [datVendedorGrafica]);

    return (
        <div>

            <Typography variant='h6' fontSize={16} fontWeight="bold" sx={{ position: 'absolute', top: 10, left: 16 }}>
                Cliente con mayor cartera en mora
            </Typography>

            <Box sx={{ position: 'absolute', top: 5, right: 16, zIndex: 5 }}>
                <Button component={Link} to="/Farmanet/GestionComercial/VisualEmpresa" size="small">Ver mas</Button>
            </Box>
            <Box sx={{ width: "100%", marginTop: "15px" }}>
                <div ref={chartRefPie} style={{ width: '100%', height: '360px', paddingTop: "10px" }}></div>
            </Box>
        </div>
    )
}
