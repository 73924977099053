import React, { useEffect, useState } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import TablaEdades from './TablaEdades'
import newRequest from '../../../../utils/newRequest'
import GraficaEdades from './GraficaEdades/GraficaEdades'
import GraficaCLientes from './GraficaClientes/GraficaCLientes'

import { Tooltip, IconButton, Box } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const Edades = ({ selectedZona, selectedTercero, selectedVendedor, selectedCliente }) => {

    const [datos, setdatos] = useState([])
    const [datosClientes, setdatosClientes] = useState([])

    // Agrega un estado para controlar si los datos se están cargando
    const [loading, setLoading] = useState(true);

    const [cambioTabla, setCambioTabla] = useState(false);

    const handleChange = () => {
        const newCambioTabla = !cambioTabla; // Invierte el estado actual
        setCambioTabla(newCambioTabla);
    };

    useEffect(() => {

        const cuerpoIndicador = {
            zona: selectedZona,
            vendedor: selectedVendedor,
            tercero: selectedTercero,
            cliente: selectedCliente
        }

        const ObtenerEdades = async () => {
            setLoading(true); // Se activa el indicador de carga
            try {

                const res = await newRequest.post(`/api/GerenciaVisual/Cartera/CarteraEdades`, cuerpoIndicador, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                if (res.status === 200) {
                    setdatos(res.data)
                } else {
                    setdatos([])
                    // return "No hay datos"
                }

                const resDos = await newRequest.post(`/api/GerenciaVisual/Cartera/CarteraEdadesClientesDiez`, cuerpoIndicador, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                if (resDos.status === 200) {
                    setdatosClientes(resDos.data)
                } else {
                    setdatosClientes([])
                    // return "No hay datos"
                }
            } catch (error) {
                console.log("error en ObtenerProcesos ", error)
            } finally {
                setLoading(false); // Se desactiva el indicador de carga después de obtener los datos
            }
        }

        ObtenerEdades();
    }, [selectedZona, selectedVendedor, selectedTercero, selectedCliente])

    return (
        <div style={{ width: "100%" }}>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '25px' }}>
                    <Box sx={{
                        position: "sticky", top: 0, display: "flex", zIndex: 2,
                        marginBottom: "-50px", marginLeft: "10px", width: "20%"
                    }}>
                        <Tooltip arrow title="Cambio de vista" placement="right" >
                            <IconButton onClick={() => handleChange()}>
                                <SwapHorizIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {loading ? ( // Muestra el indicador de carga mientras se obtienen los datos
                        <Box style={{
                            display: 'flex', alignItems: "center",
                            justifyContent: "center", height: "33.4rem"
                        }} >
                            <CircularProgress />
                        </Box>

                    ) : cambioTabla === true ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <GraficaEdades datos={datos} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                <GraficaCLientes datosClientes={datosClientes} />
                            </Grid>
                        </Grid>
                    ) : (

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TablaEdades
                                datos={datos}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div >
    )
}

export default Edades