import React, { useContext, useEffect, useRef, useState } from 'react';
import { CssBaseline, List, Container, Button, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Divider, Drawer, IconButton, Box, Menu, MenuItem, AppBar, TextField, FormControl, Checkbox, FormControlLabel, FormGroup, Stack, Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import uno from "../../images/siglasFarma.png";
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { AuthContext } from '../../loginContext';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import 'react-toastify/dist/ReactToastify.css';
import SimpleDialog from './SimpleDialog';
import "./navbarInicio.css";
import { toast } from 'react-toastify';
import newRequest from '../../utils/newRequest';

const drawerWidth = 240;

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });
    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const DrawerContent = ({ handleDrawerToggle }) => (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2 }}>
            Farma Comercial
        </Typography>
        <Divider />
        <List>
            {[
                { text: 'Inicio', path: '/' },
                { text: '¿Quienes somos?', path: '/QuienesSomos' },
                { text: 'Eventos', path: '/Eventos' },
                { text: 'Portafolio', path: '/Portafolio' },
                { text: 'Contactenos', path: '/Contacto' },
                { text: 'Iniciar sesión', path: '/login' }
            ].map(({ text, path }, index) => (
                <ListItem disablePadding key={text}>
                    <ListItemButton sx={{ textAlign: 'left' }} component={Link} to={path}>
                        <ListItemText primary={text} />
                        {index === 5 && <AccountCircleIcon sx={{ marginRight: 1, fontSize: "20px" }} />}
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>
);

const NavbarInicio = (props) => {
    const { isAuthenticated, currentUser, logout, login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl); // Controla si el menú está abierto
    // const { window } = props;

    const handleClick = (event) => setAnchorEl(event.currentTarget); // Abre el menú
    const handleClose = () => setAnchorEl(null); // Cierra el menú

    // const cerrarSesion = () => {
    //     logout();
    //     navigate("/");
    // };

    const cerrarSesion = async () => {
        try {
            logout();
            navigate("/");
        } catch (error) {
            console.error("Error al cerrar sesión:", error.response ? error.response.data : error.message);
        }
    };

    // const container = window !== undefined ? () => window().document.body : undefined;

    const menuItems = [
        { text: 'Inicio', path: '/' },
        { text: '¿Quienes somos?', path: '/QuienesSomos' },
        // { text: 'Eventos', path: '/Eventos' },
        { text: 'Portafolio', path: '/Portafolio' },
        { text: 'Contactenos', path: '/Contacto' }
    ];

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false); // Estado para el submenú
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    // Cerrar el menú y submenú al hacer clic fuera
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
                setIsSubmenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [datax, setInputs] = useState({ username: "", password: "" });

    const [err, setErr] = useState(null);

    const handleChange = (e) => setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const [isAuthInitialized, setAuthInitialized] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(datax);
            setAuthInitialized(true);
            navigate("/Plataformas");
            toast.info("Elija la plataforma", {
                width: "350px",
                position: "bottom-left",
                autoClose: 3000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        } catch (err) {
            setErr(err.response.data.message);
        }
    };

    useEffect(() => {
        if (isAuthenticated && isAuthInitialized) navigate("/Plataformas");
    }, [isAuthenticated, isAuthInitialized, navigate]);

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <header className="ud-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg" ref={menuRef}>
                                <a class="navbar-brand" href="index.html">
                                    <img src={uno} width="150px" style={{ marginTop: "15px" }} alt="Farma Comercial" />
                                </a>
                                <MenuIcon className="navbar-toggler" onClick={toggleMenu}
                                    style={{ fontSize: "30px" }} />
                                <div className={`navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
                                    <ul id="nav" className="navbar-nav mx-auto">
                                        {menuItems.map((item, index) => (
                                            <li className="nav-item" key={index}>
                                                <Link
                                                    className="ud-menu-scroll"
                                                    to={item.path}
                                                    component={Link}
                                                    underline="none"
                                                    color="inherit"
                                                >
                                                    {item.text}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="navbar-btn d-none d-sm-inline-block">

                                    {isAuthenticated ? (
                                        <>
                                            <Button
                                                aria-label="more"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                // sx={{ color: "white" }}
                                                onClick={handleClick}

                                            >
                                                <Typography >
                                                    {currentUser.userData?.UsuCod}
                                                </Typography>
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                                slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                            width: "200px",
                                                            borderRadius: "8px",
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            '&::before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem component={Link} to="/Plataformas">
                                                    <Typography variant="body2" sx={{ color: "#0000008C", fontSize: "1.2rem" }}>
                                                        Plataformas
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem onClick={cerrarSesion}>
                                                    <Typography variant="body2" sx={{ color: "#0000008C", fontSize: "1.2rem" }}>
                                                        Cerrar sesión
                                                    </Typography>
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                                sx={{ color: "black" }}
                                            >
                                                Iniciar Sesión
                                            </Button>
                                            
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                            width: "300px",
                                                            borderRadius: "8px",
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            '&::before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <Box sx={{ display: "grid", gap: "1em", padding: "1em", borderRadius: "14px" }}>
                                                    <TextField
                                                        size="small"
                                                        onChange={handleChange}
                                                        name="usuario"
                                                        label="Usuario"
                                                        type="text"
                                                        autoComplete="off"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        label="Contraseña"
                                                        onChange={handleChange}
                                                        name="clave"
                                                        type={showPassword ? 'text' : 'password'}
                                                    />
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox onClick={() => setShowPassword(!showPassword)} />}
                                                            label="Mostrar contraseña"
                                                        />
                                                    </FormGroup>

                                                    {err && (
                                                        <Stack sx={{ width: '100%' }} >
                                                            <Alert severity="warning">{err}</Alert>
                                                        </Stack>
                                                    )}

                                                    <Button variant="contained" fullWidth onClick={handleLogin}>
                                                        Iniciar Sesión
                                                    </Button>
                                                </Box>
                                            </Menu>
                                        </>
                                    )}


                                </div>
                            </nav>
                        </div>
                    </div>
                </div >
            </header >
        </>
    );
};
export default NavbarInicio;