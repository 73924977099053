import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Outlet, useNavigate } from 'react-router-dom';
import { Drawer, MenuItem, MenuList, Paper, Tooltip, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BiotechIcon from '@mui/icons-material/Biotech';
import SettingsIcon from '@mui/icons-material/Settings';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DrawIcon from '@mui/icons-material/Draw';
import LogoutIcon from '@mui/icons-material/Logout';
import QRCode from 'qrcode.react';

import {
    Modal, TextField, Button, Menu,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import logoFarma from "../images/siglasFarma.png";
import { AuthContext } from '../loginContext';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'; const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

const DrawerLateral = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

const Modalqr = ({ open, handleClose }) => {

    const [url, setUrl] = React.useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '0.5px solid #000',
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
    };

    const generateQRCode = () => {
        // Aquí puedes personalizar la lógica para generar el código QR según el valor de 'url'
        // Por ahora, simplemente usaremos el valor actual de 'url'
        return (
            <QRCode
                value={url}
                size={300}
                fgColor="#000000"
                bgColor="#ffffff"
                level="L"
                id={"sameId_as_QRCode_compoent_id"}
            />
        );
    };

    const downloadCode = () => {
        const canvas = document.getElementById("sameId_as_QRCode_compoent_id");
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/jpg")
                .replace("image/jpg", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl
            downloadLink.download = `qr${url}.jpg`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setUrl("")
        }
    }


    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField label="Ingresa la url" autoComplete="off" fullWidth
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        size='small' variant="outlined"
                        sx={{ marginTop: 2, marginRight: 2 }} />

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        {generateQRCode()}
                    </div>

                    <Button variant="contained" sx={{ marginTop: 2, width: '100%' }} onClick={() => downloadCode()}>
                        Descargar
                    </Button>
                </Box>
            </Modal>
        </Box>

    );
};

function AppbarMenu() {

    const { currentUser, logout } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMobile, setOpenMobile] = React.useState(false);
    const openMenu = Boolean(anchorEl);
    const [openQr, setOpenQr] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpenMobile(newOpen);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecta si es un dispositivo móvil

    // Conserve el valor seleccionado, despues de recargar
    const storedIndex = parseInt(localStorage.getItem('selectedIndex')) || 0;

    const [selectedIndex, setSelectedIndex] = React.useState(storedIndex);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpenQr = () => setOpenQr(true);

    const handleCloseQr = () => {
        setOpenQr(false)
    }

    // 1565c0
    // 1976d214
    const listItemStyle = {
        // height: 40,
        // width: 42,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 0.8,
        marginBottom: 0.8,
        borderRadius: "12px",
        justifyContent: open ? 'initial' : 'center',
        '&.Mui-selected': {
            backgroundColor: '',
            color: 'white',
            // '&:hover': {
            //     backgroundColor: '#1a389b',
            // },
            '& .MuiListItemText-primary': {
                color: '#1565c0',
            },
            '& .MuiSvgIcon-root': {
                color: '#1565c0',
            },
        },
        // '&:not(.Mui-selected):hover': {
        //     backgroundColor: '#F4EEEE',
        // },
    };

    const CustomListItem = ({ to, text, icon, index }) => (

        <Tooltip title={text} placement="right">

            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link}
                    to={to}
                    sx={listItemStyle}
                    selected={selectedIndex === index}
                    onClick={(event) => {
                        handleListItemClick(event, index);
                        handleDrawerClose();
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
    );

    const CustomListItemMobile = ({ to, text, icon, index }) => (
        <Tooltip title={text} placement="right">
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link}
                    to={to}
                    sx={listItemStyle}
                    selected={selectedIndex === index}
                    onClick={(event) => {
                        handleListItemClick(event, index);
                        handleDrawerClose();
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
    );

    const DrawerList = (
        <Box sx={{ width: 250, marginTop: "3em" }} role="presentation" onClick={toggleDrawer(false)}>
            <List>

                <CustomListItemMobile to="/Farmanet"
                    text="Dashboard"
                    icon={<DashboardIcon />} index={0} />

                <CustomListItemMobile to="/Farmanet/GerenciaVisual"
                    text="GerenciaVisual"
                    icon={<BarChartRoundedIcon />} index={1} />

                <CustomListItemMobile to="/Farmanet/Calidad" text="Calidad"
                    icon={<DoneAllRoundedIcon />} index={2} />

                <Divider />

                <CustomListItemMobile to="/Farmanet/GestionComercial" text="GestionComercial"
                    icon={<PersonPinRoundedIcon />} index={3} />

                <CustomListItemMobile to="/Farmanet/Importaciones" text="Importaciones"
                    icon={<DirectionsBoatFilledRoundedIcon />} index={4} />

                <CustomListItemMobile to="/Farmanet/Financiera" text="Financiera"
                    icon={<AttachMoneyIcon />} index={5} />

                <CustomListItemMobile to="/Farmanet/Tecnica" text="Tecnica"
                    icon={<BiotechIcon />} index={6} />
            </List>
            <Divider />
        </Box>
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Efecto para cerrar el drawer si no es un dispositivo móvil
    React.useEffect(() => {
        if (!isMobile) {
            setOpenMobile(false); // Cierra el drawer si detecta que no es móvil
            setOpen(false); // Opcionalmente cierra también el drawer lateral
        }
    }, [isMobile]);

    // Actualizar el valor en localStorage cuando selectedIndex cambie
    React.useEffect(() => {
        localStorage.setItem('selectedIndex', selectedIndex.toString());
    }, [selectedIndex]);


    const navegarFirma = () => {
        setSelectedIndex('')
        handleClose();
        navigate("/Farmanet/FirmaDigital")
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                style={{
                    background: "white",
                    borderBottomWidth: 1,
                    borderBottomColor: "#e0e0e0",
                    borderBottomStyle: "solid"
                }}>
                <Toolbar style={{ backgroundColor: "white" }}>
                    {isMobile ? <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)} // Condicional para abrir/cerrar
                            edge="start"
                        >
                            {openMobile ? <ChevronLeftIcon sx={{ color: "#757575" }} />
                                : <MenuIcon sx={{ color: "#757575" }} />} {/* Condiciona el ícono según el estado */}
                        </IconButton >

                        <div style={{
                            display: "flex",
                            alignItems: "center", // Centra los elementos verticalmente
                            justifyContent: "space-between", // Asegura que los elementos se mantengan alineados correctamente
                            // padding: "10px", // Añade un padding opcional para espaciar los elementos del borde
                            paddingLeft: "20px",
                            width: "100%", // Asegura que el contenedor ocupe todo el ancho
                            boxSizing: "border-box"
                        }}>
                            <img style={{ marginRight: 5 }} src={logoFarma} alt='Logo Farma'
                                height={35} />

                            <div style={{ display: "flex", alignItems: "center" }}>

                                <IconButton
                                    aria-label="settings"
                                    sx={{ color: "black", borderRadius: "100%" }}
                                    onClick={handleClick} // Asegúrate de manejar el click event aquí
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </div>
                        </div>
                    </> : <>
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={open ? handleDrawerClose : handleDrawerOpen} // Condicional para abrir/cerrar
                                edge="start"

                            >
                                {open ? <ChevronLeftIcon sx={{ color: "#757575" }} /> : <MenuIcon sx={{ color: "#757575" }} />} {/* Condiciona el ícono según el estado */}
                            </IconButton>

                            <div style={{
                                display: "flex",
                                alignItems: "center", // Centra los elementos verticalmente
                                justifyContent: "space-between", // Asegura que los elementos se mantengan alineados correctamente
                                // padding: "10px", // Añade un padding opcional para espaciar los elementos del borde
                                paddingLeft: "20px",
                                width: "100%", // Asegura que el contenedor ocupe todo el ancho
                                boxSizing: "border-box"
                            }}>
                                {/* Logo y nombre a la izquierda */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img style={{ marginRight: 5 }} src={logoFarma} alt='Logo Farma'
                                        height={40} />
                                    {/*  */}
                                </div>

                                {/* <Typography variant="h6" noWrap component="div" color='black'>
                                    Farmanet
                                </Typography> */}

                                {/* Nombre de usuario y el icono de configuración a la derecha */}
                                <div style={{ display: "flex", alignItems: "center" }}>

                                    <IconButton
                                        aria-label="settings"
                                        sx={{ color: "#323338", borderRadius: "10px" }}
                                    >
                                        <NotificationsNoneIcon />
                                    </IconButton>

                                    <IconButton
                                        aria-label="settings"
                                        sx={{ color: "#323338", borderRadius: "10px" }}
                                        onClick={handleClick} // Asegúrate de manejar el click event aquí
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </div>
                            </div>


                            {toggleDrawer(false)}
                        </></>}
                </Toolbar>
            </AppBar>


            <DrawerLateral
                variant={isMobile ? "temporary" : "permanent"}
                open={open}
                onClose={handleDrawerClose}
                sx={{
                    display: isMobile && !open ? "none" : "block",
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <CustomListItem to="/Farmanet"
                        text="Dashboard"
                        icon={<DashboardIcon />} index={0} />

                    <CustomListItem to="/Farmanet/GerenciaVisual"
                        text="GerenciaVisual"
                        icon={<BarChartRoundedIcon />} index={1} />

                    <CustomListItem to="/Farmanet/Calidad" text="Calidad"
                        icon={<DoneAllRoundedIcon />} index={2} />

                    <Divider />

                    <CustomListItem to="/Farmanet/GestionComercial" text="GestionComercial"
                        icon={<PersonPinRoundedIcon />} index={3} />

                    <CustomListItem to="/Farmanet/Importaciones" text="Importaciones"
                        icon={<DirectionsBoatFilledRoundedIcon />} index={4} />

                    <CustomListItem to="/Farmanet/Financiera" text="Financiera"
                        icon={<AttachMoneyIcon />} index={5} />

                    <CustomListItem to="/Farmanet/Tecnica" text="Tecnica"
                        icon={<BiotechIcon />} index={6} />

                </List>
            </DrawerLateral>

            {/* Drawer aparece en dispositivos móviles */}
            <Drawer open={openMobile} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 2,
                    overflow: 'auto', // Permite el desplazamiento si el contenido excede el tamaño
                    maxHeight: '100vh', // Limita la altura máxima al tamaño de la ventana
                    boxSizing: 'border-box', // Asegura que el padding esté incluido en el tamaño total
                }}
            >
                <DrawerHeader />
                <Outlet />
            </Box>

            <Modalqr open={openQr} handleClose={handleCloseQr} />

            <Menu
                // anchorEl={anchorEl}
                // open={openMenu}
                // onClose={handleClose}
                // MenuListProps={{
                //     'aria-labelledby': 'basic-button',
                // }}

                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >


                <Paper elevation={0} sx={{ width: 250, maxWidth: '100%', height: "100%", }}>
                    <MenuList sx={{ padding: 1 }}>

                        <MenuItem onClick={() => handleOpenQr()}>
                            <ListItemIcon>
                                <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Mi perfil</ListItemText>
                        </MenuItem>


                        <MenuItem onClick={() => handleOpenQr()}>
                            <ListItemIcon>
                                <QrCodeIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Crear Qr</ListItemText>
                        </MenuItem>

                        <MenuItem onClick={navegarFirma}>
                            <ListItemIcon>
                                <DrawIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Crear firma digital</ListItemText>
                        </MenuItem>
                        <Divider />

                        <MenuItem onClick={() => logout()}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            Cerrar sesión
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>

        </Box>
    );
}


export default AppbarMenu;
