import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef } from 'react'
import * as echarts from 'echarts';
import { Link } from 'react-router-dom';

export const GraficaBarras = ({ datVendedorTabla }) => {

    const chartRef = useRef(null);


    // Memoización de los datos del gráfico
    const dataTabla = useMemo(() => {
        const nombresMeses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        return datVendedorTabla.map((row) => {
            const nombreMes = nombresMeses[row.mes - 1];
            return {
                mes: nombreMes,
                ventas: row.Ventas2024,
                presupuesto: row.Presupuesto2024,
            };
        });
    }, [datVendedorTabla]);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const option = {
            // title: {
            //     text: 'Ventas mensuales',
            //     textStyle: {
            //         fontFamily: 'Roboto', // Cambia la fuente a la que desees
            //         fontSize: "16px", // Cambia el tamaño de la fuente
            //         fontWeight: 'bold', // Puedes ajustar esto a 'normal', 'bold', 'bolder', 'lighter' o un número
            //         color: 'black' // Cambia el color del texto
            //     }
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    data: dataTabla.map(item => item.mes),
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: 'Ventas', type: 'line',
                    data: dataTabla.map(item => item.ventas),
                    smooth: true
                },
                {
                    name: 'Presupuesto', type: 'line',
                    data: dataTabla.map(item => item.presupuesto),
                    smooth: true
                },
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chartInstance.dispose();
        };
    }, [dataTabla]);


    return (
        <div>

            <Typography variant='h6' fontSize={16} fontWeight="bold" sx={{ position: 'absolute', top: 10, left: 16 }}>
                Ventas mes
            </Typography>

            <Box sx={{ position: 'absolute', top: 5, right: 16, zIndex: 5 }}>
                <Button component={Link} to="/Farmanet/GestionComercial/VisualEmpresa" size="small">Ver mas</Button>
            </Box>
            <Box sx={{ width: "100%", marginTop: "15px" }}>
                <div ref={chartRef} style={{ width: '100%', height: '360px', paddingTop: "10px" }}></div>
            </Box>
        </div>
    )
}
