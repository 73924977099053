import React from 'react'
import { Typography } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import { PrincipioValores } from './Principio_valores/Principio_valores';
import { NuestrasPoliticas } from './NuestrasPoliticas/NuestrasPoliticas';
import logoFarma from "../../img/logo-farmacomercial.png"

const QuienesSomos = () => {
    return (
        < >
            <section id="features" className="ud-features" style={{ marginTop: "5em" }}>
                <div className="container">
                    <div className="row align-items-center">
                        {/* Texto y Botón en una columna responsive */}
                        <div className="col-lg-6 col-md-12 ">
                            <div className="ud-section-title text-center text-lg-start">
                                <span>Conócenos</span>
                                <h2>¿Quiénes somos?</h2>
                                <Typography variant="h6" gutterBottom >
                                    Organización colombiana soportada en la experiencia de un equipo humano interdisciplinario altamente competente, pone a disposición y al alcance de toda la comunidad que desee
                                    <span style={{ fontWeight: "bold", marginLeft: "5px", marginRight: "5px" }}>Vivir más y Vivir mejor,</span> productos para la salud como un componente fundamental para su bienestar y mejora de calidad.
                                </Typography>

                                <Typography variant="h6">
                                    Contamos con representantes de ventas que le orientará directamente en todo el territorio nacional, con la presencia en las
                                    <span style={{ fontWeight: "bold" }}> zonas regionales del centro Cundiboyacenses; Llano Orientales, Eje Cafetero, Cauca y Valle del Cauca; Sur colombiano (Huila, Tolima, Caqueta); Costa Caribe y Santanderes.</span>
                                </Typography>
                            </div>
                        </div>

                        {/* Imagen en una columna responsive */}
                        <div className="col-lg-6 col-md-12 text-center">
                            <img src={logoFarma} className="img-fluid" style={{ maxHeight: "120px", marginBottom: "1.5em" }} alt="Logo Farma" />
                        </div>
                    </div>

                    {/* Sección Misión y Visión */}
                    <div className="row align-items-stretch">
                        {/* Misión */}
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-4 d-flex flex-column justify-content-center">
                            <div className="ud-single-feature wow fadeInUp w-100" data-wow-delay=".1s">
                                <div className="ud-feature-content">
                                    <div className="ud-feature-title">Misión</div>
                                </div>
                                <Typography variant="h6" gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>LABFACOM</span> es una moderada e innovadora compañía farmacéutica dedicada al desarrollo importación, fabricación y comercialización de productos farmacéuticos, dispositivos médicos, cosméticos, y dispositivos médicos quirúrgicos, para el cuidado de la salud, tanto para prevenir como tratar enfermedades, asegurando la eficacia terapéutica con altos estándares de control, innovación y desarrollo sostenible.
                                </Typography>
                            </div>
                        </div>

                        {/* Visión */}
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-4 d-flex flex-column ">
                            <div className="ud-single-feature wow fadeInUp w-100" data-wow-delay=".1s">
                                <div className="ud-feature-content">
                                    <div className="ud-feature-title">Visión</div>
                                </div>
                                <Typography variant="h6" gutterBottom >
                                    <span style={{ fontWeight: "bold" }}>LABFACOM</span> Para el año 2030 LABFACOM pretende el reconocimiento de marca con la innovación continua del portafolio de productos, asegurando su crecimiento, desarrollo responsable y sostenible.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container">
                    <PrincipioValores />
                </div>
            </section>


            <section >
                <div className="container">
                    <NuestrasPoliticas />
                </div>
            </section>

            <Footer />
        </>
    )
}

export default QuienesSomos