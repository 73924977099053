import React, { useEffect, useState, useRef } from 'react';
import * as echarts from 'echarts';
import CircularProgress from '@mui/material/CircularProgress';
import newRequest from '../../../../utils/newRequest';


const BarsChart = ({ fechaInicial, fechaFinal, filteredData, selectedRowName, datosParametros }) => {
    const chartRef = useRef(null);
    const [mostrarGraficaBarras, setMostrarGraficaBarras] = useState(false);

    useEffect(() => {
        const cargarGraficoBarras = async () => {
            try {
                let res;
                // if (estadoFecha === false) {
                res = await newRequest.post('/api/GerenciaVisual/profundidadGraficaArticulos', {
                    ...datosParametros,
                    articulo: selectedRowName,
                    fechaInicial: fechaInicial,
                    fechaFinal: fechaFinal
                });

                const datosFiltrados = res.data.filter((item) => item.NitIde === filteredData[0]?.value);
                const periodos = datosFiltrados.map((item) => item.periodo);
                const valores = datosFiltrados.map((item) => item[selectedRowName]);

                const data = {
                    labels: periodos,
                    datasets: [
                        {
                            label: selectedRowName,
                            data: valores,
                            backgroundColor: 'rgba(255, 89, 89, 0.6)',
                            barThickness: valores.length === 1 ? 120 : undefined // Ajusta el ancho de la barra si solo hay un dato
                        }
                    ]
                };
                setMostrarGraficaBarras(data);
                // }
            } catch (error) {
                console.log("error", error);
            }
        };

        cargarGraficoBarras();
    }, [datosParametros, selectedRowName, fechaInicial, fechaFinal, filteredData]);

    useEffect(() => {
        if (mostrarGraficaBarras && chartRef.current) {
            const chartInstance = echarts.init(chartRef.current);

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: mostrarGraficaBarras.labels,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        color: 'black'
                    }
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: 100
                },
                series: [
                    {
                        name: selectedRowName,
                        type: 'bar',
                        barWidth: mostrarGraficaBarras.datasets[0].barThickness || '60%',
                        data: mostrarGraficaBarras.datasets[0].data,
                        itemStyle: {
                            color: 'rgba(255, 89, 89, 0.6)'
                        }
                    }
                ]
            };

            chartInstance.setOption(option);

            // Clean up on component unmount
            return () => {
                chartInstance.dispose();
            };
        }
    }, [mostrarGraficaBarras, selectedRowName]);

    return (
        <div style={{
            height: '300px', width: '100%', display: 'flex',
            justifyContent: 'center', alignItems: 'center'
        }}>
            {mostrarGraficaBarras ?
                <div ref={chartRef} style={{ width: '100%', height: '100%' }} /> :
                <CircularProgress />
            }
        </div>
    );
}

export default BarsChart;
