import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts'
import { Typography } from '@mui/material';

const GraficaCLientes = ({ datosClientes }) => {

    useEffect(() => {

        // Filtra los datos para obtener solo aquellos con 'TotalCartera' definido
        const datosFiltrados = datosClientes.filter(cliente => cliente.TotalCartera !== undefined);

        // Inicializa el gráfico en el elemento con ID "chart"
        const chart = echarts.init(document.getElementById('chart'));


        // Configuración del gráfico
        const option = {
            title: {
                text: 'Total Cartera por Cliente',
                subtext: 'top 10 Clientes',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                // formatter: '{a} <br/>{b} : ${c}',
            },
            legend: {
                data: datosFiltrados.map(cliente => cliente.Cliente),
                bottom: 0, // Alinea el legend en la parte inferior
            },
            xAxis: {
                type: 'category',
                data: ['Total Cartera'],
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '${value}',
                },
            },
            series: datosFiltrados.map(cliente => ({
                name: cliente.Cliente,
                type: 'bar',
                data: [cliente.TotalCartera],
            })),
        };

        // Configura la opción en el gráfico
        chart.setOption(option);

        // Ajusta el tamaño del gráfico cuando cambia el tamaño de la ventana
        const resizeHandler = () => {
            chart.resize();
        };

        window.addEventListener('resize', resizeHandler);

        // Limpia el gráfico cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', resizeHandler);
            chart.dispose();
        };
    }, [datosClientes, datosClientes]);

    return (
        <div>
            <div id="chart" style={{ width: '100%', height: '32.4rem'  }}></div>
        </div>
    )
}

export default GraficaCLientes