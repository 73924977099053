// SeleccionProcesoIndicador.jsx
import React from 'react';
import {
    Box, FormControl, Autocomplete, TextField, Grid, Paper,
} from '@mui/material';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const SeleccionProcesoIndicador = ({
    value, setValue, procesos, selectedProceso, setselectedProceso,
    indicadores, selectedIndicadores, setselectedIndicadores, datos, prevSelectedProceso, setselectedIndicadoresId
}) => {
    return (
        <Paper variant="elevation"   // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
            style={{
                borderRadius: "8px",
                position: "sticky",
                // border: '1px solid black',
                top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                boxShadow: " 0px 0px 20px 5px rgba(0, 0, 0, 0.1)", // Agrega una sombra debajo del Paper
                transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
            }}
        >
            <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1, justifyItems: "center", }}>
                <Grid container spacing={1} alignItems="center" sx={{
                    marginBottom: "17px", marginLeft: "10px", marginRight: "10px", marginTop: "8px",
                    justifyContent: "space-between", display: "flex", justifyItems: "center",
                }}>

                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} >
                        {/* <Stack direction="row" alignItems="center" spacing={0.5}> */}
                        <FormControl fullWidth>
                            <Box sx={{ marginTop: "-10px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Año"
                                            // placeholder="Seleccione el año"
                                            openTo="year"
                                            views={['year']}
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={value}
                                            onChange={setValue}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} >
                        <Box style={{ display: "flex" }}>

                            <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} >
                                <FormControl fullWidth>
                                    {(value === null ? null : dayjs(value).year()) ?
                                        (
                                            <>
                                                <Autocomplete fullWidth
                                                    options={procesos}
                                                    getOptionLabel={(option) => option.ProEmpNom.toLowerCase() || ''}
                                                    onChange={(event, newValue) => {
                                                        setselectedProceso(newValue?.ProEmpSec || '');
                                                    }}
                                                    disabled={(value === null ? null : dayjs(value).year()) ? false : true}
                                                    size='small'
                                                    disablePortal
                                                    sx={{ marginRight: 2 }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder=""
                                                            label="Seleccione el proceso"
                                                            // Aplica estilos directamente al input para asegurar que el texto esté en minúsculas
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { textTransform: "lowercase" },
                                                            }}
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <li {...props} style={{ textTransform: "lowercase" }}>
                                                            {option.ProEmpNom}
                                                        </li>
                                                    )}
                                                />
                                            </>
                                        ) :
                                        (
                                            <>
                                                <TextField disabled
                                                    label="Debe seleccionar un año"
                                                    variant="outlined" size='small' />
                                            </>
                                        )
                                    }
                                    aqui el indicador : {selectedProceso}
                                </FormControl>
                            </Grid>

                            <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} >
                                <FormControl fullWidth sx={{ marginLeft: 1 }}>
                                    {datos ?
                                        (
                                            <>
                                                <Autocomplete fullWidth
                                                    options={indicadores}
                                                    getOptionLabel={(option) =>
                                                        prevSelectedProceso
                                                            ? option.IndProEmpNom.toLowerCase()
                                                            : ''
                                                    }
                                                    onChange={(event, newValue) => {
                                                        setselectedIndicadores(newValue?.IndProEmpSec || '');
                                                        if (newValue) {
                                                            const selectedIndex = indicadores.findIndex(
                                                                (item) => item.IndProEmpSec === newValue.IndProEmpSec
                                                            );
                                                            setselectedIndicadoresId(selectedIndex)
                                                            // console.log('Posición seleccionada:', selectedIndex);

                                                        }
                                                    }}
                                                    size='small'
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            // placeholder="Seleccione un indicador"
                                                            label="Seleccione un indicador"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                            }}
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <li {...props} style={{ textTransform: "lowercase" }}>
                                                            {option.IndProEmpNom}
                                                        </li>
                                                    )}
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <TextField disabled
                                                    label="Procesos sin indicador"
                                                    variant="outlined" size='small' />
                                            </>
                                        )
                                    }
                                    aqui el indicador : {selectedIndicadores}
                                </FormControl>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}
export default SeleccionProcesoIndicador;