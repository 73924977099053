import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Typography, Card, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import IndicadorPorcentaje from './IndicadorPorcentaje';
import newRequest from '../../../../../utils/newRequest';
import { validacionData } from './formulaIndicadores.js';
import Grid from '@mui/material/Grid2';


// Estilos optimizados con styled para la carta
const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: "8px",
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    padding: "10px",
    // border: "1px solid #e4e4e7", // Aplicar el color del borde
    '&:hover': {
        transform: 'scale(1.01)', // Efecto de agrandamiento
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Sombra más profunda al hacer hover
    },
}));
const CartaIndicador = React.memo(({ item, chartId, alignment, selectAñoIncial, mes }) => {

    // Memorizar BodyConsultaInd para evitar recrearlo en cada render
    const BodyConsultaInd = useMemo(() => ({
        año: selectAñoIncial,
        mes: mes,
        codigoProceso: alignment,
        Idproceso: item.ProEmpSec,
    }), [selectAñoIncial, mes, alignment, item]);

    const [datosInd, setdatosInd] = useState([]);

    // Función optimizada para obtener los indicadores
    const ObtenerConsultaIndicadores = useCallback(async () => {
        try {
            const res = await newRequest.post(
                `/api/GerenciaVisual/Indicadores/ConsultaCmIndicador`,
                BodyConsultaInd,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            setdatosInd(res.data || []);
        } catch (error) {
            console.log("error", error);
        }
    }, [BodyConsultaInd]);

    useEffect(() => {
        ObtenerConsultaIndicadores();
    }, [ObtenerConsultaIndicadores]);

    const valorformula = validacionData(datosInd);

    return (
        <StyledPaper elevation={0}> {/* Aquí usamos Paper con hover */}
            <Card elevation={0} style={{
                borderRadius: "8px",
                // backgroundColor: "#f0f0f0", // Cambia este color por el que desee
                height: "220px",
            }}>

                <Grid size="auto">
                    <Grid size={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                noWrap
                                variant='h6'
                                sx={{
                                    fontSize: "1.1em",
                                    width: "280px"
                                }}
                            >
                                {item.ProEmpNom}
                            </Typography>

                            <Typography noWrap sx={{ color: "#71717a" }}>
                                id: {item.ProEmpSec}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid size={12}>
                        <Box>
                            <IndicadorPorcentaje chartId={chartId} porcentaje={valorformula.resultado} />
                        </Box>
                    </Grid>

                    <Grid size={12}>
                        <Box sx={{
                            display: "flex", justifyContent: "space-between",
                            justifyItems: "center", alignItems: "center"
                        }}>
                            <Typography style={{ fontSize: "2em", fontWeight: "bolder" }}>
                                {valorformula.resultado}
                            </Typography>

                            <span style={{ fontSize: "15px", fontWeight: "normal", color: "#71717a" }}>
                                ({valorformula.recalculopeso})
                            </span>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </StyledPaper>
    );
});

export default CartaIndicador;
