import React, { useEffect } from "react";
import { Box, Grid, Typography, Container, } from '@mui/material';

import {
    Autoplay, Navigation, Pagination,
} from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Sembraton
import fotoUno from "../../img/sembraton/uno.jpg"
import fotoDos from "../../img/sembraton/dos.jpg"
// import fotoCuatro from "../../img/sembraton/cuatro.jpg"
// Maraton
// import uno from "../../img/maraton/uno.jpg"
import dos from "../../img/maraton/dos.jpg"
import tres from "../../img/maraton/tres.jpg"
// import cuatro from "../../img/maraton/cuatro.jpg"
import cinco from "../../img/maraton/cinco.jpg"
import Footer from "../../../components/Footer/Footer";


export const Eventos = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        // <div >
        //     <Container  >
        //         <Box style={{ width: "100%" }}>
        //             <Box sx={{
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 justifyContent: 'center',
        //                 marginTop: '10em',
        //             }}>
        //                 <Box sx={{
        //                     backgroundColor: "#003da5",
        //                     height: "45px", width: "400px",
        //                     borderRadius: "15px 30px 15px 30px",
        //                 }}
        //                 >
        //                     <Typography
        //                         sx={{
        //                             marginLeft: "10px", display: "flex",
        //                             alignItems: "center",
        //                             color: "white", justifyContent: "center",
        //                             fontSize: "25px", textAlign: "center"
        //                         }}
        //                     >
        //                         Eventos
        //                     </Typography>
        //                 </Box>
        //             </Box>
        //             <Box sx={{
        //                 display: 'flex', alignItems: 'start',
        //                 justifyContent: 'start', marginTop: '4em',
        //             }}>
        //                 <Box sx={{
        //                     backgroundColor: "#d42b29", height: "45px",
        //                     width: "400px", borderRadius: "15px 30px 15px 30px",
        //                 }}
        //                 >
        //                     <Typography
        //                         sx={{
        //                             marginLeft: "10px", display: "flex",
        //                             alignItems: "center",
        //                             color: "white", justifyContent: "center",
        //                             fontSize: "25px", textAlign: "center"
        //                         }}
        //                     >
        //                         Sembraton
        //                     </Typography>
        //                 </Box>
        //             </Box>
        //             <Grid container spacing={2} sx={{ marginTop: "2rem" }}>
        //                 <Grid item xs={12} sm={7} md={7} lg={7}>
        //                     <span>
        //                         <Swiper
        //                             spaceBetween={30}
        //                             centeredSlides={true}
        //                             autoplay={{
        //                                 delay: 3500,
        //                                 disableOnInteraction: false,
        //                             }}
        //                             pagination={{
        //                                 clickable: true,
        //                             }}
        //                             style={{ borderRadius: "15px" }}
        //                             navigation={true}
        //                             modules={[Autoplay, Pagination, Navigation]}
        //                             className="mySwiperSembraton"
        //                         >
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={fotoUno} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={fotoDos} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={fotoCuatro} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                         </Swiper>
        //                     </span>
        //                 </Grid>
        //                 <Grid item xs={12} sm={5} md={5} lg={5}>

        //                     <iframe
        //                         title="VideoFarma"
        //                         src="https://drive.google.com/file/d/1-ORL2pQjagu1MWYMmLhmWwNZP3XrdE5J/preview"
        //                         allow="autoplay"
        //                         style={{
        //                             width: "100%", height: "30rem", borderRadius: "15px", objectFit: "contain",
        //                             aspectRatio: 3 / 2, border: "none", // Agregar esta línea
        //                         }}
        //                     >
        //                     </iframe>
        //                     {/* </div> */}
        //                 </Grid>
        //             </Grid>

        //             <Box sx={{
        //                 display: 'flex', alignItems: 'end',
        //                 justifyContent: 'end', marginTop: '4em',
        //             }}>
        //                 <Box sx={{
        //                     backgroundColor: "#d42b29", height: "45px",
        //                     width: "400px", borderRadius: "15px 30px 15px 30px",
        //                 }}
        //                 >
        //                     <Typography
        //                         sx={{
        //                             marginLeft: "10px", display: "flex",
        //                             alignItems: "center",
        //                             color: "white", justifyContent: "center",
        //                             fontSize: "25px", textAlign: "center"
        //                         }}
        //                     >
        //                         Maraton - corre para vivir
        //                     </Typography>
        //                 </Box>
        //             </Box>

        //             <Grid container spacing={2} sx={{ marginTop: "2rem" }}>

        //                 <Grid item xs={12} sm={5} md={5} lg={5} >
        //                     <iframe
        //                         // title="VideoFarma"
        //                         // src="https://drive.google.com/file/d/1D-XICi8I6hiCXTKawikiwoGephgdDA6R/preview"
        //                         src="https://drive.google.com/file/d/1D-XICi8I6hiCXTKawikiwoGephgdDA6R/preview"
        //                         allow="autoplay"
        //                         style={{
        //                             height: "30rem", borderRadius: "15px", objectFit: "contain", width: "100%",
        //                             aspectRatio: 3 / 2, border: "none", // Agregar esta línea
        //                         }}
        //                     >
        //                     </iframe>
        //                 </Grid>

        //                 <Grid item xs={12} sm={7} md={7} lg={7}>
        //                     <span>
        //                         <Swiper
        //                             spaceBetween={30}
        //                             centeredSlides={true}
        //                             autoplay={{
        //                                 delay: 4500,
        //                                 disableOnInteraction: false,
        //                             }}
        //                             pagination={{
        //                                 clickable: true,
        //                             }}
        //                             style={{ borderRadius: "15px" }}
        //                             navigation={true}
        //                             modules={[Autoplay, Pagination, Navigation]}
        //                             className="mySwiperSembraton"
        //                         >
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={uno} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={dos} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={tres} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={cuatro} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                             <SwiperSlide className="swiper-slides-sembraton">
        //                                 <img src={cinco} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" />
        //                             </SwiperSlide>
        //                         </Swiper>
        //                     </span>
        //                 </Grid>
        //             </Grid>
        //         </Box>
        //     </Container>

        //     <Box sx={{ marginTop: "8rem" }}>
        //         <Footer />
        //     </Box>

        // </div >
        <div>
            <Container>
                <Box style={{ width: "100%" }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '10em',
                    }}>
                        <Box sx={{
                            backgroundColor: "#003da5",
                            height: "45px", width: "400px",
                            borderRadius: "15px 30px 15px 30px",
                        }}>
                            <Typography sx={{
                                marginLeft: "10px", display: "flex",
                                alignItems: "center",
                                color: "white", justifyContent: "center",
                                fontSize: "25px", textAlign: "center"
                            }}>
                                Eventos
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex', alignItems: 'start',
                        justifyContent: 'start', marginTop: '4em',
                    }}>
                        <Box sx={{
                            backgroundColor: "#d42b29", height: "45px",
                            width: "400px", borderRadius: "15px 30px 15px 30px",
                        }}>
                            <Typography sx={{
                                marginLeft: "10px", display: "flex",
                                alignItems: "center",
                                color: "white", justifyContent: "center",
                                fontSize: "25px", textAlign: "center"
                            }}>
                                Sembraton
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} sx={{ marginTop: "2rem" }}>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <span>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    style={{ borderRadius: "15px" }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiperSembraton"
                                >
                                    <SwiperSlide key="fotoUno" className="swiper-slides-sembraton">
                                        <img src={fotoUno} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="Foto Uno" />
                                    </SwiperSlide>
                                    <SwiperSlide key="fotoDos" className="swiper-slides-sembraton">
                                        <img src={fotoDos} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="Foto Dos" />
                                    </SwiperSlide>
                                    {/* <SwiperSlide key="fotoCuatro" className="swiper-slides-sembraton">
                                        <img src={fotoCuatro} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="Foto Cuatro" />
                                    </SwiperSlide> */}
                                </Swiper>
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <iframe
                                title="VideoFarma"
                                src="https://drive.google.com/file/d/1-ORL2pQjagu1MWYMmLhmWwNZP3XrdE5J/preview"
                                allow="autoplay"
                                style={{
                                    width: "100%", height: "30rem", borderRadius: "15px", objectFit: "contain",
                                    aspectRatio: 3 / 2, border: "none",
                                }}
                            >
                            </iframe>
                        </Grid>
                    </Grid>

                    <Box sx={{
                        display: 'flex', alignItems: 'end',
                        justifyContent: 'end', marginTop: '4em',
                    }}>
                        <Box sx={{
                            backgroundColor: "#d42b29", height: "45px",
                            width: "400px", borderRadius: "15px 30px 15px 30px",
                        }}>
                            <Typography sx={{
                                marginLeft: "10px", display: "flex",
                                alignItems: "center",
                                color: "white", justifyContent: "center",
                                fontSize: "25px", textAlign: "center"
                            }}>
                                Maraton - corre para vivir
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container spacing={2} sx={{ marginTop: "2rem" }}>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <iframe
                                title="VideoFarma"
                                src="https://drive.google.com/file/d/1D-XICi8I6hiCXTKawikiwoGephgdDA6R/preview"
                                allow="autoplay"
                                style={{
                                    height: "30rem", borderRadius: "15px", objectFit: "contain", width: "100%",
                                    aspectRatio: 3 / 2, border: "none",
                                }}
                            >
                            </iframe>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <span>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 4500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    style={{ borderRadius: "15px" }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiperSembraton"
                                >
                                    {[ dos, tres, cinco].map((foto, index) => (
                                        <SwiperSlide key={`maraton${index}`} className="swiper-slides-sembraton">
                                            <img src={foto} style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt={`Maraton ${index}`} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </span>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Box sx={{ marginTop: "8rem" }}>
                <Footer />
            </Box>
        </div>
    )
}