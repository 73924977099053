import React, { useCallback, useMemo } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    TableHead
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';

const getMonthName = (monthNumber) => {
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return months[monthNumber - 1]; // El mesNumber empieza desde 1
};

export default function TablaAcumuladoPyG({ data, selectedMesInicial, selectedMesFinal }) {

    const formatCurrency = useCallback((value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
        }).format(value);
    }, []);

    const renderSection = (rows, label, prefix) => {
        let total = 0;
        const sectionRows = rows.filter(row => row.puccod.startsWith(prefix));

        const rowElements = sectionRows.map((row) => {
            total += row.total;
            return (
                <TableRow key={row.puccod}>
                    <TableCell align='left'> {row.puccod}</TableCell>
                    <TableCell align="left">
                        <Typography noWrap width={"250px"} title={row.pucNom}>
                            {row.pucNom}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">{formatCurrency(row.total)}</TableCell>
                </TableRow>
            );
        });

        return (
            <>
                <TableRow>
                    <TableCell align="left" colSpan={2} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                        {label}
                    </TableCell>
                    <TableCell align="right" colSpan={1} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                        {formatCurrency(total)}
                    </TableCell>
                </TableRow>
                {rowElements}
            </>
        );
    };

    const calculateTotal = (rows, prefix) => {
        return rows
            .filter(row => row.puccod.startsWith(prefix))
            .reduce((sum, row) => sum + row.total, 0);
    };


    // Calcular totales
    const totalIngresos = useMemo(() => Math.abs(calculateTotal(data, "41")), [data]);
    const totalCostoVenta = useMemo(() => Math.abs(calculateTotal(data, "61")), [data]);

    // Calcular la Utilidad Bruta
    const utilidadBruta = totalIngresos - totalCostoVenta;

    // Calcular gastos de Administración y Ventas
    const totalAdministracion = useMemo(() => Math.abs(calculateTotal(data, "51")), [data]);
    const totalVentas = useMemo(() => Math.abs(calculateTotal(data, "52")), [data]);

    // Calcular la Utilidad Operacional
    const utilidadOperacional = utilidadBruta - totalAdministracion - totalVentas;

    // Calcular Ingresos y Gastos no Operacionales
    const totalGastosNoOperacionales = useMemo(() => Math.abs(calculateTotal(data, "53")), [data]);
    const totalIngresosNoOperacionales = useMemo(() => Math.abs(calculateTotal(data, "42")), [data]);

    // Calcular la Utilidad Neta
    const utilidadNeta = utilidadOperacional + totalIngresosNoOperacionales - totalGastosNoOperacionales;

    // Obtener nombres de los meses
    const nombreMesInicial = getMonthName(selectedMesInicial);
    const nombreMesFinal = getMonthName(selectedMesFinal);

    //SUMA TOTAL
    // const utilidadEjercicio = useMemo(() => {
    //     return Math.abs(totalIngresos + totalCostoVenta +
    //         totalAdministracion + totalVentas + totalGastosNoOperacionales + totalIngresosNoOperacionales);
    // }, [totalAdministracion, totalCostoVenta, totalGastosNoOperacionales, totalIngresos,
    //     totalIngresosNoOperacionales, totalVentas]);

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Combinar todas las secciones de la tabla en un solo array con la misma estructura que la tabla
        const combinedData = [
            { 'Categoría': 'Ingresos Operacionales', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.filter(row => row.puccod.startsWith("41")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.total,
            })),
            { 'Categoría': '', 'Código': '', 'Nombre': 'Total Ingresos Operacionales', 'Total': totalIngresos },

            { 'Categoría': 'Costo de Ventas', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.filter(row => row.puccod.startsWith("61")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.total,
            })),
            { 'Categoría': '', 'Código': '', 'Nombre': 'Total Costo de Ventas', 'Total': totalCostoVenta },

            { 'Categoría': '', 'Código': '', 'Nombre': 'Utilidad Bruta', 'Total': utilidadBruta },

            { 'Categoría': 'Administración', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.filter(row => row.puccod.startsWith("51")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.total,
            })),
            { 'Categoría': '', 'Código': '', 'Nombre': 'Total Administración', 'Total': totalAdministracion },

            { 'Categoría': 'Ventas', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.filter(row => row.puccod.startsWith("52")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.total,
            })),
            { 'Categoría': '', 'Código': '', 'Nombre': 'Total Ventas', 'Total': totalVentas },

            { 'Categoría': '', 'Código': '', 'Nombre': 'Utilidad Operacional', 'Total': utilidadOperacional },

            { 'Categoría': 'Gastos no Operacionales', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.filter(row => row.puccod.startsWith("53")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.total,
            })),
            { 'Categoría': '', 'Código': '', 'Nombre': 'Total Gastos no Operacionales', 'Total': totalGastosNoOperacionales },

            { 'Categoría': 'Ingresos no Operacionales', 'Código': '', 'Nombre': '', 'Total': '' },
            ...data.filter(row => row.puccod.startsWith("42")).map(row => ({
                'Categoría': '',
                'Código': row.puccod,
                'Nombre': row.pucNom,
                'Total': row.total,
            })),
            { 'Categoría': '', 'Código': '', 'Nombre': 'Total Ingresos no Operacionales', 'Total': totalIngresosNoOperacionales },

            { 'Categoría': '', 'Código': '', 'Nombre': 'Utilidad Neta', 'Total': utilidadNeta }
        ];

        // Convertir el array combinado en una hoja de Excel
        const worksheet = XLSX.utils.json_to_sheet(combinedData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Informe Completo');

        // Guardar el archivo Excel
        XLSX.writeFile(workbook, `Informe_PyG_${nombreMesInicial}_${nombreMesFinal}.xlsx`);
    };

    return (
        <div>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                <Typography variant='h6' sx={{ flexGrow: 1, }}>
                    Acumulado {nombreMesInicial} - {nombreMesFinal}
                </Typography>
                <Box sx={{ flexGrow: 0 }} >

                    <Tooltip title="Descargar tabla">
                        <IconButton onClick={exportToExcel}>
                            <DownloadIcon sx={{ width: "20px", height: "20px", }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <Table stickyHeader sx={{}} size="small">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} >Cuenta</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }}>Descripción</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} align='right' >Valor</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {renderSection(data, "Ingresos Operacionales", "41")}
                    {renderSection(data, "Costo de Ventas", "61")}

                    {/* Mostrar la Utilidad Bruta */}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Utilidad Bruta
                                <Tooltip title={`Ingresos Operacionales MENOS Costo de Ventas`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1'
                        }}>
                            {formatCurrency(utilidadBruta)}
                        </TableCell>
                    </TableRow>

                    {renderSection(data, "Administración", "51")}
                    {renderSection(data, "Ventas", "52")}

                    {/* Mostrar la Utilidad Operacional */}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Utilidad Operacional
                                <Tooltip title={`Utilidad Bruta MENOS Administración  y Ventas`}>                                        <IconButton
                                    sx={{
                                        position: "absolute",
                                        right: 0, // Ajustar al borde derecho
                                        top: "50%", // Centrado verticalmente
                                        transform: "translateY(-50%)"
                                    }}
                                >
                                    <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#a2efe1'
                        }}>
                            {formatCurrency(utilidadOperacional)}
                        </TableCell>
                    </TableRow>

                    {renderSection(data, "Gastos no Operacionales", "53")}
                    {renderSection(data, "Ingresos no Operacionales", "42")}

                    {/* Mostrar la Utilidad Neta */}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#ffcd7d', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Utilidad Neta
                                <Tooltip title={`utilidad Operacional + Ingresos No Operacionales - Gastos No Operacionales`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#ffcd7d'
                        }}>
                            {formatCurrency(utilidadNeta)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </div>
    );
}
