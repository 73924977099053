import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Stack, FormControl, FormLabel, InputAdornment, IconButton, OutlinedInput, CssBaseline, Alert, styled } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiCard from '@mui/material/Card';
import uno from "./images/logo-farmacomercial.png";
import { AuthContext } from './loginContext';
import AppTheme from "./theme/AppTheme";

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    // height: "500px",
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow: 'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow: 'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // minHeight: '150px', // Altura mínima para mantener el espacio de la imagen
    // maxHeight: '150px',
    // marginBottom: theme.spacing(2),
}));

const SignInContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
    position: 'relative',
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
}));

const Login = (props) => {
    const { isAuthenticated, login } = useContext(AuthContext);
    const [datax, setInputs] = useState({ username: "", password: "" });
    const [err, setErr] = useState(null);
    const [isAuthInitialized, setAuthInitialized] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(datax);
            setAuthInitialized(true);
            navigate("/Farmanet");
        } catch (err) {
            setErr(err.response.data.message);
        }
    };

    useEffect(() => {
        if (isAuthenticated && isAuthInitialized) {
            navigate("/Farmanet");
        }
    }, [isAuthenticated, isAuthInitialized, navigate]);

    return (
        <AppTheme {...props}>
            <CssBaseline enableColorScheme />
            <SignInContainer>
                <Card variant="outlined">
                    {/* Contenedor de imagen para que no se deforme */}
                    <ImageContainer>
                        <img src={uno} alt="Logo" style={{ maxWidth: '100%', objectFit: 'contain' }} />
                    </ImageContainer>

                    <Typography component="h1" variant="h6" sx={{
                        fontSize: "20px",
                        display: "flex", justifyContent: "center",
                        alignItems: "center"
                    }}>Iniciar Sesión</Typography>

                    <Box component="form" noValidate sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2, flexGrow: 1 }}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="usuario">Usuario</FormLabel>
                            <TextField id="usuario" name="usuario" placeholder="Ingrese su usuario" autoFocus onChange={handleChange} fullWidth variant="outlined" />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="clave">Contraseña</FormLabel>
                            <OutlinedInput
                                id="clave"
                                name="clave"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                placeholder="Ingrese su contraseña"
                                startAdornment={<InputAdornment position="start"><i className="fa-solid fa-lock"></i></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            style={{
                                                cursor: 'pointer',
                                                borderRadius: '50%',
                                                backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {err && (
                            <Stack sx={{ width: '100%', mb: 2 }} spacing={2}>
                                <Alert severity="warning">{err}</Alert>
                            </Stack>
                        )}
                        {/* Aseguramos que el botón esté al final */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={handleLogin}
                            sx={{ mt: 'auto' }} // Esto posiciona el botón al final
                        >
                            Iniciar Sesión
                        </Button>
                    </Box>
                </Card>
            </SignInContainer>
        </AppTheme>
    );
};

export default Login;
