import React, { useCallback, useEffect, useState } from 'react'
import newRequest from '../../../../utils/newRequest';
import TablaPasivo from './TablaPasivo';

const PorPasivo = ({ selectAñoIncial, selectedMesInicial }) => {
    const [data, setdata] = useState([]);
    const [pasivoNoCorr, setPasivoNoCorr] = useState([])

    // Obtenemos los datos
    const getAyPData = useCallback(async () => {
        try {
            const [responseAyP] = await Promise.all([
                newRequest.post('/api/Financiera/financieraRoutes/pasivoCombinados', {
                    año: selectAñoIncial,
                    mesInicial: selectedMesInicial,
                }),
            ]);
            setdata(Array.isArray(responseAyP.data) ? responseAyP.data : []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [selectAñoIncial, selectedMesInicial]);

    const obtenerPasivoNoCorriente = useCallback(async () => {
        try {
            const res = await newRequest.post(`/api/Financiera/financieraRoutes/pasivoNoCorriente`, {
                año: selectAñoIncial,
                mesInicial: selectedMesInicial,
            })
            setPasivoNoCorr(res.data[0])
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [selectAñoIncial, selectedMesInicial])

    useEffect(() => {
        getAyPData();
        obtenerPasivoNoCorriente();
    }, [getAyPData, obtenerPasivoNoCorriente]);

    return (
        <div>
            <TablaPasivo data={data} pasivoNoCorr={pasivoNoCorr} />
        </div>
    )
}

export default PorPasivo