import React from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    ResponsiveContainer, Legend, Label, ComposedChart,
    Line, Bar, Scatter, LineChart
} from 'recharts';
import { Typography, Box } from '@mui/material';

const GraficaVentasClientes = ({ dataZona }) => {

    const vendedorColors = ['#053B50', '#0E21A0', '#FFC436', '#ff5733', '#4a90e2',
        '#f56b25', '#ac9c8a', '#BB2525', '#00ADB5', '#0079FF', '#0c7474'];

    const nombresMeses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    const transformDataForChart = (dataZona) => {
        // Crear un array de meses del 1 al 12
        const meses = Array.from({ length: 12 }, (_, index) => index + 1);
        const data = meses.map((mes) => {
            const monthData = {
                meses: nombresMeses[mes - 1], // Mes en el eje X
            };
            // Sumar los valores de todos los clientes para este mes
            const totalMes = dataZona.reduce((total, cliente) => {
                const valorMes = cliente[mes];
                // Si el valor del mes no es nulo, sumarlo al total
                if (valorMes !== null) {
                    return total + valorMes;
                }
                return total;
            }, 0);

            monthData.total = totalMes; // Agregar el total al objeto de datos
            return monthData;
        });

        return data;
    };

    const dataForChart = transformDataForChart(dataZona);

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={dataForChart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="meses" />
                    <YAxis
                        tickFormatter={(value) => {
                            if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                            if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                            if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                            if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                            return value; // Valor original si no supera los miles
                        }}
                        tick={{ fontSize: 14 }}
                    />
                    <Tooltip formatter={(value) => new Intl.NumberFormat().format(value)} />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="total"
                        name="Ventas Totales"
                        stroke="#8884d8" // Color de la línea
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default GraficaVentasClientes