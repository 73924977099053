import React, { useEffect, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Typography, Tooltip, Button, ButtonGroup } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import newRequest from '../../../../utils/newRequest';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TablaTipoProceso = ({ setOpenDrawer, tipoProcesos, onIDSelected, ObtenerTipoProcesos }) => {

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);


    const handleButtonClick = (row) => {
        try {
            // console.log("Aqui pasa el row", row.TipProEmpId)
            setId(row.TipProEmpId)
            // onIDSelected(row); 
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.TipProEmpId)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const EliminarTipoProceso = async (row) => {
        try {
            // console.log("Aqui pasa el row ELIMINAR", row.TipProEmpId)
            await newRequest.delete(`/api/Calidad/TipoProceso/EliminarUnTipoProceso/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            ObtenerTipoProcesos();
        } catch (error) {
            console.error(error);
        }
    };

    const crearTipoProceso = () => {
        setId("") //Devolvemos el id vacio para que no abra el modal solo
        onIDSelected("")
        setOpenDrawer(true)
    }

    useEffect(() => {
        if (id) {
            const ObtenerUnDatoProceso = async () => {
                try {
                    const res = await newRequest.post(`/api/Calidad/TipoProceso/ObtenerUnTipoProceso`, {
                        id: id
                    });
                    setOpenDrawer(true) // Abre el modal
                    onIDSelected(res.data); // Llama a la función con el ID como argumento
                    setId("")
                } catch (error) {
                    console.error(error);
                }
            }
            ObtenerUnDatoProceso()
        } else {
            // console.log("creando un ")
        }
    }, [id, idDos, onIDSelected, setOpenDrawer])

    //should be memoized or stable
    const columns = [
        {
            accessorKey: 'TipProEmpNom',
            header: 'Nombre del tipo',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.TipProEmpNom}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.TipProEmpNom}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'TipProEmpObs', //normal accessorKey
            header: 'Observacion',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.TipProEmpObs}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.TipProEmpObs}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },

        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            Cell: ({ cell }) => {
                return (
                    < div >
                        <ButtonGroup variant="text" >
                            <Tooltip title="Editar">
                                <Button onClick={() => handleButtonClick(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EditIcon sx={{ width: '25px', height: '25px', color: '#118DF0' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Eliminar">
                                <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <DeleteIcon sx={{ width: '25px', height: '25px', color: '#FF2442' }} />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div >
                )
            },
            muiTableBodyCellProps: {
                align: 'center',
                height: "20px !important",
            },
            // size: 150,
        },
    ]

    const table = useMaterialReactTable({
        columns: columns,
        data: tipoProcesos,
        muiTablePaperProps: {
            elevation: 0,
        },
        enableStickyHeader: true,
        muiTableHeadCellProps: {
            sx: {
                flex: '0 0 auto',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
        initialState: { density: 'compact' },
        enableDensityToggle: false,
        enableFilters: false,
        renderTopToolbarCustomActions: ({ table }) => (
            <Button variant="outlined"
                onClick={crearTipoProceso}>
                <Typography variant='subtitle2' sx={{ fontSize: "13px" }}>
                    Crear Tipo
                </Typography>
                <AddCircleIcon style={{ fontSize: "19px", marginLeft: "5px" }} />
            </Button>
        ),
        localization: MRT_Localization_ES,
        muiTableContainerProps: {
            sx: { height: "25rem", width: "100%", }
        }
    })


    return (
        <div>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este tipo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarTipoProceso();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <MaterialReactTable
                table={table} //only pass in table instead of all table options
            />
        </div>
    )
}

export default TablaTipoProceso