import React, { Suspense, useEffect, useState } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Typography, TextField, Autocomplete,
    Fab,
    CircularProgress
    // Skeleton
} from '@mui/material';
import WalletIcon from '@mui/icons-material/Wallet';
import { useNavigate } from 'react-router-dom';
import newRequest from '../../../utils/newRequest';
import Edades from './Edades/Edades';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Cartera = () => {

    const navigate = useNavigate();


    // ? Zona seleciconado
    const [selectZona, setSelectZona] = useState([]);
    const [selectedZona, setSelectedZona] = useState(null);

    // ? Opciones de los vendedores
    const [selectVendedor, setSelectVendedor] = useState([])
    const [selectedVendedor, setSelectedVendedor] = useState(null)

    // ? Cliente seleccionado
    const [selectCliente, setSelectCliente] = useState([])
    const [selectedCliente, setSelectedCliente] = useState(null)

    // ? Opciones del tercero
    const [selectTercero, setSelectTercero] = useState([])
    const [selectedTercero, setSelectedTercero] = useState(null)


    useEffect(() => {
        const selectZona = async () => {
            try {
                // const resZona = await axios.get(`http://localhost:8000/api/GerenciaVisual/profundidadSelectZona`);
                const resZona = await newRequest.get('/api/GerenciaVisual/profundidadSelectZona')
                setSelectZona(resZona.data);
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectVendedor = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect');
                setSelectVendedor(res.data)
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectCliente = async () => {
            try {
                const resCliente = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect')
                setSelectCliente(resCliente.data)
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectTercero = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect');
                setSelectTercero(res.data)
            } catch (error) {
                console.log("error 🦋", error)

            }
        }


        selectZona();
        selectVendedor();
        selectCliente();
        selectTercero();
    }, [])

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }

    return (
        <div>
            <Box >

                <Grid item xs={12} sm={6} md={12} >
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between", // Espacio entre los elementos
                                alignItems: "center", // Alinea verticalmente los elementos en el centro
                                marginBottom: "1em",
                            }}
                        >
                            <Fab size='small'
                                onClick={handleBack}
                                style={{
                                    background: "white",
                                    borderRadius: 13,
                                }}
                            >
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                <Typography color="text.primary">Gerencia visual</Typography>
                                <Typography color="text.primary">Cartera</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Box>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                            style={{
                                borderRadius: "13px",
                                position: "sticky",
                                top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                                zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                                boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                                transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                            }}
                        >

                            <Box
                                sx={{
                                    alignContent: "center", justifyContent: "space-between", display: "flex",
                                    alignItems: "center", margin: 1
                                }}
                            >
                                <Grid >
                                </Grid>

                                <Grid container spacing={1} item xs={12} sm={12} md={12} lg={8} xl={8}
                                    sx={{ marginTop: "3px", marginBottom: "8px", }}>
                                    <Grid item xs={12} sm={3}>
                                        <Autocomplete
                                            id="zona" name="zona" size="small" margin="dense"
                                            autoComplete={false}
                                            options={selectZona}
                                            getOptionLabel={(option) => option.ZonNom}
                                            fullWidth sx={{ marginBottom: "5px", }}
                                            onChange={(event, newValue) => {
                                                setSelectedZona(newValue?.ZonCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Zona" size="small" variant="outlined" margin="dense"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { fontSize: 14, },
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* <div>Valor seleccionado: {selectedZona}</div> */}
                                    </Grid>

                                    {/* VENDEDOR */}
                                    <Grid item xs={12} sm={3}>
                                        <Autocomplete
                                            id="cliente" name="Articulo" margin="dense" size="small" autoComplete={false}
                                            options={selectVendedor}
                                            getOptionLabel={(option) => option.VenNom}
                                            fullWidth
                                            onChange={(event, newValue) => {
                                                setSelectedVendedor(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                // estadoFechasetVisual(true)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Vendedor" size="small" variant="outlined" margin="dense"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { fontSize: 14, },
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* <div>Valor seleccionado vendedor: {selectedVendedor}</div> */}
                                    </Grid>

                                    {/* TERCERO */}
                                    <Grid container item xs={12} sm={3} >
                                        <Autocomplete
                                            id="cliente" name="Articulo" autoComplete={false} margin="dense"
                                            options={selectTercero} getOptionLabel={(option) => option.tercero}
                                            fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                            onChange={(event, newValue) => {
                                                setSelectedTercero(newValue?.nitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                // estadoFechasetVisual(true)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tercero" size="small" variant="outlined" margin="dense"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { fontSize: 14, },
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* <div>Valor seleccionado Tercero: {selectedTercero}</div> */}
                                    </Grid>

                                    {/* CLIENTES */}
                                    <Grid item xs={12} sm={3}  >
                                        <Autocomplete
                                            id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                            options={selectCliente} getOptionLabel={(option) => option.cliente}
                                            fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                            onChange={(event, newValue) => {
                                                setSelectedCliente(newValue?.NitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Clientes" size="small" variant="outlined" margin="dense"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { fontSize: 14, }, // Cambia el tamaño de la fuente aquí
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        <Box sx={{ marginBottom: 3 }}>
                            <Paper variant="outlined" sx={{
                                p: { xs: 1, md: 1 }, borderRadius: "13px",
                                marginTop: 3,
                            }}>
                                <Suspense fallback={<CircularProgress />}>
                                    <Edades selectedZona={selectedZona} selectedTercero={selectedTercero}
                                        selectedVendedor={selectedVendedor} selectedCliente={selectedCliente}
                                    />
                                </Suspense>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default Cartera