import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';

const GraficoProducto = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const ventasKey = `Ventas${selectAñoIncial}`;
        const costoKey = `Costo${selectAñoIncial}`;
        const presupuestoKey = `Presupuesto${selectAñoIncial}`;

        const data = rows.map((row, index) => {
            const producto = row.producto; // Abreviar el nombre del producto a la primera palabra
            const ventas = row[ventasKey];
            const costo = row[costoKey];
            const presupuesto = row[presupuestoKey];
            let tendencia = 0;
            if (index >= 1) {
                const ventasAnterior = +rows[index - 1][ventasKey];
                const presupuestoAnterior = +rows[index - 1][presupuestoKey];
                const diferencia = (presupuestoAnterior - ventasAnterior);
                tendencia = diferencia + presupuesto;
            } else {
                tendencia = presupuesto;
            }
            return {
                name: producto,
                Ventas: ventas,
                Costo: costo,
                Presupuesto: presupuesto,
                Tendencia: tendencia,
                VentasMedicamentos: row.linea === 'MEDICAMENTOS' ? ventas : 0,
                VentasDispositivos: row.linea === 'DISPOSITIVOS MEDICOS' ? ventas : 0,
            };
        });

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                confine: true,
                formatter: (params) => {
                    let content = `${params[0].name}<br/>`;
                    params.forEach(param => {
                        content += `${param.seriesName}: $${new Intl.NumberFormat('es-CO').format(param.value)}<br/>`;
                    });
                    return content;
                }
            },
            legend: {
                data: ['Medicamentos', 'Dispositivos Médicos'],
                top: 'top'
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                axisLabel: {
                    rotate: 60,
                    formatter: function (value) {
                        return value.length > 10 ? value.slice(0, 6) + '...' : value;
                    }
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value) => {
                        if (value >= 1e9) return `$${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `$${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `$${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-$${(-value / 1e6).toFixed(1)}M`; // Negativos
                        return `$${value}`; // Valor original
                    }
                }
            },
            series: [
                {
                    name: 'Ventas',
                    type: 'bar',
                    data: data.map(item => item.Ventas),
                    // itemStyle: {
                    //     color: '#2eb165'
                    // },
                    smooth: true
                },
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
            window.removeEventListener('resize', handleResize);
        };
    }, [rows, selectAñoIncial]);

    return (
        <div>
            <Box sx={{ width: "100%", height: 380 }} ref={chartRef}></Box>
        </div>
    );
};

export default GraficoProducto;
