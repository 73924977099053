import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Dialog, DialogContent, Tabs, Tab } from '@mui/material';
import newRequest from '../../../../utils/newRequest';

import TablaIndicadoresEmpresa from './TablaIndicadoresEmpresa';
import ModalIndicadoresEmpresa from './ModalIndicadoresEmpresa';
import ModalDos from './ModalDos';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const ListaIndicadores = ({ proceso, obtenerProceso }) => {
    const [loading, setLoading] = useState(false);
    const [firstProEmpSecSet, setFirstProEmpSecSet] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawerDos, setOpenDrawerDos] = useState(false);
    const [selectedID, setSelectedID] = useState("");
    const [procesoEmpresa, setProcesoEmpresa] = useState([]);
    const [objetivoIndicador, setObjetivoIndicador] = useState([])
    const [datosIndicador, setDatosIndicador] = useState([]);
    const [procesosEmpresa, setProcesosEmpresa] = useState([]);
    const [value, setValue] = useState(0);
    const [selectedProEmpSec, setSelectedProEmpSec] = useState(null);
    const [cantidadIndicador, setCantidadIndicador] = useState([]);
    const [valueDos, setValueDos] = useState(0);

    const handleChangeDos = (event, newValue) => {
        setValueDos(newValue);
    };

    const handleChange = (event, newValue) => {
        const selectedData = proceso[newValue];
        setSelectedProEmpSec(selectedData.ProEmpSec);
        setValue(newValue);
        setValueDos(0);
    };

    const procesoData = {
        id: selectedProEmpSec,
        tipo: valueDos
    };

    const obtenerIndicador = async () => {
        try {
            setLoading(true);
            const res = await newRequest.post(`/api/Calidad/Indicadores/ObtenerIndicador`, procesoData, {
                headers: { "Content-Type": "application/json" }
            });
            if (res.data.length) {
                setDatosIndicador(res.data);
            } else {
                setDatosIndicador([]);
            }
        } catch (error) {
            console.error("Error al obtener indicadores", error);
        } finally {
            setLoading(false);
        }
    };

    const obtenerCantidadIndicador = async () => {
        try {
            const res = await newRequest.post(`/api/Calidad/Indicadores/DatosCantidadIndicador`, {
                id: selectedProEmpSec,
            });
            if (res.data.length) {
                setCantidadIndicador(res.data);
            } else {
                setCantidadIndicador([]);
            }
        } catch (error) {
            console.error("Error al obtener cantidad de indicadores", error);
        }
    };

    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };

    const procesosEmpresaIndicador = async () => {
        try {
            const res = await newRequest.get("/api/Calidad/Indicadores/ProcesosEmpresaIndicador");
            setProcesoEmpresa(res.data);
        } catch (error) {
            console.error("Error al obtener procesos de empresa", error);
        }
    };

    const ObjetivoIndicador = async () => {
        try {
            const res = await newRequest.get("/api/Calidad/Indicadores/ObjetivoIndicador")
            if (res.status !== 200) {
                throw new Error('Error en la respuesta del servidor')
            } else {
                setObjetivoIndicador(res.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    const getProcesos = async () => {
        try {
            const res = await newRequest.get('/api/Calidad/Indicadores/ProcesosEmpresaIndicador');
            setProcesosEmpresa(res.data);
        } catch (error) {
            console.error("Error al obtener procesos", error);
        }
    };

    useEffect(() => {
        obtenerCantidadIndicador();
    }, [valueDos, value]);

    useEffect(() => {
        if (!firstProEmpSecSet && proceso.length > 0) {
            setSelectedProEmpSec(proceso[0].ProEmpSec);
            setFirstProEmpSecSet(true);
        }
    }, [proceso, firstProEmpSecSet]);

    useEffect(() => {
        procesosEmpresaIndicador();
        ObjetivoIndicador();
        getProcesos();

        if (selectedProEmpSec !== null) {
            obtenerIndicador();
            obtenerCantidadIndicador();
        }
    }, [selectedProEmpSec, valueDos, proceso]);

    return (
        <div style={{ height: "100%" }}>
            <Grid container spacing={2} style={{ height: "100%" }}>
                <Grid item xs={2} style={{ height: "100%" }}>
                    <Box sx={{ display: 'flex', height: "100%", paddingTop: -2 }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            sx={{ width: '100%', borderRight: 1, borderColor: 'divider' }}
                        >
                            {proceso.map((data, index) => (
                                <Tab
                                    key={index}
                                    label={`${data.ProEmpNom} (${data.total})`}
                                    sx={{ display: "flex", alignItems: "flex-start", textAlign: "left", textTransform: "lowercase" }}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Grid>

                <Grid item xs={10} style={{ height: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={valueDos} onChange={handleChangeDos} aria-label="basic tabs example">
                            <Tab label={`estrategico (${cantidadIndicador[0]?.Estra || 0})`} />
                            <Tab label={`operativo (${cantidadIndicador[0]?.Opera || 0})`} />
                        </Tabs>
                    </Box>

                    <Box sx={{ height: "calc(100% - 48px)", overflowY: 'auto' }}>
                        {loading ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            procesosEmpresa.map((data, index) => (
                                <TabPanel key={data.ProEmpSec} value={value} index={index}>
                                    <TablaIndicadoresEmpresa
                                        datosIndicador={datosIndicador}
                                        setOpenDrawer={setOpenDrawer}
                                        setOpenDrawerDos={setOpenDrawerDos}
                                        onIDSelected={handleIDSelected}
                                        ObtenerIndicador={obtenerIndicador}
                                        obtenerProceso={obtenerProceso}
                                        obtenerCantidadIndicador={obtenerCantidadIndicador}
                                    />
                                </TabPanel>
                            ))
                        )}
                    </Box>
                </Grid>
            </Grid>

            <Dialog
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                maxWidth="md"
                PaperProps={{ sx: { borderRadius: "13px" } }}
            >
                <DialogContent>
                    <ModalIndicadoresEmpresa
                        setOpenDrawer={setOpenDrawer}
                        procesoEmpresa={procesoEmpresa}
                        selectedID={selectedID}
                        objetivoIndicador={objetivoIndicador}
                        ObtenerIndicador={obtenerIndicador}
                        obtenerProceso={obtenerProceso}
                        obtenerCantidadIndicador={obtenerCantidadIndicador}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDrawerDos}
                onClose={() => setOpenDrawerDos(false)}
                maxWidth="md"
                PaperProps={{ sx: { borderRadius: "13px" } }}
            >
                <DialogContent>
                    <ModalDos
                        valueDos={valueDos}
                        selectedID={selectedID}
                        setOpenDrawerDos={setOpenDrawerDos}
                        ObtenerIndicador={obtenerIndicador}
                        obtenerProceso={obtenerProceso}
                        obtenerCantidadIndicador={obtenerCantidadIndicador}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ListaIndicadores;
