import { Alert, Autocomplete, Box, Breadcrumbs, CircularProgress, Fab, Grid, Paper, Snackbar, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { toast } from 'react-toastify';
import newRequest from '../../../utils/newRequest';
import HailIcon from '@mui/icons-material/Hail';
import SeguimientoAse from './SeguimientoAse/SeguimientoAse';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const meses = [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun",
    "Jul", "Ago", "Sep", "Oct", "Nov", "Dic",
];


const SeguimientoAsesor = () => {

    const navigate = useNavigate();
    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(currentMonth);

    // ? Seleccionar Año y Mes final
    const [selectedMesFinal, setSelectedMesFinal] = useState(currentMonth);

    // ? Opciones de los vendedores
    const [selectVendedor, setSelectVendedor] = useState([])
    const [selectedVendedor, setSelectedVendedor] = useState(null)

    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState({ message: '', severity: 'info' });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleOpenSnackbar = (message, severity) => {
        setMessageInfo({ message, severity });
        setOpen(true);
    };

    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
            setSelectedMesInicial(1);
            setSelectedMesFinal(12);
        } else {
            toast.warning(`La selección no es válida`, {
                position: "bottom-left",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        }
    };

    const getMesesDisponibles = () => {
        return meses.map((mes, index) => ({
            nombre: mes,
            valor: index + 1,
        }));
    };

    const handleMesInicial = (month) => {
        if (selectAñoIncial === currentYear && month > currentMonth) {
            handleOpenSnackbar("No puedes seleccionar un mes posterior al mes actual", "warning");
        } else {
            setSelectedMesInicial(month);
        }
    };

    const handleMesFinal = (month) => {
        if (selectAñoIncial === currentYear && month < selectedMesInicial) {
            handleOpenSnackbar("No puedes seleccionar un mes anterior al mes inicial", "warning");
        } else {
            setSelectedMesFinal(month);
        }
    };

    const fetchData = async () => {
        try {
            // Añadir cabeceras a la solicitud: Puedes agregar una cabecera Cache-Control con el valor no-cache o no-store 
            // forzar que el servidor envíe una nueva respuesta sin importar si el contenido ha cambiado o no.
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSelectOptions = async (endpoint, setState) => {
        try {
            // const res = await newRequest.get(endpoint);
            const res = await newRequest.get(endpoint, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            setState(res.data.length > 0 ? res.data : []);
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchSelectOptions('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect', setSelectVendedor);

    }, []);

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }

    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={messageInfo.severity}
                    // variant="standard"
                    sx={{
                        width: '100%', background: "#222831", color: "white"
                    }}
                >
                    {messageInfo.message}
                </Alert>
            </Snackbar>

            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between", // Espacio entre los elementos
                            alignItems: "center", // Alinea verticalmente los elementos en el centro
                            marginBottom: "1em",
                        }}
                    >
                        <Fab size='small'
                            onClick={handleBack}
                            style={{
                                background: "white",
                                borderRadius: 13,
                            }}
                        >
                            <ChevronLeftIcon />
                        </Fab>
                        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                            <Typography color="text.primary">Gerencia visual</Typography>
                            <Typography color="text.primary">Seguimiento por Asesor</Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "13px",
                            position: "sticky",
                            top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                            zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                            boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                            transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                        }}
                    >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                            <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>


                                <Grid container item xs={12} sm={8}>

                                </Grid>

                                <Grid container item xs={12} sm={4}>
                                    <Autocomplete
                                        id="cliente" name="Articulo" margin="dense" size="small" autoComplete={false}
                                        options={selectVendedor}
                                        getOptionLabel={(option) => option.VenNom}
                                        fullWidth
                                        onChange={(event, newValue) => {
                                            setSelectedVendedor(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            // estadoFechasetVisual(true)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Vendedor" size="small" variant="outlined" margin="dense"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14, },
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <div>Valor seleccionado vendedor: {selectedVendedor}</div> */}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>

                    <Box sx={{ marginBottom: 3 }}>
                        <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3, }}>
                            <Suspense fallback={<CircularProgress />}>
                                <SeguimientoAse selectedVendedor={selectedVendedor} />
                            </Suspense>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default SeguimientoAsesor