import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Paper, LinearProgress } from '@mui/material';


import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';

import newRequest from '../../../../utils/newRequest';
import ModalObjetivo from './ModalObjetivo';
import TablaObjetivo from './TablaObjetivo';

const ObjetivoEmpresa = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [openDrawer, setOpenDrawer] = useState(false); // Estado para abrir y cerrar el modal
    const [selectedID, setSelectedID] = useState(""); // Estado para almacenar el ID seleccionado
    const [objetivosData, setobjetivos] = useState([])
    const [selectPer, setSelectPer] = useState([])

    // Función para manejar el ID seleccionado
    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };

    const ObtenerObjetivo = async () => {
        try {
            const res = await newRequest.get('api/Calidad/Objetivos/ObtenerObjetivo');
            if (res.status !== 200) {
                throw new Error('Error en la respuesta del servidor');
            } else {
                setobjetivos(res.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Establece isLoading en false una vez que la carga está completa
        }
    }

    const SelectPerspectiva = async () => {
        try {
            const res = await newRequest.get('api/Calidad/Objetivos/SelectPerspectiva');
            setSelectPer(res.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        SelectPerspectiva();
        ObtenerObjetivo();
    }, [])

    const list = (
        <Box sx={{ width: 550 }} >
            <List>
                <ModalObjetivo selectPer={selectPer}
                    objetivosData={objetivosData}
                    ObtenerObjetivo={ObtenerObjetivo}
                    setOpenDrawer={setOpenDrawer}
                    openDrawer={openDrawer}
                    selectedID={selectedID} />
            </List>
        </Box>
    );

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    {isLoading ? ( // Muestra el loader si isLoading es true
                        <LinearProgress />
                    ) : (
                        <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px" }}>
                            <Box sx={{ marginTop: 2, flexDirection: 'column', alignItems: 'center' }} >
                                <TablaObjetivo objetivosData={objetivosData} ObtenerObjetivo={ObtenerObjetivo}
                                    setOpenDrawer={setOpenDrawer} onIDSelected={handleIDSelected} />
                            </Box>
                        </Paper>
                    )}
                </Grid>
                <div>
                    <SwipeableDrawer
                        anchor="right"
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}
                        onOpen={() => setOpenDrawer(true)}
                    >
                        {list}
                    </SwipeableDrawer>
                </div>
            </Grid>
        </div>
    )
}

export default ObjetivoEmpresa