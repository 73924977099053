import React, { useEffect, useMemo } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, TableContainer, Paper, Table, TableHead,
    TableRow, TableCell, TableBody, Box, Tooltip, Typography,
} from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
    MaterialReactTable,
} from 'material-react-table';



const ModalRecaudos = ({ openRecaudos, cerrarModal, datos }) => {


    const totalColumnAñoInicial = datos?.reduce((total, row) => total + row['pagos'], 0);

    useEffect(() => {

    }, [openRecaudos, datos])

    console.log("datos", datos)

    const columns = [
        {
            accessorKey: 'NitCom', //access nested data with dot notation
            header: 'Cliente',
            enableSorting: false, // disable sorting for this column
            enableColumnActions: false,
            size: 20,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    width: "8rem",
                    maxWidth: "8rem",
                }
            },
            muiTableBodyCellProps: {
                sx: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    width: "8rem",
                    maxWidth: "8rem",
                    fontWeight: 'normal',
                },
            },
        },
        {
            accessorKey: 'comnum',
            header: 'Factura',
            size: 20,
            enableSorting: false, // disable sorting for this column
            enableColumnActions: false,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#E74C3C',
                    color: "white",
                    width: "8rem",
                    maxWidth: "8rem",
                }
            },

        },
        {
            accessorKey: 'ano', //normal accessorKey
            header: 'Año',
            size: 20,
            enableSorting: false, // disable sorting for this column
            enableColumnActions: false,
            muiTableHeadCellProps: {

                sx: {
                    backgroundColor: '#FF5722',
                    color: "white",
                    width: "8rem",
                    maxWidth: "8rem",
                }
            },
        },
        {
            accessorKey: 'm',
            header: 'Mes',
            size: 20,
            enableSorting: false, // disable sorting for this column
            // enableColumnActions: false,
            muiTableHeadCellProps: {
                // align:"center",
                sx: {
                    backgroundColor: '#FF5722',
                    color: "white",
                    // width: "8rem",
                    // maxWidth: "8rem",
                }
            },
            // muiTableBodyCellProps: {

            //     // align:"center",
            //     sx: {
            //         border: '1px solid rgba(224, 224, 224, 1)',
            //         width: "8rem",
            //         maxWidth: "8rem",
            //         fontWeight: 'normal',
            //     },
            // },
        },
        {
            accessorKey: 'pagos',
            header: 'Pago',
            size: 20,
            enableSorting: false, // disable sorting for this column
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: "center",
                sx: {
                    backgroundColor: '#27AE60',
                    color: "white",
                    width: "8rem",
                    maxWidth: "8rem",
                }
            },
            muiTableBodyCellProps: {

                align: "center",
                sx: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    width: "8rem",
                    maxWidth: "8rem",
                    fontWeight: 'normal',
                },
            },
            Cell: ({ cell, row }) => (
                <>
                    {cell.getValue() ? (
                        cell.getValue().toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                    ) : (
                        '$0' // Si el valor es vacío, muestra $0
                    )}
                </>
            ),
        },
    ];




    return (
        <div>
            <Dialog open={openRecaudos}
                sx={{
                    width: "100%", display: "flex",
                    height: "100%",
                    justifyContent: "center", alignItems: "center"
                }}
                PaperProps={{
                    sx: { borderRadius: "13px", }
                }}
            // onClose={cerrarModal}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
                    Recaudo
                </DialogTitle>

                <DialogContent>

                    <MaterialReactTable
                        columns={columns}
                        data={datos}
                        enableStickyHeader
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        initialState={{
                            density: "compact"
                        }}
                        muiTablePaperProps={{
                            elevation: 0,
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                // fontSize: '0.9rem !important',
                                whiteSpace: "nowrap",
                                height: "45px !important",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                border: '1px solid rgba(224, 224, 224, 1)',
                            },
                        }}
                        localization={MRT_Localization_ES}
                    />

                    {/* <TableContainer component={Paper} sx={{ height: 508, }}>
                        <Table stickyHeader size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"
                                        sx={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            height: "40px",  // Ajusta este valor según tus necesidades
                                            maxHeight: "40px", width: "150px", maxWidth: "150px",
                                            background: "#1C64F2", color: "white"
                                        }}>Cliente</TableCell>
                                    <TableCell align="center"
                                        sx={{
                                            whiteSpace: "nowrap",
                                            maxHeight: "40px",
                                            width: "150px",
                                            maxWidth: "150px",
                                            background: "#E74C3C", color: "white"
                                        }}
                                    >Factura</TableCell>
                                    <TableCell align="center" sx={{
                                        whiteSpace: "nowrap",
                                        maxHeight: "40px",
                                        width: "150px",
                                        maxWidth: "150px",
                                        background: "#FF5722", color: "white"
                                    }}>Año</TableCell>
                                    <TableCell align="center" sx={{
                                        whiteSpace: "nowrap",
                                        maxHeight: "40px",
                                        width: "150px",
                                        maxWidth: "150px",
                                        background: "#FF5722", color: "white"

                                    }} >Mes</TableCell>
                                    <TableCell align="center" sx={{
                                        background: "#27AE60", color: "white"
                                    }} > Pago</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {datos.map((row) => (
                                    <TableRow key={row.FechaVencimiento}>
                                        <TableCell align="left"
                                            sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                height: "40px",  // Ajusta este valor según tus necesidades
                                                maxHeight: "40px", width: "150px", maxWidth: "150px"
                                            }}>
                                            <Tooltip title={row.NitCom}>
                                                {row.NitCom}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center"
                                            sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>
                                            <Tooltip title={row.comnum}>
                                                {row.comnum}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center" sx={{
                                            width: "500px",
                                            border: '1px solid rgba(224, 224, 224, 1)',
                                        }}>
                                            {row.ano}
                                        </TableCell>

                                        <TableCell align="center" sx={{
                                            width: "500px",
                                            border: '1px solid rgba(224, 224, 224, 1)',
                                        }}>
                                            {row.m}
                                        </TableCell>

                                        <TableCell align="center" sx={{
                                            border: '1px solid rgba(224, 224, 224, 1)',
                                        }}>${row.pagos.toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}

                    <Typography variant='subtitle1' sx={{ marginTop: "1rem", marginBottom: "-1rem" }}>
                        Total del pago: ${totalColumnAñoInicial.toLocaleString()}
                    </Typography>

                </DialogContent>

                <DialogActions>
                    <Button onClick={cerrarModal} sx={{ color: "#E74C3C" }}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalRecaudos