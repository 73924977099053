import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Button, Typography, TextField, Divider } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DrawIcon from '@mui/icons-material/Draw';
import { AuthContext } from '../../../loginContext';
import newRequest from '../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const ModalAnalisis = ({ indicadores, selectedIndicadoresId, obtenerIndicador, NuevoIndicador, setOpenDrawerAnalisis,
     NuevoAnalisis, DatoIndicador, selectedProceso }) => {

    const fecha = new Date();
    const { currentUser } = useContext(AuthContext);
    const [analisisObs, setAnalisisObs] = useState("");

    const CrearAnalisis = async () => {
        try {
            const procesoData = {
                DatIndSec: NuevoIndicador?.DatIndSec, // id del campo  para actualizar
                DatIndAnaObs: analisisObs,
                DatIndAnaFec: `${fecha}`, // Fecha y hora actual
                DatIndAnaUsu: currentUser.userData?.UsuCod, // Usuario
                DatIndAnaNom: indicadores[selectedIndicadoresId]?.IndProRel
            }

            const response = NuevoAnalisis.length > 0
                ? await newRequest.put(`/api/Calidad/InsertarIndicadores/ActualizarAnalisis/${NuevoAnalisis[0].DatIndSec[0]}/${NuevoAnalisis[0].DatIndMes}`, procesoData, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                : await newRequest.post(`/api/Calidad/InsertarIndicadores/InsertarAnalisisInd`, procesoData, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

            if (response.status === 200) {
                toast.success(
                    // `Actualizada correctamente`,
                    `Analisis ${NuevoAnalisis.length > 0 ? "actualizado" : "creado"} correctamente`,

                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                DatoIndicador()
                setOpenDrawerAnalisis(false)
            } else {
                toast.warning("Error al interactuar con el servidor", {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error(
                `Error al insertar los datos`,
                {
                    position: "bottom-center",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    }
    useEffect(() => {
        setAnalisisObs(NuevoAnalisis[0]?.DatIndAnaObs || "");
    }, [NuevoAnalisis])

    return (
        <Box>
            <Box sx={{
                marginBottom: "5px", display: "flex", alignContent: "right",
                justifyContent: "right", marginTop: "-15px"
            }}>
            </Box>

            <Box sx={{
                display: "flex", alignContent: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {indicadores[selectedIndicadoresId]?.IndProRel}
                </Typography>
            </Box>
            <Divider />
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component="form" noValidate sx={{ width: "100%" }} >
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth autoComplete='off' type='text'
                                label="Descripcion del analisis" multiline variant="outlined"
                                rows={7} size='small' name='DatIndAnaObs'
                                value={analisisObs}
                                onChange={(e) => setAnalisisObs(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button onClick={CrearAnalisis} variant="contained" sx={{ background: "#1C64F2" }} fullWidth >
                                    <Box style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <span>{NuevoAnalisis.length > 0 ? "Actualizar Analisis" : "Crear Analisis"}</span>
                                            <span> {NuevoAnalisis.length > 0 ?
                                                (
                                                    <DrawIcon sx={{ marginLeft: "4px", width: "20px", height: "20px" }} />
                                                ) : (
                                                    <AddIcon sx={{ marginLeft: "4px", width: "20px", height: "20px" }} />
                                                )}
                                            </span>
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Box>

        </Box>
    )
}

export default ModalAnalisis