import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
    Typography, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import newRequest from '../../../../utils/newRequest';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


const TablaParametrosActividad = ({ actividad, setOpenDrawerActividad,
    onIdActividad, ObtenerActividadImportacion }) => {

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);


    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const crearActividad = () => {
        setId("")
        setIdDos("")
        setOpenDrawerActividad(true)
        onIdActividad("")
    }

    const handleButtonClick = (row) => {
        try {
            // console.log("row.ImpCanId", row.ImpCanId)
            setId(row.ImpCanId)
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.ImpCanId)
        //Abrimos el modal
        setOpenConfirmDialog(true);
        // Limpiamos el id
    };



    const EliminarActividad = async () => {
        try {
            // Aqui recibimos el estado
            await newRequest.delete(`/api/Importaciones/parametros/EliminarActividad/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setId("")
            setIdDos("")
            ObtenerActividadImportacion();
        } catch (error) {
            console.error("error", error);
        }
    }

    useEffect(() => {
        if (id) {

            const ObtenerUnaActividad = async () => {
                const res = await newRequest.post(`/api/Importaciones/parametros/ObtenerUnaActividad`, {
                    id: id
                })
                if (res.status === 200) {
                    onIdActividad(res.data)
                    setOpenDrawerActividad(true)
                }
            }

            ObtenerUnaActividad();
        }
    }, [id, idDos, setOpenDrawerActividad, onIdActividad])

    const columns = [
        {
            accessorKey: 'ImpCanNom', //access nested data with dot notation
            header: 'Nombre de la actividad',
            size: 200,
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: '16px !important',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorKey: 'ImpCanDia',
            header: 'dias(Sugeridos)',
            size: 70,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: '16px !important',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            enableColumnActions: false,
        },
        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            size: 70,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            enableColumnActions: false,
            Cell: ({ cell }) => {
                // console.log("Cell, aqui cell", cell.row.original)
                return (
                    < div >
                        {/* <Tooltip title="Editar" >
                            <IconButton sx={{
                                width: "30px", height: "30px",
                            }}
                            >
                                <i className="fa-solid fa-pencil" style={{
                                    fontSize: "18px", color: "#118DF0"
                                }} ></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar" >
                            <IconButton sx={{
                                width: "30px", height: "30px",
                            }}
                            //  onClick={() => EliminarTipoProceso(cell.row.original)} 
                            >
                                <i className="fa-solid fa-trash" style={{
                                    fontSize: "18px",
                                    color: "#FF2442"
                                    // color: "#FF6969"
                                }} ></i>
                            </IconButton>
                        </Tooltip> */}
                        <ButtonGroup variant="text" aria-label="outlined button group">
                            <Button onClick={() => handleButtonClick(cell.row.original)} startIcon={<i className="fa-solid fa-pencil" style={{
                                fontSize: "16px", color: "#118DF0", marginLeft: 5
                            }} ></i>}>
                            </Button>

                            <Button onClick={() => handleOpenConfirmDialog(cell.row.original)} startIcon={<i className="fa-solid fa-trash" style={{
                                fontSize: "16px", color: "#FF2442", marginLeft: 10
                            }} ></i>}>
                            </Button>
                        </ButtonGroup>

                    </div >
                )
            },
        },

    ];

    return (
        <div>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este dato?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarActividad();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>


            <MaterialReactTable columns={columns} data={actividad}
                muiTablePaperProps={{
                    elevation: 0,
                }}
                enableStickyHeader
                localization={MRT_Localization_ES}
                muiTableHeadCellProps={{
                    sx: {
                        // borderRight: '2px solid #e0e0e0', //add a border between columns
                        fontWeight: 'normal',
                        fontSize: '16px !important',
                    },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                    // <Box sx={{ display: 'flex', gap: '1rem', p: '4px', justifyContent: "space-between" }}>
                    <Button variant="outlined" size="large"
                        onClick={crearActividad}>
                        <i className="fa-solid fa-plus" style={{ marginRight: "5px" }}></i>
                        <Typography sx={{ fontSize: "13px" }}>
                            Crear Actividad
                        </Typography>
                    </Button>
                    // </Box>
                )}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFilters={false}
                muiTableBodyCellProps={{
                    sx: {
                        fontWeight: 'normal',
                        // fontSize: '12px !important', // Reducir el tamaño de fuente para las celdas de cuerpo
                        // border: '1px solid rgba(224, 224, 224, 1)',
                        // whiteSpace: "normal", // Agregar esta línea
                    },
                }}
                muiTableContainerProps={{ sx: { maxHeight: '450px', height: "420px", width: "100%" } }}
            />
        </div>
    );
}

export default TablaParametrosActividad