import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography } from '@mui/material';

const GraficoVentasProductos = ({ dataZona }) => {

    const nombresMeses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    // Array de colores alternativos
    const colores = ['#053B50', '#0E21A0', '#FFC436', '#ff5733', '#4a90e2',
        '#f56b25', '#ac9c8a', '#BB2525', '#00ADB5', '#0079FF', '#0c7474'];

    let medicamentosData = dataZona.filter((producto) => producto.linea === "MEDICAMENTOS");
    let dispositivosData = dataZona.filter((producto) => producto.linea === "DISPOSITIVOS MEDICOS");

    const transformDataForChartMedicamentos = (dataZona) => {
        const meses = Array.from({ length: 12 }, (_, index) => index + 1);

        const data = meses.map((mes) => {
            const monthData = {
                meses: nombresMeses[mes - 1], // Mes en el eje X
            };
            medicamentosData.forEach((producto) => {
                const valorMes = producto[mes];
                monthData[producto.Codigo] = valorMes !== null ? valorMes : 0;
            });
            return monthData;
        });
        return data;
    };

    const transformDataForChartDispositivos = (dataZona) => {
        const meses = Array.from({ length: 12 }, (_, index) => index + 1);

        const data = meses.map((mes) => {
            const monthData = {
                meses: nombresMeses[mes - 1], // Mes en el eje X
            };
            dispositivosData.forEach((producto) => {
                const valorMes = producto[mes];
                monthData[producto.Codigo] = valorMes !== null ? valorMes : 0;
            });
            return monthData;
        });
        return data;
    };

    const dataForChart = transformDataForChartMedicamentos(dataZona);
    const dataForChartProductos = transformDataForChartDispositivos(dataZona);

    // Estilo personalizado para el Tooltip
    const tooltipStyle = {
        fontSize: '13px', // Ajusta el tamaño de fuente aquí
    };

    // console.log("datosZonas PRODUCTO", dataForChart)

    return (
        <div style={{ width: "100%", height: "600px", overflowY: "auto" }}>
            <Typography variant="h6" gutterBottom >
                Ventas de medicamentos
            </Typography>
            <ResponsiveContainer width="100%" height={450}>
                <LineChart
                    data={dataForChart}
                    // width={100}
                    // height={50}
                    margin={{
                        top: 30,
                        right: 30,
                        left: 10,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="meses" />
                    <Tooltip
                        formatter={(value) => new Intl.NumberFormat().format(value)}
                        contentStyle={tooltipStyle} // Aplica el estilo personalizado
                    />
                    <YAxis tickFormatter={(value) => {
                        if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                        return value; // Valor original si no supera los miles
                    }}
                        tick={{ fontSize: 14 }}
                    />
                    {/* <Legend /> */}
                    {medicamentosData.map((producto, index) => (
                        <Line
                            key={producto.Codigo}
                            type="monotone"
                            dataKey={producto.Codigo}
                            name={producto.Producto}
                            // stroke="#8884d8" // Puedes cambiar el color aquí
                            stroke={colores[index % colores.length]} // Asignar un color de la paleta
                            // activeDot={{ r: 8 }}
                            dot={{ r: 5 }} // Tamaño del punto en la línea
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
            {/* <YAxis /> */}
            {/* <Tooltip /> */}
            <Typography variant="h6" gutterBottom >
                Ventas de Dispositivos
            </Typography>
            <ResponsiveContainer width="100%" height={450}>
                <LineChart
                    // width={100}
                    // height={50}
                    data={dataForChartProductos}
                    margin={{
                        top: 30,
                        right: 30,
                        left: 10,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="meses" />
                    <Tooltip
                        formatter={(value) => new Intl.NumberFormat().format(value)}
                        contentStyle={tooltipStyle} // Aplica el estilo personalizado
                    />
                    <YAxis tickFormatter={(value) => {
                        if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                        return value; // Valor original si no supera los miles
                    }}
                        tick={{ fontSize: 14 }}
                    />
                    {dispositivosData.map((producto, index) => (
                        <Line
                            key={producto.Codigo}
                            type="monotone"
                            dataKey={producto.Codigo}
                            name={producto.Producto}
                            // stroke="#8884d8" // Puedes cambiar el color aquí
                            stroke={colores[index % colores.length]} // Asignar un color de la paleta
                            dot={{ r: 5 }} // Tamaño del punto en la línea
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>

        </div>
    )
}

export default GraficoVentasProductos