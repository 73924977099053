import { Grid, Typography, Box, Container } from '@mui/material'
import React, { useEffect } from 'react'
import Vademecum from "./Vademecum/Vademecum.jsx"
import Footer from '../../../components/Footer/Footer.jsx';

const Portafolio = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div >
            <Container >
                <Box style={{ width: "100%", }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '10em',
                    }}>
                        <Box sx={{
                            backgroundColor: "#003da5",
                            height: "45px", width: "400px",
                            borderRadius: "15px 30px 15px 30px",
                        }}
                        >
                            <Typography
                                sx={{
                                    marginLeft: "10px", display: "flex",
                                    alignItems: "center",
                                    color: "white", justifyContent: "center",
                                    fontSize: "25px", textAlign: "center"
                                }}
                            >
                                Portafolio
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container >
                        <Box style={{ width: "100%", paddingLeft: "5rem", paddingRight: "5rem", height: "100hv" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Vademecum />
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Container>

            <Box sx={{ marginTop: "8rem" }}>
                <Footer />
            </Box>

        </div>
    )
}

export default Portafolio