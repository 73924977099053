import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContextProvider } from './loginContext';

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontOpticalSizing: 'auto',
    fontStyle: 'normal',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div >
    <React.StrictMode>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </ThemeProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </div>
);
