import React, { useEffect, useState, } from 'react'
import {
    Box, Grid, Button, Typography,
    TextField, Divider, FormControl,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import newRequest from '../../../../utils/newRequest';
import DrawIcon from '@mui/icons-material/Draw';
import PercentIcon from '@mui/icons-material/Percent';

const ModalDos = ({ selectedID, setOpenDrawerDos, ObtenerIndicador,
    obtenerProceso, valueDos }) => {

    const [IndProPesoUno, SetIndProPesoUno] = useState('')

    const eventoActualizar = async () => {
        try {
            // Convierte IndProPesoUno a un número
            const pesoUno = parseFloat(IndProPesoUno);

            // Verifica si IndProPesoUno es un número válido
            if (isNaN(pesoUno)) {
                throw new Error('IndProPesoUno no es un número válido.');
            }

            const procesoData = {
                IndProPesoUno: pesoUno, // Usa el valor convertido
                proceso: selectedID?.ProEmpSec,
                tipo: valueDos
            }

            let response;
            response = await newRequest.put(`/api/Calidad/Indicadores/ActualizarpesoUno/${selectedID?.IndProEmpSec} `,
                procesoData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.status === 200) {
                toast.success(
                    `Error al actualizada`,
                    {
                        position: "bottom-left",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } else {
                toast.success(
                    `Actualizada correctamente`,
                    {
                        position: "bottom-left",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                ObtenerIndicador();
                setOpenDrawerDos(false)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (selectedID) {
            SetIndProPesoUno(selectedID.IndProPesoUno || "");
        } else {
            SetIndProPesoUno("")
        }
    }, [selectedID])


    return (
        <div style={{ width: "100%" }}>


            <Box sx={{
                display: "flex", alignContent: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    Indicador: <span>{selectedID.IndProEmpNom}</span>
                </Typography>
            </Box>

            <Divider />

            <Box
                sx={{
                    marginTop: 2,
                    width: "100%",
                    alignItems: 'center',
                }}
            >

                <Box component="form" noValidate sx={{ width: "700px" }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth sx={{ width: "100%" }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Valor del peso del indicador
                                </Typography>
                                <TextField type="number"
                                    autoComplete={false} required value={IndProPesoUno}
                                    onChange={(e) => SetIndProPesoUno(e.target.value)}
                                    size='small' placeholder="Ingrese el valor del peso"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <PercentIcon sx={{ width: '20px', height: '20px', marginRight: "10px" }} />
                                        ),
                                    }} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained" onClick={eventoActualizar} fullWidth>
                                    <Typography variant='h6' sx={{ fontSize: "13px" }}>
                                        Actualizar Peso
                                    </Typography>
                                    <DrawIcon sx={{ marginLeft: "5px" }} />
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </div>
    )
}

export default ModalDos