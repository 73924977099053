import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';


const GraficoLinea = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {
    const chartRef = useRef(null);

    // Función para formatear números
    const formatNumber = (value) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);


    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const nombreLinea = `linea`;
        const ventasKey = `Ventas${selectAñoIncial}`;
        const costoKey = `Costo${selectAñoIncial}`;
        const presupuestoKey = `Presupuesto${selectAñoIncial}`;

        const data = rows.map((row, index) => {
            const linea = row[nombreLinea];
            const ventas = row[ventasKey];
            const costo = row[costoKey];
            const presupuesto = row[presupuestoKey];
            let tendencia = 0;

            if (index >= 1) {
                const ventasAnterior = +rows[index - 1][ventasKey];
                const presupuestoAnterior = +rows[index - 1][presupuestoKey];
                const diferencia = (presupuestoAnterior - ventasAnterior);
                tendencia = diferencia + presupuesto;
            } else {
                tendencia = presupuesto;
            }
            return {
                name: linea,
                value: ventas,
                Costo: costo,
                Presupuesto: presupuesto,
                Tendencia: tendencia
            };
        });

        const option = {
            tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: ${c} ({d}%)',
                formatter: (params) => {
                    return `${params.seriesName} <br/>${params.name}: $${formatNumber(params.value)} (${params.percent}%)`;
                },
                confine: true, // Mantener el tooltip dentro del contenedor
            },
            legend: {
                top: 'top',
                left: 'center',
                data: data.map(item => item.name)
            },
            series: [
                {
                    name: 'Ventas',
                    type: 'pie',
                    // radius: '50%',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: true,
                    padAngle: 5,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff', // Color blanco para el borde
                        borderWidth: 5, // Ancho del borde para crear el efecto de separación
                    },
                    data: data,
                    emphasis: {
                        itemStyle: {
                            show: true
                        }
                    },
                    // label: {
                    //     formatter: '{b}: ${c}'
                    // }
                    label: {
                        formatter: (params) => `${params.name}: $${formatNumber(params.value)}`
                    }
                }
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [rows, selectAñoIncial]);

    return (
        <div >
            <Box sx={{ width: "100%", height: "400px", marginTop: "2rem" }} ref={chartRef}></Box>
        </div>
    );
};

export default GraficoLinea;
