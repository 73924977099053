import React, { useEffect, useState } from 'react'
import { MaterialReactTable } from 'material-react-table';
import { Box, Typography, Tooltip, Button } from '@mui/material';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ButtonGroup } from '@mui/material';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TablaObjetivo = ({ objetivosData, ObtenerObjetivo,
    setOpenDrawer, onIDSelected }) => {

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleButtonClick = (row) => {
        try {
            setId(row.ObjSec)
        } catch (error) {
            console.error(error);
        }
    };

    const crearObjeto = () => {
        setId("") //Devolvemos el id vacio para que no abra el modal solo
        onIDSelected("")
        setOpenDrawer(true)
    }

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.ObjSec)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const EliminarunObjeto = async () => {
        try {
            // Aqui recibimos el estado
            await newRequest.delete(`api/Calidad/Objetivos/EliminarObjeto/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            ObtenerObjetivo()
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            setOpenDrawer(true) // Abre el modal
            const ObtenerUnDatoProceso = async () => {
                try {
                    const res = await newRequest.post(`api/Calidad/Objetivos/ObtenerUnaPerspectiva`, {
                        id: id
                    });
                    onIDSelected(res.data); // Llama a la función con el ID como argumento
                    setId("")
                } catch (error) {
                    console.error(error);
                }
            }
            ObtenerUnDatoProceso()
        } else {
            // console.log("creando un ")
        }
    }, [id, idDos, onIDSelected, setId])

    const columns = [
        {
            accessorKey: 'ObjNom',
            header: 'Nombre',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",
                    fontWeight: 'normal',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.ObjNom}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.ObjNom}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'ObjDesc', //normal accessorKey
            header: 'Descripción',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",
                    fontWeight: 'normal',
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.ObjDesc}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.ObjDesc}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'ObjObs', //normal accessorKey
            header: 'Observación',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.ObjObs}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.ObjObs}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'ObjUsu', //normal accessorKey
            header: 'Usuario',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white",
                    width: "60px", maxWidth: "60px"
                }
            },
            size: 90,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    width: "60px", maxWidth: "60px"
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.ObjUsu}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.ObjUsu}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'ObjFec', //normal accessorKey
            header: 'Fecha',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem", maxWidth: "15rem"
                }
            },
        },
        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                height: "20px !important",
            },
            Cell: ({ cell }) => {
                return (
                    < div >
                        <ButtonGroup variant="text" >
                            <Tooltip title="Editar">
                                <Button onClick={() => handleButtonClick(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EditIcon sx={{ width: '25px', height: '25px', color: '#118DF0' }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                                <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <DeleteIcon sx={{ width: '25px', height: '25px', color: '#FF2442' }} />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div >
                )
            },

        },
    ]

    return (
        <div>

            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este Objetivo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        EliminarunObjeto();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            <MaterialReactTable columns={columns} data={objetivosData}
                muiTablePaperProps={{
                    elevation: 0,
                }}
                enableStickyHeader
                renderTopToolbarCustomActions={({ table }) => (
                    <Button variant="outlined" onClick={crearObjeto}                     >
                        <Typography sx={{ fontSize: "13px" }}>
                            Crear Objetivo
                        </Typography>

                        <AddCircleIcon style={{ marginLeft: "5px", fontSize: "19px" }} />
                    </Button>
                )}

                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'normal',
                        fontSize: '16px !important',
                    },
                }}

                muiTableBodyCellProps={{
                    sx: {
                        // fontSize: '0.9rem !important',
                        whiteSpace: "nowrap",
                        // height: "45px !important",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                }}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFilters={false}
                localization={MRT_Localization_ES}
                muiTableContainerProps={{
                    sx: { height: 450, width: "100%", }
                }}
            />
        </div>
    )
}

export default TablaObjetivo 