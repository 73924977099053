import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';

const IndicadorPorcentaje = ({ chartId, porcentaje }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const porcentajeNumerico = typeof porcentaje === 'string' ? parseFloat(porcentaje.replace('%', '')) : porcentaje;
    const porcentajeValido = !isNaN(porcentajeNumerico) && porcentajeNumerico >= 0 ? Math.abs(porcentajeNumerico) : 0;

    useEffect(() => {
        if (!chartRef.current) return;

        chartInstance.current = echarts.init(chartRef.current);

        const colorConfig = [
            [0.8, '#FF6E76'],
            [0.95, '#FFB534'],
            [1, '#65B741']
        ];

        const calculateColor = () => {
            const value = porcentajeValido / 100;
            let finalColor = colorConfig[colorConfig.length - 1][1];
            for (let i = 0; i < colorConfig.length; i++) {
                if (value <= colorConfig[i][0]) {
                    finalColor = colorConfig[i][1];
                    break;
                }
            }
            return finalColor;
        };

        const option = {
            series: [
                {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    center: ['50%', '50%'],
                    radius: '100%',
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 18,
                            color: [
                                [porcentajeValido / 100, calculateColor()],
                                [1, '#F8EDED']
                            ]
                        }
                    },
                    pointer: {
                        icon: 'none',
                    },
                    axisTick: {
                        length: 0,
                        lineStyle: {
                            color: 'auto',
                        }
                    },
                    splitLine: {
                        length: 0,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        distance: -60,
                        formatter: function () {
                            return '';
                        }
                    },
                    title: {
                        offsetCenter: [0, '-10%']
                    },
                    detail: {
                        show: false
                    },
                    data: [
                        {
                            value: porcentajeValido / 100,
                        }
                    ]
                },
            ]
        };

        chartInstance.current.setOption(option);

        let resizeTimeout;
        const resizeObserver = new ResizeObserver(() => {
            if (resizeTimeout) clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                chartInstance.current && chartInstance.current.resize();
            }, 200); // Ajusta el retraso según sea necesario
        });

        resizeObserver.observe(chartRef.current);

        return () => {
            if (resizeTimeout) clearTimeout(resizeTimeout);
            // Verificar si chartRef.current es un elemento antes de llamar a unobserve
            if (chartRef.current instanceof Element) {
                resizeObserver.unobserve(chartRef.current);
            }
            chartInstance.current && chartInstance.current.dispose();
        };
    }, [chartId, porcentajeValido]);

    return (
        <Box ref={chartRef} sx={{
            width: '100%',
            height: '150px',
            // marginTop: 2
        }} />
    );
};

export default IndicadorPorcentaje;
