import React, { useMemo } from 'react'
import { MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import { Typography } from '@mui/material';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP', // Código de moneda para el peso colombiano
    }).format(value);
};

const PorRecaudoTabla = ({ recaudo }) => {

    const calcularTotal = (data, key) => {
        const valor = data.reduce((sum, row) => sum + (Number(row[key]) || 0), 0);
        return valor;
    }
    // Reusar el total de las ventas
    const totalVentas = useMemo(() => calcularTotal(recaudo, 'ventas'), [recaudo]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'vendedor', //access nested data with dot notation
                header: 'Vendedor',
                muiTableHeadCellProps: {
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#2666CF',
                        color: "white"
                    }
                }, size: 30,
                Cell: ({ cell }) => (
                    <Typography variant="body2" noWrap width={200} title={cell.getValue()}>
                        {cell.getValue()}
                    </Typography>
                ),
                Footer: "Total"
            },
            {
                accessorKey: 'ventas',
                header: 'Ventas',
                muiTableHeadCellProps: {
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#2666CF',
                        color: "white"
                    }
                }, size: 30,
                Cell: ({ cell }) => formatCurrency(cell.getValue()), // Usa la función para formatear las ventas
                Footer: () => (
                    <Typography variant="body2">
                        {formatCurrency(calcularTotal(recaudo, 'ventas'))}
                    </Typography>
                ),
            },
            {
                accessorKey: '%',
                header: '%',
                muiTableHeadCellProps: {
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#2666CF',
                        color: "white"
                    }
                },
                size: 30,
                Cell: ({ row }) => {
                    const ventas = Number(row.original.ventas);
                    const porcentaje = (ventas / totalVentas) * 100;
                    return <Typography>{porcentaje.toFixed(2)}%</Typography>;
                },
            },
            {
                accessorKey: 'Recaudo', //normal accessorKey
                header: 'Recaudo',
                muiTableHeadCellProps: {
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#2666CF',
                        color: "white"
                    }
                }, size: 30,
                Cell: ({ cell }) => formatCurrency(cell.getValue()), // Usa la función para formatear las ventas
                Footer: () => (
                    <Typography variant="body2">
                        {formatCurrency(calcularTotal(recaudo, 'Recaudo'))}
                    </Typography>
                ),
            },
            {
                accessorKey: '%Recaudo',
                header: '% Recaudo',
                muiTableHeadCellProps: {
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#2666CF',
                        color: "white"
                    }
                },
                size: 30,
                Cell: ({ row }) => {
                    const ventas = Number(row.original.Recaudo);  // Accede a las ventas de cada fila
                    const porcentaje = (ventas / calcularTotal(recaudo, 'Recaudo')) * 100;
                    return <Typography>{porcentaje.toFixed(2)}%</Typography>;
                },
            },
        ],
        [recaudo, totalVentas],
    );

    const table = useMaterialReactTable({
        columns,
        data: recaudo, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        muiTableContainerProps: {
            sx: {
                height: {
                    xs: '20vh', // Altura en teléfonos móviles
                    sm: '30vh', // Altura en tablets
                    md: '36vh', // Altura en pantallas pequeñas de escritorio
                    lg: "50vh",
                    xl: "45vh"
                },
                width: " 100%"
            }
        },
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        muiTablePaperProps: {
            elevation: 0
        },
        initialState: { density: 'compact', pagination: { pageSize: 20, pageIndex: 0 } }, //customize the default page size and page index

    });

    return (
        <div>
            <MaterialReactTable table={table} />
        </div>
    )
}

export default PorRecaudoTabla