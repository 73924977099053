import React, { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Paper, Typography, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import newRequest from '../../../../utils/newRequest';
import ListaIndicadores from './ListaIndicadores';

const CustomTabPanel = React.memo(function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
});

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const IndicadoresEmpresa = () => {
    const [state, setState] = useState({ loading: false, proceso: [] });

    const obtenerProceso = useCallback(async () => {
        try {
            setState(prev => ({ ...prev, loading: true }));
            const res = await newRequest.get(`/api/Calidad/Indicadores/ProcesosIndicador`);
            setState({ loading: false, proceso: res.data });
        } catch (error) {
            console.error("Error fetching data", error);
            setState(prev => ({ ...prev, loading: false }));
        }
    }, []);

    useEffect(() => {
        obtenerProceso();
    }, [obtenerProceso]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {state.loading ? (
                    <LinearProgress />
                ) : (
                    <Paper
                        sx={{
                            borderRadius: "13px",
                            height: { sm: "70vh", md: "70vh", lg: "70vh" },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <ListaIndicadores
                            proceso={state.proceso}
                            obtenerProceso={obtenerProceso}
                        />
                    </Paper>
                )}
            </Grid>
        </Grid>
    );
}

export default IndicadoresEmpresa;
