import { useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Typography, Button } from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', {     // Formatea el valor como moneda colombiana
        style: 'currency',
        currency: 'COP', // Código de moneda para el peso colombiano
    }).format(value);
};

const SeguimientoAseTabla = ({ seguimiento, onMostrarGrafico, mostrarGrafico }) => {

    const calcularTotal = (data, key) => {
        const valor = data.reduce((sum, row) => sum + (Number(row[key]) || 0), 0);
        return valor;
    }

    const calcularTotalRangosPorFila = (row) => {
        const keys = ["0-30", "30-60", "60-90", "90-180", "180-360", "MAYOR DE 360"];
        return keys.reduce((total, key) => total + (Number(row[key]) || 0), 0);
    }

    const calcularPorcentajeSinVencer = (row) => {
        const saldo = Number(row["Saldo"]);
        const sinVencer = Number(row["sinVencer"]);
        return saldo !== 0 ? (sinVencer / saldo) * 100 : 0; // Evita la división por cero
    }

    const columnasFijas = useMemo(() => [
        {
            accessorKey: 'Vendedor',
            header: 'Vendedor',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white",
                    fontWeight: "normal"
                }
            }, size: 60,
            Cell: ({ cell }) => (
                <Box>
                    <Typography maxWidth={200} variant='subtitle2' noWrap title={cell.row.original.Vendedor}>
                        {cell.row.original.Vendedor}
                    </Typography>
                </Box>
            ),
            Footer: "Total"
        },
        {
            accessorKey: 'Saldo',
            header: 'Saldo',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white",
                    fontWeight: "normal"
                }
            },
            Cell: ({ cell, row }) => {
                return (
                    formatCurrency(cell.getValue())
                );
            },
            Footer: () => (
                <Typography variant="body2">
                    {formatCurrency(calcularTotal(seguimiento, 'Saldo'))}
                </Typography>
            ),
        },
        {
            accessorKey: 'sinVencer',
            header: 'Sin vencer',
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white",
                    fontWeight: "normal"
                }
            },
            enableColumnActions: false,
            Cell: ({ cell, row }) => {
                return (
                    formatCurrency(cell.getValue())
                );
            },
            Footer: () => (
                <Typography variant="body2">
                    {formatCurrency(calcularTotal(seguimiento, 'sinVencer'))}
                </Typography>
            ),
        },

        {
            accessorKey: '%SIN VENCER',
            header: '%SIN VENCER',
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white",
                    fontWeight: "normal"
                }
            },
            enableColumnActions: false,
            size: 110,
            Cell: ({ row }) => {
                const porcentaje = calcularPorcentajeSinVencer(row.original);
                return (
                    <Typography variant="body2">
                        {porcentaje.toFixed(2)}%
                    </Typography>
                );
            },
        },

        {
            accessorKey: '%VENCIDO',
            header: '%VENCIDO',
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white",
                    fontWeight: "normal"
                }
            },
            enableColumnActions: false,
            size: 110,
            Cell: ({ row }) => {
                const calcularPorcentajeEdades = (row) => {
                    const saldo = Number(row["Saldo"]);
                    const sumaEdades = calcularTotalRangosPorFila(row);
                    return saldo !== 0 ? (sumaEdades / saldo) * 100 : 0; // Evita la división por cero
                }

                const porcentaje = calcularPorcentajeEdades(row.original);
                return (
                    <Typography variant="body2">
                        {porcentaje.toFixed(2)}%
                    </Typography>
                );
            },
        }
    ], [seguimiento]);

    const columnasDinamicas = useMemo(() => {
        if (seguimiento.length === 0) return [];
        return Object.keys(seguimiento[0] || {})
            .filter(key => !columnasFijas.some(col => col.accessorKey === key))
            .map((key, index) => ({
                accessorKey: key,
                header: <Typography variant='body2' noWrap title={key} width={120}>{key}</Typography>,
                size: 30,
                enableColumnActions: false,
                muiTableHeadCellProps: {
                    align: 'left',
                    sx: {
                        color: "white",
                        backgroundColor: index < 0 ? '#2666CF' : (index % 2 === 0) ? '#27AE60' : '#FF5722',
                    }
                },
                Cell: ({ cell, row }) => {
                    return (
                        formatCurrency(cell.getValue())
                    );
                },
                Footer: () => (
                    <Typography variant="body2">
                        {formatCurrency(calcularTotal(seguimiento, key))}
                    </Typography>
                ),
            }));
    }, [seguimiento, columnasFijas]);

    const columns = useMemo(() => [...columnasFijas, ...columnasDinamicas], [columnasFijas, columnasDinamicas]);
    const table = useMaterialReactTable({
        columns,
        data: seguimiento,
        muiTablePaperProps: {
            elevation: 0
        },
        localization: MRT_Localization_ES,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        muiTableContainerProps: {
            sx: {
                height: {
                    xs: '20vh', // Altura en teléfonos móviles
                    sm: '30vh', // Altura en tablets
                    md: '36vh', // Altura en pantallas pequeñas de escritorio
                    lg: "45vh",
                    xl: '50vh'
                },
                width: " 100%"
            }
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Button color={mostrarGrafico ? "error" : "primary"} variant="contained" endIcon={<PieChartIcon />} onClick={onMostrarGrafico}>
                    {mostrarGrafico ? "Cerrar estadística" : "Ver estadística"}
                </Button>
            </Box>
        ),
        initialState: {
            density: 'compact', pagination: { pageSize: 20, pageIndex: 0 },
            columnPinning: { left: ['Vendedor'], right: ['%SIN VENCER', '%VENCIDO'] }
        },
    });

    return (
        <div>
            <MaterialReactTable table={table} />
        </div>
    );
}

export default SeguimientoAseTabla;
