/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
    MaterialReactTable,
    MRT_ShowHideColumnsButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import OpcionesTabla from './OpcionesTabla';
import * as XLSX from 'xlsx'; // Importar XLSX
import { saveAs } from 'file-saver'; // Importar file-saver para guardar el archivo
import DownloadIcon from '@mui/icons-material/Download';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

const formatNumber = (value) => {
    return new Intl.NumberFormat('es-CO', {
        maximumFractionDigits: 0,
    }).format(value);
};

const TablaVentasProducto = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {
    const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];

    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'Codigo' && key !== 'Producto' && key !== 'linea' && key !== 'Total') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    const dataWithTotals = useMemo(() => dataZona.map(row => ({
        ...row,
        Total: calculateRowTotal(row),
    })), [dataZona]);

    const sumaTotal = useMemo(() => dataZona.reduce((total, row) => total + calculateRowTotal(row), 0), [dataZona]);

    const totalQuantities = {};
    campos.forEach(campo => {
        if (campo !== 'vendedor' && !campo.endsWith('Total')) {
            totalQuantities[campo] = dataZona.reduce((total, row) => total + (parseFloat(row[campo]) || 0), 0);
        }
    });

    const formatCellValue = (value) => {
        if (selectedOption === "c") {
            return formatNumber(value); // Sin símbolo $
        }
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            maximumFractionDigits: 0,
        }).format(value); // Con símbolo $
    };

    const columns = [
        {
            id: 'vendedor',
            accessorKey: 'vendedor',
            header: 'vendedor',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 10,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <Tooltip title={`${cell.row.original.vendedor}`} arrow>
                    <Box>
                        <div>{cell.row.original.vendedor}</div>
                    </Box>
                </Tooltip>
            ),
            footer: 'Total',
        },
        ...campos
            .filter(campo => campo !== 'vendedor')
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                header: meses[index % meses.length], // Asegurarse de no sobrepasar el array
                enableColumnActions: false,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: index < 5 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                Cell: ({ cell }) => (
                    <div>{formatCellValue(cell.getValue())}</div>
                ),
                Footer: ({ column }) => (
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "left" }}>
                        {formatCellValue(totalQuantities[column.id])}
                    </div>
                ),
            })),
        {
            id: 'Total',
            accessorKey: 'Total',
            header: 'Total',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            size: 10,
            Cell: ({ cell }) => (
                <div>{formatCellValue(cell.getValue())}</div>
            ),
        },
    ];

    // Función para exportar a Excel
    const exportToExcel = () => {
        const orderedData = dataWithTotals.map((row) => {
            const orderedRow = {
                vendedor: row.vendedor,
            };

            meses.forEach((mes, index) => {
                const campo = campos[index];
                orderedRow[mes] = row[campo] != null && row[campo] !== '' ? row[campo] : 0;
            });

            orderedRow.Total = row.Total != null && row.Total !== '' ? row.Total : 0;
            return orderedRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(orderedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ventas por Vendedor');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Ventas_por_Vendedor.xlsx');
    };

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            columnPinning: { left: ['vendedor'], right: ['Total'] },
            density: 'compact'
        },
        localization: MRT_Localization_ES,
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold">  Ventas por vendedor</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total de los meses:
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {formatCellValue(sumaTotal)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ToggleGlobalFilterButton table={table} />
                <Tooltip title="Grafica">
                    <IconButton onClick={handleOpen}>
                        <BarChartIcon />
                    </IconButton>
                </Tooltip>
                <MRT_ShowHideColumnsButton table={table} />
                <IconButton onClick={exportToExcel}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        ),
        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });

    return (
        <MaterialReactTable table={table} />
    );
};

export default TablaVentasProducto;
