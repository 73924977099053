/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
    MaterialReactTable,
    // MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    // MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
    // MRT_ToggleDensePaddingButton,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// EXPORTACION DEL EXCEL
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const TablaPerfil = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows,
    presupuestoTotal, noDataMessage, onChangeCambioTabla, cambioTabla }) => {

    const [valorProyeccion, setvalorProyeccion] = useState(0);
    const converAñoAnt = selectAñoIncial - 1;
    const columnName = `Ventas${converAñoAnt}`;
    const ColumnañoInicial = `Ventas${selectAñoIncial}`;
    const ColumnaPresupuesto = `Presupuesto${selectAñoIncial}`;
    const ColumnaCosto = `Costo${selectAñoIncial}`;

    // 1. Calcular la suma de la columna dinámica
    const calculateTotal = (rows, column) => rows.reduce((total, row) => total + row[column], 0);

    const totalColumn = calculateTotal(rows, columnName);
    const totalColumnAñoInicial = calculateTotal(rows, ColumnañoInicial);

    const totalColumnaPresupuesto = calculateTotal(rows, ColumnaPresupuesto);
    const totalColumnaCosto = calculateTotal(rows, ColumnaCosto);

    const resultadoResven = ((totalColumnAñoInicial / totalColumn) * 100 - 100).toFixed(2);
    const formattedTotalResven = isNaN(resultadoResven) ? 0 : resultadoResven;

    // FORMULA VENTAS VS PRESUPUESTOS
    const resultadoVentvsPres = ((totalColumnAñoInicial / totalColumnaPresupuesto) * 100).toFixed(2);
    const formattedVentvsPres = isFinite(resultadoVentvsPres) ? resultadoVentvsPres : '0.00';

    const totalRentabilidad = (100 - (totalColumnaCosto / totalColumnAñoInicial * 100)).toFixed(2);
    const formatRentabilidad = isFinite(totalRentabilidad) ? totalRentabilidad : '0.00';

    const totalProyeccion = rows.reduce((total, row) => {
        if (row[ColumnañoInicial]) {
            return (totalColumnAñoInicial / valorProyeccion * 12) / presupuestoTotal["Pr2"] * 100;
        }
        return total;
    }, 0);

    const formatNumber = (value) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);

    function getEstadoUnoIcono() {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>


                {resultadoResven < 0 ?
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                        Bajó
                        <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                    </Typography>
                    :
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                        Subío
                        <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                    </Typography>
                }
            </div>
        )
    };

    function getVentasAntvdAct() {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {resultadoResven < 0 ?
                    <Typography variant="body2">
                        {resultadoResven < 0 ?
                            `${formattedTotalResven} % ` :
                            `${formattedTotalResven} % `}
                    </Typography>
                    :
                    <Typography variant="body2">
                        {resultadoResven < 0 ?
                            `${formattedTotalResven} % ` :
                            `${formattedTotalResven} % `}
                    </Typography>
                }
            </div>
        )
    };

    function getVentvsPresEstado() {
        const totalColumnAñoInicialNumber = Number(totalColumnAñoInicial);
        const totalColumnaPresupuestoNumber = Number(totalColumnaPresupuesto);
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {totalColumnAñoInicialNumber < totalColumnaPresupuestoNumber ? (
                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                        Bajó
                        <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                    </Typography>
                ) : (
                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                        Subió
                        <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                    </Typography>
                )}
            </Box>
        );
    };

    function getVentvsPres() {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {resultadoVentvsPres < 0 ?
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center", }}>
                        {resultadoResven < 0 ?
                            `${formattedVentvsPres} % ` : `${formattedVentvsPres} % `}
                    </Typography>
                    :
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center", }}>
                        {resultadoResven < 0 ?
                            `${formattedVentvsPres} % ` :
                            `${formattedVentvsPres} % `}
                    </Typography>
                }
            </div>
        )
    };

    const getCommonMuiTableHeadCellProps = (backgroundColor) => ({
        align: 'left',
        sx: {
            fontWeight: 'normal',
            // fontSize: '12px !important',
            backgroundColor,
            color: 'white',
        },
    });

    const getCommonMuiTableBodyCellProps = (align = 'center') => ({
        align,
    });

    const CustomCell = ({ cell, cambioTabla, formatNumber }) => (
        <>
            {cambioTabla
                ? cell.getValue()?.toLocaleString?.('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
                : formatNumber(cell.getValue())}
        </>
    );

    const EstadoCell = ({ total }) => (
        <div style={{ width: '100%', textAlign: "left", display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
            <Box sx={{ display: 'flex', alignItems: 'left' }}>
                {total > 0 ? (
                    <>
                        Subió
                        <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                    </>
                ) : (
                    <>
                        Bajó
                        <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                    </>
                )}
            </Box>
        </div>
    );

    const columns = [
        {
            accessorKey: "perfil",
            header: "perfil",
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                // fontSize: '5px !important',
            },
            Cell: ({ cell }) => {
                const perfil = cell.row.original.perfil;
                return (
                    <Typography variant="body2" style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", overflow: "hidden", width: "120px"
                    }}>
                        {perfil}
                    </Typography>
                );
            },
            footer: 'Total',
            size: 30
        },
        {
            accessorKey: "Periodo",
            header: "Periodo",
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableSorting: false,
            enableColumnActions: false,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
            Cell: ({ cell }) => {
                const periodo = cell.row.original.Periodo;
                return (
                    <Typography title={periodo} variant="body2" style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", overflow: "hidden", width: "70px"
                    }}>
                        {periodo}
                    </Typography>
                );
            },
            size: 10
        },
        {
            accessorKey: `Ventas${converAñoAnt}`,
            header: `Ventas ${converAñoAnt}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            Cell: ({ cell }) => <CustomCell cell={cell} cambioTabla={cambioTabla} formatNumber={formatNumber} />,
            footer: (
                <Typography variant="body2">{formatNumber(totalColumn)}</Typography>
            ),
            size: 30,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
        },
        {
            accessorKey: `Ventas${selectAñoIncial}`,
            header: `Ventas${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            Cell: ({ cell }) => <CustomCell cell={cell} cambioTabla={cambioTabla} formatNumber={formatNumber} />,
            footer:
                <Typography variant="body2" >
                    {formatNumber(totalColumnAñoInicial)}
                </Typography>,
            size: 30,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
        },
        {
            accessorKey: 'Estado',
            header: 'Estado',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#27AE60'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[`Ventas${selectAñoIncial}`];
                const prop2 = dato[`Ventas${converAñoAnt}`];
                const total = (prop1 !== 0 && prop2 !== 0) ? (prop1 / prop2 * 100) - 100 : 0;
                return <EstadoCell total={total} />;
            },
            size: 30,
            footer: getEstadoUnoIcono(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        {
            accessorKey: `ResVentas${converAñoAnt}vs${selectAñoIncial}Valor`,
            header: `Ven ${converAñoAnt}vs${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#27AE60'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[`Ventas${selectAñoIncial}`];
                const prop2 = dato[`Ventas${converAñoAnt}`];
                const total = (prop1 !== 0 && prop2 !== 0) ? (prop1 / prop2 * 100) - 100 : 0;
                const formattedTotal = isNaN(total) ? 0 : total.toFixed(2);
                return `${formattedTotal}%`;
            },
            size: 30,
            footer: getVentasAntvdAct(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        {
            accessorKey: `Presupuesto${selectAñoIncial}`,
            header: `Presupuesto ${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            Cell: ({ cell }) => <CustomCell cell={cell} cambioTabla={cambioTabla} formatNumber={formatNumber} />,
            footer: (
                <div >
                    <Typography variant="body2">{formatNumber(totalColumnaPresupuesto)}</Typography>
                </div>
            ),
            size: 30,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
        },
        {
            accessorKey: `estadoPresupuesto${selectAñoIncial}`,
            header: `Estado`,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    fontSize: '12px !important',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 30,
            enableSorting: false, // disable sorting for this column
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[`Ventas${selectAñoIncial}`];
                const prop2 = dato[`Presupuesto${selectAñoIncial}`];
                return dato[prop1] > dato[prop2] ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }} >
                            Subío
                            <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                        </Box>
                    </div>
                    :
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }} >
                            Bajó
                            <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                        </Box>
                    </div>
                    ;
            },
            footer: getVentvsPresEstado(),
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorKey: `ResPresupuesto${selectAñoIncial}vs${selectAñoIncial}`,
            header: `VentVSPres ${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#E74C3C'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[`Ventas${selectAñoIncial}`];
                const prop2 = dato[`Presupuesto${selectAñoIncial}`];
                const total = (prop1 !== 0 && prop2 !== 0) ? (prop1 / prop2 * 100) : 0;
                return `${total.toFixed(2)}%`;
            },
            size: 30,
            footer: getVentvsPres(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        ...(
            cambioTabla
                ? [
                    {
                        accessorKey: `Costo${selectAñoIncial}`,
                        header: `Costo ${selectAñoIncial}`,
                        muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
                        enableColumnActions: false,
                        Cell: ({ cell }) => (
                            <>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}
                            </>
                        ),
                        footer: (
                            <Typography variant="body2" >
                                ${formatNumber(totalColumnaCosto)}
                            </Typography>
                        ),
                        size: 30,
                        muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
                    },
                    {
                        accessorKey: 'Rentabilidad',
                        header: 'Rentabilidad',
                        muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#FF5722'),
                        enableSorting: false,
                        enableColumnActions: false,
                        Cell: ({ row }) => {
                            // FORMULA: COSTO / VENTAS DEL AÑO ACTUAL
                            const dato = row.original;
                            const prop1 = dato[`Costo${selectAñoIncial}`];
                            const prop2 = dato[`Ventas${selectAñoIncial}`];
                            const total = (prop1 !== 0 && prop2 !== 0) ? 100 - ((prop1 / prop2) * 100) : 0;
                            return (
                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'left', marginLeft: '15px' }}>
                                    {total.toFixed(2)}%
                                </Typography>
                            )
                        },
                        size: 30,
                        footer: (
                            <Typography variant="body2" style={{ display: 'flex', alignItems: 'left', marginLeft: '15px' }}>
                                {formatRentabilidad} %
                            </Typography>
                        ),
                        muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
                    },
                    {
                        accessorKey: `Proyeccion`,
                        header: `Proyeccion`,
                        muiTableHeadCellProps: {
                            align: 'center',
                            sx: {
                                fontWeight: 'normal',
                                fontSize: '14px !important',
                                backgroundColor: '#27AE60',
                                color: "white"
                            }
                        },
                        size: 30,
                        enableSorting: false, // disable sorting for this column
                        enableColumnActions: false,
                        Cell: ({ row, cell }) => {
                            const calcularRentabilidad = () => {
                                const dato = row.original;
                                const propiedades = Object.keys(dato); //Accede a las llaves del json
                                const prop2 = propiedades[3]; // Ejemplo: "Ventas2023"
                                let suma = 0;
                                for (let i = 0; i <= row.original.id - 1; i++) {
                                    suma = suma + rows[i][prop2];
                                }
                                const total = suma / row.original.id * 12;
                                setvalorProyeccion((selectedMesFinal - selectedMesInicial + 1))
                                return formatNumber(total)
                            };
                            const formattedValue = cell.getValue()?.toLocaleString?.('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            });
                            return (
                                <span style={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
                                    $
                                    {formattedValue}
                                    {calcularRentabilidad()}
                                </span>
                            );
                        },
                        footer:
                            <Typography variant='body2' style={{ display: "flex", alignItems: "left" }}>
                                {totalProyeccion.toFixed(2)}%
                            </Typography>,
                        muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
                    }
                ]
                : []
        )
    ];




    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'presupuesto_ventas_perfil';

        const formattedData = rows.map((dato) => ({
            [`Perfil`]: dato.perfil,
            [`Ventas ${converAñoAnt}`]: dato[columnName],
            [`Ventas ${selectAñoIncial}`]: dato[ColumnañoInicial],
            [`Estado de las Ventas`]: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[columnName]; // Ventas 2022
                let total;
                if (prop2 !== 0 && prop1 !== 0) {
                    total = (prop1 / prop2 * 100) - 100;
                } else {
                    total = 0;
                }
                return total < 0 ? 'Bajó' : 'Subió';
            })(),
            [`${columnName}vs${selectAñoIncial}`]: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[columnName]; // Ventas 2022
                if (prop2 !== 0 && prop1 !== 0) {
                    const total = (prop1 / prop2 * 100) - 100;
                    return `${total.toFixed(2)}%`;
                } else {
                    return '0%';
                }
            })(),
            Presupuesto: dato[ColumnaPresupuesto],
            Estado: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[ColumnaPresupuesto]; // Presupuesto 2023
                return prop1 > prop2 ? 'Subió' : 'Bajó';
            })(),
            ventVsPres: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[ColumnaPresupuesto]; // Presupuesto 2023
                if (prop2 !== 0) {
                    const total = (prop1 / prop2) * 100;
                    return `${total.toFixed(2)}%`;
                } else {
                    return '0%';
                }
            })(),
            costo: dato[ColumnaCosto],
            Rentabilidad: (() => {
                const prop1 = dato[ColumnaCosto]; //Costo 2023 - Utiliza las propiedades dinámicas
                const prop2 = dato[ColumnañoInicial]; //Ventas 2023 - Utiliza las propiedades dinámicas
                if (prop2 !== 0 && prop1 !== 0) {
                    const total = 100 - (prop1 / prop2) * 100;
                    return `${total.toFixed(2)}%`;
                } else {
                    return '0%';
                }
            })(),
            Proyeccion: (() => {
                const prop2 = dato[ColumnañoInicial]; // Ejemplo: "Ventas2023"
                const total = prop2 / (selectedMesFinal - selectedMesInicial + 1) * 12;
                // console.log("Aqui ")
                // return total; // Asegúrate de tener esta función de formato
                return total; // Asegúrate de tener esta función de formato
            })()
        }));

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
    };

    const table = useMaterialReactTable({
        columns,
        data: rows, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        positionToolbarAlertBanner: 'bottom', //show selected rows count on bottom toolbar
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['perfil'] }
        },
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
                <IconButton
                    onClick={exportToExcel}
                >
                    <DownloadIcon />
                </IconButton>
                <MRT_ShowHideColumnsButton table={table} />
            </Box>
        ),
        localization: MRT_Localization_ES,
        muiTableContainerProps: { sx: { height: "20rem", width: "100%" } }
    });

    return (
        <div >
            <Box sx={{
                height: "100%", padding: "5px"
            }}>
                <MaterialReactTable
                    table={table} //only pass in table instead of all table options
                />
            </Box>
        </div>
    )
}

export default TablaPerfil