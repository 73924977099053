import React, { useEffect, useState } from 'react'
import {
    Box, Grid, Paper, LinearProgress
    // Skeleton
} from '@mui/material';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import newRequest from '../../../../utils/newRequest';
import TablaPerspectivaEmpresa from './TablaPerspectivaEmpresa';
import ModalPerspectivaEmpresa from './ModalPerspectivaEmpresa';

const PerspectivaEmpresa = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [openDrawer, setOpenDrawer] = useState(false); // Estado para abrir y cerrar el modal
    const [datosPerspectiva, setDatosPerspectiva] = useState([])

    const [selectedID, setSelectedID] = useState(""); // Estado para almacenar el ID seleccionado

    // Función para manejar el ID seleccionado
    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };

    const ObtenerPerspectivaEmpresa = async () => {
        try {
            const res = await newRequest.get('api/Calidad/PerspectivaEmpresa/ObtenerPerspectivaEmpresa');
            if (res.status !== 200) {
                throw new Error("Error en el servidor")
            } else {
                setDatosPerspectiva(res.data)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        ObtenerPerspectivaEmpresa();
    }, [])



    const list = (
        <Box sx={{ width: 550 }} >
            <List>
                <ModalPerspectivaEmpresa
                    selectedID={selectedID} ObtenerPerspectivaEmpresa={ObtenerPerspectivaEmpresa}
                    setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
            </List>
        </Box>
    );

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    {isLoading ? ( // Muestra el loader si isLoading es true
                        <LinearProgress />
                    ) : (
                        <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px" }}>
                            <Box sx={{ marginTop: 2, flexDirection: 'column', alignItems: 'center', }}>
                                <div>
                                    <TablaPerspectivaEmpresa onIDSelected={handleIDSelected} datosPerspectiva={datosPerspectiva}
                                        setOpenDrawer={setOpenDrawer} ObtenerPerspectivaEmpresa={ObtenerPerspectivaEmpresa}
                                    />
                                </div>
                            </Box>
                        </Paper>
                    )}
                </Grid>
                <div>
                    <SwipeableDrawer
                        anchor="right"
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}
                        onOpen={() => setOpenDrawer(true)}
                    >
                        {list}
                    </SwipeableDrawer>
                </div>
            </Grid>
        </div>
    )
}

export default PerspectivaEmpresa