import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, TableContainer, Paper, Table, TableHead,
    TableRow, TableCell, TableBody, Box,
} from '@mui/material';
import newRequest from '../../../../utils/newRequest';

const ModalDocumentoPendiente = ({ open, handleClose, datos, Loading }) => {

    useEffect(() => {
    }, [open, datos, Loading]);

    return (
        <Dialog
            open={open}
            sx={{
                width: "100%", display: "flex",
                height: "100%",
                justifyContent: "center", alignItems: "center"
            }}
            PaperProps={{
                sx: { borderRadius: "13px", }
            }}
        >

            <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
                {"Documentos pendientes"}
            </DialogTitle>

            <DialogContent>
                {Loading ?
                    (<div>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>
                    </div>)
                    :
                    (<div>
                        <TableContainer component={Paper} sx={{ height: 508, }}>
                            <Table stickyHeader size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"
                                            sx={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                height: "40px",  // Ajusta este valor según tus necesidades
                                                maxHeight: "40px", width: "150px", maxWidth: "150px",
                                                background: "#1C64F2", color: "white"
                                            }}>Vendedor</TableCell>
                                        <TableCell align="center"
                                            sx={{
                                                whiteSpace: "nowrap",
                                                maxHeight: "40px",
                                                width: "150px",
                                                maxWidth: "150px",
                                                background: "#E74C3C", color: "white"
                                            }}
                                        >Nro. Documento</TableCell>
                                        <TableCell align="center" sx={{
                                            whiteSpace: "nowrap",
                                            maxHeight: "40px",
                                            width: "150px",
                                            maxWidth: "150px",
                                            background: "#FF5722", color: "white"
                                        }}>Fecha Inicial</TableCell>
                                        <TableCell align="center" sx={{
                                            whiteSpace: "nowrap",
                                            maxHeight: "40px",
                                            width: "150px",
                                            maxWidth: "150px",
                                            background: "#FF5722", color: "white"

                                        }} >Fecha Vencimiento</TableCell>
                                        <TableCell align="center" sx={{
                                            background: "#27AE60", color: "white"
                                        }} >
                                            Plazo</TableCell>
                                        <TableCell align="center"
                                            sx={{
                                                background: "#27AE60", color: "white"
                                            }}>Mora</TableCell>
                                        <TableCell align="center" sx={{
                                            whiteSpace: "nowrap",
                                            maxHeight: "40px",
                                            width: "150px",
                                            maxWidth: "150px",
                                            background: "#E74C3C", color: "white"

                                        }}>Dif. Dias</TableCell>
                                        <TableCell align="center" sx={{
                                            background: "#E74C3C", color: "white"
                                        }}
                                        >Iva</TableCell>
                                        <TableCell align="center"
                                            sx={{
                                                whiteSpace: "nowrap",
                                                maxHeight: "40px",
                                                width: "150px",
                                                maxWidth: "150px",
                                                background: "#1C64F2", color: "white"
                                            }}
                                        >SubTotal</TableCell>
                                        <TableCell align="center"
                                            sx={{
                                                whiteSpace: "nowrap",
                                                maxHeight: "40px",
                                                width: "150px",
                                                maxWidth: "150px",
                                                background: "#1C64F2", color: "white"
                                            }}>Valor Doc</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datos.map((row) => (
                                        <TableRow key={row.FechaVencimiento}>
                                            <TableCell align="left"
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    height: "40px",  // Ajusta este valor según tus necesidades
                                                    maxHeight: "40px", width: "150px", maxWidth: "150px"
                                                }}>{row.vendedores}</TableCell>
                                            <TableCell align="center"
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}>{row.CarTriMovFacSec}</TableCell>
                                            <TableCell align="center" sx={{
                                                width: "500px",
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>
                                                {new Date(row.FechaOriginal).toISOString().split('T')[0]}
                                            </TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>{new Date(row.FechaVencimiento).toISOString().split('T')[0]}</TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>{row.Plazo}</TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>{row.Mora}</TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>{row.DiasDiferencia}</TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>{row.Iva}</TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>${row.SubTotal.toLocaleString()}</TableCell>
                                            <TableCell align="center" sx={{
                                                border: '1px solid rgba(224, 224, 224, 1)',
                                            }}>${row.ValorDoc.toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>)}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} sx={{ color: "#E74C3C" }}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ModalDocumentoPendiente;
