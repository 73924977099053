import { Alert, Box, Breadcrumbs, Button, Divider, Fab, Grid, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material'
import React, { useRef } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DrawIcon from '@mui/icons-material/Draw';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import logo from "../../../public/logo.png";
import html2canvas from 'html2canvas';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';

const Firmas = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const printRef = useRef();

    const [datoUsuarios, setDatoUsuarios] = useState({
        nombreCompleto: "",
        cargo: "",
        correoElectronico: "",
        telefono: ""
    });

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleDownloadPng = async () => {
        const { nombreCompleto, cargo, correoElectronico, telefono } = datoUsuarios;
        if (!nombreCompleto || !cargo || !correoElectronico || !telefono) {
            // alert("Debes completar todos los campos");
            handleClick();
            return;
        }
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = data;
        link.download = 'firma.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [emailError, setEmailError] = useState("");

    const [phoneError, setPhoneError] = useState("");


    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, '').slice(0, 10);
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return match ? `${match[1]}-${match[2]}-${match[3]}` : cleaned;
    };
    // const handleInputChange = (event) => {
    //     setDatoUsuarios({
    //         ...datoUsuarios,
    //         [event.target.name]: event.target.value
    //     });
    // };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // Validar el correo electrónico
        if (name === 'correoElectronico') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError('Correo electrónico inválido');
            } else {
                setEmailError('');
            }
        }

        if (name === 'telefono') {
            const formattedPhone = formatPhoneNumber(value);
            setDatoUsuarios({
                ...datoUsuarios,
                [name]: formattedPhone
            });

            if (formattedPhone.replace(/\D/g, '').length < 10) {
                setPhoneError('El número de teléfono debe tener exactamente 10 dígitos');
            } else {
                setPhoneError('');
            }
        } else {
            setDatoUsuarios({
                ...datoUsuarios,
                [name]: value
            });
        }
    };

    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                {/* <CloseIcon fontSize="small" /> */}
            </IconButton>
        </React.Fragment>
    );


    return (
        <div>

            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Debe completar los campos"
                action={action}
            />

            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Fab onClick={() => navigate(-1)} style={{ background: "white", width: "45px", height: "45px" }}>
                            <ChevronLeftIcon />
                        </Fab>
                        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                            <Typography color="text.primary">Atras</Typography>
                            {/* <Typography color="text.primary">{prevPath || "Inicio"}</Typography> */}
                        </Breadcrumbs>
                    </Box>
                    <Typography variant="h6" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "10px"
                    }}>
                        <DrawIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
                        Creador de firmas
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper variant="outlined" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "12px",
                            position: "sticky",
                            top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                            zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                            boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                            // transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                        }}
                    >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                            <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>
                                <Grid item xs={12} sm={3}>
                                    <TextField autoComplete="off"
                                        id="outlined-basic"
                                        name="nombreCompleto"  // Asegúrate de que el nombre coincida con el estado
                                        onChange={handleInputChange}
                                        fullWidth
                                        label="Nombre completo"
                                        variant="outlined"
                                        margin='dense'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField id="outlined-basic"
                                        name='cargo' autoComplete="off"
                                        onChange={handleInputChange}
                                        fullWidth label="Cargo" variant="outlined"
                                        margin='dense'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        autoComplete="off"
                                        id="outlined-basic"
                                        name="correoElectronico"
                                        type="email"
                                        onChange={handleInputChange}
                                        fullWidth
                                        label="Correo electrónico"
                                        variant="outlined"
                                        error={Boolean(emailError)}
                                        helperText={emailError}
                                        margin='dense'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        autoComplete="off"
                                        id="telefono"
                                        name="telefono"
                                        type="text"
                                        onChange={handleInputChange}
                                        fullWidth
                                        label="Teléfono"
                                        variant="outlined"
                                        error={Boolean(phoneError)}
                                        helperText={phoneError}
                                        value={datoUsuarios.telefono}
                                        margin='dense'
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Alert severity='info' >
                                        Debes completar los campos, para descargar la firma
                                    </Alert>
                                </Grid>
                            </Grid>
                        </Box>

                        <Divider sx={{ marginLeft: 5, marginRight: 5 }} />

                        <div ref={printRef} style={{
                            display: "flex", justifyContent: "center", borderRadius: "12px",
                            alignItems: "center", width: "100%", marginTop: "20px"
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs zeroMinWidth>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                                            <img src={logo} width={200} alt="Logo" />
                                        </Box>

                                        <Box style={{ width: "auto", maxWidth: "110em", marginRight: "8px", }}>
                                            {/* <Box style={{ maxWidth: "50px", minWidth: "360px", width: "auto", marginRight: "8px" }}> */}
                                            <Box style={{ marginLeft: "8px" }}>
                                                <Typography variant='h4' overflow={2} sx={{ fontWeight: "bold", }} >
                                                    {datoUsuarios.nombreCompleto.length ? datoUsuarios.nombreCompleto : "Nombre completo"}
                                                </Typography>
                                                <Typography variant='h5' style={{ whiteSpace: "nowrap" }}>
                                                    {datoUsuarios.cargo.length ? datoUsuarios.cargo : "Cargo"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <div style={{ backgroundColor: "#C10E21", width: "5px", height: "320px", borderRadius: "4px", margin: "8px" }}></div>
                                </Grid>

                                <Grid item xs zeroMinWidth>
                                    <Box style={{ marginRight: "8px", marginTop: "1rem" }}>
                                        <Box style={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                                            <Typography variant='h5' style={{ marginBottom: "-10px", fontWeight: "bold" }}>LABORATORIO</Typography>
                                        </Box>
                                        <Typography style={{ fontSize: "3rem", color: "#e6b324", textAlign: "center", marginBottom: "4px" }}>
                                            <span style={{ color: "#0e0e87", marginRight: "-10px" }}>FARMA</span> <span style={{ color: "#C10E21" }}>COMERCIAL</span>
                                        </Typography>

                                        <Typography variant='h5' style={{ marginTop: "-10px", textAlign: "center", fontWeight: "bold" }}>Vivir más, Vivir mejor</Typography>
                                        <Box style={{ textAlign: "start", marginTop: "1rem" }}>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                <MailOutlineRoundedIcon style={{ fontSize: "2.5rem", color: "#0e0e87" }} />
                                                <Typography variant='h5' style={{ marginLeft: "8px", whiteSpace: "nowrap" }}>
                                                    {datoUsuarios.correoElectronico.length ? datoUsuarios.correoElectronico : "Dirreción de correo"}
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                <PhoneInTalkRoundedIcon style={{ fontSize: "2.5rem", color: "#C10E21" }} />
                                                <Typography variant='h5' style={{ marginLeft: "8px", whiteSpace: "nowrap" }}>
                                                    {datoUsuarios.telefono.length ? datoUsuarios.telefono : "Teléfono"}
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                <PublicRoundedIcon style={{ fontSize: "2.5rem", color: "#0e0e87" }} />
                                                <Typography variant='h5' style={{ marginLeft: "8px", whiteSpace: "nowrap" }}>laboratoriofarmacomercial.com</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>

                        <Divider sx={{ marginLeft: 5, marginRight: 5 }} />

                        <Grid container justifyContent="flex-end" style={{ padding: 10 }}>
                            <Grid item>
                                <Button startIcon={<DownloadIcon />} variant="contained" onClick={handleDownloadPng}>
                                    Descargar
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>

                {/* Colores
                    R193 G14 B33 - #C10E21 - ROJO
                    C0 M0 Y0 K100 - #000000 NEGRO
                    R48 G24 B114- #301872 AZUL
                    C90 M91 Y10 K41 - #0e0e87 AZUL
                */}
                {/* para hacer juego
                    #1515cc
                    #1313b5
                    #10109e
                    #0e0e87
                    #10109e
                    #090959
                    #090959
                    */}
            </Grid>
        </div>
    )
}

export default Firmas