import React, { useEffect, useState } from 'react'
import {
    Box, Grid, Paper, Stack, Typography, TextField, Autocomplete,
    Alert, Checkbox, FormControlLabel, InputLabel
    // Skeleton
} from '@mui/material';

import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';

import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const defaultTheme = createTheme();


const ModalProcesoEmpresa = ({ selectedID, getProcesos, setOpenDrawer,
    jefeProceso, tipoProcesos, SumaPeso, openDrawer }) => {

    // ? Opciones del jefe
    const [selectedjefe, setSelectedjefe] = useState(null);

    // ? Muestra las opciones del tipo y las selecciona
    const [selectTipoProceso, setSelectTipoProceso] = useState('');

    // ? Muestra los datos para el campo del nombre y la cedula
    const [selectedCedula, setSelectedCedula] = useState("")
    const [selectedNombre, setSelectedNombre] = useState("")

    const [ProEmpCheck, setProEmpCheck] = useState("N")
    const [ProEmpNom, setProEmpNom] = useState("")
    // const [SubProNitSec, setSubProNitSec] = useState("")
    // const [TipProEmpId, setTipProEmpId] = useState("")
    // const [ProEmpPor, setProEmpPor] = useState("")
    const [ProEmpObs, setProEmpObs] = useState("")

    // ? Determina la opcion del tipo
    const handleChange = (event) => {
        setSelectTipoProceso(event.target.value);
    };

    const handleChangechecked = (event) => {
        // setChecked(event.target.checked);
        const isChecked = event.target.checked;
        const newProEmpCheck = isChecked ? "S" : "N";
        setProEmpCheck(newProEmpCheck);
    };

    const EventoClick = async () => {
        try {
            // Validar que los campos obligatorios no estén vacíos
            if (!ProEmpNom || !selectedjefe || !selectTipoProceso || !ProEmpObs) {
                // Mostrar un mensaje de error si algún campo está vacío
                toast.error("Por favor, complete todos los campos obligatorios.", {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                return; // No continuar si hay campos vacíos
            }

            const procesoData = {
                ProEmpCheck: ProEmpCheck,
                ProEmpNom: ProEmpNom,
                SubProNitSec: selectedjefe,
                TipProEmpId: `${selectTipoProceso}`,
                ProEmpPor: '0',
                ProEmpObs: ProEmpObs
            };

            let response;

            if (selectedID.length > 0) {
                response = await newRequest.put(
                    `/api/Calidad/Procesos/ActualizarProcesoEmpresa/${selectedID[0].ProEmpSec}`, procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                response = await newRequest.post("/api/Calidad/Procesos/CrearProcesoEmpresa", procesoData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            }
            if (response.status === 200) {
                toast.success(
                    `Proceso ${selectedID.length > 0 ? "actualizado" : "creado"} correctamente`,
                    {
                        position: "top-center",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setSelectedCedula("")
                setSelectedNombre("")
                setProEmpNom("")
                setProEmpObs("")
                setSelectedjefe(null)
                setOpenDrawer(false)
                getProcesos();
                SumaPeso()

                setSelectTipoProceso("")
            } else {
                toast.warning("Error al interactuar con el servidor", {
                    position: "top-center",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error(
                `El número agregado supera la suma de 100`,
                {
                    position: "top-center",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    }
    useEffect(() => {
        if (openDrawer === false) {
            setProEmpNom("")
            setSelectedCedula("")
            setSelectedNombre("")
            setSelectTipoProceso("")
            setProEmpObs("")
            setProEmpCheck("N")
            setSelectedjefe(null)
        }
    }, [openDrawer])

    useEffect(() => {
        if (selectedID) {
            // setProEmpPor(selectedID[0].ProEmpPor || "0")
            setProEmpCheck(selectedID[0].ProEmpCheck || "")
            setProEmpNom(selectedID[0].ProEmpNom || "");
            setProEmpObs(selectedID[0].ProEmpObs || "");
            setSelectTipoProceso(selectedID[0].TipProEmpId || "");
            setSelectedjefe(selectedID[0].NitSec || "");
            setSelectedCedula(selectedID[0].NitIde || "")
            setSelectedNombre(selectedID[0].NitCom || "")
        } else {
            // Si selectedID está vacío, asegúrate de limpiar los campos
            setProEmpNom("")
            setSelectedCedula("")
            setSelectedNombre("")
            setSelectTipoProceso("")
            setProEmpObs("")
            setProEmpCheck("N")
            setSelectedjefe(null)
        }
    }, [selectedID]);

    const opcionesFiltradas = jefeProceso.filter(option => option.UsuNom !== undefined);

    return (
        <Box>
            <Paper elevation={0} sx={{ p: { xs: 2, md: 3 } }}>
                <Box sx={{
                    marginBottom: "5px", display: "flex", alignContent: "right",
                    justifyContent: "right", marginTop: "2.5rem"
                }}>

                </Box>
                <Box sx={{
                    display: "flex", alignContent: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? "Actualiza un proceso" : " Crear un proceso"}
                    </Typography>
                    <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                        {selectedID.length > 0 ? (
                            <span style={{ marginLeft: "3px" }}>  id: {selectedID[0]?.ProEmpSec}</span>
                        ) : ""}
                    </Typography>
                </Box>
                <Box style={{ height: "10px" }} />
                <Divider />
                <Stack sx={{ width: '100%', marginTop: 2, marginBottom: 2 }} spacing={2}>
                    <Alert severity="warning">
                        Los campos con * son requeridos
                    </Alert>
                </Stack>
                <ThemeProvider theme={defaultTheme}>
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form">
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={8}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Proceso de la empresa *
                                    </Typography>
                                    <TextField fullWidth autoComplete='off' size='small'
                                        placeholder='Nombre del proceso'
                                        value={ProEmpNom}
                                        onChange={(e) => setProEmpNom(e.target.value.toUpperCase())}
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                        InputProps={{
                                            // sx:{marginTop:"21px"},
                                            startAdornment: (
                                                // <i  className="fa-solid fa-percent"></i>
                                                <i style={{ marginRight: "10px" }} className="fa-solid fa-building"></i>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} sx={{ marginTop: "15px" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={ProEmpCheck === "S"} onChange={handleChangechecked} />}
                                        label="Habilitar proceso"
                                        labelPlacement="start"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Jefe del proceso  *
                                        </Typography>
                                        <Autocomplete size='small' autoComplete='off'
                                            // options={jefeProceso}
                                            // getOptionLabel={(option) => option.UsuNom}
                                            // getOptionLabel={(option, index) => `${index}-${option.UsuNom}`} // Utilizar el índice como parte de la clave
                                            options={opcionesFiltradas}
                                            getOptionLabel={(option) => option.UsuNom} // Utilizar una propiedad única y no undefined

                                            fullWidth
                                            value={jefeProceso.find((option) => option.NitSec === selectedjefe) || null}
                                            onChange={(event, newValue) => {
                                                setSelectedjefe(newValue?.NitSec || '');
                                                setSelectedNombre(newValue?.UsuNom || '')
                                                setSelectedCedula(newValue?.Nitide || '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined" placeholder='Jefe del proceso'
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        // style: { fontSize: 14, height: 35 },
                                                    }}

                                                />
                                            )}
                                        />
                                        {/* <div>Jefe seleccionado: {selectedjefe ? `${selectedjefe}  ${selectedCedula}` : ''}</div> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label"
                                            sx={{ marginTop: "20px" }}>
                                            Tipo de proceso  *
                                        </InputLabel>
                                        <Select size='small' label='Jefe del proceso'
                                            value={selectTipoProceso}
                                            onChange={handleChange} sx={{ marginTop: "25px" }}
                                        >
                                            {tipoProcesos.map((tipo) => (
                                                <MenuItem key={tipo.TipProEmpId} value={tipo.TipProEmpId}>
                                                    {tipo.TipProEmpNom.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* <div>Tipo seleccionado : {selectTipoProceso}</div> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Cedula
                                    </Typography>
                                    <TextField fullWidth disabled autoComplete='off' size='small'
                                        id="outlined-multiline-static"
                                        value={selectedCedula}
                                        // label="Cedula"
                                        required placeholder='Cedula'
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#EEE2DE', // Cambiar el color del borde aquí
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#61677A', // Color del borde cuando el TextField está enfocado
                                                },
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Nombre
                                    </Typography>
                                    <TextField fullWidth disabled size='small'
                                        id="outlined-multiline-static" placeholder='Nombre'
                                        // label="Nombre"
                                        value={selectedNombre}
                                        required
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Observación  *
                                    </Typography>
                                    <TextField fullWidth
                                        value={ProEmpObs} size='small'
                                        onChange={(e) => setProEmpObs(e.target.value.toUpperCase())}
                                        multiline rows={6} placeholder='Agrega una observación'
                                        InputLabelProps={{
                                            style: { color: '#222831' }, // Change label color here
                                        }}

                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained"
                                    onClick={EventoClick} sx={{ background: "#1C64F2" }}
                                    fullWidth
                                >
                                    <Box style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <span>{selectedID.length > 0 ? "Actualizar" : " Crear"}</span>
                                            <span> {selectedID.length > 0 ?
                                                (
                                                    <EditIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                ) : (
                                                    <AddIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                                                )}
                                            </span>
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ThemeProvider>
            </Paper>

        </Box >
    )
}

export default ModalProcesoEmpresa