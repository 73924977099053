import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';
import GraficoPerfil from './GraficoPerfil';
import TablaPerfil from './TablaPerfil';
import newRequest from '../../../../utils/newRequest';
import OpcionesTabla from '../porProducto/OpcionesTabla';

const Porperfil = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial,
    selectedProduct, selectedCliente, selectedLineas, selectedVendedor, selectedTercero,
    checked, Excluir, selectedZona }) => {

    const [rows, setRows] = useState([]); // Estado para almacenar las filas
    const [presupuestoTotal, setPresupuestoTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const converAñoAnt = selectAñoIncial - 1;
    const [noDataMessage, setNoDataMessage] = useState('');
    const [cambioTabla, setCambioTabla] = useState(true);

    const handleCambioTablaChange = useCallback((newCambioTabla) => {
        setCambioTabla(newCambioTabla);
    }, []);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasPerfil', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                CambioDato: cambioTabla,
                zona: selectedZona
            });
            const data = res.data;
            if (data.length > 0) {
                const rowsWithId = data.map((row, index) => ({ id: index + 1, ...row }));
                setRows(rowsWithId);
                setNoDataMessage('Ventas por perfil');
            } else {
                setRows([]);
                setNoDataMessage('No hay datos disponibles');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [converAñoAnt, selectAñoIncial, selectedMesInicial, selectedMesFinal, selectedProduct, selectedCliente, selectedLineas, selectedVendedor, selectedTercero, checked, Excluir, cambioTabla, selectedZona]);

    useEffect(() => {
        const getPresupuestoAnual = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialTotalPresupuestoAnual', {
                    total: selectAñoIncial
                });
                setPresupuestoTotal(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getPresupuestoAnual();
        fetchData();
    }, [fetchData, selectAñoIncial]);

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: "25px" }}>
                    {loading ? (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="350px">
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Cargando gráfico de perfil...</Typography>
                        </Box>
                    ) : (
                        <GraficoPerfil selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                            selectedMesFinal={selectedMesFinal} rows={rows} />
                    )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ marginBottom: "25px" }}>
                    {loading ? (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="350px">
                            <CircularProgress />
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Cargando tabla de perfil...</Typography>
                        </Box>
                    ) : (
                        <>
                            <div style={{
                                position: "sticky", top: 0, display: "flex", zIndex: 2,
                                marginBottom: "-40px", marginLeft: "10px", width: "60%"
                            }}>
                                <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "center", paddingTop: "10px" }}>
                                    {noDataMessage && <div>{noDataMessage}</div>}
                                </Typography>
                                <OpcionesTabla onChangeCambioTabla={handleCambioTablaChange} cambioTablaFromParent={cambioTabla} />
                            </div>

                            <TablaPerfil selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                selectedMesFinal={selectedMesFinal} rows={rows}
                                presupuestoTotal={presupuestoTotal} loading={loading}
                                checked={checked} noDataMessage={noDataMessage}
                                onChangeCambioTabla={handleCambioTablaChange} // Pasando la función como prop al hijo
                                cambioTabla={cambioTabla}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default Porperfil;
