// export const getColorStyle = (number1, number2, criterio) => {

//     let style = '';
//     let parsedNumber1, parsedNumber2;

//     // Verificar si number2 es numérico
//     if (isNumeric(number2)) {
//         // let parsedNumber1, parsedNumber2;

//         // if (criterio.toLowerCase() !== 'rango') {
//         //     // Convertir number1 a número si contiene un porcentaje
//         //     parsedNumber1 = number1.includes('%') ? parseFloat(number1.replace('%', '')) / 100 : parseFloat(number1);

//         //     // Resto del código...
//         // }

//         // if (criterio.toLowerCase() === 'rango') {
//         //     // Descomponer la cadena en un array usando el guion como separador
//         //     const arreglometa = number1.split("-");

//         //     // Convertir las partes a números si contienen un porcentaje
//         //     parsedNumber1 = arreglometa[0].includes('%') ? parseFloat(arreglometa[0].replace('%', '')) / 100 : parseFloat(arreglometa[0]);
//         //     parsedNumber2 = arreglometa[1].includes('%') ? parseFloat(arreglometa[1].replace('%', '')) / 100 : parseFloat(arreglometa[1]);

//         //     // Resto del código...
//         // }

//         // Convert number2 to a float
//         parsedNumber2 = parseFloat(number2);

//         if (criterio.toLowerCase() !== 'rango') {
//             // Convertir number1 a número; si tiene %, convertir a fracción
//             parsedNumber1 = number1.includes('%')
//                 ? parseFloat(number1.replace('%', '')) / 100
//                 : parseFloat(number1);
//         }

//         if (criterio.toLowerCase() === 'rango') {
//             // Dividir el rango en un array usando el guion como separador
//             const arreglometa = number1.split("-");
//             // Convertir partes a números; si contienen %, convertir a fracción
//             parsedNumber1 = arreglometa[0].includes('%')
//                 ? parseFloat(arreglometa[0].replace('%', '')) / 100
//                 : parseFloat(arreglometa[0]);
//             parsedNumber2 = arreglometa[1].includes('%')
//                 ? parseFloat(arreglometa[1].replace('%', '')) / 100
//                 : parseFloat(arreglometa[1]);
//         }

//         console.log("number1: ", number1, "number2 :", number2, "criterio :", criterio, "parsedNumber1 :", parsedNumber1, "parsedNumber2 :", parsedNumber2)

//         // Resto del código...

//         switch (criterio.toLowerCase()) {
//             case 'maximo':
//                 style = number2 > parsedNumber1 ? '#FF9B9B' : (number2 === parsedNumber1 ? '#B5F1CC' : '#B5F1CC');

//                 break;
//             // case 'minimo':
//             //     style = number2 < number1 ? '#FF9B9B' : (number2 === parsedNumber1 ? '#B5F1CC' : '#B5F1CC');
//             //     // style = number2 < parsedNumber1 ? '#FF9B9B' : (number2 === parsedNumber1 ? '#B5F1CC' : '#B5F1CC');
//             //     break;

//             // case 'minimo':
//             //     // Compare parsedNumber1 and parsedNumber2 as percentages or decimals
//             //     style = parsedNumber2 < parsedNumber1 ? '#B5F1CC' : '#FF9B9B'; // Green if number2 is less than number1, else red
//             //     break;

//             case 'minimo':
//                 // Si parsedNumber1 es menor que parsedNumber2, debe ser rojo (malo); si es mayor, verde (bueno)
//                 style = parsedNumber1 < parsedNumber2
//                     ? '#FFCDD2' // Rojo si parsedNumber1 es menor que parsedNumber2
//                     : parsedNumber1 > parsedNumber2
//                         ? '#8ADAB2' // Verde si parsedNumber1 es mayor que parsedNumber2
//                         : ''; // Sin color si son iguales o no cumplen la condición
//                 break;



//             case 'rango':
//                 if (number2 < parsedNumber1 || number2 > parsedNumber2) {
//                     style = '#FF9B9B'; /* Rojo */

//                 } else if (number2 === parsedNumber1) {
//                     style = '#B5F1CC'; /* Verde */
//                 } else {
//                     style = '#B5F1CC'; /* Verde Oscuro */
//                 }
//                 break;
//             default:
//                 style = '';
//                 break;
//         }
//     }

//     return style;
// };
// // const isNumeric = (value) => {
// //     return !isNaN(parseFloat(value)) && isFinite(value);
// // };



// // Helper function to check if a value is numeric
// const isNumeric = (value) => {
//     return !isNaN(value) && isFinite(value);
// };



export const getColorStyle = (number1, number2, criterio) => {
    let style = '';
    let parsedNumber1, parsedNumber2;

    // Verificar si number2 es numérico
    if (isNumeric(number2)) {
        // Convertir number2 a número flotante
        // parsedNumber2 = parseFloat(number2);

        // if (criterio.toLowerCase() !== 'rango') {
        //     // Convertir number1 a número; si tiene %, convertir a fracción
        //     parsedNumber1 = number1.includes('%')
        //         ? parseFloat(number1.replace('%', '')) / 100
        //         : parseFloat(number1);
        // }

        // if (criterio.toLowerCase() === 'rango') {
        //     // Dividir el rango en un array usando el guion como separador
        //     const arreglometa = number1.split("-");
        //     // Convertir partes a números; si contienen %, convertir a fracción
        //     parsedNumber1 = arreglometa[0].includes('%')
        //         ? parseFloat(arreglometa[0].replace('%', '')) / 100
        //         : parseFloat(arreglometa[0]);
        //     parsedNumber2 = arreglometa[1].includes('%')
        //         ? parseFloat(arreglometa[1].replace('%', '')) / 100
        //         : parseFloat(arreglometa[1]);
        // }


        parsedNumber2 = parseFloat(number2);

        if (criterio.toLowerCase() !== 'rango') {
            // Verificar si number1 tiene un porcentaje y convertirlo para comparación
            if (number1.includes('%')) {
                parsedNumber1 = parseFloat(number1.replace('%', '')); // Convertir a decimal para comparación
            } else {
                parsedNumber1 = parseFloat(number1); // Mantener como número para comparación
            }
        }

        console.log("parsedNumber1 :", parsedNumber1, "parsedNumber2 :", parsedNumber2)

        // Lógica para el criterio 'minimo'
        switch (criterio.toLowerCase()) {
            // case 'minimo':
            //     // Si number2 es menor que parsedNumber1 (incluso si es negativo), mostrar en rojo
            //     style = parsedNumber2 < parsedNumber1
            //         ? '#FFCDD2' // Rojo si parsedNumber2 es menor que parsedNumber1
            //         : parsedNumber2 >= parsedNumber1
            //             ? '#8ADAB2' // Verde si parsedNumber2 es mayor o igual a parsedNumber1
            //             : ''; // Sin color si no cumple ninguna condición
            //     break;
            case 'maximo':
                // Rojo si number2 supera parsedNumber1, verde si está dentro del límite o es igual
                style = parsedNumber2 > parsedNumber1
                    ? '#F28585' // Rojo si number2 es mayor que number1
                    : '#8ADAB2'; // Verde si number2 es menor o igual que number1
                break;
            case 'minimo':
                // Si parsedNumber2 es mayor o igual a parsedNumber1 (15% en este caso), verde; si es menor, rojo
                style = parsedNumber2 >= parsedNumber1
                    ? '#8ADAB2' // Verde si parsedNumber2 es mayor o igual a parsedNumber1
                    : '#F28585'; // Rojo si parsedNumber2 es menor que parsedNumber1
                break;

            default:
                style = '';
                break;
        }
    }

    return style;
};

// Helper function para verificar si un valor es numérico
const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
};
