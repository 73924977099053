import React, { useEffect, useState } from "react";
import "./FormularioQuejas.css";
import {
    TextField,
    Button,
    Grid,
    Box,
    InputAdornment,
    Typography,
    Container
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NumbersIcon from '@mui/icons-material/Numbers';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';
import Footer from "../../../components/Footer/Footer";
import newRequest from "../../../utils/newRequest";

export const FormularioQuejas = () => {
    const [formDataEmail, setFormDataEmail] = useState({
        subject: "",
        nic_cc: "",
        nombreCompleto: "",
        email: "",
        celular: "",
        nombreProducto: "",
        fecha: "",
        numeroLote: "",
        file: "",
        ciudad: null,
        descripcion: "",
    });

    const [ciudades, setCiudades] = useState([]);

    const handleInputChange = (event) => {
        setFormDataEmail({
            ...formDataEmail,
            [event.target.name]: event.target.value
        });
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setFormDataEmail({
            ...formDataEmail,
            pdf: file,
        });
    };

    const obtenerCiudades = async () => {
        try {
            const res = await newRequest.get(`/api/formulario/ciudades`);
            const ciudadesTransformadas = res.data.map(ciudad => ({
                value: ciudad.ciucod,
                label: ciudad.Ciudad
            }));
            setCiudades(ciudadesTransformadas);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        obtenerCiudades();
    }, []);

    return (
        <>
            <section>
                <div className="container py-5" style={{ marginTop: "5em" }}>
                    <div className="col-lg-6 col-md-12 ">
                        <div className="ud-section-title text-center text-lg-start" style={{ marginBottom: "3 em" }}>
                            <span>Sistema de Peticiones, Quejas, Reclamos y Sugerencias</span>
                            <h2>PQRS</h2>
                        </div>
                    </div>

                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    required fullWidth
                                    label="Motivo de la solicitud"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EventNoteIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    required fullWidth
                                    label="Nit o cedula"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AssignmentIndIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    required fullWidth
                                    label="Nombre completo"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    required fullWidth
                                    label="Dirección de correco electronico"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                <TextField
                                    required fullWidth
                                    label="celular"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocalPhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    required fullWidth
                                    label="Nombre del producto"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InventoryIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField
                                    required fullWidth
                                    label="fecha"
                                    type="date"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonthIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                <TextField
                                    required fullWidth
                                    label="Numero del lote"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                <TextField
                                    required fullWidth
                                    label="Archivo" type="file"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Autocomplete
                                    required
                                    disablePortal
                                    fullWidth
                                    name="ciudad"
                                    value={formDataEmail.ciudad || null}
                                    id="combo-box-demo"
                                    options={ciudades}
                                    getOptionLabel={(option) => option.label || ""}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    onChange={(event, newValue) => {
                                        setFormDataEmail({
                                            ...formDataEmail,
                                            ciudad: newValue
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Ciudad" />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    required
                                    name="descripcion"
                                    value={formDataEmail.descripcion}
                                    onChange={handleInputChange}
                                    label="Descripción"
                                    variant="outlined"
                                    fullWidth multiline
                                    rows={4}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button
                                    type="submit" 
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    style={{ borderRadius: "10px" }}
                                    endIcon={<SendIcon />}
                                >
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    );
};
