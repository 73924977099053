import React, { Fragment, useEffect, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Table, TableHead, TableBody, TableRow, TableContainer,
    Box, Typography, Toolbar, TablePagination,
    styled,
    Skeleton
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { Link, useParams, useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getColorStyle } from './ColorUtils';
import { MRT_Localization_ES } from 'material-react-table/locales/es';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TablaProcesoEmpresaInd = ({ dataTabla, loading }) => {

    const { indProEmpSec, tipo, indicador, criterio } = useParams();

    const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

    // Estado existente...
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [scrollVisible, setScrollVisible] = useState(false); // Estado para manejar el scroll

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Resetear a la primera página
    };

    const navigate = useNavigate();

    useEffect(() => {

    }, [dataTabla])
    // Verificación para asegurarse de que dataTabla no es undefined
    if (!dataTabla) {
        return null; // O maneja este caso según tus necesidades
    }

    // Verifica si la propiedad "Result" es igual a "Este año aun no tiene datos"
    // const hasNoData = dataTabla.Result === "Este año aun no tiene datos";

    //trim() para eliminar posibles espacios en blanco al principio o al final de la cadena:
    const hasNoData = dataTabla[0]?.Result === "Este año aun no tiene datos";;

    if (hasNoData) {
        return <Typography>No hay datos disponibles para este año.</Typography>;
    }

    // const handleRowClick = (IndProEmpSec) => {
    //     navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${indProEmpSec}/${tipo}/ResultadoIndicador/${IndProEmpSec}`);
    // };

    const handleRowClick = (IndProEmpSec, criterio) => {
        navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${indProEmpSec}/${tipo}/ResultadoIndicador/${IndProEmpSec}/${criterio}`);
    };

    // Helper function to format quantities (no decimals for small numbers)
    const formatQuantity = (value) => {
        return parseFloat(value).toLocaleString('es-ES', { maximumFractionDigits: 0 });
    };

    // Helper function to format as currency
    const formatCurrency = (value) => {
        return `$ ${parseFloat(value).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    };

    // Helper function to format as percentage
    const formatPercentage = (value) => {
        return `${parseFloat(value).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
    };

    function capitalizeFirstLetter(text) {
        return text.replace(/\b\w/g, (char) => char.toUpperCase());
    }


    return (
        <Box sx={{ margin: "5px" }}>
            <TableContainer
                style={{
                    height: "62vh",
                    borderRadius: "5px",
                    // overflow: scrollVisible ? "auto" : "hidden", // Mostrar u ocultar scroll
                }}
            // onMouseEnter={() => setScrollVisible(true)}  // Mostrar scroll al poner el cursor
            // onMouseLeave={() => setScrollVisible(false)} // Ocultar scroll al quitar el cursor
            >
                <Table stickyHeader size='small'>

                    <TableHead sx={{
                        // backgroundColor: '#1C64F2', color: 'white',
                    }}>
                        <TableRow>
                            <TableCell style={{
                                backgroundColor: '#2563eb',
                                color: "white",
                            }}>
                                <Typography variant='body1'>
                                    Indicador
                                </Typography>
                            </TableCell>

                            <TableCell style={{
                                backgroundColor: '#27ae60',
                                color: "white",
                            }} >
                                <Typography variant='body1'>
                                    Meta
                                </Typography>
                            </TableCell>

                            <TableCell style={{
                                backgroundColor: '#27ae60',
                                color: "white",
                            }} >
                                <Typography variant='body1'>
                                    Resultado
                                </Typography>
                            </TableCell>

                            {meses.map((mes, index) => (
                                <TableCell style={{
                                    // backgroundColor: index % 2 === 0 ? '#2563eb' : '#2eb165',  // Alterna entre los dos colores
                                    // backgroundColor: "#2563eb",
                                    backgroundColor: '#2563eb',
                                    color: "white",
                                    // width: "20em",
                                    maxWidth: "8em",
                                    minWidth: "8em"
                                }} key={index}>
                                    <Typography variant='body1'>
                                        {mes}
                                    </Typography>
                                </TableCell>
                            ))}

                            <TableCell style={{
                                backgroundColor: '#e74c3c',
                                color: "white",
                                // fontWeight: "bold"
                            }}>
                                <Typography variant='body1'>
                                    Frecuencia
                                </Typography>
                            </TableCell>

                            <TableCell style={{
                                backgroundColor: '#e74c3c',
                                color: "white",
                            }}>
                                <Typography variant='body1'>
                                    Formula
                                </Typography>
                            </TableCell>

                            <TableCell style={{
                                backgroundColor: '#2563eb',
                                color: "white",
                                // fontWeight: "bold"
                            }}>
                                <Typography variant='body1'>
                                    Criterio
                                </Typography>
                            </TableCell>

                            <TableCell style={{
                                backgroundColor: '#2563eb',
                                color: "white",
                            }}>
                                <Typography variant='body1'>
                                    Peso
                                </Typography>
                            </TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {dataTabla
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => (
                                <StyledTableRow key={rowIndex} hover
                                    onClick={() => handleRowClick(row.IndProEmpSec, row.Criterio)}
                                    style={{ cursor: 'pointer' }} // Cambia el cursor para mejorar la UX
                                >
                                    <TableCell
                                        style={{
                                            minHeight: '2rem',
                                            height: '2.8rem',
                                            maxWidth: '15rem',
                                            minWidth: '15rem',
                                            width: '15rem',
                                            position: 'relative',
                                            paddingRight: '1rem',
                                        }}
                                        title={`${row.Indicador} ( ${row.IndProTipCod} - ${row.IndProTipCod2} )`}
                                    >
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography noWrap variant="body2" sx={{ textTransform: "uppercase" }}>

                                                {row.Indicador} ( {row.IndProTipCod} - {row.IndProTipCod2} )
                                            </Typography>
                                        )}
                                    </TableCell>



                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography noWrap variant="body2">
                                                {
                                                    row?.Meta !== undefined ? (
                                                        row?.Meta.includes('%') ? (
                                                            // If Meta contains '%', format as percentage
                                                            formatPercentage(row.Meta)
                                                        ) : (
                                                            // If Meta is a large number, format as currency, else format as quantity
                                                            parseFloat(row.Meta) > 1000 ? (
                                                                formatCurrency(row.Meta)
                                                            ) : (
                                                                parseFloat(row.Meta) === 0 ? '0' : formatQuantity(row.Meta)
                                                            )
                                                        )
                                                    ) : 'Sin Meta'
                                                }
                                            </Typography>
                                        )}
                                    </TableCell>

                                    <TableCell >
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography noWrap variant='body2'>
                                                {
                                                    row["Meta"] && row["Meta"].includes('%') ? (
                                                        // If Meta contains '%', format as percentage
                                                        row["Resultado Indicador"] !== 'SinCalculo' ?
                                                            formatPercentage(row["Resultado Indicador"]) : 'Sin cálculo'
                                                    ) : (
                                                        // Otherwise, check if it's currency or a quantity
                                                        row["Resultado Indicador"] !== 'SinCalculo' ? (
                                                            parseFloat(row["Resultado Indicador"]) > 1000 ? (
                                                                // If value is greater than 1000, format as currency
                                                                formatCurrency(row["Resultado Indicador"])
                                                            ) : (
                                                                // Otherwise, format as a plain quantity
                                                                parseFloat(row["Resultado Indicador"]) === 0 ? '0' : formatQuantity(row["Resultado Indicador"])
                                                            )
                                                        ) : 'Sin cálculo'
                                                    )
                                                }
                                            </Typography>
                                        )}
                                    </TableCell>

                                    {/* Renderizado condici  onal basado en la frecuencia */}
                                    {
                                        meses.map((mes, mesIndex) => {
                                            // Lógica para determinar si la celda debe mostrarse basada en la frecuencia
                                            let mostrarCelda = false;
                                            let resultado = null;
                                            let colSpan = 1; // Predeterminado a 1, ajustar según la lógica de frecuencia

                                            switch (row.IndProFre) {
                                                case 'Mensual':
                                                    mostrarCelda = true;
                                                    resultado = row[meses[mesIndex]];
                                                    colSpan = 1;
                                                    break;
                                                case 'Bimestral':
                                                    mostrarCelda = mesIndex % 2 === 0; // Verdadero cada dos meses
                                                    resultado = row[meses[mesIndex]]; // Asumiendo que el resultado bimestral está en el primer mes del bimestre
                                                    colSpan = 2;
                                                    break;
                                                case 'Trimestral':
                                                    mostrarCelda = mesIndex % 3 === 0; // Verdadero cada tres meses
                                                    resultado = row[meses[mesIndex]]; // Asumiendo que el resultado trimestral está en el primer mes del trimestre
                                                    colSpan = 3;
                                                    break;
                                                case 'Cuatrimestral':
                                                    mostrarCelda = mesIndex % 4 === 0; // Verdadero cada cuatro meses
                                                    resultado = row[meses[mesIndex]];
                                                    colSpan = 4;
                                                    break;
                                                case 'Semestral':
                                                    mostrarCelda = mesIndex % 6 === 0; // Verdadero cada seis meses
                                                    resultado = row[meses[mesIndex]];
                                                    colSpan = 6;
                                                    break;
                                                case 'Anual':
                                                    // Asumiendo que el resultado anual está en el primer mes del año
                                                    mostrarCelda = mesIndex === 0; // Solo el primer mes contiene el valor para anual
                                                    resultado = row[meses[mesIndex]];
                                                    colSpan = 12; // Ocupa toda la fila
                                                    break;
                                                default:
                                                    // Si no coincide con ninguno de los casos anteriores
                                                    mostrarCelda = false;
                                                    resultado = null;
                                                    colSpan = 1; // Predeterminado a 1
                                                    break;
                                            }

                                            if (mostrarCelda) {
                                                return (
                                                    <TableCell key={mesIndex} colSpan={colSpan} style={{}}>
                                                        {resultado !== null && !isNaN(resultado) ? (
                                                            <div style={{
                                                                height: "30px",
                                                                borderRadius: "5px",
                                                                margin: "-10px",
                                                                background: getColorStyle(row['Meta'], resultado, row['Criterio']),
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}>
                                                                {loading ? (
                                                                    <Skeleton
                                                                        animation="wave"
                                                                        height="2.8rem"
                                                                        width='100%'
                                                                        style={{ marginRight: "1em" }}
                                                                    />
                                                                ) : (
                                                                    <Typography noWrap variant="body2">
                                                                        <span style={{ display: "flex" }}>
                                                                            {row['Meta'] && row['Meta'].includes('%') ? (
                                                                                // Format as percentage
                                                                                `${parseFloat(resultado).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
                                                                            ) : (
                                                                                // Check if the value is large enough to be considered currency, otherwise quantity
                                                                                parseFloat(resultado) > 1000 ? (
                                                                                    // Format as currency
                                                                                    `$ ${parseFloat(resultado).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                                                                ) : (
                                                                                    // Format as quantity (no decimals for small numbers)
                                                                                    parseFloat(resultado) === 0 ? '0' : parseFloat(resultado).toLocaleString('es-ES', { maximumFractionDigits: 0 })
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    </Typography>
                                                                )}
                                                            </div>
                                                        ) : <span></span>}
                                                    </TableCell>
                                                );
                                            }

                                            return null; // En casos donde no se debe mostrar la celda, retorna null
                                        })
                                    }

                                    < TableCell >
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography variant='body2' noWrap sx={{ textTransform: "uppercase" }}>
                                                {row.IndProFre} - ( {row.IndProTipCod} - {row.IndProTipCod2} )
                                            </Typography>
                                        )}
                                    </TableCell>

                                    <TableCell style={{ maxWidth: "8rem", width: "8rem" }} title={row.formula}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography variant='body2' noWrap sx={{ textTransform: "uppercase" }}>
                                                {row.formula}
                                            </Typography>
                                        )}
                                    </TableCell>

                                    <TableCell >
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography variant='body2' noWrap sx={{ textTransform: "uppercase" }}>
                                                {row.Criterio}
                                            </Typography>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                height="2.8rem"
                                                width='100%'
                                                style={{ marginRight: "1em" }}
                                            />
                                        ) : (
                                            <Typography variant='body2' noWrap sx={{ textTransform: "uppercase" }}>
                                                {row.peso}%
                                            </Typography>
                                        )}
                                    </TableCell>
                                </StyledTableRow >
                            ))}
                    </TableBody>
                </Table>
            </TableContainer >

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataTabla.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}
            />
        </ Box >
    )
}

export default TablaProcesoEmpresaInd