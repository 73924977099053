import React, { useEffect, useState, useRef } from 'react'
import {
    Box, Typography, Tooltip, Button, Dialog, DialogActions, Grid,
    DialogContent, DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';


const TablaProcesoEmpresa = ({ setOpenDrawer, procesosEmpresa, alignment, handleChange, tipoProcesos,
    onIDSelected, getProcesos, alignmentTipo, handleChangeTipo, sumaPeso }) => {

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleButtonClick = (row) => {
        try {
            setId(row.ProEmpSec)
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.ProEmpSec)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const EliminarunProceso = async () => {
        try {
            // Aqui recibimos el estado
            await newRequest.delete(`/api/Calidad/Procesos/EliminarUnProceso/${idDos}`);
            toast.success("Dato eliminado correctamente", {
                position: "bottom-center",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            getProcesos()
        } catch (error) {
            console.error(error);
        }
    };

    const crearProceso = () => {
        setId("") //Devolvemos el id vacio para que no abra el modal solo
        onIDSelected("")
        setOpenDrawer(true)
    }

    useEffect(() => {
        if (id) {
            const ObtenerUnDatoProceso = async () => {
                try {
                    const res = await newRequest.post(`/api/Calidad/Procesos/obtenerUnProcesosEmpresa`, {
                        id: id
                    });
                    setOpenDrawer(true) // Abre el modal
                    onIDSelected(res.data); // Llama a la función con el ID como argumento
                    setId("")
                } catch (error) {
                    console.error(error);
                }
            }
            ObtenerUnDatoProceso()
        } else {
            // console.log("creando")
        }
    }, [id, idDos, onIDSelected, setOpenDrawer])

    useEffect(() => {

    }, [alignment, alignmentTipo, procesosEmpresa, tipoProcesos])

    const columns = [
        {
            accessorKey: 'ProEmpNom',
            header: 'Nombre del proceso',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                // height: "20px !important",
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.ProEmpNom}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase', initialLetter: "bold" }}>
                                {cell.row.original.ProEmpNom}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'NitSec', //normal accessorKey
            header: 'Identificación',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            size: 20,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.NitSec}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.NitSec}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'NitCom',
            header: 'Responsalble',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#27AE60',
                    color: "white"
                }
            },
            size: 90,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.NitCom}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.NitCom}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'ProEmpCheck',
            header: 'Estado',
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    fontWeight: 'normal',
                    //  fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            size: 90,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.ProEmpCheck}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
                                {cell.row.original.ProEmpCheck = 'S' ? <>activo</> : <>Iinactivo</>}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    // fontSize: "14px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            Cell: ({ cell }) => {
                return (
                    < div >
                        <ButtonGroup variant="text" >

                            <Tooltip title="Editar">
                                <Button onClick={() => handleButtonClick(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <EditIcon sx={{ width: '25px', color: '#118DF0' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Eliminar">
                                <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/* <DeleteIcon sx={{ width: '25px', height: '25px', color: '#FF2442' }} /> */}
                                    <DeleteIcon sx={{ width: '23px', color: '#FF2442' }} />

                                </Button>
                            </Tooltip>

                        </ButtonGroup>
                    </div >
                )
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
    ];

    //optionally access the underlying virtualizer instance
    const columnVirtualizerInstanceRef = useRef(null);

    const table = useMaterialReactTable({
        columns: columns,
        data: procesosEmpresa,
        muiTablePaperProps: {
            elevation: 0,
        },
        localization: MRT_Localization_ES,
        enableStickyHeader: true,
        columnVirtualizerInstanceRef, //optional
        columnVirtualizerOptions: { overscan: 4 }, //optionally customize the virtualizer


        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    width: "100%",
                    overflowX: "auto", // Permite el desplazamiento horizontal
                    whiteSpace: "nowrap", // Evita el retorno de línea en texto largo
                    display: "flex",
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: "space-between",
                    alignItems: { xs: 'start', md: 'center' },
                }}>

                <Grid container spacing={2} sx={{}}>
                    <Grid item xs={12} sm={4} md={3} lg={4} xl={5}>
                        <ToggleButtonGroup
                            color="info"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            size='small'
                            sx={{ height: "35px" }}
                        >

                            <ToggleButton
                                value={0}
                                style={{
                                    backgroundColor: alignment === 0 ? '#1976d2' : 'initial',
                                    color: alignment === 0 ? 'white' : 'initial',
                                }}
                            >
                                <Typography variant='subtitle2' style={{ textTransform: "capitalize" }}>
                                    Activo
                                </Typography>
                            </ToggleButton>
                            <ToggleButton
                                value={1}
                                style={{
                                    backgroundColor: alignment === 1 ? '#1976d2' : 'initial',
                                    color: alignment === 1 ? 'white' : 'initial',
                                }}
                            >
                                <Typography variant='subtitle2' sx={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}>
                                    <Typography variant='subtitle2' style={{ textTransform: "capitalize" }}>
                                        Inactivo
                                    </Typography>
                                </Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} sm={7} md={6} lg={5} xl={5}  >
                        <ToggleButtonGroup
                            color="success"
                            size='small'
                            value={alignmentTipo}
                            exclusive
                            onChange={handleChangeTipo}
                            aria-label="Platform"
                            sx={{ height: "35px" }}
                        >

                            {tipoProcesos.map((index) => {
                                // console.log("index", index.TipProEmpId)
                                return (
                                    <ToggleButton value={index?.TipProEmpId} sx={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}
                                    >
                                        <Typography variant='subtitle2' sx={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                        }}>
                                            <Typography variant='subtitle2' style={{ textTransform: "capitalize" }}>
                                                {index?.TipProEmpNom}
                                            </Typography>
                                        </Typography>
                                    </ToggleButton>
                                )
                            }
                            )}
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={1} xl={1} sx={{}}>
                        <Button variant="outlined" endIcon={<AddCircleIcon />}
                            onClick={crearProceso} >
                            <Typography variant='subtitle2' sx={{
                                fontSize: "13px",
                            }} >
                                Crear Proceso
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box >
        ),
        initialState: { density: 'compact' },
        enableDensityToggle: false,
        enableFilters: false,
        muiTableHeadCellProps: {
            sx: {
                fontWeight: 'normal !important',
                fontSize: '16px !important',

            },
        },
        muiTableBodyCellProps: {
            sx: {
                // fontSize: '0.9rem !important',
                whiteSpace: "nowrap",
                // height: "45px !important",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // border: '1px solid rgba(224, 224, 224, 1)',
            },
        },
        muiTableContainerProps: {
            sx: { height: "25rem", width: "100%", }
        }
    })

    return (
        <Box>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este proceso?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarunProceso();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            <MaterialReactTable
                table={table} //only pass in table instead of all table options
            />
        </Box>
    )
}

export default TablaProcesoEmpresa