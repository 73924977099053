import React, { useEffect, useState, useCallback } from 'react'
import { Box, Typography, Breadcrumbs, LinearProgress, Dialog, DialogContent, Grid, Paper, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import dayjs from 'dayjs';
import newRequest from '../../../utils/newRequest';
import 'react-toastify/dist/ReactToastify.css';
import TablaIndicador from './TablaIndicador';
import ModalIndicador from './ModalIndicador';
import ModalAnalisis from './ModalAnalisis';
import SeleccionProcesoIndicador from './SeleccionProcesoIndicador ';
import Uno from "../../../images/NoHayDatos.jpg"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import useProcesos from './hooks/useProcesos';
import useIndicadores from './hooks/useIndicadores';

const InsetarIndicador = () => {
    const [selectedProceso, setSelectedProceso] = useState(null);
    const [prevSelectedProceso, setPrevSelectedProceso] = useState(null);
    const [selectedIndicadores, setSelectedIndicadores] = useState(null);
    const [selectedIndicadoresId, setSelectedIndicadoresId] = useState(null);
    const [obtenerIndicador, setObtenerIndicador] = useState([]);
    const [selectedID, setSelectedID] = useState("");
    const [nuevoIndicador, setNuevoIndicador] = useState("");
    const [nuevoAnalisis, setNuevoAnalisis] = useState("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawerAnalisis, setOpenDrawerAnalisis] = useState(false);
    const [value, setValue] = useState(null);
    // const [loading, setLoading] = useState(false);
    const loading = false

    const navigate = useNavigate();

    const { procesos, loading: loadingProcesos } = useProcesos();
    const { indicadores, loading: loadingIndicadores, datos } = useIndicadores(selectedProceso, value);

    // Memorizar la función para evitar redefiniciones innecesarias
    const obtenerDatoIndicador = useCallback(async () => {
        const cuerpoIndicador = {
            año: value === null ? null : dayjs(value).year(),
            proceso: selectedProceso,
            indicador: selectedIndicadores
        };
        try {
            const res = await newRequest.post(`/api/Calidad/InsertarIndicadores/Indicador`, cuerpoIndicador, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (res.data.length > 0) {
                setObtenerIndicador(res.data);
            } else {
                setObtenerIndicador([]);
            }
        } catch (error) {
            console.log("error", error);
        }
    }, [value, selectedProceso, selectedIndicadores]);

    useEffect(() => {
        if (selectedProceso !== null && prevSelectedProceso !== null && selectedProceso !== prevSelectedProceso) {
            setSelectedIndicadores(null);
        }
        setPrevSelectedProceso(selectedProceso);
        obtenerDatoIndicador();
    }, [selectedProceso, selectedIndicadoresId, value, nuevoIndicador, obtenerDatoIndicador, prevSelectedProceso]);

    const handleBack = () => {
        navigate("/Farmanet/Calidad")
    }

    // Estado para almacenar el ID seleccionado
    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };
    // Función para enviar datos del indicador
    const handleDatosIndicador = (datos) => {
        setNuevoIndicador(datos);
    };
    // Función para enviar datos del indicador
    const handleDatosAnalisis = (datos) => {
        setNuevoAnalisis(datos);
    };


    console.log("obtenerIndicador obtenerIndicador", obtenerIndicador)

    return (
        <Box>
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between", // Espacio entre los elementos
                            alignItems: "center", // Alinea verticalmente los elementos en el centro
                            marginBottom: "1em",
                        }}
                    >
                        <Fab size='small'
                            onClick={handleBack}
                            style={{
                                background: "white",
                                borderRadius: 13,
                            }}
                        >
                            <ChevronLeftIcon />
                        </Fab>
                        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                            <Typography color="text.primary">Gerencia visual</Typography>
                            <Typography color="text.primary">Insertar indicador</Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
            </Grid>

            <Box >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <SeleccionProcesoIndicador
                            value={value}
                            setValue={setValue}
                            procesos={procesos}
                            selectedProceso={selectedProceso}
                            setselectedProceso={setSelectedProceso}
                            indicadores={indicadores}
                            selectedIndicadores={selectedIndicadores}
                            setselectedIndicadores={setSelectedIndicadores}
                            datos={datos}
                            prevSelectedProceso={prevSelectedProceso} // Asegúrate de que esto esté definido en InsetarIndicador
                            setselectedIndicadoresId={setSelectedIndicadoresId} // Asegúrate de que esto esté definido en InsetarIndicador
                        />

                        {loadingProcesos || loadingIndicadores ? (<LinearProgress />) : selectedIndicadores ? (
                            <Paper variant="outlined" elevation={0} sx={{
                                p: { xs: 1, md: 1 },
                                borderRadius: "8px",
                            }}>
                                <TablaIndicador
                                    obtenerIndicador={obtenerIndicador}
                                    selectedIndicadoresId={selectedIndicadoresId}
                                    indicadores={indicadores}
                                    loading={loading}
                                    setOpenDrawer={setOpenDrawer}
                                    setOpenDrawerAnalisis={setOpenDrawerAnalisis}
                                    onIDSelected={handleIDSelected}
                                    onIdAnalisis={handleDatosAnalisis}
                                    onDataIndicador={handleDatosIndicador}
                                    DatoIndicador={obtenerDatoIndicador}
                                    year={value == null ? null : dayjs(value).year()}
                                />
                            </Paper>
                        ) : (
                            <Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        alignContent: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Uno} alt='' style={{
                                        width: "450px", height: "350px",
                                        objectFit: "contain", mixBlendMode: 'darken'
                                    }} />
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        alignContent: "center",
                                        marginTop: "-25px",
                                        height: 155,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom sx={{}}>
                                        {selectedIndicadores ? (
                                            <span>No existen datos con filtros para el indicador</span>
                                        ) : (
                                            <span>No existen datos con filtros para el indicador</span>
                                        )}
                                    </Typography>
                                </Box>
                            </Box> // Box
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Box >
                <Dialog
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    maxWidth="sm" // Puedes ajustar este valor según tus necesidades
                    PaperProps={{
                        sx: { borderRadius: "13px", }
                    }}
                    sx={{
                        width: "100%", display: "flex", justifyContent:
                            "center", alignItems: "center",
                    }}
                >
                    <DialogContent >
                        <ModalIndicador indicadores={indicadores}
                            selectedProceso={selectedProceso}
                            selectedIndicadoresId={selectedIndicadoresId}
                            selectedID={selectedID} NuevoIndicador={nuevoIndicador}
                            DatoIndicador={obtenerDatoIndicador}
                            setOpenDrawer={setOpenDrawer}
                        />
                    </DialogContent>
                </Dialog>
            </Box>

            <Box >
                <Dialog
                    open={openDrawerAnalisis}
                    onClose={() => setOpenDrawerAnalisis(false)}
                    maxWidth="md"
                    PaperProps={{
                        sx: {
                            borderRadius: "13px",
                            minWidth: "900px",
                        },
                    }}
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <DialogContent>
                        <ModalAnalisis indicadores={indicadores}
                            obtenerIndicador={obtenerIndicador}
                            DatoIndicador={obtenerDatoIndicador}
                            NuevoIndicador={nuevoIndicador}
                            NuevoAnalisis={nuevoAnalisis}
                            selectedIndicadoresId={selectedIndicadoresId}
                            setOpenDrawerAnalisis={setOpenDrawerAnalisis} />
                    </DialogContent>
                </Dialog>
            </Box>
        </Box >
    )
}
export default InsetarIndicador