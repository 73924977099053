import React from 'react'
import { Grid, Typography, Box, CardActionArea, Paper, Container } from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

import Mantis from "../../img/Mantis.jpg"
import Farmanet from "../../img/farmanet.jpg"

export const Plataformas = () => {
    return (
        <div>
            <Container>
                <Box style={{ width: "100%" }}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '8em',
                    }}>
                        <Box sx={{
                            backgroundColor: "#003da5",
                            height: "45px", width: "400px",
                            borderRadius: "15px 30px 15px 30px",
                        }}
                        >
                            <Typography
                                sx={{
                                    marginLeft: "10px", display: "flex",
                                    alignItems: "center",
                                    color: "white", justifyContent: "center",
                                    fontSize: "25px", textAlign: "center"
                                }}
                            >
                                Plataformas
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container spacing={2} sx={{ marginTop: "4rem" }}>
                        <Grid item xs={12} sm={6} md={6} lg={6} >
                            <Paper variant="elevation" elevation={3} style={{ borderRadius: "13px" }}>
                                <Card sx={{ display: 'flex', height: "130px", borderRadius: "13px", height: "250px" }}>
                                    <CardActionArea sx={{ display: "flex" }}
                                        component="a" target='_blank'
                                        href="http://162.251.146.228:8080/MantisFarmaComercial/servlet/com.version8.loginempresa">
                                        <Grid item xs={3} sm={3} md={7}>
                                            <Box sx={{ width: "100%", height: "100%" }}>
                                                <img
                                                    src={Mantis}
                                                    alt=""
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={5}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography variant="h5" component="div" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                        Mantis
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Grid>
                                    </CardActionArea>
                                </Card>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} >
                            <Paper variant="elevation" elevation={3} style={{ borderRadius: "13px" }}>
                                <Card sx={{ display: 'flex', height: "130px", borderRadius: "13px", height: "250px" }}>
                                    <CardActionArea
                                        component="a"
                                        href="/Farmanet" sx={{ display: "flex" }}
                                    //  component={Link} to="/Farmanet" sx={{ display: "flex" }}
                                    >
                                        <Grid item xs={3} sm={3} md={7}>
                                            <Box
                                                sx={{
                                                }}
                                            >
                                                <img src={Farmanet} alt="" style={{
                                                    width: "100%", height: "100%"
                                                }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={5}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    {/* <Typography sx={{ fontSize: 14, color: "#FE0000" }} color="text.secondary" gutterBottom>
                                                    Gerencia visual
                                                </Typography> */}
                                                    <Typography variant="h5" component="div"
                                                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                    >
                                                        FarmaNet
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Grid>
                                    </CardActionArea>
                                </Card>
                            </Paper>
                        </Grid>
                    </Grid>

                </Box>
            </Container>

        </div >
    )
}
