import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Stack, Button, Typography, TextField, Alert, Divider, FormControl, } from '@mui/material';
import { AuthContext } from '../../../loginContext';
import newRequest from '../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import DrawIcon from '@mui/icons-material/Draw';
import { styled } from '@mui/material/styles';

const ModalIndicador = ({ indicadores,
    selectedIndicadoresId, selectedID,
    NuevoIndicador, DatoIndicador,
    setOpenDrawer,
    selectedProceso }) => {


    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: 'black',
            // color: '#A0AAB4',
        },
        '& .MuiInput-underline:after': {
            // borderBottomColor: '#B2BAC2',
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
                // borderColor: '#E0E3E7',
            },
            '&:hover fieldset': {
                // borderColor: '#B2BAC2',
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#c4c4c4',
                // borderColor: '#6F7E8C',
            },
        },
    });

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
        'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const { currentUser } = useContext(AuthContext);

    const [DatIndDatUno, setDatIndDatUno] = useState("");
    const [DatIndDatDos, setDatIndDatDos] = useState("");
    const [IndProSig, setIndProSig] = useState("");
    const [DatIndAcum, setDatIndAcum] = useState("");
    const [DatIndResPro, setDatIndResPro] = useState("");
    const IndVersion = "1";

    let resultado = 0;

    useEffect(() => {
        if (selectedID.length > 0) {
            setDatIndDatUno(selectedID[0]?.DatIndDatUno || "");
            setDatIndDatDos(selectedID[0]?.DatIndDatDos || "");
            setDatIndAcum(selectedID[0]?.DatIndAcum || "");
            setDatIndResPro(selectedID[0]?.DatIndResPro || "");
            setIndProSig(selectedID[0]?.IndProSig);
        } else {
            setDatIndDatUno("");
            setDatIndDatDos("");
            setDatIndAcum("");
            setDatIndResPro("");
            setIndProSig("");
        }
    }, [selectedID]);

    const calcularResultado = useMemo(() => {
        let resultado = 0;
        switch (selectedID.length > 0 ? IndProSig : NuevoIndicador?.datos?.indProSig) {
            case "-":
                resultado = parseFloat(DatIndDatUno) - parseFloat(DatIndDatDos);
                break;
            case "*":
                resultado = parseFloat(DatIndDatUno) * parseFloat(DatIndDatDos);
                break;
            case "/":
                resultado = parseFloat(DatIndDatUno) / parseFloat(DatIndDatDos);
                break;
            case "+":
                resultado = parseFloat(DatIndDatUno) + parseFloat(DatIndDatDos);
                break;
            default:
                console.log("Operación no válida");
        }
        return resultado;
    }, [DatIndDatUno, DatIndDatDos, IndProSig, selectedID, NuevoIndicador]);

    useEffect(() => {
        if (NuevoIndicador && NuevoIndicador.infoCompleta) {
            let acumuladoUno = 0;
            let acumuladoDos = 0;
            NuevoIndicador.infoCompleta.forEach(mes => {
                acumuladoUno += parseFloat(mes.DatIndDatUno);
                acumuladoDos += parseFloat(mes.DatIndDatDos);
            });

            let resultadoTotal = 0;
            switch (selectedID.length > 0 ? IndProSig : NuevoIndicador.datos.indProSig) {
                case "-":
                    resultadoTotal = acumuladoUno + parseFloat(DatIndDatUno) - (acumuladoDos + parseFloat(DatIndDatDos));
                    break;
                case "*":
                    resultadoTotal = (acumuladoUno + parseFloat(DatIndDatUno)) * (acumuladoDos + parseFloat(DatIndDatDos));
                    break;
                case "/":
                    resultadoTotal = (acumuladoUno + parseFloat(DatIndDatUno)) / (acumuladoDos + parseFloat(DatIndDatDos));
                    break;
                case "+":
                    resultadoTotal = acumuladoUno + parseFloat(DatIndDatUno) + acumuladoDos + parseFloat(DatIndDatDos);
                    break;
                default:
                    console.log("Operación no válida");
            }

            let resultAcumulado = 0;
            NuevoIndicador.infoCompleta.forEach(cantResult => {
                resultAcumulado += parseFloat(cantResult.DatIndRes);
            });

            setDatIndResPro(((resultAcumulado + calcularResultado) / (NuevoIndicador.infoCompleta.length + 1)).toFixed(4));
            setDatIndAcum(resultadoTotal.toFixed(4));
        }
    }, [DatIndDatUno, DatIndDatDos, NuevoIndicador, calcularResultado, selectedID.length, IndProSig]);

    switch (selectedID.length > 0 ? (IndProSig) : (NuevoIndicador?.datos?.indProSig)) {
        case "-":
            resultado = parseFloat(DatIndDatUno) - parseFloat(DatIndDatDos);
            break;
        case "*":
            resultado = parseFloat(DatIndDatUno) * parseFloat(DatIndDatDos);
            break;
        case "/":
            resultado = parseFloat(DatIndDatUno) / parseFloat(DatIndDatDos);
            break;
        case "+":
            resultado = parseFloat(DatIndDatUno) + parseFloat(DatIndDatDos);
            break;
        default:
            console.log("Operación no válida");
    }

    const EventoClick = async () => {
        if (!isFinite(calcularResultado)) {
            alert("El resultado es infinito. No se puede guardar.");
            return;
        }
        try {
            const procesoData = {
                DatIndMes: (selectedID.length > 0 ? (selectedID[0]?.DatIndMes) : (`${NuevoIndicador.index}`)), //mes
                DatIndDatUno: DatIndDatUno,
                DatIndDatDos: DatIndDatDos,
                DatIndRes: calcularResultado.toFixed(4),
                DatIndAcum: (NuevoIndicador.index === 1 || selectedID[0]?.DatIndMes === 1) ? calcularResultado.toFixed(4) : DatIndAcum,
                DatIndUsu: currentUser.userData?.UsuCod,
                DatIndResPro: (NuevoIndicador.index === 1 || selectedID[0]?.DatIndMes === 1) ? calcularResultado.toFixed(4) : DatIndResPro,
                IndProAno: (selectedID.length > 0 ? selectedID[0]?.IndProAno[0] : NuevoIndicador.year),
                IndVersion: IndVersion,
                IndProEmpSec: (selectedID.length > 0 ? (selectedID[0]?.IndProEmpSec[0]) : (`${NuevoIndicador.indicador}`)), //Indicador que esta seleccionado
                ProEmpSec: `${selectedProceso}`
            };

            const response = selectedID.length > 0
                ? await newRequest.put(`/api/Calidad/InsertarIndicadores/ActualizarIndicador/${selectedID[0]?.DatIndSec}`, procesoData, {
                    headers: { 'Content-Type': 'application/json' }
                })
                : await newRequest.post(`/api/Calidad/InsertarIndicadores/CrearIndicador`, procesoData, {
                    headers: { 'Content-Type': 'application/json' }
                });

            if (response.status === 200) {
                DatoIndicador();
                setOpenDrawer(false);
                toast.success(`indicador ${selectedID.length > 0 ? "actualizado" : "creado"} correctamente`, {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                });
            } else {
                alert("La solicitud de actualización no se completó correctamente.");
            }
        } catch (error) {
            console.log("Error en la consulta", error);
            alert("Se produjo un error en la solicitud.");
        }
    };

    return (
        <Box>
            <Box sx={{
                marginBottom: "5px", display: "flex", alignContent: "right",
                justifyContent: "right", marginTop: "-15px"
            }}>
            </Box>

            <Box sx={{
                display: "flex", alignContent: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant='h5' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {selectedID.length > 0 ? "Actualizar indicador" : "Insertar indicador"}

                </Typography>

                <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 1 }}>
                    {selectedID ? (
                        <Box>
                            <span>{meses[selectedID[0]?.DatIndMes - 1]} </span>
                            <span>{selectedID[0]?.IndProAno[0]}</span>
                        </Box>
                    ) : (
                        <Box>
                            <span>{meses[NuevoIndicador.index - 1]} </span>
                            <span>{NuevoIndicador.year}</span>
                        </Box>
                    )}
                </Typography>
            </Box>

            <Divider />

            <Box
                sx={{
                    marginTop: 2,
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component="form" noValidate sx={{ width: "100%" }} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField type="number" size='small'
                                    fullWidth autoComplete='off' required
                                    value={DatIndDatUno}
                                    onChange={(e) => setDatIndDatUno(e.target.value)}
                                    placeholder={`Valor ${indicadores[selectedIndicadoresId]?.IndProRel}`}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField type="number" size='small'
                                    fullWidth autoComplete='off' required
                                    value={DatIndDatDos}
                                    onChange={(e) => setDatIndDatDos(e.target.value)}
                                    placeholder={`Valor ${indicadores[selectedIndicadoresId]?.IndProRelDos}`}
                                    variant="outlined" />

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="info">
                                    <Typography variant='body1' color="black">
                                        <span>El <span style={{ fontWeight: "bold" }}>resultado</span>
                                            de los valores son producto de una </span>
                                        <span style={{ fontWeight: "bold" }}>
                                            {
                                                selectedID?.length > 0 ? (
                                                    selectedID?.[0]?.IndProSig === '*' ? 'multiplicación' :
                                                        selectedID?.[0]?.IndProSig === '/' ? 'división' :
                                                            selectedID?.[0]?.IndProSig === '+' ? 'suma' :
                                                                selectedID?.[0]?.IndProSig === '-' ? 'resta' :
                                                                    'un signo desconocido'
                                                ) : (
                                                    NuevoIndicador?.datos?.indProSig === '*' ? 'multiplicación' :
                                                        NuevoIndicador?.datos?.indProSig === '/' ? 'división' :
                                                            NuevoIndicador?.datos?.indProSig === '+' ? 'suma' :
                                                                NuevoIndicador?.datos?.indProSig === '-' ? 'resta' :
                                                                    'un signo desconocido'
                                                )
                                            }
                                        </span>

                                    </Typography>
                                </Alert>
                            </Stack>
                        </Grid>


                        <Grid item xs={12} sm={4} sx={{ marginTop: "1em" }}>
                            <FormControl fullWidth>
                                <CssTextField fullWidth autoComplete='off' required
                                    value={resultado.toFixed(4)} disabled size='small'
                                    label="Resultado Indicador" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ marginTop: "1em" }}>
                            <FormControl fullWidth>
                                <CssTextField fullWidth autoComplete='off' required size='small'
                                    value={NuevoIndicador.index === 1 || selectedID[0]?.DatIndMes === 1 ? resultado.toFixed(4) : DatIndAcum}
                                    disabled
                                    label="Acomulado" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ marginTop: "1em" }}>
                            <FormControl fullWidth>
                                <CssTextField fullWidth autoComplete='off' required size='small'
                                    value={NuevoIndicador.index === 1 || selectedID[0]?.DatIndMes === 1 ? resultado.toFixed(4) : DatIndResPro}
                                    disabled
                                    label="Acomulado" />
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={12}>
                            <Box sx={{
                                marginBottom: "5px", display: "flex", alignContent: "right",
                                justifyContent: "right", marginTop: "15px",
                            }}>
                                <Button variant="contained"
                                    onClick={EventoClick} sx={{ background: "#1C64F2" }}
                                    fullWidth
                                >
                                    <Box style={{ alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant='body2' sx={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <span>{selectedID.length > 0 ? "Actualizar" : " Crear"}</span>
                                            <span> {selectedID.length > 0 ?
                                                (
                                                    <DrawIcon sx={{ marginLeft: "4px", width: "20px", height: "20px" }} />
                                                ) : (
                                                    <AddIcon sx={{ marginLeft: "4px", width: "20px", height: "20px" }} />
                                                )}
                                            </span>
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Grid>


                    </Grid>
                </Box>

            </Box>

        </Box>
    )
}

export default ModalIndicador