import React, { useEffect, useState, lazy, Suspense } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel,
    CircularProgress,
    Fab
    // Skeleton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import newRequest from '../../../utils/newRequest';
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';

const PorVendedor = lazy(() => import('./PorVendedor/PorVendedor'))

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const GestionVisualEmpresa = () => {
    const navigate = useNavigate();


    const [checked, setChecked] = useState(true);
    const [Excluir, SetExcluir] = useState(false);

    const [añoSelect, setAñoSelect] = useState([]);

    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(currentMonth);

    // ? Seleccionar Año y Mes final
    // const [selectAñoFinal, setAñoFinal] = useState(currentYear);
    const [selectedMesFinal, setSelectedMesFinal] = useState(currentMonth);

    //  ? Produto seleccionado
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);

    // ? Cliente seleccionado
    const [selectCliente, setSelectCliente] = useState([])
    const [selectedCliente, setSelectedCliente] = useState(null)

    // ? Opciones de la linea
    const [selectLinea, setSelectLinea] = useState([])
    const [selectedLineas, setSelectedLineas] = useState(null)

    // ? Opciones de los vendedores
    const [selectVendedor, setSelectVendedor] = useState([])
    const [selectedVendedor, setSelectedVendedor] = useState(null)

    // ? Opciones del tercero
    const [selectTercero, setSelectTercero] = useState([])
    const [selectedTercero, setSelectedTercero] = useState(null)

    // ? Zona seleciconado
    const [selectZona, setSelectZona] = useState([]);
    const [selectedZona, setSelectedZona] = useState(null);

    const meses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic",
    ];

    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
            setSelectedMesInicial(1);
            setSelectedMesFinal(12)
        } else {
            // Muestra una alerta o realiza alguna acción para indicar que la selección no es válida
            Store.addNotification({
                title: "La selección no es válida.",
                message: "El año inicial no puede ser mayor al año final",
                type: "info",
                showIcon: true,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                },
            });
        }
    };

    const handleMesInicial = (month) => {
        if (selectAñoIncial === currentYear && month > currentMonth) {
            // Muestra una notificación indicando que la selección no es válida
            Store.addNotification({
                title: "La selección no es válida.",
                message: "No puedes seleccionar un mes posterior al mes actual",
                type: "info",
                showIcon: true,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                },
            });
        } else {
            setSelectedMesInicial(month);
        }
    };

    const getMesesDisponiblesInicial = () => {
        // if (selectAñoFinal === currentYear) {
        //     // Año final es el año actual
        //     return meses.map((mes, index) => ({
        //         nombre: mes,
        //         valor: index + 1,
        //     }));
        // } else {
        // Año final es posterior al año actual
        return meses.map((mes, index) => ({
            nombre: mes,
            valor: index + 1,
        }));
        // }
    };

    const handleMesFinal = (month) => {
        if (selectAñoIncial === currentYear && month < selectedMesInicial) {
            // Muestra una alerta indicando que la selección no es válida
            Store.addNotification({
                title: "La selección no es válida.",
                message: "No puedes seleccionar un mes posterior al mes actual",
                type: "info",
                showIcon: true,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                },
            });
        } else {
            // setSelectedMesInicial(month);
            setSelectedMesFinal(month);
        }
    };

    const getMesesDisponiblesFinal = () => {
        return meses.map((mes, index) => ({
            nombre: mes,
            valor: index + 1,
        }));
        // }
    };

    const handleChange = (event) => {
        // if (selectTercero !== null) {
        //     setChecked(false);
        // }
        //  else {
        setChecked(event.target.checked);
        // }

    };

    const handleChangeExcluir = (event) => {
        SetExcluir(event.target.checked);
    };

    useEffect(() => {
        //Aqui se obtiene el año anterior y año actual
        const fetchData = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno')
                setAñoSelect(res.data.reverse());
            } catch (error) {
                console.error(error);
            }
        }

        if (selectedTercero > 1 || selectedCliente) {
            setChecked(false);
        } else {
            setChecked(true);
        }

        const selectArticulos = async () => {
            try {
                const resProductos = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasArticulosSelect')
                setSelectProduct(resProductos.data);
            } catch (error) {
                console.log("error 🦋", error)
            }
        };

        const selectCliente = async () => {
            try {
                const resCliente = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect')
                setSelectCliente(resCliente.data)
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectOpcionesLinea = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialOpcionesLinea');
                setSelectLinea(res.data);
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectVendedor = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect');
                setSelectVendedor(res.data)
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectTercero = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect');
                setSelectTercero(res.data)
            } catch (error) {
                console.log("error 🦋", error)

            }
        }

        const selectZona = async () => {
            try {
                // const resZona = await axios.get(`http://localhost:8000/api/GerenciaVisual/profundidadSelectZona`);
                const resZona = await newRequest.get('/api/GerenciaVisual/profundidadSelectZona')
                setSelectZona(resZona.data);
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        fetchData();
        selectArticulos();
        selectCliente();
        selectOpcionesLinea();
        selectVendedor();
        selectTercero();
        selectZona()
    }, [selectAñoIncial, selectedMesInicial, selectedMesFinal,
        selectedProduct, selectedCliente, selectedLineas, selectedVendedor, selectedTercero])


    // const handleBack = () => {
    //     navigate("/Farmanet/GestionComercial")
    // }

    return (
        <div>
            <Box >

                <Grid item xs={12} sm={6} md={12} >
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        {/* <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Fab onClick={() => navigate(-1)} style={{ background: "white", width: "45px", height: "45px" }}>
                                <ChevronLeftIcon />
                            </Fab> */}

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between", // Espacio entre los elementos
                                alignItems: "center", // Alinea verticalmente los elementos en el centro
                                marginBottom: "1em",
                            }}
                        >
                            <Fab size='small'
                                onClick={() => navigate(-1)}
                                style={{
                                    background: "white",
                                    borderRadius: 13,
                                }}
                            >
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                <Typography color="text.primary">Volver</Typography>
                                {/* <Typography color="text.primary">Visual empresa</Typography> */}
                            </Breadcrumbs>
                        </Box>
                    </Box>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <Paper variant="elevation" square elevation={3} style={{ borderRadius: "5px", position: "relative" }}> */}
                        <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                            style={{
                                borderRadius: "13px",
                                position: "sticky",
                                top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                                zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                                boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                                transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                            }}
                        >
                            <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                                <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>
                                    {/* Aqui va el año */}
                                    <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ display: "flex" }}>
                                        <Stack direction="column" alignItems="center" spacing={0.5}>
                                            {añoSelect.map((año) => (
                                                <div key={año.PerMesano}>
                                                    <ToggleButtonGroup
                                                        color="primary" value={selectAñoIncial}
                                                        exclusive onClick={() => añoInicial(año.PerMesano)}
                                                        sx={{ height: "30px" }}
                                                    >
                                                        <ToggleButton key={año.PerMesano}
                                                            style={{
                                                                backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                                color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                            }}
                                                            value={año.PerMesano}>
                                                            {año.PerMesano}
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                            ))}
                                        </Stack>
                                    </Grid>

                                    {/* Aqui va los meses */}
                                    <Grid item xs={9} sm={11} md={5} lg={6} xl={5} sx={{ display: "flex", }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <Stack direction="row" alignItems="center" >
                                                <Grid item container xs={12} sm={12} lg={12} spacing={0.5}>
                                                    {getMesesDisponiblesInicial().map((mes, index) => (
                                                        <Grid item key={index}>
                                                            <div key={mes.valor}>
                                                                <ToggleButtonGroup
                                                                    size="small"
                                                                    color="primary"
                                                                    value={selectedMesInicial}
                                                                    exclusive
                                                                    onClick={() => handleMesInicial(index + 1)}
                                                                    sx={{ height: "30px" }}
                                                                >
                                                                    <ToggleButton
                                                                        value={mes.valor}
                                                                        style={{
                                                                            backgroundColor: selectedMesInicial === mes.valor ? '#1976d2' : 'initial',
                                                                            color: selectedMesInicial === mes.valor ? 'white' : 'initial',
                                                                        }}>
                                                                        {mes.nombre}
                                                                    </ToggleButton>
                                                                </ToggleButtonGroup>
                                                            </div>

                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" sx={{ marginTop: "10px" }}>
                                                <Grid item container xs={12} sm={12} lg={12} spacing={0.5}>
                                                    {getMesesDisponiblesFinal().map((mes, index) => (
                                                        <Grid item key={index}>
                                                            <div key={mes.valor}>
                                                                <ToggleButtonGroup
                                                                    size="small"
                                                                    color="primary"
                                                                    value={selectedMesFinal}
                                                                    exclusive
                                                                    onClick={() => handleMesFinal(index + 1)}
                                                                    sx={{ height: "30px" }}
                                                                >
                                                                    <ToggleButton
                                                                        value={mes.valor}
                                                                        style={{
                                                                            backgroundColor: selectedMesFinal === mes.valor ? '#1976d2' : 'initial',
                                                                            color: selectedMesFinal === mes.valor ? 'white' : 'initial',
                                                                        }}>
                                                                        {mes.nombre}
                                                                    </ToggleButton>
                                                                </ToggleButtonGroup>
                                                            </div>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    {/* Aqui va los select */}
                                    <Grid item xs={12} sm={11} md={4} lg={4} xl={5} sx={{}}>
                                        <div style={{ display: "flex" }}>
                                            {/* TERCERO */}
                                            <Grid container item xs={12} sm={6} >
                                                <Autocomplete
                                                    id="cliente" name="Articulo" autoComplete={false} margin="dense"
                                                    options={selectTercero} getOptionLabel={(option) => option.tercero}
                                                    fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedTercero(newValue?.nitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                        // estadoFechasetVisual(true)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Tercero" size="small" variant="outlined" margin="dense"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado Tercero: {selectedTercero}</div> */}
                                            </Grid>
                                            {/* CLIENTES */}
                                            <Grid container item xs={12} sm={6}  >
                                                <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                                    options={selectCliente} getOptionLabel={(option) => option.cliente}
                                                    fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedCliente(newValue?.NitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Clientes" size="small" variant="outlined" margin="dense"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, }, // Cambia el tamaño de la fuente aquí
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado cliente: {selectedCliente}</div> */}
                                            </Grid>
                                            {/* Zona */}
                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="zona" name="zona" size="small" margin="dense"
                                                    autoComplete={false}
                                                    options={selectZona}
                                                    getOptionLabel={(option) => option.ZonNom}
                                                    fullWidth sx={{ marginBottom: "5px", }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedZona(newValue?.ZonCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Zona" size="small" variant="outlined" margin="dense"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado: {selectedZona}</div> */}
                                            </Grid>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            {/* Productos */}
                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                                    options={selectProduct} getOptionLabel={(option) => option.producto}
                                                    fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProduct(newValue?.ArtSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                        // estadoFechasetVisual(true)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Producto" size="small" variant="outlined" margin="dense"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado producto: {selectedProduct}</div> */}
                                            </Grid>
                                            {/* LINEA */}
                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                                    options={selectLinea} getOptionLabel={(option) => option.InvGruNom}
                                                    fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedLineas(newValue?.invGruCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                        // estadoFechasetVisual(true)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Linea" size="small" variant="outlined" margin="dense"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado linea: {selectedLineas}</div> */}
                                            </Grid>
                                            {/* Vendedor */}
                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="cliente" name="Articulo" margin="dense" size="small" autoComplete={false}
                                                    options={selectVendedor}
                                                    getOptionLabel={(option) => option.VenNom}
                                                    fullWidth
                                                    onChange={(event, newValue) => {
                                                        setSelectedVendedor(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                        // estadoFechasetVisual(true)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Vendedor" size="small" variant="outlined" margin="dense"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado vendedor: {selectedVendedor}</div> */}
                                            </Grid>
                                        </div>
                                    </Grid>

                                    {/* Aqui va los check */}
                                    <Grid item xs={12} sm={1} md={1} lg={1} xl={1} sx={{}} >
                                        {/* {selectedTercero} */}
                                        {/* {console.log("selectedTercero", selectedTercero.length)} */}
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                disabled={selectedTercero || selectedCliente} // <- si alguno de los dos tiene datos deshabilite el prospecto
                                            />
                                        } label="Prospecto" labelPlacement="end" />
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={Excluir}
                                                onChange={handleChangeExcluir}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                disabled={!selectedTercero} // <- Si no tiene datos deshabilitelo 
                                            />
                                        } label="Excluir" labelPlacement="end" />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        {/* <Suspense fallback={<CircularProgress />}>
                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                            </Paper>
                        </Suspense> */}

                        <Box sx={{ marginBottom: 3 }}>
                            <Paper variant="outlined" sx={{
                                p: { xs: 1, md: 1 }, borderRadius: "13px",
                                marginTop: 3,
                            }}>
                                <Suspense fallback={<CircularProgress />}>
                                    <PorVendedor selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial} checked={checked} Excluir={Excluir}
                                        selectedMesFinal={selectedMesFinal} selectedProduct={selectedProduct}
                                        selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                        selectedVendedor={selectedVendedor} selectedTercero={selectedTercero}
                                        selectedZona={selectedZona}
                                    />
                                </Suspense>
                            </Paper>
                        </Box>

                    </Grid>

                </Grid>

            </Box>
        </div>
    )
}

export default GestionVisualEmpresa;