import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';

import {
    Box, Breadcrumbs, Paper, Stack, Typography, TextField, ToggleButton,
    ToggleButtonGroup, FormControlLabel, RadioGroup, FormControl, Radio, Chip, MenuItem,
    Autocomplete, InputAdornment,
    Fab,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import newRequest from '../../../utils/newRequest';
import ProfunidadPor from './ProfunidadAlPortafolio/ProfunidadPor';
import ProPortaDisp from './ProPortaDisp/ProPortaDisp';
import Grid from '@mui/material/Grid2';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const ProfundidadAlPortafolio = () => {

    const [añoSelect, setAñoSelect] = useState([]);
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectVendedores, setSelectVendedores] = useState([]);
    const [selectedVendedores, setSelectedVendedores] = useState(null);
    const [selectZona, setSelectZona] = useState([]);
    const [selectedZona, setSelectedZona] = useState(null);
    const [selectCliente, setSelectCliente] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('info'); // puede ser 'error', 'warning', 'info', 'success'


    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectAñoFinal, setAñoFinal] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(currentMonth);
    const [selectedMesFinal, setSelectedMesFinal] = useState(currentMonth);
    const [tipoValor, setTipoValor] = useState(false);
    const [datosParametros, setDatosParametros] = useState({
        articulo: "",
        cliente: "",
        zona: "",
        asesor: "",
        fechaInicial: "",
        fechaFinal: "",
        tipo: false,
    });

    const navigate = useNavigate();

    const FechaInicial = useMemo(() => `${selectAñoIncial}-${selectedMesInicial}-01`, [selectAñoIncial, selectedMesInicial]);
    const FechaFinal = useMemo(() => `${selectAñoFinal}-${selectedMesFinal}-01`, [selectAñoFinal, selectedMesFinal]);

    const updateDatosParametros = useCallback(() => {
        const articulo = selectedProduct.map((codArticulos) => codArticulos.ArtSec).join(', ');
        setDatosParametros({
            articulo,
            cliente: selectedCliente,
            zona: selectedZona,
            asesor: selectedVendedores,
            fechaInicial: FechaInicial,
            fechaFinal: FechaFinal,
            tipo: tipoValor,
        });
    }, [selectedProduct, selectedCliente, selectedZona, selectedVendedores, FechaInicial, FechaFinal, tipoValor]);


    const getMesesDisponibles = useCallback((año, isInicial) => {
        if (año === currentYear) {
            return meses.slice(0, currentMonth).map((mes, index) => ({
                nombre: mes,
                valor: index + 1,
            }));
        }
        return meses.map((mes, index) => ({
            nombre: mes,
            valor: index + 1,
        }));
    }, []);

    const handleAñoInicial = useCallback((year) => {
        // estadoFechaset(true);
        if (year <= selectAñoFinal) {
            setAñoInical(year);
            setSelectedMesInicial(1);
        } else {
            showNotification("La selección no es válida.", "El año inicial no puede ser mayor al año final", "info");
        }
    }, [selectAñoFinal]);
    // }, [selectAñoFinal, estadoFechaset]);

    const handleAñoFinal = useCallback((year) => {
        // estadoFechaset(true);
        if (selectAñoIncial !== 2023 || year >= selectAñoIncial) {
            setAñoFinal(year);
            setSelectedMesFinal(1);
        } else {
            showNotification("La selección no es válida.", "El año final no puede ser mayor al año inicial", "info");
        }
    }, [selectAñoIncial]);

    const handleMesInicial = useCallback((month) => {
        // estadoFechaset(true);
        if (selectAñoIncial === selectAñoFinal && month > selectedMesFinal) {
            setSelectedMesFinal(month);
        }
        setSelectedMesInicial(month);
    }, [selectAñoIncial, selectAñoFinal, selectedMesFinal]);

    const handleMesFinal = useCallback((month) => {
        setSelectedMesFinal(month);
        // estadoFechaset(true);
        if (selectAñoIncial === selectAñoFinal && selectedMesInicial > month) {
            showNotification("La selección no es válida.", "No se puede seleccionar el mes de la fecha final superior a la inicial", "warning");
            setSelectedMesInicial(month);
        }
    }, [selectAñoIncial, selectAñoFinal, selectedMesInicial]);

    const fetchData = useCallback(async () => {
        try {
            const resAño = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno');
            setAñoSelect(resAño.data.reverse());

            const resProductos = await newRequest.get('/api/GerenciaVisual/profundidadSelectArticulo');
            setSelectProduct(resProductos.data);

            const resVendedores = await newRequest.get('/api/GerenciaVisual/profundidadSelectVendedores');
            setSelectVendedores(resVendedores.data);

            const resZona = await newRequest.get('/api/GerenciaVisual/profundidadSelectZona');
            setSelectZona(resZona.data);

            const resCliente = await newRequest.get('/api/GerenciaVisual/profundidadCliente');
            setSelectCliente(resCliente.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        updateDatosParametros();
    }, [updateDatosParametros]);

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }

    const showNotification = (title, message, type) => {
        setSnackbarMessage(`${title} - ${message}`);
        setSnackbarType(type);
        setSnackbarOpen(true);
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose} severity={snackbarType} sx={{
                        width: '100%',
                        background: "#373A40", color: "white"
                    }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Grid size={{ xs: 12, sm: 12, md: 12 }} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Fab size='small'
                            onClick={handleBack}
                            style={{
                                background: "white",
                                borderRadius: 13,
                            }}
                        >
                            <ChevronLeftIcon />
                        </Fab>

                        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                            <Typography color="text.primary">Gerencia visual</Typography>
                            <Typography color="text.primary">Profundidad al portafolio</Typography>
                        </Breadcrumbs>
                    </Box>

                </Box>
            </Grid>

            <Grid container spacing={1}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} >
                    <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "13px", position: "sticky", top: 55,
                            zIndex: 3, boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)",
                            transition: "box-shadow 0.3s",
                        }}
                    >
                        <Box sx={{
                            alignContent: "center",
                            display: "flex", alignItems: "center", margin: 1
                        }}>

                            <Grid container size={{ xs: 12 }} spacing={1} alignItems="center" sx={{ marginTop: "8px" }}>

                                <Grid size={{ xs: 4, sm: 2, md: 2, lg: 2, xl: 2 }}  >
                                    <Stack direction="row" alignItems="center" spacing={0.5}>
                                        {añoSelect.map((año) => (
                                            <ToggleButtonGroup
                                                key={año.PerMesano}
                                                color="primary"
                                                size='small'
                                                value={selectAñoIncial}
                                                exclusive
                                                onChange={() => handleAñoInicial(año.PerMesano)}
                                                sx={{
                                                    height: "30px",
                                                    "& .MuiToggleButton-root": {
                                                        backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                        color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                    },
                                                }}
                                            >
                                                <ToggleButton value={año.PerMesano}>
                                                    {año.PerMesano}
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        ))}
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={0.5} sx={{ marginTop: "5px" }}>
                                        {añoSelect.map((año) => (
                                            <ToggleButtonGroup
                                                key={año.PerMesano}
                                                color="primary"
                                                size='small'
                                                value={selectAñoFinal}
                                                exclusive
                                                onChange={() => handleAñoFinal(año.PerMesano)}
                                                sx={{
                                                    height: "30px",
                                                    "& .MuiToggleButton-root": {
                                                        backgroundColor: selectAñoFinal === año.PerMesano ? '#1976d2' : 'initial',
                                                        color: selectAñoFinal === año.PerMesano ? 'white' : 'initial',
                                                    },
                                                }}
                                            >
                                                <ToggleButton value={año.PerMesano}>
                                                    {año.PerMesano}
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        ))}
                                    </Stack>
                                </Grid>

                                {/* Aqui va los meses */}
                                <Grid size={{ xs: 8, sm: 10, md: 4, lg: 6, xl: 5 }} sx={{ display: "flex", }}>
                                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} >
                                        <Stack direction="row" alignItems="center">
                                            <Grid container spacing={0.5}>
                                                {getMesesDisponibles(selectAñoIncial, true).map((mes, index) => (
                                                    <Grid size="auto" key={index}>
                                                        <ToggleButtonGroup
                                                            size="small"
                                                            color="primary"
                                                            value={selectedMesInicial}
                                                            exclusive
                                                            onChange={() => handleMesInicial(index + 1)}
                                                            sx={{
                                                                height: "30px",
                                                                "& .MuiToggleButton-root": {
                                                                    backgroundColor: selectedMesInicial === mes.valor ? '#1976d2' : 'initial',
                                                                    color: selectedMesInicial === mes.valor ? 'white' : 'initial',
                                                                },
                                                            }}
                                                        >
                                                            <ToggleButton value={mes.valor}>
                                                                {mes.nombre}
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" sx={{ marginTop: "10px" }}>
                                            <Grid container spacing={0.5}>
                                                {getMesesDisponibles(selectAñoFinal, false).map((mes, index) => (
                                                    <Grid size="auto" key={index}>
                                                        <ToggleButtonGroup
                                                            size="small"
                                                            color="primary"
                                                            value={selectedMesFinal}
                                                            exclusive
                                                            onChange={() => handleMesFinal(index + 1)}
                                                            sx={{
                                                                height: "30px",
                                                                "& .MuiToggleButton-root": {
                                                                    backgroundColor: selectedMesFinal === mes.valor ? '#1976d2' : 'initial',
                                                                    color: selectedMesFinal === mes.valor ? 'white' : 'initial',
                                                                },
                                                            }}
                                                        >
                                                            <ToggleButton value={mes.valor}>
                                                                {mes.nombre}
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                {/* Aqui va los select */}
                                <Grid size={{ xs: 8, sm: 10, md: 5, lg: 3, xl: 4 }}>
                                    <Grid container size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} >
                                        <Autocomplete
                                            id="articulo" size="small" margin="dense"
                                            name="articulo"
                                            autoComplete={false}
                                            options={selectProduct}
                                            getOptionLabel={(option) => option.producto || ''}
                                            fullWidth
                                            multiple
                                            value={selectedProduct}
                                            onChange={(event, newValue) => {
                                                setSelectedProduct(newValue);
                                                // estadoFechaset(true)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Productos"
                                                    size="small" margin="none"
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        startadornment: (
                                                            <InputAdornment position="start">
                                                                <i className="fa-solid fa-prescription-bottle"></i>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={selectedProduct.map((option) => option.producto).join(', ')}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.producto === value.producto} // Compara las opciones por su valor "producto"
                                            renderOption={(props, option) => (
                                                <MenuItem {...props} key={option.ArtSec} value={option}>
                                                    {option.producto}
                                                </MenuItem>
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={option.producto.substring(0, 10) + (option.producto.length > 10 ? '...' : '')} // Acorta el nombre del producto a 10 caracteres y agrega puntos suspensivos si es necesario
                                                        {...getTagProps({ index })}
                                                        style={{ height: '24px', fontSize: '12px', }} // Aplica estilos para reducir el alto y el tamaño de fuente de los chips
                                                    />
                                                ))
                                            }
                                        />
                                    </Grid>
                                    <div style={{ display: "flex", marginTop: "10px", }} >
                                        {/* Asesor */}
                                        <Grid container size={{ xs: 12, sm: 6 }} >
                                            <Autocomplete
                                                id="asesor" name="asesor" size="small"
                                                autoComplete={false}
                                                options={selectVendedores}
                                                getOptionLabel={(option) => option.VenNom}
                                                fullWidth
                                                onChange={(event, newValue) => {
                                                    setSelectedVendedores(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    // estadoFechaset(true)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Asesor"
                                                        size="small" margin="dense"
                                                        sx={{ paddingRight: "10px" }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startadornment: (
                                                                <InputAdornment position="start">
                                                                    <i className="fa-solid fa-user-tie"></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado: {selectedVendedores}</div> */}
                                        </Grid>
                                        {/* Zona */}
                                        <Grid container size={{ xs: 12, sm: 6 }} >
                                            <Autocomplete
                                                id="zona" name="zona" size="small"
                                                autoComplete={false}
                                                options={selectZona}
                                                getOptionLabel={(option) => option.ZonNom}
                                                fullWidth
                                                onChange={(event, newValue) => {
                                                    setSelectedZona(newValue?.ZonCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    // estadoFechaset(true)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Zona"
                                                        size="small" margin="dense"
                                                        sx={{ paddingRight: "10px" }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startadornment: (
                                                                <InputAdornment position="start">
                                                                    <i className="fa-solid fa-city"></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado: {selectedZona}</div> */}
                                        </Grid>

                                        {/* Cliente */}
                                        <Grid container size={{ xs: 12, sm: 6 }} >
                                            <Autocomplete
                                                id="cliente" size="small" margin="dense"
                                                name="cliente"
                                                autoComplete={false}
                                                options={selectCliente}
                                                getOptionLabel={(option) => option.cliente}
                                                fullWidth
                                                onChange={(event, newValue) => {
                                                    setSelectedCliente(newValue?.cod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    // estadoFechaset(true)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Clientes"
                                                        size="small" margin="dense"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startadornment: (
                                                                <InputAdornment position="start">
                                                                    <i className="fa-solid fa-users"></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado: {selectedCliente}</div> */}
                                        </Grid>
                                    </div>
                                </Grid>

                                {/* Aqui va los radio buttons */}
                                <Grid size={{ xs: 4, sm: 1, md: 1, lg: 1, xl: 1 }}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={tipoValor.toString()} // Convertir a string
                                            onChange={(event) => {
                                                const value = event.target.value === "true";
                                                setTipoValor(value);
                                                // estadoFechaset(true)
                                            }}
                                        >
                                            <FormControlLabel value={true} control={
                                                <Radio />} label="Valor" />
                                            <FormControlLabel value={false} control={
                                                <Radio />} label="Cantidad" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>

                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} >

                    <Paper variant="outlined" sx={{ padding: 1, borderRadius: "8px",}}>

                        <Suspense fallback={<CircularProgress />}>
                            <ProfunidadPor
                                datosParametros={datosParametros}
                                fechaInicial={FechaInicial}
                                fechaFinal={FechaFinal}
                                actualizarFechas={setDatosParametros}
                                tipoValor={tipoValor}
                            />
                        </Suspense>
                        {/* */}

                        <Suspense fallback={<CircularProgress />}>
                            <ProPortaDisp
                                datosParametros={datosParametros}
                                fechaInicial={FechaInicial}
                                fechaFinal={FechaFinal}
                                actualizarFechas={setDatosParametros}
                                tipoValor={tipoValor}
                            />
                        </Suspense>

                    </Paper>
                </Grid>

            </ Grid>
        </Box >
    )
}
export default ProfundidadAlPortafolio