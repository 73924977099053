import React, { useEffect, useState } from 'react'
import {
    Box, Breadcrumbs, Paper, Stack, Typography, ToggleButton,
    ToggleButtonGroup, Fab, Snackbar, Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import PaymentsIcon from '@mui/icons-material/Payments';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PorPyG from "./PorPyG/PorPyG";
import { toast } from 'react-toastify';
import newRequest from '../../../utils/newRequest';
import { AcumualadoPyG } from './AcumuladoPyG/AcumualadoPyG';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Grid from '@mui/material/Grid2';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const meses = [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun",
    "Jul", "Ago", "Sep", "Oct", "Nov", "Dic",
];

export const PrincipalPyG = () => {

    const navigate = useNavigate();

    console.log("currentMonth currentMonth", currentMonth)

    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(1); // Inicializa con enero

    // ? Seleccionar Año y Mes final
    const [selectedMesFinal, setSelectedMesFinal] = useState(currentMonth);

    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState({ message: '', severity: 'info' });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleOpenSnackbar = (message, severity) => {
        setMessageInfo({ message, severity });
        setOpen(true);
    };

    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
            setSelectedMesInicial(1);
            setSelectedMesFinal(12);
        } else {
            toast.warning(`La selección no es válida`, {
                position: "bottom-left",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        }
    };

    const getMesesDisponibles = () => {
        return meses.map((mes, index) => ({
            nombre: mes,
            valor: index + 1,
        }));
    };

    const handleMesInicial = (month) => {
        if (selectAñoIncial === currentYear && month > currentMonth) {
            handleOpenSnackbar("No puedes seleccionar un mes posterior al mes actual", "warning");
        } else {
            setSelectedMesInicial(month);
        }
    };

    const handleMesFinal = (month) => {
        if (selectAñoIncial === currentYear && month < selectedMesInicial) {
            handleOpenSnackbar("No puedes seleccionar un mes anterior al mes inicial", "warning");
        } else {
            setSelectedMesFinal(month);
        }
    };

    const fetchData = async () => {
        try {
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={messageInfo.severity}
                    // variant="standard"
                    sx={{
                        width: '100%', background: "#222831", color: "white"
                    }}
                >
                    {messageInfo.message}
                </Alert>
            </Snackbar>


            <Grid container >
                <Grid xs={12} sm={6} md={12}>
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1em" }}>

                            <Fab onClick={() => navigate(-1)} size='small'
                                style={{ background: "white", borderRadius: 13 }}>
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "15px" }}>
                                <Typography color="text.primary">Financiera</Typography>
                                <Typography color="text.primary">PyG</Typography>
                            </Breadcrumbs>
                        </Box>

                    </Box>
                </Grid>
            </Grid>

            {/* <Grid container spacing={1}> */}

            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "13px",
                            position: "sticky",
                            top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                            zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                            boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                            transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                        }}
                    >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                            <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>
                                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: "flex" }}>
                                    <Stack direction="column" alignItems="center" spacing={0.5}>
                                        {añoSelect.map((año) => (
                                            <div key={año.PerMesano}>
                                                <ToggleButtonGroup
                                                    color="primary" value={selectAñoIncial}
                                                    exclusive onClick={() => añoInicial(año.PerMesano)}
                                                    sx={{ height: "30px" }}
                                                >
                                                    <ToggleButton key={año.PerMesano}
                                                        style={{
                                                            backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                            color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                        }}
                                                        value={año.PerMesano}>
                                                        {año.PerMesano}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        ))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={10} sm={10} md={11} lg={11} xl={11} sx={{ display: "flex", }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Stack direction="row" alignItems="center">
                                            <Grid item container xs={12} sm={12} lg={12} spacing={0.5}>
                                                {getMesesDisponibles().map((mes, index) => (
                                                    <Grid item key={index}>
                                                        <div key={mes.valor}>
                                                            <ToggleButtonGroup
                                                                size="small"
                                                                color="primary"
                                                                value={selectedMesInicial}
                                                                exclusive
                                                                onChange={() => handleMesInicial(mes.valor)}
                                                                sx={{ height: "30px" }}
                                                            >
                                                                <ToggleButton
                                                                    value={mes.valor}
                                                                    style={{
                                                                        backgroundColor: selectedMesInicial === mes.valor ? '#1976d2' : 'initial',
                                                                        color: selectedMesInicial === mes.valor ? 'white' : 'initial',
                                                                    }}>
                                                                    {mes.nombre}
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>

                                        <Stack direction="row" alignItems="center" sx={{ marginTop: "10px" }}>
                                            <Grid item container xs={12} sm={12} lg={12} spacing={0.5}>
                                                {getMesesDisponibles().map((mes, index) => (
                                                    <Grid item key={index}>
                                                        <div key={mes.valor}>
                                                            <ToggleButtonGroup
                                                                size="small"
                                                                color="primary"
                                                                value={selectedMesFinal}
                                                                exclusive
                                                                onChange={() => handleMesFinal(mes.valor)}
                                                                sx={{ height: "30px" }}
                                                            >
                                                                <ToggleButton
                                                                    value={mes.valor}
                                                                    style={{
                                                                        backgroundColor: selectedMesFinal === mes.valor ? '#1976d2' : 'initial',
                                                                        color: selectedMesFinal === mes.valor ? 'white' : 'initial',
                                                                    }}>
                                                                    {mes.nombre}
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid> */}

            {/* <Grid item xs={12}>
                    <Paper variant="outlined" sx={{
                        p: { xs: 1, md: 1 }, borderRadius: "13px",
                        marginTop: 1,
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <PorPyG />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <AcumualadoPyG selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal} selectAñoIncial={selectAñoIncial} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> */}
            {/* </Grid > */}

            <Grid container spacing={2}>
                <Grid size={12}>
                    <Grid container spacing={2} alignItems="center" sx={{ marginTop: 1 }}>
                        <Grid size={{ sm: 12, md: 12 }}>
                            <FormControl style={{ width: "150px", background: "white", borderRadius: "8px" }} size="small">
                                <InputLabel id="demo-simple-select-label">Año</InputLabel>
                                <Select
                                    labelId="select-año-label"
                                    id="select-año"
                                    label="Año"
                                    value={selectAñoIncial}
                                    onChange={(event) => añoInicial(event.target.value)}
                                    style={{ borderRadius: "8px" }}
                                >
                                    {añoSelect.map((año) => (
                                        <MenuItem value={año.PerMesano} key={año.PerMesano}>
                                            {año.PerMesano}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Meses */}
                        <Grid size={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{ display: "flex", gap: 1 }}>
                                    <FormControl style={{ width: "300px", background: "white", borderRadius: "8px" }} size="small">
                                        <InputLabel>Mes Inicial</InputLabel>
                                        <Select
                                            label="Mes Incial"
                                            value={selectedMesInicial}
                                            onChange={(e) => handleMesInicial(e.target.value)}
                                            startAdornment={
                                                <CalendarMonthIcon style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                                            }
                                        >
                                            {meses.map((item, index) => (
                                                <MenuItem value={index + 1} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl style={{ width: "300px", background: "white", borderRadius: "8px" }} size="small">
                                        <InputLabel>Mes Final</InputLabel>
                                        <Select
                                            label="Mes Final"
                                            value={selectedMesFinal}
                                            onChange={(e) => handleMesFinal(e.target.value)}
                                            startAdornment={
                                                <CalendarMonthIcon style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                                            }
                                        >
                                            {meses.map((item, index) => (
                                                <MenuItem value={index + 1} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: "1em" }}>
                        <Grid container spacing={2}>

                            <Grid size={{ xs: 12, md: 6 }} >
                                <Paper variant="outlined" sx={{ p: 1, borderRadius: "8px", marginTop: 1 }}>
                                    <div style={{ maxHeight: "69vh", overflow: "auto", paddingRight: "1rem" }}>
                                        <PorPyG />
                                    </div>
                                </Paper>
                            </Grid>

                            <Grid size={{ xs: 12, md: 6 }} >
                                <Paper variant="outlined" sx={{ p: 1, borderRadius: "8px", marginTop: 1 }}>
                                    <div style={{ maxHeight: "69vh", overflow: "auto", paddingRight: "1rem" }}>
                                        <AcumualadoPyG selectedMesInicial={selectedMesInicial}
                                            selectedMesFinal={selectedMesFinal} selectAñoIncial={selectAñoIncial} />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>



            </Grid>
            {/* </Grid> */}
        </div >
    )
}
