import { IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import InfoIcon from '@mui/icons-material/Info';

function TablaPasivo({ data, pasivoNoCorr }) {
    const formatCurrency = useCallback((value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
        }).format(value);
    }, []);

    // Calcular el total global
    const calculateGrandTotal = (rows) => {
        return rows.reduce((sum, row) => sum + row.Total, 0);
    };

    const renderSection = (rows, label, prefix) => {
        let total = 0;
        const sectionRows = rows.filter(row => row.puccod.startsWith(prefix));

        const rowElements = sectionRows.map((row) => {
            total += row.Total;
            return (
                <TableRow key={row.puccod} sx={{ border: 'none' }}>
                    <TableCell align='left' sx={{ border: 'none' }}> {row.puccod}</TableCell>
                    <TableCell align="left" sx={{ border: 'none' }}>
                        <Typography noWrap width={"250px"} title={row.pucNom}>
                            {row.pucNom}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }} align="right">{formatCurrency(row.Total)}</TableCell>
                </TableRow >
            );
        });

        return (
            <>
                <TableRow sx={{ border: 'none' }}>
                    <TableCell align="left" colSpan={2} sx={{ fontWeight: 'bold', backgroundColor: '#F5F5F5', border: 'none' }}>
                        {label}
                    </TableCell>
                    <TableCell align="right" colSpan={1} sx={{ fontWeight: 'bold', backgroundColor: '#F5F5F5', border: 'none' }}>
                        {formatCurrency(total)}
                    </TableCell>
                </TableRow>
                {rowElements}
            </>
        );
    };

    // Calcular el total general
    const grandTotal = calculateGrandTotal(data);

    // console.log("xaxaxaxaxxaxaxaxax", grandTotal)
    // console.log("xaxaxaxaxxaxaxaxax", pasivoNoCorr?.total)
    const PasivoCorriente = grandTotal - pasivoNoCorr?.total

    return (
        <div >
            <Typography variant="h6" style={{
                fontWeight: "bold",
                marginBottom: "0.5em", fontSize: "18px"
            }}>
                Pasivos
            </Typography>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} >Cuenta</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }}>Descripción</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} align='right' >Valor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderSection(data, "Disponible", "21")}
                    {renderSection(data, "Proveedores", "22")}
                    {renderSection(data, "Cuentas por pagar", "23")}
                    {renderSection(data, "Impuesto gravamenes y tasas", "24")}
                    {renderSection(data, "Obligaciones laborales", "25")}
                    {renderSection(data, "Otros pasivos", "28")}
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#7CF5FF', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Pasivo  corriente
                                <Tooltip title={`resta de la cuenta 2468 Restarla con la cuenta 2335 `}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#7CF5FF'
                        }}>
                            {/* {formatCurrency(pasivoNoCorr.total)} */}
                            {formatCurrency(PasivoCorriente)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#FFDC7F', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Pasivo no corriente
                                <Tooltip title={`resta de la cuenta 2468 Restarla con la cuenta 2335 `}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#FFDC7F'
                        }}>
                            {formatCurrency(pasivoNoCorr.total)}
                            {/* {formatCurrency(PasivoNoCorriente)} */}
                        </TableCell>
                    </TableRow>


                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell
                            colSpan={3}
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                height: 5,
                                backgroundColor: '#B7B7B7',
                                // zIndex: 1,
                                // boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
                                fontWeight: "bold"
                            }}
                        >
                            <Typography variant='h6' sx={{ textAlign: 'left', fontSize: 16, fontWeight: "bold", color: "black" }}>
                                Total pasivos:  {formatCurrency(grandTotal)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>

            </Table>


        </div>
    )
}

export default TablaPasivo