import { Typography, Select, MenuItem } from '@mui/material'
import React, {  useState } from 'react'
import newRequest from '../../utils/newRequest'
import { useEffect } from 'react'

const Adminitrador = () => {

    const [perfiles, setPerfiles] = useState([])
    const [selectedRoute, setSelectedRoute] = useState(''); // Estado para almacenar la ruta seleccionada


    const ObtenerPerfiles = async () => {
        try {
            const res = await newRequest.get("/api/admin/Perfiles");
            console.log("res adm", res.data)
            setPerfiles(res.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        ObtenerPerfiles();
    }, [])


    const routes = [
        '/admin',
        '/admin/perfil/:id',
        // Agrega más rutas según sea necesario
    ];

    console.log("perfiles",perfiles)

    // Manejar el cambio en el Select
    const handleRouteChange = (event) => {
        const selectedRoute = event.target.value;
        setSelectedRoute(selectedRoute);
        // navigate(selectedRoute); // Navegar a la ruta seleccionada
    };


    return (
        <div>

            <Typography variant='h5'>
                Añade los perfiles para los permisos
            </Typography>

            {/* Select para mostrar y seleccionar las rutas */}
            <Select value={selectedRoute} onChange={handleRouteChange}>
                {perfiles.map((route) => (
                    <MenuItem key={route.PerusuCod} value={route.PerusuCod}>
                        {route.PerUsuNom}
                    </MenuItem>
                ))}
            </Select>


            {/* <MaterialReactTable
                columns={columns}
                data={perfiles}
                initialState={{
                    density: 'compact',
                    pagination: { pageIndex: 0, pageSize: 10 },
                }}

            /> */}
        </div>
    )
}

export default Adminitrador