import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';

const GraficoZona = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const converAñoAnt = selectAñoIncial - 1;

        // Obtener el nombre de las propiedades según el año seleccionado
        const nombreZona = `zona`;
        const ventasKey = `Ventas${selectAñoIncial}`;
        const costoKey = `Costo${selectAñoIncial}`;
        const presupuestoKey = `Presupuesto${selectAñoIncial}`;
        const AñoAnterior = `Ventas${converAñoAnt}`;

        const data = rows.map((row, index) => {
            const nombreVende = row[nombreZona];
            const ventas = row[ventasKey];
            const ventasAñoAnterior = row[AñoAnterior];
            const costo = row[costoKey];
            const presupuesto = row[presupuestoKey];
            let tendencia = 0;

            if (index >= 1) {
                const ventasAnterior = +rows[index - 1][ventasKey];
                const presupuestoAnterior = +rows[index - 1][presupuestoKey];
                const diferencia = (presupuestoAnterior - ventasAnterior);
                tendencia = diferencia + presupuesto;
            } else {
                tendencia = presupuesto;
            }

            return {
                name: nombreVende,
                Ventas: ventas,
                ventasAnt: ventasAñoAnterior,
                Costo: costo,
                Presupuesto: presupuesto,
                Tendencia: tendencia
            };
        });

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params) => {
                    let content = `${params[0].name}<br/>`;
                    params.forEach(param => {
                        content += `${param.seriesName}: ${new Intl.NumberFormat().format(param.value)}<br/>`;
                    });
                    return content;
                }
            },
            legend: {
                data: ['Ventas', 'Ventas Año Anterior', 'Costo', 'Presupuesto', 'Tendencia'],
                top: 'top'
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                // axisLabel: {
                //     fontSize: 15
                // }
                axisLabel: {
                    // interval: 0, 
                    rotate: 60,
                    formatter: function (value) {
                        // Truncar si el nombre es más largo de 10 caracteres y agregar "..."
                        return value.length > 10 ? value.slice(0, 5) + '...' : value;
                    }
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {

                    // fontSize: 15,
                    formatter: (value) => {
                        if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                        return value; // Valor original si no supera los miles
                    }
                }
            },
            series: [
                {
                    name: 'Ventas',
                    type: 'bar',
                    data: data.map(item => item.Ventas),
                    // itemStyle: {
                    //     color: '#0A6EBD'
                    // }
                },
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [rows, selectAñoIncial]);

    return (
        <div>
            <Box sx={{ marginTop: "15px", width: "100%", height: "400px" }} ref={chartRef}></Box>
        </div>
    );
};

export default GraficoZona;
